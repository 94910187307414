import React from "react";

export default (props) => {
	return (
		<div className={`icon ${props.iconClass ? props.iconClass : ""}`}>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="11.966"
				height="8.647"
				viewBox="0 0 11.966 8.647"
			>
				<path
					d="M-4825.737-2210.229l3.318,3.318,5.819-5.819"
					transform="translate(4827.151 2214.144)"
					fill="none"
					stroke="#fff"
					strokeLinecap="round"
					strokeWidth="2"
				/>
			</svg>
		</div>
	);
};
