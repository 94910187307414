import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { AppBar } from "@mui/material";
import { Tabs } from "@mui/material";
import { Tab } from "@mui/material";

function a11yProps(index) {
	return {
		id: `scrollable-auto-tab-${index}`,
		"aria-controls": `scrollable-auto-tabpanel-${index}`,
	};
}

function TabPanel(props) {
	const { content, value, wrapperClasses, iSingleTab, index, ...other } = props;

	return (
		<div
			className={wrapperClasses}
			role="tabpanel"
			hidden={iSingleTab ? false : value !== index}
			id={`scrollable-auto-tabpanel-${index}`}
			aria-labelledby={`scrollable-auto-tab-${index}`}
			{...other}
		>
			{(value === index || iSingleTab) && (
				<div className="tab__content">{content ? content() : ""}</div>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

/**
 * Custom tabs component
 *
 * @param {Object.<string, any>} props
 * @param {[]} props.tabs
 * @param {number} tabs[].id - Tab id
 * @param {string} tabs[].label - Tab label
 * @param {string} tabs[].iconClasses - Tab label iconClasses, Default: "",
 * @param {React.ReactNode} tabs[].additionalLabel - Renders after label
 * @param {string} props.wrapperClasses - Tabs component wrapper element classes, Default: ""
 * @param {string} props.tabClasses - Individual tab classes, Default: ""
 * @param {string} props.tabsClasses - Tabs wrapper classes, Default: "mb-regular"
 * @param {string} props.tabListClasses - Tab list wrapper classes, Default: ""
 * @param {number} props.initialTabValue - Which tab should be selected by default, Default: 0
 * @param {boolean} props.triggerInitial - Whether the current tab should reinit
 * @param {function} [props.customTabChange] - Triggered on tab change, optional
 *
 * @return {React.FC} Custom tabs component
 */

const CustomTabs = ({
	tabs,
	wrapperClasses = "",
	tabClasses = "",
	tabsClasses = "mb-regular",
	tabListClasses = "",
	initialTabValue = 0,
	triggerInitial = false,
	customTabChange,
	isTabPart = false,
	acceptSingle = false,
	wrapperClassesAppBar = "px-24",
}) => {
	const [tabValue, setTabValue] = useState(initialTabValue);

	useEffect(() => {
		setTabValue(initialTabValue);
	}, [initialTabValue, triggerInitial]);

	const handleTabChange = (_, newValue) => {
		setTabValue(newValue);
		customTabChange && customTabChange(newValue);
	};

	if (!tabs || tabs.size === 0) {
		return "";
	}

	const renderLabel = (label, iconClasses = "", id, isSingle) => {
		if (!isSingle || acceptSingle) {
			return (
				<div className={`d-flex flex-row  align-items-center ${iconClasses}`}>
					{isTabPart && id === tabValue && (
						<div className="tab-part--selected circle"></div>
					)}
					<div> {label}</div>

					{/* {additionalLabel && (
						<div className={`${additionalLabelClasses}`}>{additionalLabel}</div>
					)} */}
				</div>
			);
		}

		return <div className="h-20p"></div>;
	};

	return (
		<div className={`flex-column  w-100 ${wrapperClasses}`}>
			<AppBar
				classes={{
					root: `tabs__wrapper border-b-1 border-n150 ${wrapperClassesAppBar}`,
				}}
				position="static"
				color="default"
			>
				<Tabs
					value={tabValue}
					onChange={handleTabChange}
					textColor="inherit"
					variant="scrollable"
					scrollButtons="auto"
					aria-label="simple tabs example"
					classes={{
						root: `tabs ${tabsClasses}`,
						flexContainer: `${tabListClasses}`,
						indicator: "tabs__indicator",
					}}
				>
					{tabs.map((tab, index) => {
						if (tab && !!!tab.hide) {
							return (
								<Tab
									key={tab.id}
									disableRipple={true}
									value={tab.id}
									disabled={tab.disabled}
									classes={{
										root: `${
											isTabPart ? "tab-part" : "tab"
										} selector-line ${tabClasses}`,
										selected: `${
											isTabPart ? "tab-part--selected" : "tab--selected"
										}`,
									}}
									label={renderLabel(
										tab.label,
										tab.iconClasses,
										tab.id,
										tabs.length === 1
									)}
									{...a11yProps(tab.id)}
								/>
							);
						}
						return "";
					})}
				</Tabs>
			</AppBar>
			{tabs.map((tab) => {
				if (tab && !!!tab.hide) {
					return (
						<TabPanel
							value={tabValue}
							index={tab.id}
							key={tab.id}
							content={tab.content}
							wrapperClasses={tab.wrapperClasses}
							iSingleTab={tabs.length === 1}
						/>
					);
				}
				return "";
			})}
		</div>
	);
};

export default CustomTabs;
