import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import localize, { LocKeys } from "../../constants/localizations";
import BimifyHeartIcon from "../../shared/icons/BimifyHeartIcon";
import CustomSnackbar from "../../shared/CustomSnackbar/CustomSnackbar";
import Modal from "../Modal";
import { clearAuthRequestState } from "../../actions";
import { alertTypeConstants } from "../../constants/constants";
import { useNavigate } from "react-router-dom-v5-compat";

/**
 * Signup success response - open Thank you modal
 * Signup error response - open snackbar
 * @param {*} props
 * @returns
 */
const SignupRequestModals = (props) => {
	const {
		// From state
		request,
		action,
		errorResponse,
		errorMessage,
		userEmail,
		clearAuthRequestState,
	} = props;
	const navigate = useNavigate();

	const [showError, setShowError] = useState(!action && request);
	const [showSuccessModal, setShowSuccessModal] = useState(action && request);

	useEffect(() => {
		if (request) {
			if (!action) {
				setShowError(true);
			} else {
				setShowSuccessModal(true);
			}
		}
	}, [action, request]);

	const onCloseSuccess = () => {
		navigate("/login");
		clearAuthRequestState();
	};

	const errorOnClose = () => {
		setShowError(false);
		clearAuthRequestState();
	};

	return (
		<>
			<Modal
				show={showSuccessModal}
				title={`${localize(LocKeys.THANK_YOU)}!`}
				onClose={onCloseSuccess}
			>
				<div className="text-center">
					<div className="justify-content-center mb-regular">
						<BimifyHeartIcon />
						<h3 className="mt-regular">
							{localize(LocKeys.THANK_YOU_FOR_CHOOSING)}
						</h3>
					</div>
					<div className="mb-regular">
						{localize(LocKeys.REQUEST_CONFIRMATION_LINK)}&nbsp;
						{<strong>{userEmail}</strong>}.&nbsp;
						{localize(LocKeys.REQUEST_VERIFY_EMAIL)}
					</div>
				</div>
			</Modal>

			<CustomSnackbar
				open={showError}
				onClose={errorOnClose}
				type={alertTypeConstants.ERROR}
				title={localize(LocKeys.ERROR)}
			>
				{errorResponse || errorMessage}
			</CustomSnackbar>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		action: state.getIn(["authentication", "action"]),
		request: state.getIn(["authentication", "request"]),
		userEmail: state.getIn(["user", "tempUserData", "email"]),
		errorResponse: state.getIn(["authentication", "errorResponse"]),
		errorMessage: state.getIn(["authentication", "errorMessage"]),
	};
};
export default connect(mapStateToProps, { clearAuthRequestState })(
	SignupRequestModals
);
