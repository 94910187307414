import React, { useEffect } from "react";
import UTIF from "utif";
import Lightbox from "react-awesome-lightbox";
import "react-awesome-lightbox/build/style.css";

/**
 * File viewer - Lightbox library
 * @param {*} props
 * @returns
 */
const FileViewer = (props) => {
	const { previewLink, name } = props;

	//Convert TIFF formats (tiff format isn't supported for web browser)
	useEffect(() => {
		UTIF.replaceIMG();
	}, [previewLink]);

	const onClose = () => {
		console.log("On close..");
	};

	return (
		<div className="viewer--file">
			<Lightbox image={previewLink} title={name} onClose={onClose} />
		</div>
	);
};

export default FileViewer;
