import React from "react";

export default (props) => {
	return (
		<div className={`icon ${props.iconClass ? props.iconClass : ""}`}>
			<svg
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M2 9.21053H13.5789V11.3158H2V9.21053ZM2 5H13.5789V7.10526H2V5ZM2 13.4211H9.36842V15.5263H2V13.4211ZM20.5158 11.2421L16.0421 15.7053L13.8105 13.4737L12.3263 14.9579L16.0421 18.6842L22 12.7263L20.5158 11.2421Z"
					fill="#F79400"
				/>
			</svg>
		</div>
	);
};
