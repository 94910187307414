import React, { useMemo } from "react";
import ComponentTooltip from "../../../../../../shared/components/component-tooltip/ComponentTooltip";
import IconWrapper from "../../../../../../shared/icons/IconWrapper";
import RevitIcon from "../../../../../../shared/icons/deliveryFormats/RevitIcon";
import TrashIcon from "../../../../../../shared/icons/actions/TrashIcon";
import { convertToMBorGB } from "../../../../../../shared/components/Widget/upload-file/UploadFile";
import { connect } from "react-redux";
import localize, { LocKeys } from "../../../../../../constants/localizations";

const SingleFile = ({
	fileName,
	fileSize,
	removeTempRevitTemplateFile = null,
	downloadFile = null,
	version,
}) => {
	const renderVersion = useMemo(() => {
		return version ? `, ${localize(LocKeys.VERSION)}: ${version}` : "";
	}, [version]);
	return (
		<div className="border-1 border-n150 p-s border-radius-regular d-flex align-items-center w-25">
			<div className="row row--m-gutters">
				<div className="col col-70">
					<div className="p-s mr-s">
						<IconWrapper icon={<RevitIcon />} size={24} />
					</div>
					<div className="d-flex flex-column w-100">
						<ComponentTooltip content={fileName} direction={"top"}>
							<div className="body-font body-font--small regular text-overflow-ellipsis">
								{fileName}
							</div>
						</ComponentTooltip>
						<div className="body-font--xs color-n300">
							{convertToMBorGB(fileSize)}
							{renderVersion}
						</div>
					</div>
				</div>
				<div className="col col-30 justify-content-end">
					{removeTempRevitTemplateFile && (
						<button
							onClick={(e) => {
								e.stopPropagation();
								removeTempRevitTemplateFile();
							}}
							type="button"
							className="btn btn--icon--solo btn--tertiary border-none bg-none p-s ml-auto"
						>
							<IconWrapper
								size="24"
								icon={
									<TrashIcon iconClass="d-flex icon--target-fill fill--red" />
								}
							/>
						</button>
					)}
					{downloadFile && (
						<button
							onClick={(e) => {
								e.stopPropagation();
								downloadFile();
							}}
							type="button"
							className="btn btn--download-24 btn--icon--solo btn--tertiary border-none bg-none ml-auto btn--icon btn--icon--n300 p-s btn--icon--before"
						/>
					)}
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state, ownProps) => {
	const { isEdit = false } = ownProps;

	return {
		version: isEdit
			? state.getIn([
					"template",
					"tempRevitTemplate",
					"template",
					"fileData",
					"version",
				])
			: state.getIn(["template", "revitTemplate", "version"]),
		fileName: isEdit
			? state.getIn([
					"template",
					"tempRevitTemplate",
					"template",
					"fileData",
					"file",
					"name",
				])
			: state.getIn(["template", "revitTemplate", "file", "name"]),

		fileSize: isEdit
			? state.getIn([
					"template",
					"tempRevitTemplate",
					"template",
					"fileData",
					"file",
					"size",
				])
			: state.getIn(["template", "revitTemplate", "file", "size"]),
	};
};

export default connect(mapStateToProps, {})(SingleFile);
