import { statusConstants } from "../../../../constants/constants";
import { isBimify } from "../../../../utils/permission/user";

export const canEditOffer = (status) => {
	const isRequested = status === statusConstants.REQUESTED;
	const isOffered = status === statusConstants.OFFERED;
	const isIncomplete = status === statusConstants.INCOMPLETE;

	return isBimify() || isRequested || isOffered || isIncomplete;
};
