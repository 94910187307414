import { Map, fromJS } from "immutable";
import chatConstants from "../constants/chatConstants";

/**
 * CHAT reducer redux
 */
const initialState = Map({
	chat: Map(),
	unreadTotal: null,
	actionSuccess: false,
	actionFailure: false,
	hasActiveChatRoom: false,
});

export default (state = initialState, action) => {
	switch (action.type) {
		case chatConstants.ROOM_CONVERSATION:
			return state
				.setIn(["chat", "messages"], fromJS(action.data.result))
				.setIn(["chat", "meta"], fromJS(action.data.meta));

		case chatConstants.UNREAD_TOTAL_MESSAGES:
			return state.setIn(["chat", "unreadTotal"], fromJS(action.data.result));

		case chatConstants.UNREAD_ROOM_MESSAGES:
			return state.setIn(
				["chat", "unreadByRoom", fromJS(action.data.room)],
				fromJS(action.data.result)
			);

		case chatConstants.ACTION_SUCCESS:
			return state.set("chatAction", true).set("chatRequest", true);

		case chatConstants.SET_HAS_ACTIVE_CHAT_ROOM:
			return state.set("hasActiveChatRoom", action.hasRoom);

		case chatConstants.SET_ACTIVE_ROOM:
			return state.set("activeChatRoom", fromJS(action.data));

		case chatConstants.ACTION_FAILURE:
			return state
				.set("chatAction", false)
				.set("chatRequest", true)
				.set("failureMessage", fromJS(action.error));

		default:
			return state;
	}
};
