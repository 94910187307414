import React, { useMemo, useState } from "react";
import renderError from "./renderError";
import clsx from "clsx";
import CustomLabel from "./CustomLabel";
import { Checkbox, Chip, TextField, Autocomplete } from "@mui/material";
import CheckmarkIcon from "../../shared/icons/CheckmarkIcon";
import CloseIcon from "../../shared/icons/actions/CloseIcon";
import { useEffect } from "react";
import { ExpandMoreSharp } from "@mui/icons-material";
import ClearIcon from "@mui/icons-material/Clear";
import TrashIcon from "../../shared/icons/actions/TrashIcon";
import IconWrapper from "../../shared/icons/IconWrapper";
import { getInputFormatIcon } from "../../utils/getTypeIcon";
import { colorConstants } from "../../constants/constants";

/**
 * Component for select field
 */

export default ({
	input,
	label = "",
	labelInfoContent = "",
	labelNote = "",
	required = true,
	meta: { touched, error },
	options,
	size = "w-100",
	classField,
	multiple = false,
	initialValue = multiple ? [] : null,
	valueClasses = "",
	customError = false,
	disableClearable = false,
	customErrorLabel,
	placeholder = "",
	customOnChange,
	disabled = false,
	showCloseIcon = false,
	id = "autocomplete",
	customRenderOption,
	groupBy = false,
	buildingList = false,
	dropdownHeight = "350px",
	fullWidth = "true",
	multipleClasses = "height-unset",
	isKeyStrigify = false,
	customOnChangeItem = null,
	limitTags = 3,
}) => {
	const { onChange } = input;
	const emptyVal = multiple ? [] : null;
	const [val, setVal] = useState(options.length > 0 ? initialValue : emptyVal);

	useEffect(() => {
		if (
			(val === null && initialValue) ||
			(initialValue && val !== initialValue && buildingList)
		) {
			let find = !multiple
				? options.find((option) => option.value === initialValue)
				: options.filter(({ value }) => initialValue.includes(value)) || [];

			if (!!find) {
				setVal(options.length > 0 ? initialValue : null);
			}
		}

		if (initialValue === null && val !== null) {
			handleChange(null, null);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [initialValue, label, options, val, groupBy, buildingList]);

	let errorExist = false;
	if (touched && error) {
		errorExist = true;
	}

	const handleChange = (_, value) => {
		let newValue;

		if (Array.isArray(value)) {
			newValue = value.map((el) => el.value);
		} else {
			newValue = value?.value || emptyVal;
		}

		setVal(newValue);

		customOnChange && customOnChange(newValue);

		customOnChangeItem && customOnChangeItem(value || null);
		onChange && onChange(newValue);
	};

	const getOptionSelected = (option, { value }) => {
		return isKeyStrigify
			? option.value?.toString() === value?.toString()
			: +option.value === +value;
	};

	const renderInput = (params) => {
		return (
			<TextField
				{...params}
				variant="standard"
				InputProps={{
					...params.InputProps,
					classes: {
						root: `strip-native-styles my-neg-xxs`,
						input: `strip-native-styles
				    `,
					},
				}}
				placeholder={placeholder ? placeholder : label ? label : ""}
			/>
		);
	};

	const renderOption = (props, option, { selected }) => {
		if (customRenderOption) {
			return customRenderOption(props, option);
		} else if (multiple) {
			return (
				<li {...props} key={option.value}>
					<Checkbox
						checkedIcon={<CheckmarkIcon />}
						classes={{
							root: `checkbox
								`,
							disabled: "checkbox--disabled",
							checked: "checkbox--selected",
						}}
						checked={selected}
					/>
					{option.label}
				</li>
			);
		} else if (groupBy) {
			return (
				<li {...props} key={option.value}>
					<div className={`dropdown-item--sub`}>
						<div className="w-100">
							{getOptionLabel(option)}{" "}
							{option?.note && (
								<span className="label__note">({option.note})</span>
							)}
						</div>
					</div>
				</li>
			);
		} else {
			return option?.showIcon ? (
				<li {...props} key={option.value}>
					<div className={`d-flex align-items-center w-100`}>
						<IconWrapper
							size="24"
							wrapperClasses="mr-s"
							icon={getInputFormatIcon(option.iconType)}
						/>

						<div className="w-100">{getOptionLabel(option)}</div>
					</div>
				</li>
			) : (
				<li {...props} key={option.value}>
					<div className="w-100">
						{getOptionLabel(option)}
						{option?.note && (
							<span className="label__note">({option.note})</span>
						)}
					</div>
				</li>
			);
		}
	};

	const getOptionLabel = (option) => {
		return option.label || "";
	};

	const handleDelete = (value) => {
		let tempVal = val.filter((el) => el !== value);

		setVal(tempVal);
		customOnChange && customOnChange(Object.assign([], tempVal));
	};

	const renderTags = (tagValue, getTagProps) => {
		return tagValue.map((option, index) => (
			<Chip
				label={option.label}
				classes={{
					root: "chip__root chip__root--autocomplete pr-0 m-xxs",
					label: "chip__label",
					deletable: "chip--deletable",
					deleteIcon: "chip__delete-icon",
				}}
				onMouseDown={(event) => {
					event.stopPropagation();
				}}
				onClick={() => handleDelete(option.value)}
				onDelete={() => handleDelete(option.value)}
				{...getTagProps({ index })}
				disabled={val.indexOf(option) !== -1}
				deleteIcon={<CloseIcon iconClass="d-flex chip__delete-icon px-s" />}
			/>
		));
	};

	let selection = !multiple
		? val && options.find(({ value }) => value === val)
		: options.filter(({ value }) => val.includes(value)) || [];

	const autoCompletePopper = useMemo(() => {
		return multiple ? "" : "autocomplete-popper ";
	}, [multiple]);

	return (
		<div
			className={clsx(
				"form__input form__input--select",
				size,
				classField,
				autoCompletePopper
			)}
		>
			{label && (
				<CustomLabel
					id={id}
					label={label}
					required={required}
					infoContent={labelInfoContent}
					note={labelNote}
				/>
			)}

			<Autocomplete
				disablePortal
				id={id}
				ListboxProps={{
					style: { maxHeight: dropdownHeight },
				}}
				options={options}
				limitTags={limitTags}
				disableClearable={disableClearable}
				fullwidth={fullWidth}
				getOptionLabel={getOptionLabel}
				renderInput={renderInput}
				renderOption={renderOption}
				clearIcon={
					showCloseIcon ? (
						<IconWrapper size={"24"} icon={<TrashIcon iconClass="d-flex" />} />
					) : (
						<ClearIcon fontSize="small" />
					)
				}
				popupIcon={<ExpandMoreSharp style={{ color: colorConstants.N300 }} />}
				groupBy={(option) => groupBy && option.subHeader}
				autoHighlight={true}
				onChange={handleChange}
				isOptionEqualToValue={getOptionSelected}
				value={selection || null}
				multiple={multiple}
				disableCloseOnSelect={multiple}
				getOptionDisabled={(option) => option?.disabled === true}
				renderTags={renderTags}
				classes={{
					root: `field__root ${multiple ? multipleClasses : ""}
					${disabled ? "field__root--disabled" : ""}
					${(multiple ? val.length > 0 : !!val) ? "field__root--complete" : ""}
					${errorExist ? "field__root--error" : ""}
					${valueClasses}`,
					listbox: "dropdown-list",
					option: "dropdown-item",
					popper: "autocomplete__popper",
					inputRoot: "autocomplete__input",
				}}
			/>
			{renderError(error, touched, customError, customErrorLabel)}
		</div>
	);
};
