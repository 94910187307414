import { Map, fromJS } from "immutable";
import { symbolConstants } from "../constants/symbolConstants";

/**
 * SYMBOL reducer redux
 */

const initialState = Map({
	symbolsList: Map(),
	symbolsMeta: Map(),
	symbol: Map(),
	tempSymbol: Map({
		name: "",
		familyName: "",
		discipline: null,
		disciplineComponentCategory: null,
		category: null,
		file: null,
		imageURL: null,
		fileData: null,
	}),
	action: false,
	request: false,
	errorResponse: "",
	stats: null,
	successMessage: "Success!",
	errorMessage: "Something went wrong",
});

export default (state = initialState, action) => {
	/**
	 * SET temp symbol data
	 * @param {object} data - symbol data
	 * @returns
	 */
	const setTempSymbol = (data) => {
		const tempSymbol = {
			id: data.id,
			family: data.familyId,
			imageURL: data.fileUrl,
			file: data.file,
			name: data.name,
			disciplineComponentCategory:
				data?.disciplineComponentCategory?.id || null,
			discipline: data?.disciplineComponentCategory?.discipline?.id || null,
			category:
				data?.disciplineComponentCategory?.componentCategory?.id || null,
			symbolFunction: data?.componentCategoryFunction?.id || null,
		};
		return state.set("tempSymbol", fromJS(tempSymbol));
	};

	switch (action.type) {
		case symbolConstants.GET_SYMBOLS:
			return state
				.set("symbolsList", fromJS(action.data.result?.result))
				.set("symbolsMeta", fromJS(action.data.meta));

		case symbolConstants.GET_SYMBOL:
			return state.set("symbol", fromJS(action.data));

		case symbolConstants.SET_TEMP_SYMBOL:
			return setTempSymbol(action.data);

		case symbolConstants.UPDATE_TEMP_FIELD_SYMBOL:
			const { fieldKey, value } = action.data;

			return state.setIn(["tempSymbol", fieldKey], value);

		case symbolConstants.CLEAR_TEMP_SYMBOL:
			return state.set("tempSymbol", initialState.get("tempSymbol"));

		case symbolConstants.CLEAR_SINGLE_SYMBOL:
			return state.set("symbol", initialState.get("symbol"));

		case symbolConstants.SYMBOL_ACTION_GET_SUCCESS:
			return state
				.set("successMessage", fromJS(action.data))
				.set("action", true)
				.set("request", true);

		case symbolConstants.SYMBOL_ACTION_GET_FAILURE:
			return state
				.set("action", false)
				.set("request", true)
				.set("errorResponse", fromJS(action.error));

		case symbolConstants.CLEAR_SYMBOL_REQUEST_STATE:
			return state
				.set("action", false)
				.set("request", false)
				.set("errorMessage", "")
				.set("successMessage", "");

		case symbolConstants.SET_SYMBOL_TEMP_FILE:
			return state.setIn(["tempSymbol", "fileData"], action.data);

		case symbolConstants.SET_SYMBOL_TEMP_FILE_URL:
			return state.setIn(["tempSymbol", "imageURL"], action.data);

		default:
			return state;
	}
};
