import io from "socket.io-client";

export const socket = io(window.__ENV__.REACT_APP_API, {
	transports: ["websocket"],
});

export const getSocket = () => {
	try {
		return io(window.__ENV__.REACT_APP_API, {
			transports: ["websocket"],
		});
	} catch (error) {
		console.log("error", error);
	}
};

export const emitWebSocket = (type, data) => {
	const socket = getSocket();

	switch (type) {
		case "message":
			socket.emit(type, data);
			break;
		case "seenRoomMessages":
			socket.emit(type, data);
			break;
		case "countUnreadTotal":
			socket.emit(type, data);
			break;

		case "countUnreadRoom":
			socket.emit(type, data);
			break;
		default:
			socket.emit(type, data);
			break;
	}
};
