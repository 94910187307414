import { combineReducers } from "redux-immutable";
import { reducer } from "redux-form/immutable";
import authReducer from "./authReducer";
import companyReducer from "./companyReducer";
import userReducer from "./userReducer";
import buildingReducer from "./buildingReducer";
import projectReducer from "./projectReducer";
import administrationReducer from "./administrationReducer";
import invoiceReducer from "./invoiceReducer";
import companySpecsReducer from "./companySpecsReducer";
import chatReducer from "./chatReducer";
import projectTempReducer from "./projectTempReducer";
import fileReducer from "./fileReducer";
import globalWidgetReducer from "./globalWidgetReducer";
import jobReducer from "./jobReducer";
import globalReducer from "./globalReducer";
import lodSpecificationReducer from "./lodSpecificationReducer";
import tempAdministrationReducer from "./tempAdministrationReducer";
import priceReducer from "./priceReducer";
import dashboardReducer from "./dashboardReducer";
import textEditorReducer from "./textEditorReducer";
import manualReducer from "./manualReducer";
import symbolReducer from "./symbolReducer";
import queryParameterReducer from "./queryParameterReducer";
import ticketingReducer from "./ticketingReducer";
import familyReducer from "./familyReducer";
import templateReducer from "./templateReducer";

/**
 * Main reducer used for combine all others
 */

const appReducer = combineReducers({
	form: reducer,
	authentication: authReducer,
	company: companyReducer,
	companySpecs: companySpecsReducer,
	user: userReducer,
	building: buildingReducer,
	project: projectReducer,
	administration: administrationReducer,
	projectTemp: projectTempReducer,
	invoice: invoiceReducer,
	chat: chatReducer,
	file: fileReducer,
	global: globalReducer,
	globalWidget: globalWidgetReducer,
	lodSpecification: lodSpecificationReducer,
	tempAdministration: tempAdministrationReducer,
	job: jobReducer,
	dashboard: dashboardReducer,
	price: priceReducer,
	textEditor: textEditorReducer,
	manuals: manualReducer,
	symbol: symbolReducer,
	queryParameter: queryParameterReducer,
	ticketing: ticketingReducer,
	families: familyReducer,
	template: templateReducer,
});

const rootReducer = (state, action) => {
	// when a logout action is dispatched it will reset redux state
	if (action.type === "USER_LOGGED_OUT") {
		state = undefined;
	}

	return appReducer(state, action);
};

export default rootReducer;
