import React from "react";
import localize, { LocKeys } from "../../../../../../constants/localizations";
import { deleteParameter } from "../../../../../../actions";
import { connect } from "react-redux";

const DeleteParameter = ({
	removeModal,
	toggleRemoveModal,
	removeParameter,
	deleteParameter,
}) => {
	return (
		<div>
			<div>
				{localize(LocKeys.ARE_YOY_SURE_DELETE_MESSAGE)}
				<div className="chip justify-content-center align-items-center text--capitalize d-inline-flex mx-s">
					<strong>{removeModal.type}</strong>
				</div>
				{localize(LocKeys.PARAMETER).toLowerCase()}?
			</div>
			<div className="form-contents__wrapper d-flex justify-content-end mt-32">
				<button
					type="button"
					onClick={() => toggleRemoveModal()}
					className="btn btn--secondary-error"
				>
					{localize(LocKeys.CANCEL)}
				</button>
				<button
					type="button"
					onClick={(e) => {
						deleteParameter(removeModal.deleteIds, () => {
							removeParameter(
								removeModal.id,
								removeModal.categoryId,
								removeModal.lodsValue
							);
						});
						toggleRemoveModal();
					}}
					className="btn btn--error ml-m"
				>
					{localize(LocKeys.DELETE)}
				</button>
			</div>
		</div>
	);
};

export default connect(null, { deleteParameter })(DeleteParameter);
