import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import reducers from "../reducers";
// Logger only used in dev env for trace
// import logger from "redux-logger";

/**
 * Redux store
 */

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
	reducers,
	// composeEnhancers(applyMiddleware(thunk, logger))
	composeEnhancers(applyMiddleware(thunk))
);

export default store;
