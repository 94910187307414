import React, { useEffect } from "react";
import RevitTemplateForm from "./RevitTemplateForm";
import {
	getRevitTemplate,
	updateRevitTemplate,
	updateRevitTemplateMessage,
} from "../../../../../actions";
import { connect } from "react-redux";
import { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom-v5-compat";

const EditRevitTemplate = ({
	onCancel,
	getRevitTemplate,
	updateRevitTemplate,
	updateRevitTemplateMessage,
}) => {
	const { templateId } = useParams();
	const navigate = useNavigate();

	useEffect(() => {
		if (templateId) getRevitTemplate(templateId);
	}, [getRevitTemplate, templateId]);

	const cancel = useCallback(
		(isCancel = false) => {
			if (onCancel) {
				onCancel(isCancel);
			} else {
				navigate(-1);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[onCancel]
	);

	const onSubmit = (data, cb = () => {}) => {
		updateRevitTemplate(templateId, data, () => {
			cb(templateId, data.companyId, () => {
				updateRevitTemplateMessage(templateId, () => {
					cancel();
				});
			});
		});
	};

	return (
		<RevitTemplateForm
			onSubmit={onSubmit}
			editView={true}
			onCancel={() => cancel(true)}
			formName={"editRevitTemplateForm"}
		/>
	);
};

export default connect(null, {
	getRevitTemplate,
	updateRevitTemplate,
	updateRevitTemplateMessage,
})(EditRevitTemplate);
