import React, { lazy } from "react";
import { Router, Switch } from "react-router-dom";
import PrivateRoute from "./route/PrivateRoute";
import PublicRoute from "./route/PublicRoute";
import Login from "./auth/Login";
import ResetPassword from "./auth/ResetPassword";
import localize, { LocKeys } from "../constants/localizations";
import Registration from "./auth/Registration";
import "./app.scss";
import Page404 from "../shared/components/default/Page404";
import history from "../history";
import CustomWidget from "../shared/components/Widget/CustomWidget";
import { connect } from "react-redux";
import {
	getPermissions,
	setItemToLocalStorage,
} from "../utils/local-storage.util";
import {
	permissions,
	permissionsArray,
} from "../constants/permissionConstants";
import { getPaths } from "../utils/sidebar";
import LabelingTool from "./labeling-tool/LabelingTool";
import ServerError from "../shared/components/default/ServerError";
import LodContentView from "./administration/lod-specification/lod-content/single-view/LodContentView";
import { checkUserPriority, isBimify } from "../utils/permission/user";
import { PRIORITY_USER_ROLE } from "../constants/constants";
import EditSymbol from "./administration/symbol-categorisation/symbols/crud/EditSymbol";
import EditFamily from "./administration/symbol-categorisation/families/crud/EditFamily";
import EditRevitTemplate from "./administration/templates/revit/crud/EditRevitTemplate";
import { CompatRouter } from "react-router-dom-v5-compat";
import SinglePackage from "./dashboard/crud/single-view/SinglePackage";
import PublicSinglePackage from "./dashboard/public/PublicSinglePackage";

// const SinglePackage = lazy(
// 	() => import("./dashboard/crud/single-view/SinglePackage")
// );
const CreatePackage = lazy(() => import("./dashboard/crud/CreatePackage"));
const Dashboard = lazy(() => import("./dashboard/Dashboard"));
const Administration = lazy(() => import("./administration/Administration"));
const ViewLodSpec = lazy(() => import("./lod-specification/crud/ViewLodSpec"));
const EditLodSpec = lazy(() => import("./lod-specification/crud/EditLodSpec"));
const LodSpecList = lazy(() => import("./lod-specification/LodSpecList"));
const CreateLodSpec = lazy(
	() => import("./lod-specification/crud/CreateLodSpec")
);
const CreateJob = lazy(() => import("./job/crud/CreateJob"));
const JobList = lazy(() => import("./job/JobList"));
const Settings = lazy(() => import("./settings/Settings"));
const CreateOrder = lazy(() => import("./orders/crud/CreateOrder"));
const CreateBuilding = lazy(() => import("./buildings/crud/CreateBuilding"));
const SingleOrder = lazy(() => import("./orders/single-view/SingleOrder"));
const Buildings = lazy(() => import("./buildings/Buildings"));
const OrderList = lazy(() => import("./orders/OrderList"));
const InvoiceList = lazy(() => import("./invoices/InvoiceList"));
const CreateUser = lazy(() => import("./users-management/crud/CreateUser"));
const UserInfo = lazy(() => import("./users-management/UserInfo"));
const EditUser = lazy(() => import("./users-management/crud/EditUser"));
const EditCompany = lazy(() => import("./company-management/crud/EditCompany"));
const SingleCompany = lazy(() => import("./company-management/SingleCompany"));
const CreateCompany = lazy(
	() => import("./company-management/crud/CreateCompany")
);
const CompaniesList = lazy(() => import("./company-management/CompaniesList"));
const SingleBuilding = lazy(
	() => import("./buildings/single-view/SingleBuilding")
);
const Docs = lazy(() => import("./docs/Docs"));

const CreateManual = lazy(
	() => import("./administration/manuals/crud/CreateManual")
);
const ViewManual = lazy(
	() => import("./administration/manuals/crud/ViewManual")
);
const SingleSymbol = lazy(
	() =>
		import(
			"./administration/symbol-categorisation/symbols/single-view/SingleSymbol"
		)
);
const CreateSymbol = lazy(
	() =>
		import("./administration/symbol-categorisation/symbols/crud/CreateSymbol")
);

const CreateFamily = lazy(
	() =>
		import("./administration/symbol-categorisation/families/crud/CreateFamily")
);

const SingleFamily = lazy(
	() =>
		import(
			"./administration/symbol-categorisation/families/single-view/SingleFamily"
		)
);

const SingleLegalView = lazy(
	() => import("./administration/terms-and-condition/SingleLegalView")
);
//Ticketing
const CreateTicket = lazy(() => import("./ticketing/crud/CreateTicket"));
const Ticketing = lazy(() => import("./ticketing/Ticketing"));
const SingleTicket = lazy(() => import("./ticketing/single-view/SingleTicket"));
//Templates
const SingleRevitTemplate = lazy(
	() =>
		import("./administration/templates/revit/single-view/SingleRevitTemplate")
);
const CreateRevitTemplate = lazy(
	() => import("./administration/templates/revit/crud/CreateRevitTemplate")
);
const SingleJob = lazy(() => import("./job/crud/single-view/SingleJob"));

// const LodContentView = lazy(() =>
// 	import(
// 		"./administration/lod-specification/lod-content/single-view/LodContentView"
// 	)
// );

/**
 * Root APP component - define routes
 *
 * Render topbar & sidebar
 * Dynamically render components
 */
const App = (props) => {
	const loggedUserPermissions = getPermissions();
	const {
		accountSettings,
		customers,
		buildings,
		jobs,
		offers,
		invoices,
		lodSpecifications,
		dashboard,
		tickets,
	} = loggedUserPermissions ? loggedUserPermissions : {};

	const { buildingPath, jobPath, dashboardPath } = getPaths();

	if (!!history.location.search)
		setItemToLocalStorage("redirectToPath", JSON.stringify(history.location));

	return (
		<>
			<CustomWidget />
			<Router history={history}>
				<CompatRouter>
					<div className="bimify-app d-flex flex-row">
						<Switch>
							<PublicRoute
								exact
								path={"/server-error"}
								component={ServerError}
								title="Server error"
							/>
							<PublicRoute
								exact
								path="/register"
								component={Registration}
								title={localize(LocKeys.REGISTER)}
							/>
							<PublicRoute exact path={"/login"} component={Login} />
							{/* <PublicRoute exact path={"/signup"} component={Signup} /> */}
							<PublicRoute
								exact
								path={"/password-reset"}
								component={ResetPassword}
								title="Bimify"
							/>
							<PrivateRoute
								exact
								path={"/dashboard/new"}
								component={CreatePackage}
								requiredPermissions={dashboard?.permissions}
								permission={permissions.view}
								title="Create package"
							/>
							<PrivateRoute
								exact
								path={"/dashboard/:packageId"}
								component={SinglePackage}
								requiredPermissions={dashboard?.permissions}
								permission={permissions.view}
								title="Single view package"
							/>
							{dashboardPath.map((path) => (
								<PrivateRoute
									key={path}
									exact
									path={path}
									component={Dashboard}
									requiredPermissions={dashboard?.permissions}
									permission={permissions.view}
									title="Dashboard"
								/>
							))}

							<PrivateRoute
								exact
								path={"/settings/profile/edit"}
								component={EditUser}
								requiredPermissions={accountSettings?.permissions?.concat(
									customers?.permissions
								)}
								permission={permissions.view}
								title="Setting"
							/>
							<PrivateRoute
								exact
								path={"/settings/users/:userId/edit"}
								component={EditUser}
								requiredPermissions={accountSettings?.permissions?.concat(
									customers?.permissions
								)}
								permission={permissions.view}
								title="Setting"
							/>
							<PrivateRoute
								exact
								path={"/customers/:companyId/users/:userId/edit"}
								component={EditUser}
								requiredPermissions={accountSettings?.permissions?.concat(
									customers?.permissions
								)}
								permission={permissions.view}
								title="Setting"
							/>

							<PrivateRoute
								exact
								path={"/settings/users/new"}
								component={CreateUser}
								requiredPermissions={accountSettings?.permissions?.concat(
									customers?.permissions
								)}
								permission={permissions.view}
								title="Setting"
							/>
							<PrivateRoute
								exact
								path={"/customers/:companyId/users/new"}
								component={CreateUser}
								requiredPermissions={accountSettings?.permissions?.concat(
									customers?.permissions
								)}
								permission={permissions.view}
								title="Setting"
							/>

							<PrivateRoute
								exact
								priorityAccess={checkUserPriority(
									PRIORITY_USER_ROLE.PROJECT_LEAD
								)}
								requiredPermissions={accountSettings?.permissions?.concat(
									customers?.permissions
								)}
								permission={permissions.view}
								title="Revit Template - New template"
								path={"/settings/templates/revit/new"}
								component={CreateRevitTemplate}
							/>
							<PrivateRoute
								exact
								priorityAccess={checkUserPriority(
									PRIORITY_USER_ROLE.PROJECT_LEAD
								)}
								requiredPermissions={accountSettings?.permissions?.concat(
									customers?.permissions
								)}
								permission={permissions.view}
								title="Revit Template - New template"
								path={"/customers/:companyId/templates/revit/new"}
								component={CreateRevitTemplate}
							/>

							<PrivateRoute
								exact
								priorityAccess={checkUserPriority(
									PRIORITY_USER_ROLE.PROJECT_LEAD
								)}
								requiredPermissions={accountSettings?.permissions?.concat(
									customers?.permissions
								)}
								permission={permissions.view}
								path={"/settings/templates/revit/:templateId"}
								component={SingleRevitTemplate}
								title="Revit Template - Single view"
							/>
							<PrivateRoute
								exact
								priorityAccess={checkUserPriority(
									PRIORITY_USER_ROLE.PROJECT_LEAD
								)}
								requiredPermissions={accountSettings?.permissions?.concat(
									customers?.permissions
								)}
								permission={permissions.view}
								path={"/customers/:companyId/templates/revit/:templateId"}
								component={SingleRevitTemplate}
								title="Revit Template - Single view"
							/>

							<PrivateRoute
								exact
								requiredPermissions={accountSettings?.permissions?.concat(
									customers?.permissions
								)}
								priorityAccess={checkUserPriority(
									PRIORITY_USER_ROLE.PROJECT_LEAD
								)}
								permission={permissions.view}
								path={"/settings/templates/revit/:templateId/edit"}
								component={EditRevitTemplate}
								title="Revit Template - Edit template"
							/>
							<PrivateRoute
								exact
								requiredPermissions={accountSettings?.permissions?.concat(
									customers?.permissions
								)}
								priorityAccess={checkUserPriority(
									PRIORITY_USER_ROLE.PROJECT_LEAD
								)}
								permission={permissions.view}
								path={"/customers/:companyId/templates/revit/:templateId/edit"}
								component={EditRevitTemplate}
								title="Revit Template - Edit template"
							/>
							<PrivateRoute
								exact
								path={"/customers"}
								component={CompaniesList}
								requiredPermissions={customers?.permissions}
								permission={permissions.view}
								title="Customers"
							/>
							<PrivateRoute
								exact
								path={"/customers/new"}
								component={CreateCompany}
								requiredPermissions={customers?.permissions}
								permission={permissions.view}
								title="Create Company"
							/>

							<PrivateRoute
								exact
								path={"/settings/company/edit"}
								component={EditCompany}
								requiredPermissions={accountSettings?.permissions?.concat(
									customers?.permissions
								)}
								permission={permissions.view}
								title="Setting"
							/>
							<PrivateRoute
								exact
								path={"/customers/:companyId/edit"}
								component={EditCompany}
								requiredPermissions={accountSettings?.permissions?.concat(
									customers?.permissions
								)}
								permission={permissions.view}
								title="Setting"
							/>

							<PrivateRoute
								exact
								path={"/settings/users/:userId"}
								component={UserInfo}
								requiredPermissions={accountSettings?.permissions?.concat(
									customers?.permissions
								)}
								permission={permissions.view}
								title="Setting"
							/>

							<PrivateRoute
								exact
								path={"/customers/:companyId/users/:userId"}
								component={UserInfo}
								requiredPermissions={accountSettings?.permissions?.concat(
									customers?.permissions
								)}
								permission={permissions.view}
								title="Setting"
							/>

							{/* <PrivateRoute
							exact
							path={
								"/customers/:companyId/:view(templates)/:second_view(revit|archicad)?"
							}
							component={SingleCompany}
							priorityAccess={checkUserPriority(
								PRIORITY_USER_ROLE.PROJECT_LEAD
							)}
							permission={permissions.view}
							requiredPermissions={customers?.permissions}
							title="Single Company"
						/> */}

							<PrivateRoute
								exact
								path={"/customers/:companyId/:view/:second_view?"}
								component={SingleCompany}
								requiredPermissions={customers?.permissions}
								permission={permissions.view}
								title="Single Company"
							/>
							<PrivateRoute
								exact
								path={"/customers/:companyId/:view?"}
								component={SingleCompany}
								requiredPermissions={customers?.permissions}
								permission={permissions.view}
								title="Single Company"
							/>

							<PrivateRoute
								exact
								path={"/settings/:view?/:second_view?"}
								component={Settings}
								requiredPermissions={accountSettings?.permissions}
								permission={permissions.view}
								title="Setting"
							/>
							<PrivateRoute
								exact
								path={"/settings/:view?"}
								component={Settings}
								requiredPermissions={accountSettings?.permissions}
								permission={permissions.view}
								title="Setting"
							/>

							{/* <PrivateRoute
							exact
							path={[
								"/settings/:view(templates)/:second_view(revit|archicad)?",
							]}
							component={Settings}
							priorityAccess={checkUserPriority(
								PRIORITY_USER_ROLE.PROJECT_LEAD
							)}
							permission={permissions.view}
							title="Setting"
						/> */}

							{/* ---------------/buildings------------- */}

							{buildingPath.map((path) => (
								<PrivateRoute
									exact
									key={path}
									path={path}
									component={Buildings}
									requiredPermissions={buildings?.permissions}
									permission={permissions.view}
									title={localize(LocKeys.BUILDINGS)}
								/>
							))}
							{/* <PrivateRoute
							exact
							path={buildingPath}
							component={Buildings}
							requiredPermissions={buildings?.permissions}
							permission={permissions.view}
							title={localize(LocKeys.BUILDINGS)}
						/> */}
							<PrivateRoute
								exact
								path={"/buildings/new"}
								component={CreateBuilding}
								requiredPermissions={buildings?.permissions}
								permission={permissions.view}
								title={localize(LocKeys.BUILDINGS)}
							/>
							{/* <PrivateRoute
							exact
							path={
								"/buildings/:buildingId/:view(architecture|mechanical|structure|electrical|plumbing)?"
							}
							component={SingleBuilding}
							requiredPermissions={buildings?.permissions}
							permission={permissions.view}
							title={localize(LocKeys.BUILDINGS)}
						/> */}

							<PrivateRoute
								exact
								path={"/buildings/:buildingId/:view"}
								component={SingleBuilding}
								requiredPermissions={buildings?.permissions}
								permission={permissions.view}
								title={localize(LocKeys.BUILDINGS)}
							/>

							<PrivateRoute
								exact
								path={"/buildings/:buildingId"}
								component={SingleBuilding}
								requiredPermissions={buildings?.permissions}
								permission={permissions.view}
								title={localize(LocKeys.BUILDINGS)}
							/>

							{/* ---------------/offers------------- */}

							<PrivateRoute
								exact
								path={"/offers"}
								component={OrderList}
								requiredPermissions={offers?.permissions}
								permission={permissions.view}
								title={localize(LocKeys.OFFERS)}
							/>
							<PrivateRoute
								exact
								path={"/offers/new"}
								component={CreateOrder}
								requiredPermissions={offers?.permissions}
								permission={permissions.view}
								title={localize(LocKeys.OFFERS)}
							/>
							{/* <PrivateRoute
							exact
							path={
								"/offers/:orderId/:orderView(invoices|input-files|delivery)?"
							}
							component={SingleOrder}
							requiredPermissions={offers?.permissions}
							permission={permissions.view}
							title={localize(LocKeys.OFFERS)}
						/> */}
							<PrivateRoute
								exact
								path={"/offers/:orderId/:orderView"}
								component={SingleOrder}
								requiredPermissions={offers?.permissions}
								permission={permissions.view}
								title={localize(LocKeys.OFFERS)}
							/>
							<PrivateRoute
								exact
								path={"/offers/:orderId"}
								component={SingleOrder}
								requiredPermissions={offers?.permissions}
								permission={permissions.view}
								title={localize(LocKeys.OFFERS)}
							/>
							{/* <PrivateRoute
							exact
							path={
								"/offers/:orderId/:orderView(delivery)/:buildingId/:view(architecture|mechanical|structure|electrical|plumbing)?"
							}
							component={SingleBuilding}
							requiredPermissions={offers?.permissions}
							permission={permissions.view}
							title={localize(LocKeys.BUILDING)}
						/> */}
							<PrivateRoute
								exact
								path={"/offers/:orderId/delivery/:buildingId/:view"}
								component={SingleBuilding}
								requiredPermissions={offers?.permissions}
								permission={permissions.view}
								title={localize(LocKeys.BUILDING)}
							/>
							<PrivateRoute
								exact
								path={"/offers/:orderId/delivery/:buildingId"}
								component={SingleBuilding}
								requiredPermissions={offers?.permissions}
								permission={permissions.view}
								title={localize(LocKeys.BUILDING)}
							/>
							{/* <PrivateRoute
							exact
							path={
								"/offers/:orderId/:orderView(input-files)/:buildingId/:view(architecture|mechanical|structure|electrical|plumbing)?"
							}
							component={SingleOrder}
							requiredPermissions={offers?.permissions}
							permission={permissions.view}
							title={localize(LocKeys.OFFERS)}
						/> */}
							<PrivateRoute
								exact
								path={"/offers/:orderId/:orderView/:buildingId/:view?"}
								component={SingleOrder}
								requiredPermissions={offers?.permissions}
								permission={permissions.view}
								title={localize(LocKeys.OFFERS)}
							/>
							<PrivateRoute
								exact
								path={"/offers/:orderId/:orderView/:buildingId"}
								component={SingleOrder}
								requiredPermissions={offers?.permissions}
								permission={permissions.view}
								title={localize(LocKeys.OFFERS)}
							/>

							{/* ---------------/invoices------------- */}
							<PrivateRoute
								exact
								path={"/invoices"}
								component={InvoiceList}
								requiredPermissions={invoices?.permissions}
								permission={permissions.view}
								title={localize(LocKeys.INVOICES)}
							/>

							{/* //---------------/jobs-------------// */}

							{jobPath.map((path) => (
								<PrivateRoute
									exact
									key={path}
									path={path}
									component={JobList}
									requiredPermissions={jobs?.permissions}
									permission={permissions.view}
									title={localize(LocKeys.JOBS)}
								/>
							))}
							<PrivateRoute
								exact
								path={"/jobs/new"}
								component={CreateJob}
								requiredPermissions={jobs?.permissions}
								permission={permissions.view}
								title={localize(LocKeys.JOBS)}
							/>

							{/* //---------------/jobs/:jobId-------------// */}

							<PrivateRoute
								exact
								path={"/jobs/:jobId/:disciplineId/labeling-tool"}
								component={LabelingTool}
								requiredPermissions={jobs?.permissions}
								permission={permissions.view}
								title={localize(LocKeys.JOBS)}
							/>
							{/* <PrivateRoute
							exact
							path={[
								"/jobs/:jobId/:viewJob(job-specification|normalisation|customisation|quality-assurance|delivery-files|estimate)?",
								"/jobs/:jobId/:viewJob(normalisation|customisation|quality-assurance)/:view(architecture|mechanical|structure|electrical|plumbing|delivery|job-description|comments|bim-model|symbol-categorisation)?",
								"/jobs/:jobId/:viewJob(normalisation)/:view(symbol-categorisation)/:second_view(component-types|symbol)?",
							]}
							component={SingleJob}
							requiredPermissions={jobs?.permissions}
							permission={permissions.view}
							title={localize(LocKeys.JOBS)}
						/> */}
							{/* <PrivateRoute
							exact
							path={[
								"/jobs/:jobId/:viewJob?",
								"/jobs/:jobId/:viewJob/:view?",
								"/jobs/:jobId/:viewJob(normalisation)/:view(symbol-categorisation)/:second_view(component-types|symbol)?",
							]}
							component={SingleJob}
							requiredPermissions={jobs?.permissions}
							permission={permissions.view}
							title={localize(LocKeys.JOBS)}
						/> */}

							{/* <PrivateRoute
							exact
							path={"/jobs/:jobId/:viewJob?"}
							component={SingleJob}
							requiredPermissions={jobs?.permissions}
							permission={permissions.view}
							title={localize(LocKeys.JOBS)}
						/> */}

							<PrivateRoute
								exact
								path={"/jobs/:jobId/:viewJob?/:view?/:second_view?"}
								component={SingleJob}
								requiredPermissions={jobs?.permissions}
								permission={permissions.view}
								title={localize(LocKeys.JOBS)}
							/>

							<PrivateRoute
								exact
								path={
									"/jobs/:jobId/normalisation/symbol-categorisation/symbol/:symbolId"
								}
								component={SingleSymbol}
								requiredPermissions={jobs?.permissions}
								permission={permissions.view}
								title={localize(LocKeys.SYMBOL_CATEGORISATION)}
							/>
							<PrivateRoute
								exact
								path={
									"/jobs/:jobId/normalisation/symbol-categorisation/symbol/:symbolId/edit"
								}
								component={EditSymbol}
								requiredPermissions={jobs?.permissions}
								permission={permissions.view}
								title={localize(LocKeys.SYMBOL_CATEGORISATION)}
							/>
							<PrivateRoute
								exact
								path={
									"/jobs/:jobId/normalisation/symbol-categorisation/component-types/:familyId/edit"
								}
								component={EditFamily}
								requiredPermissions={jobs?.permissions}
								permission={permissions.view}
								title={localize(LocKeys.SYMBOL_CATEGORISATION)}
							/>
							<PrivateRoute
								exact
								path={
									"/jobs/:jobId/normalisation/symbol-categorisation/component-types/new"
								}
								component={CreateFamily}
								requiredPermissions={jobs?.permissions}
								permission={permissions.view}
								title={localize(LocKeys.SYMBOL_CATEGORISATION)}
							/>
							<PrivateRoute
								exact
								path={
									"/jobs/:jobId/normalisation/symbol-categorisation/component-types/:familyId"
								}
								component={SingleFamily}
								requiredPermissions={jobs?.permissions}
								permission={permissions.view}
								title={localize(LocKeys.SYMBOL_CATEGORISATION)}
							/>

							{/* //---------------/bim-model-specification-------------// */}

							<PrivateRoute
								exact
								path={"/bim-model-specification"}
								component={LodSpecList}
								requiredPermissions={lodSpecifications?.permissions}
								permission={permissions.view}
								title={localize(LocKeys.LOD_SPECIFICATIONS)}
							/>
							<PrivateRoute
								exact
								path={"/bim-model-specification/new"}
								component={CreateLodSpec}
								requiredPermissions={lodSpecifications?.permissions}
								permission={permissions.view}
								title={localize(LocKeys.LOD_SPECIFICATIONS)}
							/>
							<PrivateRoute
								exact
								path={"/bim-model-specification/:lodSpecificationId"}
								component={ViewLodSpec}
								requiredPermissions={lodSpecifications?.permissions}
								permission={permissions.view}
								title={localize(LocKeys.LOD_SPECIFICATIONS)}
							/>
							<PrivateRoute
								exact
								path={"/bim-model-specification/:lodSpecificationId/edit"}
								component={EditLodSpec}
								requiredPermissions={lodSpecifications?.permissions}
								permission={permissions.view}
								title={localize(LocKeys.LOD_SPECIFICATIONS)}
							/>

							{/* ---------------/docs------------- */}
							<PrivateRoute exact path="/docs" component={Docs} />

							{/* ---------------/administration------------- */}

							{/* <PrivateRoute
							exact
							permission={permissions.view}
							priorityAccess={
								checkUserPriority(PRIORITY_USER_ROLE.PROJECT_LEAD) && isBimify()
							}
							path={[
								"/administration/:view(bim-model-specifications)/:second_view(lod-content)/:third_view(architecture|structure|mechanical|electrical|plumbing)/:lodContentId",
							]}
							component={LodContentView}
						/> */}

							{/* <PrivateRoute
							exact
							permission={permissions.view}
							priorityAccess={
								checkUserPriority(PRIORITY_USER_ROLE.PROJECT_LEAD) && isBimify()
							}
							path={[
								"/administration/:view(bim-model-specifications|manuals|symbol-categorisation|development-constants|templates)?",
								"/administration/:view(bim-model-specifications)/:second_view(engineering-disciplines|lod|building-components|lod-content|parameters)?",
								"/administration/:view(bim-model-specifications)/:second_view(lod-content)/:third_view(architecture|structure|mechanical|electrical|plumbing)?",
								"/administration/:view(symbol-categorisation)/:second_view(component-types|symbol)?",
								"/administration/:view(templates)/:second_view(revit|archicad)?",
								"/administration/:view(development-constants)/:second_view(development-time|automatisation|exports|services)?",
								"/administration/:view(development-constants)/:second_view(automatisation)/:third_view(cad|image|point-cloud)?",
								"/administration/:view(development-constants)/:second_view(exports)/:third_view(cad|image|point-cloud)?",
								"/administration/:view(development-constants)/:second_view(services)/:third_view(cad|image|point-cloud)?",
							]}
							component={Administration}
						/> */}

							<PrivateRoute
								exact
								permission={permissions.view}
								priorityAccess={
									checkUserPriority(PRIORITY_USER_ROLE.PROJECT_LEAD) &&
									isBimify()
								}
								path={
									"/administration/bim-model-specifications/lod-content/:third_view/:lodContentId"
								}
								component={LodContentView}
							/>

							<PrivateRoute
								exact
								permission={permissions.view}
								path={"/administration/legal/:legalId"}
								component={SingleLegalView}
							/>
							<PrivateRoute
								exact
								priorityAccess={
									checkUserPriority(PRIORITY_USER_ROLE.PROJECT_LEAD) &&
									isBimify()
								}
								permission={permissions.view}
								path={"/administration/manuals/new"}
								component={CreateManual}
								title={localize(LocKeys.MANUAL)}
							/>
							<PrivateRoute
								exact
								priorityAccess={
									checkUserPriority(PRIORITY_USER_ROLE.PROJECT_LEAD) &&
									isBimify()
								}
								permission={permissions.view}
								path={"/administration/manuals/:manualId"}
								component={ViewManual}
							/>
							<PrivateRoute
								exact
								priorityAccess={
									checkUserPriority(PRIORITY_USER_ROLE.PROJECT_LEAD) &&
									isBimify()
								}
								permission={permissions.view}
								path={"/administration/symbol-categorisation/symbol/new"}
								component={CreateSymbol}
								title={localize(LocKeys.SYMBOL_CATEGORISATION)}
							/>
							<PrivateRoute
								exact
								priorityAccess={
									checkUserPriority(PRIORITY_USER_ROLE.PROJECT_LEAD) &&
									isBimify()
								}
								permission={permissions.view}
								path={"/administration/symbol-categorisation/symbol/:symbolId"}
								component={SingleSymbol}
								title={localize(LocKeys.SYMBOL_CATEGORISATION)}
							/>
							<PrivateRoute
								exact
								priorityAccess={
									checkUserPriority(PRIORITY_USER_ROLE.PROJECT_LEAD) &&
									isBimify()
								}
								permission={permissions.view}
								path={
									"/administration/symbol-categorisation/symbol/:symbolId/edit"
								}
								component={EditSymbol}
								title={localize(LocKeys.SYMBOL_CATEGORISATION)}
							/>
							<PrivateRoute
								exact
								priorityAccess={
									checkUserPriority(PRIORITY_USER_ROLE.PROJECT_LEAD) &&
									isBimify()
								}
								permission={permissions.view}
								path={
									"/administration/symbol-categorisation/component-types/:familyId/edit"
								}
								component={EditFamily}
								title={localize(LocKeys.SYMBOL_CATEGORISATION)}
							/>
							<PrivateRoute
								exact
								priorityAccess={
									checkUserPriority(PRIORITY_USER_ROLE.PROJECT_LEAD) &&
									isBimify()
								}
								permission={permissions.view}
								path={
									"/administration/symbol-categorisation/component-types/new"
								}
								component={CreateFamily}
								title={localize(LocKeys.SYMBOL_CATEGORISATION)}
							/>
							<PrivateRoute
								exact
								priorityAccess={
									checkUserPriority(PRIORITY_USER_ROLE.PROJECT_LEAD) &&
									isBimify()
								}
								permission={permissions.view}
								path={
									"/administration/symbol-categorisation/component-types/:familyId"
								}
								component={SingleFamily}
							/>
							<PrivateRoute
								exact
								priorityAccess={
									checkUserPriority(PRIORITY_USER_ROLE.PROJECT_LEAD) &&
									isBimify()
								}
								permission={permissions.view}
								path={"/administration/templates/revit/new"}
								component={CreateRevitTemplate}
							/>
							<PrivateRoute
								exact
								priorityAccess={
									checkUserPriority(PRIORITY_USER_ROLE.PROJECT_LEAD) &&
									isBimify()
								}
								permission={permissions.view}
								path={"/administration/templates/revit/:templateId"}
								component={SingleRevitTemplate}
							/>
							<PrivateRoute
								exact
								priorityAccess={
									checkUserPriority(PRIORITY_USER_ROLE.PROJECT_LEAD) &&
									isBimify()
								}
								permission={permissions.view}
								path={"/administration/templates/revit/:templateId/edit"}
								component={EditRevitTemplate}
							/>

							<PrivateRoute
								exact
								permission={permissions.view}
								priorityAccess={
									checkUserPriority(PRIORITY_USER_ROLE.PROJECT_LEAD) &&
									isBimify()
								}
								path={"/administration/:view?/:second_view?/:third_view?"}
								component={Administration}
							/>

							<PrivateRoute
								exact
								permission={permissions.view}
								path={"/administration/:view?/:second_view?/:third_view?"}
								component={Administration}
							/>

							{/* ---------------/support------------- */}

							<PrivateRoute
								exact
								//priorityAccess={true}
								requiredPermissions={tickets?.permissions}
								permission={permissions.view}
								path={"/support"}
								component={Ticketing}
								title={localize(LocKeys.TICKET)}
							/>
							<PrivateRoute
								exact
								//priorityAccess={true}
								requiredPermissions={tickets?.permissions}
								permission={permissions.view}
								path={"/support/new"}
								component={CreateTicket}
								title={localize(LocKeys.CREATE_TICKET)}
							/>
							<PrivateRoute
								exact
								//priorityAccess={true}
								requiredPermissions={tickets?.permissions}
								permission={permissions.view}
								path={"/support/:ticketId"}
								component={SingleTicket}
								title={localize(LocKeys.TICKET)}
							/>

							{/* ---------------/404------------- */}
							<PrivateRoute
								component={Page404}
								requiredPermissions={permissionsArray}
								permission={permissions.view}
								title={localize(LocKeys.ERROR)}
							/>
						</Switch>
					</div>

					{/* <PublicRoute
						exact
						isPublic={true}
						path={"/share/dashboard/:packageId"}
						component={PublicSinglePackage}
						title="Single package"
					/> */}
				</CompatRouter>
			</Router>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		auth: state.getIn(["authentication", "loggedIn"]),
		loggedUserRoles: state.getIn(["authentication", "user", "userRoles"]),
	};
};

export default connect(mapStateToProps, {})(App);
