import React from "react";

export default (props) => {
	return (
		<div className={`icon ${props.iconClass}`}>
			<svg
				width="22"
				height="24"
				viewBox="0 0 22 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M7.73566 6.34665V13.5264C7.73565 13.6392 7.76531 13.75 7.82167 13.8476C7.87803 13.9452 7.9591 14.0263 8.05669 14.0825L13.7004 17.34C13.7572 17.3728 13.8217 17.3901 13.8873 17.3901C13.953 17.39 14.0174 17.3727 14.0742 17.3398C14.131 17.3069 14.1781 17.2596 14.2109 17.2027C14.2436 17.1457 14.2608 17.0811 14.2607 17.0154V10.1193L7.73566 6.34665Z"
					fill="#1B2024"
				/>
				<path
					d="M18.5415 19.4961L21.3796 17.8552C21.477 17.7989 21.5578 17.7179 21.6141 17.6204C21.6703 17.5229 21.7 17.4122 21.7 17.2996V6.19448C21.7 6.08187 21.6703 5.97125 21.6141 5.87374C21.5578 5.77623 21.477 5.69525 21.3796 5.63895L11.7754 0.0860667C11.678 0.0296855 11.5675 0 11.455 0C11.3425 0 11.232 0.0296855 11.1346 0.0860667L7.74115 2.04846L17.9812 7.96864V19.1722C17.9814 19.2378 17.9988 19.3022 18.0316 19.3589C18.0645 19.4157 18.1116 19.4628 18.1683 19.4956C18.225 19.5284 18.2894 19.5457 18.3549 19.5458C18.4204 19.5458 18.4847 19.5287 18.5415 19.4961Z"
					fill="#1B2024"
				/>
				<path
					d="M8.22083 6.62717L14.2631 10.1193L17.9831 7.96864L7.73809 2.04907L0.620389 6.16148C0.522908 6.21784 0.441991 6.29891 0.385741 6.39654C0.329491 6.49417 0.299916 6.60491 0.299988 6.71762V17.824C0.300023 17.9366 0.329646 18.0472 0.38589 18.1447C0.442134 18.2423 0.522997 18.3232 0.620389 18.3795L10.1684 23.8988C10.2831 23.9651 10.4132 24 10.5456 24C10.678 24 10.8081 23.9651 10.9228 23.8988L13.8676 22.1968C13.9074 22.1737 13.9404 22.1405 13.9634 22.1006C13.9863 22.0607 13.9984 22.0155 13.9984 21.9694C13.9984 21.9234 13.9863 21.8781 13.9634 21.8382C13.9404 21.7983 13.9074 21.7652 13.8676 21.7421L4.34037 16.2289C4.24298 16.1726 4.16208 16.0916 4.10583 15.9941C4.04959 15.8966 4.01997 15.786 4.01993 15.6734V8.86947C4.01984 8.75691 4.04936 8.64631 4.1055 8.54879C4.16164 8.45127 4.24242 8.37028 4.33974 8.31394L7.25102 6.62779C7.39838 6.5424 7.5656 6.49739 7.73585 6.49728C7.9061 6.49717 8.07337 6.54198 8.22083 6.62717Z"
					fill="#F79426"
				/>
			</svg>
		</div>
	);
};
