import companySpecsConstants from "../constants/companySpecsConstants";
import { api } from "../api";

/**
 * Retrieves all company types
 *
 * SUCCESS: Populates 'companyTypes' in the companySpecs state.
 *
 * @returns
 */
export const getCompanyTypes = () => {
	return (dispatch) => {
		const requestOptions = {
			method: "GET",
			url: "/company/company-types",
			headers: {
				"Content-Type": "application/json",
			},
		};
		return api(requestOptions).then(
			(res) => {
				dispatch({
					type: companySpecsConstants.GET_COMPANY_TYPES,
					data: res.data.result,
				});
			},
			(err) => {}
		);
	};
};
