import React, { useState, useRef, useCallback } from "react";
import { connect } from "react-redux";
import EditButton from "../../../../../../shared/components/buttons/EditButton";
import localize, { LocKeys } from "../../../../../../constants/localizations";
import FormSubmitButtons from "../../../../../../shared/components/FormComponents/FormSubmitButtons";
import { Field, change, reduxForm } from "redux-form/immutable";
import {
	fieldTypeConstants,
	generalConstants,
} from "../../../../../../constants/constants";
import renderTextField from "../../../../../fields/renderTextField";
import {
	addNewTempParameter,
	getSpecificationCategoryParameters,
	removeTempParameter,
	resetLodParameterCategories,
	sortLodParameterCategories,
	updateComponentCategoryContentParameterCategory,
	updateLodParameterCategories,
} from "../../../../../../actions";
import Modal from "../../../../../Modal";
import AddParameter from "../modals/AddParameter";
import SelectionCheckmark from "../../../../../../shared/components/SelectionCheckmark";
import IconWrapper from "../../../../../../shared/icons/IconWrapper";
import TrashIcon from "../../../../../../shared/icons/actions/TrashIcon";
import DeleteParameter from "../modals/DeleteParameter";
import ReArrangeIcon from "../../../../../../shared/icons/actions/ReArrangeIcon";
import TooltipWithHtml from "../../../../../../shared/htmlTooltip/TooltipWithHtml";
import { useParams } from "react-router-dom-v5-compat";

/**
 * Component for editing parameter & category
 * @param {object} props
 * @returns
 */
const ParameterCategoryEdit = (props) => {
	const { lodContentId } = useParams();
	const {
		excludeIds,
		lodParameters,
		categoryId,
		categoryName,
		onCancel,
		resetLodParameterCategories,
		updateLodParameterCategories,
		updateComponentCategoryContentParameterCategory,
		removeTempParameter,
		sortLodParameterCategories,
	} = props;
	const [showModal, setShowModal] = useState(false);
	const [loading, setLoading] = useState(false);
	const dragItem = useRef();
	const dragOverItem = useRef();
	const [currentPosition, seCurrentPosition] = useState();
	const [removeModal, setRemoveModal] = useState({
		id: null,
		showModal: false,
		type: null,
		deleteIds: null,
		lodsValue: null,
		categoryId: null,
	});

	const getValue = (type, boolValue, txtValue) => {
		switch (type) {
			case fieldTypeConstants.CHECKBOX:
				return boolValue;

			default:
				return txtValue;
		}
	};

	const getKey = (type) => {
		switch (type) {
			case fieldTypeConstants.CHECKBOX:
				return "boolValue";

			default:
				return "txtValue";
		}
	};

	/**
	 * Submit form - send data to server
	 * @returns
	 */
	const onSubmit = () => {
		try {
			setLoading(true);
			const stateData = lodParameters?.toJS();
			let tempData = [];

			stateData &&
				stateData.map((lp, index) => {
					const lodsValue = lp?.lods || null;

					return (
						lodsValue &&
						lodsValue.map((lod) => {
							let values = {
								disciplineComponentCategoryLodDescriptionId:
									lod.disciplineComponentCategoryLodDescriptionId,
								id: lod.id || null,
								parameterCategoryId: lp.id,
								sortOrder: index + 1,
							};

							values[getKey(lod?.inputFieldType?.type)] = getValue(
								lod?.inputFieldType?.type,
								lod.boolValue,
								lod.txtValue
							);
							return tempData.push(values);
						})
					);
				});

			updateComponentCategoryContentParameterCategory(
				lodContentId,
				tempData,
				() => onCancel()
			);
		} catch (error) {
			return;
		}
	};

	/**
	 * Set temp value to redux state
	 */
	const setValue = (fieldKey, value, lodId, parameterId) => {
		updateLodParameterCategories({
			fieldKey,
			value,
			lodId,
			parameterId,
			categoryId,
		});
	};

	/**
	 * Render field based on type
	 * @returns
	 */
	const renderField = ({ name, type, boolValue, lodId, parameterId }) => {
		switch (type) {
			case fieldTypeConstants.CHECKBOX:
				return (
					<div className="py-s w-100 d-flex justify-content-center">
						<button
							className="btn btn--stripped"
							type="button"
							onClick={() =>
								setValue("boolValue", !boolValue, lodId, parameterId)
							}
						>
							<SelectionCheckmark
								selected={boolValue}
								type={generalConstants.MULTI}
							/>
						</button>
					</div>
				);

			default:
				return (
					<Field
						name={name}
						id={name}
						placeholder={localize(LocKeys.START_TYPING) + "..."}
						component={renderTextField}
						onKeyDown={(value) =>
							setValue("txtValue", value, lodId, parameterId)
						}
						min="0"
						maxRows={4}
						small={true}
						multiline={true}
						type="text"
						size={"w-100"}
						showPlaceholder={true}
					/>
				);
		}
	};

	const addNewParameter = () => {
		setShowModal(true);
	};

	const removeParameter = (parameterId, categoryId, lodsValue) => {
		removeTempParameter(parameterId, categoryId);
		lodsValue &&
			lodsValue.valueSeq().forEach((lod) => {
				props.dispatch(
					change(
						"parameterCategoryEditForm",
						`name_${parameterId}_${lod.get("lodId")}`,
						null
					)
				);
			});
	};

	const toggleRemoveModal = (
		deleteIds = null,
		type = null,
		id = null,
		categoryId = null,
		lodsValue = null
	) => {
		setRemoveModal({
			id: id,
			deleteIds,
			categoryId,
			type: type,
			lodsValue,
			showModal: !removeModal.showModal,
		});
	};

	//--------------------RE-ORDER--------------------------------
	const dragStart = (_, position) => {
		dragItem.current = position;
		seCurrentPosition(position);
	};

	const dragEnter = (_, position) => {
		dragOverItem.current = position;
		sortLodParameterCategories(
			dragItem.current,
			dragOverItem.current,
			categoryId
		);
	};

	const enableDropping = (e) => {
		e.preventDefault();
	};

	const drop = () => {
		dragItem.current = null;
		dragOverItem.current = null;
		seCurrentPosition(false);
	};

	const updateOpacity = useCallback(
		(id) => {
			if (currentPosition === id) {
				return "opacity-0";
			}

			return "opacity-1";
		},
		[currentPosition]
	);

	return (
		<>
			<Modal
				show={showModal}
				modalClasses="modal--medium"
				title={localize(LocKeys.NEW_PARAMETER)}
				onClose={() => setShowModal(false)}
			>
				<AddParameter
					excludeIds={excludeIds}
					categoryId={categoryId}
					onCancel={() => setShowModal(false)}
				/>
			</Modal>
			<Modal
				show={removeModal.showModal}
				title={
					localize(LocKeys.DELETE) +
					" " +
					localize(LocKeys.DISCIPLINE).toLowerCase()
				}
				onClose={toggleRemoveModal}
			>
				<DeleteParameter
					removeModal={removeModal}
					toggleRemoveModal={toggleRemoveModal}
					removeParameter={removeParameter}
				/>
			</Modal>

			<form
				className={`form d-flex flex-column flex-auto standardized-form	bg-primary--lighter`}
				onSubmit={props.handleSubmit(() => onSubmit())}
				autoComplete="off"
				noValidate
			>
				<div className="d-flex align-items-center bg-primary--light px-regular py-xs border-b-1 border-n150">
					<div className="body-font semi">{categoryName} </div>
					<EditButton
						wrapperClasses="ml-auto"
						onClick={() => {
							resetLodParameterCategories();
							onCancel();
						}}
					/>
				</div>
				<div className="bg-primary--lighter">
					{lodParameters &&
						lodParameters.valueSeq().map((lp, index) => {
							const lodsValue = lp?.get("lods") || null;
							const deleteIds = [];

							lodsValue &&
								lodsValue.valueSeq().forEach((lod) => {
									deleteIds.push(lod.get("id"));
								});
							return (
								<div
									key={index}
									onDragEnter={(e) => dragEnter(e, lp?.get("id"))}
									onDragStart={(e) => dragStart(e, lp?.get("id"))}
									onDragOver={enableDropping}
									onDragEnd={drop}
									draggable={true}
									className={`border-b-1 border-n150  ${updateOpacity(
										lp?.get("id")
									)}`}
								>
									<div className="d-flex">
										<div className="col col-10 border-r-1 border-n150">
											<div className="d-flex align-items-center w-100 py-m h-100 row row--s-gutters">
												<div className="col col-35">
													<IconWrapper
														size="24"
														icon={
															<ReArrangeIcon iconClass="d-flex icon--target-fill fill--n300 cursor-grab" />
														}
													/>

													<button
														onClick={() =>
															!!lp.get("deleteId")
																? toggleRemoveModal(
																		deleteIds,
																		lp.get("name"),
																		lp.get("id"),
																		categoryId,
																		lodsValue
																	)
																: removeParameter(
																		lp.get("id"),
																		categoryId,
																		lodsValue
																	)
														}
														type="button"
														className="btn btn--icon btn--icon--solo btn--icon--after btn--stripped"
													>
														<IconWrapper
															size="24"
															icon={
																<TrashIcon iconClass="d-flex icon--target-fill fill--n300" />
															}
														/>
													</button>
												</div>
												<div className="col col-65">
													<TooltipWithHtml
														tooltipContent={lp.get("name")}
														tooltipPlacement={"top"}
														wrapperClasses="w-100"
													>
														<div className={`text-overflow-ellipsis`}>
															{lp.get("name")}
														</div>
													</TooltipWithHtml>
												</div>
											</div>
										</div>
										{lodsValue &&
											lodsValue.valueSeq().map((lod, index) => {
												return (
													<div key={index} className="col col-18">
														<div className="w-100 d-flex border-r-1 border-n150 px-s py-xs">
															{renderField({
																type: lod.getIn(["inputFieldType", "type"]),
																name: `name_${lp.get("id")}_${lod.get(
																	"lodId"
																)}`,
																lodId: lod.get("lodId"),
																parameterId: lp.get("id"),
																txtValue: lod.get("txtValue"),
																boolValue: lod.get("boolValue"),
															})}
														</div>
													</div>
												);
											})}
									</div>
								</div>
							);
						})}

					<div className="p-m">
						<button
							className="btn btn--stripped btn--add btn--icon btn--icon--before"
							type="button"
							onClick={() => addNewParameter()}
						>
							{localize(LocKeys.NEW_PARAMETER)}
						</button>
					</div>

					<div className="border-t-1 border-n150 px-regular">
						<FormSubmitButtons
							wrapperClasses="w-100 py-regular"
							onCancel={() => {
								resetLodParameterCategories();
								onCancel();
							}}
							onSubmit={() => onSubmit}
							loading={loading}
						/>
					</div>
				</div>
			</form>
		</>
	);
};

const mapStateToProps = (state, ownProps) => {
	const { categoryId } = ownProps;

	const lodParameterCategories = state.getIn([
		"administration",
		"tempComponentCategoryContent",
		"lodParameterCategories",
	]);
	const findCategory =
		lodParameterCategories &&
		lodParameterCategories
			.valueSeq()
			.find((lpc) => lpc.get("id") === categoryId);

	const lodParameters = findCategory?.get("lodParameters");
	let initialValues = {};
	let excludeIds = [];
	lodParameters &&
		lodParameters.valueSeq().forEach((lp) => {
			const lodsValue = lp?.get("lods") || null;
			excludeIds.push(lp.get("id"));
			lodsValue &&
				lodsValue.valueSeq().forEach((lod) => {
					initialValues[`name_${lp.get("id")}_${lod.get("lodId")}`] =
						lod.get("txtValue");
				});
		});

	return {
		categoryName: findCategory?.get("name"),
		lodParameters,
		initialValues,
		excludeIds,
		specificationParameters: state.getIn([
			"administration",
			"specificationParameters",
		]),
	};
};

export default connect(mapStateToProps, {
	addNewTempParameter,
	getSpecificationCategoryParameters,
	resetLodParameterCategories,
	updateLodParameterCategories,
	updateComponentCategoryContentParameterCategory,
	removeTempParameter,
	sortLodParameterCategories,
})(
	reduxForm({ form: "parameterCategoryEditForm", touchOnBlur: false })(
		ParameterCategoryEdit
	)
);
