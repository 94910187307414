import React from "react";

export default (props) => {
	return (
		<div className={`icon ${props.iconClass ? props.iconClass : ""}`}>
			<svg
				width="32"
				height="32"
				viewBox="0 0 32 32"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M23.9993 17.3333C23.466 17.3333 22.9327 17.4667 22.5327 17.6L19.866 13.0667C20.7993 12.1333 21.3327 10.8 21.3327 9.33333C21.3327 6.4 18.9327 4 15.9993 4C13.066 4 10.666 6.4 10.666 9.33333C10.666 10.8 11.1993 12.1333 12.266 13.0667L9.59935 17.6C9.06601 17.4667 8.53268 17.3333 7.99935 17.3333C5.06602 17.3333 2.66602 19.7333 2.66602 22.6667C2.66602 25.6 5.06602 28 7.99935 28C10.5327 28 12.5327 26.2667 13.1993 24H18.9327C19.466 26.2667 21.5993 28 24.1327 28C27.066 28 29.466 25.6 29.466 22.6667C29.466 19.7333 26.9327 17.3333 23.9993 17.3333ZM15.9993 6.66667C17.466 6.66667 18.666 7.86667 18.666 9.33333C18.666 10.8 17.466 12 15.9993 12C14.5327 12 13.3327 10.8 13.3327 9.33333C13.3327 7.86667 14.5327 6.66667 15.9993 6.66667ZM7.99935 25.3333C6.53268 25.3333 5.33268 24.1333 5.33268 22.6667C5.33268 21.2 6.53268 20 7.99935 20C9.46601 20 10.666 21.2 10.666 22.6667C10.666 24.1333 9.46601 25.3333 7.99935 25.3333ZM18.7993 21.3333H13.1993C12.9327 20.4 12.5327 19.6 11.866 18.9333L14.5327 14.4C15.066 14.5333 15.466 14.6667 15.9993 14.6667C16.5327 14.6667 17.066 14.5333 17.466 14.4L20.1327 18.9333C19.5993 19.6 19.066 20.4 18.7993 21.3333ZM23.9993 25.3333C22.5327 25.3333 21.3327 24.1333 21.3327 22.6667C21.3327 21.2 22.5327 20 23.9993 20C25.466 20 26.666 21.2 26.666 22.6667C26.666 24.1333 25.466 25.3333 23.9993 25.3333Z"
					fill="#1b2024"
				/>
			</svg>
		</div>
	);
};
