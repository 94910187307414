import React from "react";

const ProjectLeadIcon = (props) => {
	return (
		<div className={`icon ${props.iconClass}`}>
			<svg
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M18 15V11H16H13V9H16V2H8V9H11V11H9.3H6V15H3V22H11V15H8V13H16V15H13V22H21V15H18ZM10 7V4H14V7H10ZM9 17V20H5V17H9ZM19 20H15V17H19V20Z"
					fill="#7F909F"
				/>
			</svg>
		</div>
	);
};

export default ProjectLeadIcon;
