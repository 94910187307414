import React, { lazy, Suspense, useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { getPackage } from "../../../../actions";
import BodyLayout from "../../../layout/content/BodyLayout";
import SuspenseLoader from "../../../loader/SuspenseLoader";
import LodSpecRequestPopups from "../../../lod-specification/LodSpecRequestPopups";
import DashboardRequestPopups from "../../DashboardRequestPopups";
import FooterView from "./view/FooterView";
import HeaderView from "./view/HeaderView";
import PackageInformation from "./view/PackageInformation";
import PackageSpecification from "./view/PackageSpecification";
import { useNavigate, useParams } from "react-router-dom-v5-compat";

const EditPackage = lazy(() => import("./edit/EditPackage"));
const EditLODSpecification = lazy(() => import("./edit/EditLODSpecification"));

const SinglePackage = (props) => {
	const { cancelFormOrder = false, getPackage } = props;
	const { packageId } = useParams();
	const navigate = useNavigate();
	const [editView, setEditView] = useState({
		information: false,
		specification: false,
	});

	useEffect(() => {
		if (!cancelFormOrder) {
			getPackage(packageId, true, false, () => navigate("/dashboard"));
		}
	}, [packageId, getPackage, navigate, cancelFormOrder]);

	const toggleEditView = useCallback(
		(information = false, specification = false) => {
			setEditView({
				information: information,
				specification: specification,
			});
		},
		[]
	);

	if (!editView.information && !editView.specification) {
		return (
			<div className="card card--border--none  card--2 bg-n000">
				<DashboardRequestPopups />
				<LodSpecRequestPopups />
				<HeaderView toggleEditView={toggleEditView} />

				<BodyLayout
					wrapperClasses="page-content--dashboard p-48 overflow-y-auto"
					isTableView={true}
				>
					<PackageInformation />
					<PackageSpecification
						cancelFormOrder={cancelFormOrder}
						toggleEditView={toggleEditView}
					/>
				</BodyLayout>
				<FooterView />
			</div>
		);
	} else if (!!editView.specification) {
		return (
			<Suspense fallback={<SuspenseLoader />}>
				<EditLODSpecification
					toggleEditView={toggleEditView}
					packageId={packageId}
				/>
			</Suspense>
		);
	} else {
		return (
			<Suspense fallback={<SuspenseLoader />}>
				<EditPackage toggleEditView={toggleEditView} />
			</Suspense>
		);
	}
};

const mapStateToProps = (state) => {
	return {
		cancelFormOrder: state.getIn([
			"dashboard",
			"salesPackage",
			"cancelFormOrder",
		]),
	};
};

export default connect(mapStateToProps, { getPackage })(SinglePackage);
