import React from "react";

export default (props) => {
	return (
		<span className={`icon ${props.iconClass ? props.iconClass : ""}`}>
			<svg
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M13.2916 11.9996L17.2844 8.00678L15.9945 6.7168L12.0016 10.7097L8.00874 6.7168L6.71875 8.00678L10.7116 11.9996L6.71875 15.9925L8.00874 17.2825L12.0016 13.2896L15.9945 17.2825L17.2844 15.9925L13.2916 11.9996ZM12.0016 13.0068L8.00874 16.9996L8.00873 16.9996L12.0016 13.0068L12.0016 13.0068ZM15.9944 16.9996L15.9945 16.9996L17.0016 15.9925L17.0016 15.9925L15.9944 16.9996ZM13.0087 11.9996L17.0016 8.00678L17.0016 8.00678L13.0087 11.9996L13.0087 11.9996Z"
					fill="#E93547"
				/>
			</svg>
		</span>
	);
};
