import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import renderError from "./renderError";
import clsx from "clsx";
import CustomLabel from "./CustomLabel";
import { FormControl } from "@mui/material";
import EditIcon from "../../shared/icons/actions/EditIcon";
import { useDebounce } from "../../hooks/useDebounce";
import {
	adornmentTypeConstants,
	fieldTypeConstants,
} from "../../constants/constants";

/**
 * Component for simple text field
 */

export default ({
	input,
	label = "",
	labelAppend,
	labelInfoContent = "",
	labelNote = "",
	required = true,
	meta: { touched, error, initial, valid, value },
	size = "w-100",
	disabled,
	type,
	classField,
	small = false,
	inputSize = "small",
	onKeyDown = undefined,
	onKeyUp = undefined,
	placeholder,
	min,
	max,
	maxLength,
	multiline,
	maxRows,
	rows,
	customError = false,
	customErrorLabel,
	numberAdornment,
	id,
	inputMode,
	customUpdateValue,
	adornmentContent,
	adornmentType,
	textAlign = false,
	pagination = false,
	editorType = false, // This kind of field looks like a title element when untouched
	editorAlign = "center", // This kind of field looks like a title element when untouched
	numericAside = false, // Used for the custom number manipulation buttons side placement
	tabIndex = undefined,
	autofocus = false,
	forwardSubmitEvent = false,
	debounce = false,
	reverseAdornment = false,
	debounceTime = 500,
	onlyInteger = false,
}) => {
	const [inputSelector, setInputSelector] = useState(
		id && document.querySelectorAll(`#${id}`)[0]
	);

	useEffect(() => {
		if (id) {
			setInputSelector(document.querySelectorAll(`#${id}`)[0]);
		}
	}, [id]);

	let errorExist = customError;
	if ((touched && error) || customError) {
		errorExist = true;
	}

	const renderIncreaseInputVal = () => {
		let value = input && +input.value;

		return (
			<button
				className={`btn--stepper  ${
					numericAside
						? "btn--stepper--aside btn--stepper--aside--top"
						: "btn--stepper--right"
				}`}
				type="button"
				onClick={() => changeVal(reverseAdornment ? false : true)}
				disabled={reverseAdornment ? value === +min : value === +max}
			>
				+
			</button>
		);
	};

	const renderDecreaseInputVal = () => {
		let value = input && +input.value;

		return (
			<button
				className={`btn--stepper ${
					numericAside
						? "btn--stepper--aside btn--stepper--aside--bottom"
						: "btn--stepper--left"
				}`}
				type="button"
				onClick={() => changeVal(reverseAdornment ? true : false)}
				disabled={reverseAdornment ? value === +max : value === +min}
			>
				-
			</button>
		);
	};

	/**
	 * Used to update input field value depending on the clicked adornment button. Sets new value.
	 *
	 * @param {bool} increment - Determines whether the value should be incremented or decremented
	 */
	const changeVal = (increment) => {
		if (!inputSelector) {
			return;
		}
		let value = +inputSelector.value;

		if (increment) {
			if (value >= max) {
				return;
			}
			value++;
		} else {
			if (value <= min) {
				return;
			}
			value--;
		}

		inputSelector.value = value;
		customUpdateValue && customUpdateValue(id, value);
	};

	let adornmentClass = "";
	let adornmentBody;

	const renderEndAdornment = () => {
		if (adornmentType || adornmentContent) {
			switch (adornmentType) {
				case adornmentTypeConstants.EMAIL:
					adornmentClass = "adornment__root--email";
					adornmentBody = "@customer.com";
					break;

				case adornmentTypeConstants.EDITOR:
					adornmentClass = "adornment__root--email";
					adornmentBody = <EditIcon iconClass="icon--target-fill fill--n200" />;
					break;

				case adornmentTypeConstants.PERCENTAGE:
					adornmentClass = "adornment__root--percentage";
					adornmentBody = "%";
					break;

				case adornmentTypeConstants.MILIMETERS:
					adornmentClass = "adornment__root--milimeters";
					adornmentBody = "mm";
					break;

				case adornmentTypeConstants.METERS:
					adornmentClass = "adornment__root--meters";
					adornmentBody = (
						<>
							m <sup>2</sup>
						</>
					);
					break;
				case adornmentTypeConstants.EURO:
					adornmentClass = "";
					adornmentBody = <>&euro;</>;
					break;

				case adornmentTypeConstants.HOURS:
					adornmentClass = "";
					adornmentBody = <>h</>;
					break;

				case adornmentTypeConstants.EURO_METERS:
					adornmentClass = "";
					adornmentBody = (
						<div className="d-flex align-items-center">
							<div className="color-n300">
								&euro;/m<sup>2</sup>
							</div>
						</div>
					);
					break;

				case adornmentTypeConstants.HOUR_SQUARE_METER:
					adornmentClass = "";
					adornmentBody = (
						<div className="d-flex align-items-center">
							<div className="color-n300">
								h/m<sup>2</sup>
							</div>
						</div>
					);
					break;

				case adornmentTypeConstants.SQUARE_METER_HOUR:
					adornmentClass = "";
					adornmentBody = (
						<div className="d-flex align-items-center">
							<div className="color-n300">
								m<sup>2</sup>/h
							</div>
						</div>
					);
					break;
				default:
					adornmentBody = adornmentContent || "";
					break;
			}
			return (
				<InputAdornment
					disableTypography={true}
					classes={{
						root: `
							adornment__root adornment__root--end
							${adornmentClass}
						`,
					}}
					position="end"
				>
					{adornmentBody}
				</InputAdornment>
			);
		}
		return null;
	};

	const validationNumber = (event) => {
		const value = event.target.value;
		const stringVal = value.toString();

		if (onlyInteger && (stringVal.includes(".") || stringVal.includes(","))) {
			const index = value.toString().indexOf(".");

			return +value.toString().slice(0, index);
		}

		return value;
	};

	const handleOnKeyUp = (event) => {
		const submit = event.keyCode === 13 && !event.shiftKey;
		const value = validationNumber(event);

		onKeyUp && onKeyUp(value, forwardSubmitEvent && submit);
	};

	const handleOnKeyDown = (event) => {
		const submit = event.keyCode === 13 && !event.shiftKey;
		const value = validationNumber(event);

		onKeyDown && onKeyDown(value, forwardSubmitEvent && submit);
	};

	return (
		<FormControl
			className={clsx("form__input form__input--text", size, classField)}
		>
			{label && (
				<CustomLabel
					label={label}
					labelAppend={labelAppend}
					required={required}
					infoContent={labelInfoContent}
					note={labelNote}
				/>
			)}
			<TextField
				tabIndex={tabIndex}
				autoFocus={autofocus}
				variant="standard"
				id={id}
				{...input}
				type={type}
				inputMode={inputMode}
				lang="sv-SE"
				disabled={disabled}
				error={errorExist}
				onWheel={(e) => e.target.blur()}
				onPaste={useDebounce({
					callback: (event) => {
						handleOnKeyDown(event);
					},
					delay: debounce ? debounceTime : 0,
				})}
				onKeyDown={useDebounce({
					callback: (event) => {
						handleOnKeyDown(event);
					},
					delay: debounce ? debounceTime : 0,
				})}
				onKeyUp={useDebounce({
					callback: (event) => {
						handleOnKeyUp(event);
					},
					delay: debounce ? debounceTime : 0,
				})}
				multiline={multiline}
				maxRows={maxRows}
				rows={rows}
				size={inputSize}
				placeholder={placeholder}
				InputProps={{
					classes: {
						root: `strip-native-styles
                ${
									inputMode === "numeric"
										? !pagination
											? "field__root--stepper__wrapper"
											: "field__root--pagination__wrapper"
										: ""
								}

								${numericAside ? "field__root--stepper__wrapper--aside" : ""}`,
						input: `field__root
						${multiline ? "field__root--multiline" : ""}
						${small ? (multiline ? "small" : "field__root--small") : ""}
                ${editorType ? "field__root--editor" : ""}
				${editorType && !!editorAlign ? "text-" + editorAlign : ""}
                ${
									inputMode === "numeric"
										? !pagination
											? "field__root--stepper"
											: "field__root--pagination"
										: ""
								}
								${textAlign && `field__root--p-default`}
				${numericAside ? "aside" : ""}
				${
					type === fieldTypeConstants.NUMBER && adornmentType
						? "field__root--adorned-end " +
							(!!adornmentType
								? `field__root--adorned-end--${adornmentType}`
								: "")
						: ""
				}
                ${
									value || (input && input.value) ? "field__root--complete" : ""
								}
                ${errorExist ? "field__root--error" : ""}`,
					},
					startAdornment: numberAdornment && (
						<InputAdornment
							classes={{
								root: `adornment__root adornment__root__stepper
										${
											numericAside
												? "adornment__root__stepper--start--aside"
												: "adornment__root__stepper--start"
										}
									`,
							}}
							position="start"
						>
							{numericAside && renderIncreaseInputVal()}
							{renderDecreaseInputVal()}
						</InputAdornment>
					),
					endAdornment:
						numberAdornment && !numericAside ? (
							<InputAdornment
								classes={{
									root: "adornment__root adornment__root__stepper adornment__root__stepper--end",
								}}
								position="end"
							>
								{renderIncreaseInputVal()}
							</InputAdornment>
						) : (
							renderEndAdornment()
						),
				}}
				inputProps={{ min: min, max: max, maxLength: maxLength }}
			/>
			{renderError(error, touched, customError, customErrorLabel)}
		</FormControl>
	);
};
