import { Map, fromJS } from "immutable";
import invoiceConstants from "../constants/invoiceConstants";
/**
 * Invoice reducer redux
 */

const initialState = Map({
	action: false,
	request: false,
	tempInvoice: Map(),
	quote: Map(),
	quoteId: "",
	invoiceList: Map(),
	invoiceMeta: Map(),
	getFailure: "",
	updateFailure: "",
	fieldValue: "",
	successMessage: "Success!",
	errorMessage: "Something went wrong",
});

//

export default (state = initialState, action) => {
	switch (action.type) {
		case invoiceConstants.GET_INVOICES:
			return state
				.set("invoiceList", fromJS(action.data.result))
				.set("invoiceMeta", fromJS(action.data.meta));

		case invoiceConstants.GET_INVOICE:
			return state
				.set("invoice", fromJS(action.data))
				.setIn(["tempInvoice", "file"], fromJS(action.data.files[0]))
				.setIn(["tempInvoice", "quoteId"], action.data.quote.id)
				.set("quote", fromJS(action.data.quote))
				.set("quoteId", action.data.quote.id);

		case invoiceConstants.I_ACTION_SUCCESS:
			return state.set("action", true).set("request", true);

		case invoiceConstants.I_ACTION_FAILURE:
			return state
				.set("action", false)
				.set("request", true)
				.set("errorResponse", fromJS(action.error));

		case invoiceConstants.SET_INVOICE_TEMP_QUOTEID:
			return state.setIn(["tempInvoice", "quoteId"], fromJS(action.data));

		case invoiceConstants.SET_INVOICE_TEMP_FILE:
			return state.setIn(["tempInvoice", "file"], fromJS(action.data));

		case invoiceConstants.REMOVE_INVOICE_TEMP_FILE:
			return state.setIn(["tempInvoice", "file"], null);

		case invoiceConstants.RESET_TEMP_INVOICE:
			return state
				.set("tempInvoice", Map())
				.set("action", false)
				.set("request", false);

		case invoiceConstants.SET_NEW_INVOICE:
			return state.set("newInvoice", fromJS(action.data));

		case invoiceConstants.I_REQUEST_DONE:
			return state.set("request", false);

		case invoiceConstants.I_ACTION_RESET:
			return state.set("action", false).set("request", false);

		case invoiceConstants.CLEAR_INVOICE:
			return state
				.set("invoice", "")
				.set("quote", Map())
				.set("quoteId", "")
				.set("action", false)
				.set("request", false)
				.setIn(["tempInvoice", "file"], null);

		case invoiceConstants.SET_SUCCESS_MESSAGE:
			return state.set("successMessage", fromJS(action.data));

		case invoiceConstants.SET_ERROR_MESSAGE:
			return state.set("errorMessage", fromJS(action.data));

		case invoiceConstants.CLEAR_INVOICE_REQUEST_STATE:
			return state.set("action", false).set("request", false);

		default:
			return state;
	}
};
