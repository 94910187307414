export const globalConstants = {
	SET_SELECTED_ROWS: "SET_SELECTED_ROWS",
	SET_CUSTOMER: "SET_CUSTOMER",
	SET_ROOM: "SET_ROOM",
};

export const FILE_PREFIX = {
	CUSTOMISATION: "customised_",
	QA: "quality_assured_",
};
