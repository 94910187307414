import { List, Map, fromJS } from "immutable";
import tempAdministrationConstants from "../constants/tempAdministrationConstants";
import { componentCategoryLabelConstants } from "../utils/label.util";
import { v4 as uuid } from "uuid";

/**
 *  Specification reducer redux
 */

const initialState = Map({
	types: Map(),
	inputFormat: Map(),
	deliveryFormats: Map(),
	deliveryFormat: Map(),
	disciplines: Map(),
	buildingComponents: Map(),
	buildingCategories: Map(),
	buildingTypes: Map(),
	modelingData: Map(),
	LODAdjustment: Map(),
	lodTimeAdjustments: Map(),
	repetitivenessAdjustments: Map(),
	buildingCategoryAdjustments: Map(),
	buildingCategoryTimeAdjustments: Map(),
	normalisationManuals: Map(),
	customisationManuals: Map(),
	specificationParameters: List(),
	specificationCategories: List(),
	qaManuals: Map(),
	manualLoader: false,
	termsAndConditions: "",
	others: Map(),
	DXFServerSettings: Map(),
	getFailure: "",
});

export default (state = initialState, action) => {
	//---------------------------DISCIPLINES----------------------------

	const addDisciplines = () => {
		const disciplines = state.get("disciplines");
		let tempDsciplines = disciplines ? disciplines?.toJS() : [];

		let tempDsciplinesSort = disciplines
			? disciplines?.toJS().sort((a, b) => {
					return a.tempId - b.tempId;
				})
			: [];

		const lastId =
			tempDsciplinesSort[tempDsciplinesSort.length - 1]?.tempId + 1;

		tempDsciplines.push({
			tempId: lastId,
			id: null,
			type: "",
			valid: false,
		});

		return state.set("disciplines", fromJS(tempDsciplines));
	};
	const removeDiscipline = (id) => {
		const disciplines = state.get("disciplines");
		let tempDsciplines = disciplines ? disciplines?.toJS() : [];
		tempDsciplines = tempDsciplines?.filter(
			(discpline) => discpline?.tempId !== id
		);

		return state.set("disciplines", fromJS(tempDsciplines));
	};
	const setDisciplines = (disciplines) => {
		let tempDsciplines = [];

		disciplines &&
			disciplines.map((item) => {
				return tempDsciplines.push({
					id: item.id,
					tempId: item.id,
					type: item.type,
					valid: true,
				});
			});

		return state.set("disciplines", fromJS(tempDsciplines));
	};
	const updateDiscipline = (data) => {
		const { id, type } = data;
		const disciplines = state.get("disciplines");
		const findIndex =
			disciplines &&
			disciplines?.toJS()?.findIndex((discpline) => discpline?.tempId === id);

		return state
			.setIn(["disciplines", findIndex, "type"], type)
			.setIn(
				["disciplines", findIndex, "valid"],
				!!String(type).trim() ? true : false
			);
	};

	const updateTempDisciplineSortOrder = (data) => {
		const { dragId, overId } = data;
		const disciplines = state.get("disciplines");
		let tempDsciplines = disciplines ? disciplines?.toJS() : [];

		const findIndexOver = tempDsciplines.findIndex(
			(discipline) => discipline?.tempId === overId
		);
		const findIndexDrag = tempDsciplines.findIndex(
			(discipline) => discipline?.tempId === dragId
		);

		if (findIndexDrag !== -1 && findIndexOver !== -1)
			if (findIndexOver > findIndexDrag) {
				tempDsciplines.splice(
					findIndexOver + 1,
					0,
					tempDsciplines[findIndexDrag]
				);
				tempDsciplines.splice(findIndexDrag, 1);
			} else if (findIndexOver < findIndexDrag) {
				tempDsciplines.splice(findIndexOver, 0, tempDsciplines[findIndexDrag]);
				tempDsciplines.splice(findIndexDrag + 1, 1);
			}

		return state.set("disciplines", fromJS(tempDsciplines));
	};

	//------------------------------LODS---------------------------------

	const addLods = () => {
		const lods = state.get("lods");
		let tempLods = lods ? lods?.toJS() : [];

		const lastLodId = tempLods[tempLods.length - 1]?.tempId + 1;

		tempLods.push({
			tempId: lastLodId,
			id: null,
			type: "",
			valid: false,
		});

		return state.set("lods", fromJS(tempLods));
	};
	const removeLod = (id) => {
		const lods = state.get("lods");
		let tempLods = lods ? lods?.toJS() : [];
		tempLods = tempLods?.filter((lod) => lod?.tempId !== id);

		return state.set("lods", fromJS(tempLods));
	};
	const setTempLods = (lods) => {
		let tempLods = [];

		lods &&
			lods.map((item) => {
				return tempLods.push({
					id: item.id,
					tempId: item.id,
					type: item.type,
					valid: true,
				});
			});

		return state.set("lods", fromJS(tempLods));
	};

	const updateLod = (data) => {
		const { id, type } = data;
		const lods = state.get("lods");
		const findIndex =
			lods && lods?.toJS()?.findIndex((lod) => lod?.tempId === id);

		return state
			.setIn(["lods", findIndex, "type"], type)
			.setIn(
				["lods", findIndex, "valid"],
				!!String(type).trim() ? true : false
			);
	};
	const updateTempLODSortOrder = (data) => {
		const { dragId, overId } = data;
		const lods = state.get("lods");
		let tempLods = lods ? lods?.toJS() : [];

		const findIndexOver = tempLods.findIndex((lod) => lod?.tempId === overId);
		const findIndexDrag = tempLods.findIndex((lod) => lod?.tempId === dragId);

		if (findIndexDrag !== -1 && findIndexOver !== -1)
			if (findIndexOver > findIndexDrag) {
				tempLods.splice(findIndexOver + 1, 0, tempLods[findIndexDrag]);
				tempLods.splice(findIndexDrag, 1);
			} else if (findIndexOver < findIndexDrag) {
				tempLods.splice(findIndexOver, 0, tempLods[findIndexDrag]);
				tempLods.splice(findIndexDrag + 1, 1);
			}

		return state.set("lods", fromJS(tempLods));
	};

	//---------------------------BUILDING_COMPONENTS----------------------

	const setBuildingComponents = (buildingComponents) => {
		let tempBuildingComponents = [];

		buildingComponents &&
			buildingComponents.map((item) => {
				return tempBuildingComponents.push({
					id: item.id,
					tempId: item.id,
					componentCategoryId: item.componentCategory.id,
					componentCategoryType: !!componentCategoryLabelConstants[
						item.componentCategory.type
					]
						? componentCategoryLabelConstants[item.componentCategory.type]
						: item.componentCategory.type,
					disciplineId: item.discipline.id,
					valid: true,
				});
			});

		return state.set("buildingComponents", fromJS(tempBuildingComponents));
	};
	const removeBuildingComponents = (id) => {
		const buildingComponents = state.get("buildingComponents");
		let tempBuildingComponents = buildingComponents
			? buildingComponents?.toJS()
			: [];
		tempBuildingComponents = tempBuildingComponents?.filter(
			(tempBuildingComponent) => tempBuildingComponent?.tempId !== id
		);
		return state.set("buildingComponents", fromJS(tempBuildingComponents));
	};
	const updateBuildingComponents = ({ fieldKey, id, value }) => {
		const buildingComponents = state.get("buildingComponents");
		let tempBuildingComponents = buildingComponents
			? buildingComponents?.toJS()
			: [];
		const findIndex = tempBuildingComponents?.findIndex(
			(tempBuildingComponent) => tempBuildingComponent?.tempId === id
		);
		const find = tempBuildingComponents?.find(
			(tempBuildingComponent) => tempBuildingComponent?.tempId === id
		);

		let tempValid = false;
		if (fieldKey === "componentCategoryType" && !!find.disciplineId) {
			tempValid = !!String(value).trim() ? true : false;
		} else if (
			fieldKey === "disciplineId" &&
			!!String(find.componentCategoryType).trim()
		) {
			tempValid = true;
		}

		return state
			.setIn(["buildingComponents", findIndex, fieldKey], value)
			.setIn(
				["buildingComponents", findIndex, "valid"],
				!!value && tempValid ? true : false
			);
	};
	const addBuildingComponents = () => {
		const buildingComponents = state.get("buildingComponents");
		let tempBuildingComponents = buildingComponents
			? buildingComponents?.toJS()
			: [];
		let tempBuildingComponentsSort = buildingComponents
			? buildingComponents?.toJS()?.sort((a, b) => {
					return a.tempId - b.tempId;
				})
			: [];

		const lastBuildingComponentId =
			tempBuildingComponentsSort[tempBuildingComponentsSort.length - 1]
				?.tempId + 1;

		tempBuildingComponents.push({
			tempId: lastBuildingComponentId,
			id: null,
			componentCategoryId: null,
			componentCategoryType: "",
			disciplineId: "",
			valid: false,
		});

		return state.set("buildingComponents", fromJS(tempBuildingComponents));
	};

	const updateTempBuildingComponentsSortOrder = (data) => {
		const { dragId, overId } = data;
		const buildingComponents = state.get("buildingComponents");
		let tempBuildingComponents = buildingComponents
			? buildingComponents?.toJS()
			: [];

		const findIndexOver = tempBuildingComponents.findIndex(
			(buildingComponent) => buildingComponent?.tempId === overId
		);
		const findIndexDrag = tempBuildingComponents.findIndex(
			(buildingComponent) => buildingComponent?.tempId === dragId
		);

		if (findIndexDrag !== -1 && findIndexOver !== -1)
			if (findIndexOver > findIndexDrag) {
				tempBuildingComponents.splice(
					findIndexOver + 1,
					0,
					tempBuildingComponents[findIndexDrag]
				);
				tempBuildingComponents.splice(findIndexDrag, 1);
			} else if (findIndexOver < findIndexDrag) {
				tempBuildingComponents.splice(
					findIndexOver,
					0,
					tempBuildingComponents[findIndexDrag]
				);
				tempBuildingComponents.splice(findIndexDrag + 1, 1);
			}

		return state.set("buildingComponents", fromJS(tempBuildingComponents));
	};

	//---------------------------BUILDING CATEGORIES-----------------------

	const setTempBuildingCategories = (categories) => {
		let tempBuildingCategories = [];

		categories &&
			categories.map((item) => {
				return tempBuildingCategories.push({
					id: item.id,
					tempId: item.id,
					type: item.type,
					valid: true,
				});
			});

		return state.set("buildingCategories", fromJS(tempBuildingCategories));
	};
	const removeBuildingCategory = (id) => {
		const buildingCategories = state.get("buildingCategories");
		let tempBuildingCategories = buildingCategories
			? buildingCategories?.toJS()
			: [];
		tempBuildingCategories = tempBuildingCategories?.filter(
			(tempBuildingCategory) => tempBuildingCategory?.tempId !== id
		);
		return state.set("buildingCategories", fromJS(tempBuildingCategories));
	};
	const updateBuildingCategory = (data) => {
		const { id, value } = data;

		const buildingCategories = state.get("buildingCategories");
		let tempBuildingCategories = buildingCategories
			? buildingCategories?.toJS()
			: [];

		const findIndex = tempBuildingCategories.findIndex(
			(item) => item.tempId === id
		);

		return state
			.setIn(["buildingCategories", findIndex, "type"], value)
			.setIn(
				["buildingCategories", findIndex, "valid"],
				!!String(value).trim() ? true : false
			);
	};
	const addBuildingCategory = () => {
		const buildingCategories = state.get("buildingCategories");
		let tempBuildingCategories = buildingCategories
			? buildingCategories?.toJS()
			: [];
		let tempBuildingCategoriesSort = buildingCategories
			? buildingCategories?.toJS()?.sort((a, b) => {
					return a.tempId - b.tempId;
				})
			: [];

		const lastBuildingCategoryId =
			tempBuildingCategoriesSort[tempBuildingCategoriesSort.length - 1]
				?.tempId + 1;

		tempBuildingCategories.push({
			tempId: lastBuildingCategoryId,
			id: null,
			type: "",
			valid: false,
		});

		return state.set("buildingCategories", fromJS(tempBuildingCategories));
	};
	const updateTempBuildingCategoriesSortOrder = (data) => {
		const { dragId, overId } = data;
		const buildingCategories = state.get("buildingCategories");
		let tempBuildingCategories = buildingCategories
			? buildingCategories?.toJS()
			: [];

		const findIndexOver = tempBuildingCategories.findIndex(
			(buildingCategory) => buildingCategory?.tempId === overId
		);
		const findIndexDrag = tempBuildingCategories.findIndex(
			(buildingCategory) => buildingCategory?.tempId === dragId
		);

		if (findIndexDrag !== -1 && findIndexOver !== -1)
			if (findIndexOver > findIndexDrag) {
				tempBuildingCategories.splice(
					findIndexOver + 1,
					0,
					tempBuildingCategories[findIndexDrag]
				);
				tempBuildingCategories.splice(findIndexDrag, 1);
			} else if (findIndexOver < findIndexDrag) {
				tempBuildingCategories.splice(
					findIndexOver,
					0,
					tempBuildingCategories[findIndexDrag]
				);
				tempBuildingCategories.splice(findIndexDrag + 1, 1);
			}

		return state.set("buildingCategories", fromJS(tempBuildingCategories));
	};

	//---------------------------BUILDING TYPES-----------------------

	const setTempBuildingTypes = (categories) => {
		let tempBuildingTypes = [];

		categories &&
			categories.map((item) => {
				const parent = item?.parent || null;

				return tempBuildingTypes.push({
					id: item.id,
					tempId: item.id,
					categoryId: parent.id,
					type: item.type,
					valid: true,
				});
			});

		return state.set("buildingTypes", fromJS(tempBuildingTypes));
	};
	const removeBuildingType = (id) => {
		const buildingTypes = state.get("buildingTypes");
		let tempBuildingTypes = buildingTypes ? buildingTypes?.toJS() : [];

		tempBuildingTypes = tempBuildingTypes?.filter(
			(tempBuildingType) => tempBuildingType?.tempId !== id
		);
		return state.set("buildingTypes", fromJS(tempBuildingTypes));
	};
	const updateBuildingType = (data) => {
		const { fieldKey, id, value } = data;
		const buildingTypes = state.get("buildingTypes");
		let tempBuildingTypes = buildingTypes ? buildingTypes?.toJS() : [];

		const findIndex = tempBuildingTypes.findIndex((item) => item.tempId === id);
		const find = tempBuildingTypes.find((item) => item.tempId === id);

		let tempValid = false;
		if (fieldKey === "type" && !!find.categoryId) {
			tempValid = !!String(value).trim() ? true : false;
		} else if (fieldKey === "categoryId" && !!String(find.type).trim()) {
			tempValid = true;
		}

		return state
			.setIn(["buildingTypes", findIndex, fieldKey], value)
			.setIn(
				["buildingTypes", findIndex, "valid"],
				!!value && tempValid ? true : false
			);
	};
	const addBuildingType = () => {
		const buildingTypes = state.get("buildingTypes");
		let tempBuildingTypes = buildingTypes ? buildingTypes?.toJS() : [];

		let tempBuildingTypesSort = buildingTypes
			? buildingTypes?.toJS()?.sort((a, b) => {
					return a.tempId - b.tempId;
				})
			: [];

		const lastBuildingTypeId =
			tempBuildingTypesSort[tempBuildingTypesSort.length - 1]?.tempId + 1;

		tempBuildingTypes.push({
			tempId: lastBuildingTypeId,
			id: null,
			categoryId: "",
			type: "",
			valid: false,
		});

		return state.set("buildingTypes", fromJS(tempBuildingTypes));
	};
	const updateTempBuildingTypesSortOrder = (data) => {
		const { dragId, overId } = data;
		const buildingTypes = state.get("buildingTypes");
		let tempBuildingTypes = buildingTypes ? buildingTypes?.toJS() : [];

		const findIndexOver = tempBuildingTypes.findIndex(
			(buildingType) => buildingType?.tempId === overId
		);
		const findIndexDrag = tempBuildingTypes.findIndex(
			(buildingType) => buildingType?.tempId === dragId
		);

		if (findIndexDrag !== -1 && findIndexOver !== -1)
			if (findIndexOver > findIndexDrag) {
				tempBuildingTypes.splice(
					findIndexOver + 1,
					0,
					tempBuildingTypes[findIndexDrag]
				);
				tempBuildingTypes.splice(findIndexDrag, 1);
			} else if (findIndexOver < findIndexDrag) {
				tempBuildingTypes.splice(
					findIndexOver,
					0,
					tempBuildingTypes[findIndexDrag]
				);
				tempBuildingTypes.splice(findIndexDrag + 1, 1);
			}

		return state.set("buildingTypes", fromJS(tempBuildingTypes));
	};

	//---------------------------MODELING DATA-----------------------

	const setTempModeling = (data) => {
		const { modelingData, disciplines } = data;
		const tempModeling = [];

		disciplines &&
			disciplines.map((discipline) => {
				const disciplineId = discipline?.id;
				const disciplineComponentCategories =
					discipline?.disciplineComponentCategories;

				return (
					disciplineComponentCategories &&
					disciplineComponentCategories.map((dcc) => {
						let tempAdjustments = [];

						modelingData &&
							modelingData.map((item) => {
								const mpDisciplineId =
									item?.disciplineComponentCategory?.discipline?.id;
								const mpComponentCategoryId =
									item?.disciplineComponentCategory?.componentCategory?.id;

								if (
									mpDisciplineId === disciplineId &&
									mpComponentCategoryId === dcc?.componentCategory?.id
								) {
									return tempAdjustments.push({
										id: item?.id,
										tempId: item?.id,
										adjustment: item?.adjustment || 0,
										timeAdjustment: item?.timeAdjustment || 0,
										inputFormat: item?.inputFormat.id,
										valid: true,
									});
								}

								return "";
							});

						return tempModeling.push({
							id: dcc.id,
							tempId: dcc.id,
							type: dcc?.componentCategory?.type,
							discipline: discipline?.type,
							valid: true,
							disciplineComponentCategoryInputFormatAdjustments:
								tempAdjustments,
						});
					})
				);
			});

		return state.set("modelingData", fromJS(tempModeling));
	};
	const updateTempModelingPrice = (data) => {
		const { fieldKey, value, id, inputFormat = null } = data;
		const modelingData = state.get("modelingData");
		let tempModelingData = modelingData ? modelingData?.toJS() : [];

		const findIndex = tempModelingData.findIndex((item) => item.tempId === id);

		if (!!inputFormat) {
			const findModelingData = tempModelingData.find(
				(item) => item.tempId === id
			);
			const findAdjustmentIndex =
				findModelingData?.disciplineComponentCategoryInputFormatAdjustments?.findIndex(
					(item) => item.inputFormat === inputFormat
				);

			return state
				.setIn(
					[
						"modelingData",
						findIndex,
						"disciplineComponentCategoryInputFormatAdjustments",
						findAdjustmentIndex,
						fieldKey,
					],
					value
				)
				.setIn(
					[
						"modelingData",
						findIndex,
						"disciplineComponentCategoryInputFormatAdjustments",
						findAdjustmentIndex,
						"valid",
					],
					!!value ? true : false
				);
		} else {
			return state
				.setIn(["modelingData", findIndex, fieldKey], value)
				.setIn(
					["modelingData", findIndex, "valid"],
					!!String(value).trim() ? true : false
				);
		}
	};
	const removeTempModelingPrice = (id) => {
		const modelingData = state.get("modelingData");
		let tempModelingData = modelingData ? modelingData?.toJS() : [];

		tempModelingData = tempModelingData?.filter(
			(tempModelingPrice) => tempModelingPrice?.tempId !== id
		);
		return state.set(
			"modelingData",
			tempModelingData ? fromJS(tempModelingData) : Map()
		);
	};
	const addTempModelingPrice = () => {
		const modelingData = state.get("modelingData");
		const inputFormats = state.get("inputFormat");

		let tempModelingData = modelingData ? modelingData?.toJS() : [];

		let tempModelingDataSort = modelingData
			? modelingData?.toJS()?.sort((a, b) => {
					return a.tempId - b.tempId;
				})
			: [];

		const lastPriceCategoryId =
			tempModelingDataSort?.length > 0
				? +tempModelingDataSort[tempModelingDataSort.length - 1]?.tempId + 1
				: 0;
		const tempAdjustments = [];

		inputFormats &&
			inputFormats.map((item) => [
				tempAdjustments.push({
					id: null,
					tempId: null,
					adjustment: null,
					inputFormat: item?.get("id"),
					valid: false,
				}),
			]);

		tempModelingData.push({
			valid: false,
			tempId: lastPriceCategoryId,
			id: null,
			name: "",
			type: null,
			disciplineComponentCategoryInputFormatAdjustments: tempAdjustments,
		});

		return state.set("modelingData", fromJS(tempModelingData));
	};

	//---------------------------LOD_ADJUSTMENT---------------

	const setTempLODAdjustment = (data) => {
		const LODAdjustments = data;
		const tempLODAdjustments = [];

		LODAdjustments &&
			LODAdjustments.map((LODAdjustment) => {
				const adjustments = LODAdjustment?.lodInputFormatAdjustments;

				let tempAdjustments = [];
				let adjustment = 0;
				let timeAdjustment = 0;

				adjustments &&
					adjustments.map((item) => {
						adjustment = +item?.adjustment * 100;
						timeAdjustment = (+item?.timeAdjustment * 100).toFixed(2);

						return tempAdjustments.push({
							id: item?.id,
							tempId: item?.id,
							adjustment: (+item?.adjustment * 100).toFixed(2),
							timeAdjustment: timeAdjustment,
							inputFormat: item?.inputFormat.id,
							valid: true,
						});
					});

				return tempLODAdjustments.push({
					id: LODAdjustment.id,
					tempId: LODAdjustment.id,
					name: LODAdjustment.type,
					adjustment: adjustment.toFixed(2),
					timeAdjustment,
					valid: true,
					lodInputFormatAdjustments: tempAdjustments,
				});
			});

		return state.set("LODAdjustments", fromJS(tempLODAdjustments));
	};
	const updateTempLODAdjustment = (data) => {
		const { fieldKey, value, id } = data;
		const LODAdjustments = state.get("LODAdjustments");
		let tempLODAdjustments = LODAdjustments ? LODAdjustments?.toJS() : [];

		const findIndex = tempLODAdjustments.findIndex(
			(item) => item.tempId === id
		);

		return state
			.setIn(["LODAdjustments", findIndex, fieldKey], value)
			.setIn(
				["LODAdjustments", findIndex, "valid"],
				!!String(value).trim() ? true : false
			);
	};
	const removeTempLODAdjustment = (id) => {
		const LODAdjustments = state.get("LODAdjustments");
		let tempLODAdjustments = LODAdjustments ? LODAdjustments?.toJS() : [];

		tempLODAdjustments = tempLODAdjustments?.filter(
			(tempLODAdjustment) => tempLODAdjustment?.tempId !== id
		);
		return state.set(
			"LODAdjustments",
			tempLODAdjustments ? fromJS(tempLODAdjustments) : Map()
		);
	};
	const addTempLODAdjustment = () => {
		const LODAdjustments = state.get("LODAdjustments");
		const inputFormats = state.get("inputFormat");

		let tempLODAdjustments = LODAdjustments ? LODAdjustments?.toJS() : [];

		let tempLODAdjustmentsSort = LODAdjustments
			? LODAdjustments?.toJS()?.sort((a, b) => {
					return a.tempId - b.tempId;
				})
			: [];

		const lastLODAdjustmentId =
			tempLODAdjustmentsSort?.length > 0
				? +tempLODAdjustmentsSort[tempLODAdjustmentsSort.length - 1]?.tempId + 1
				: 0;
		const tempAdjustments = [];

		inputFormats &&
			inputFormats.map((item) => [
				tempAdjustments.push({
					id: null,
					tempId: null,
					adjustment: "",
					valid: false,
					inputFormat: item?.get("id"),
				}),
			]);

		tempLODAdjustments.push({
			tempId: lastLODAdjustmentId,
			id: null,
			name: "",
			type: null,
			valid: false,
			lodInputFormatAdjustments: tempAdjustments,
		});

		return state.set("LODAdjustments", fromJS(tempLODAdjustments));
	};

	//---------------------------PRICE_ADJUSTMENT---------------

	const setTempBuildingCategoryAdjustment = (data) => {
		const buildingCategoryAdjustments = data;
		const tempBuildingCategoryAdjustments = [];

		buildingCategoryAdjustments &&
			buildingCategoryAdjustments.map((buildingCategoryAdjustment) => {
				const adjustments =
					buildingCategoryAdjustment?.buildingTypeInputFormatAdjustments;

				let tempAdjustments = [];
				let adjustment = 0;
				let timeAdjustment = 0;

				adjustments &&
					adjustments.map((item) => {
						adjustment = (+item?.adjustment * 100).toFixed(2);
						timeAdjustment = (+item?.timeAdjustment * 100).toFixed(2);
						return tempAdjustments.push({
							id: item?.id,
							tempId: item?.id,
							adjustment: adjustment,
							timeAdjustment: timeAdjustment,
							inputFormat: item?.inputFormat.id,
							valid: true,
						});
					});

				return tempBuildingCategoryAdjustments.push({
					id: buildingCategoryAdjustment.id,
					tempId: buildingCategoryAdjustment.id,
					adjustment: adjustment,
					timeAdjustment: timeAdjustment,
					name: buildingCategoryAdjustment.type,
					valid: true,
					buildingTypeInputFormatAdjustments: tempAdjustments,
				});
			});

		return state.set(
			"buildingCategoryAdjustments",
			fromJS(tempBuildingCategoryAdjustments)
		);
	};

	const updateTempBuildingCategoryAdjustment = (data) => {
		const { fieldKey, value, id } = data;
		const buildingCategoryAdjustments = state.get(
			"buildingCategoryAdjustments"
		);
		let tempBuildingCategoryAdjustments = buildingCategoryAdjustments
			? buildingCategoryAdjustments?.toJS()
			: [];

		const findIndex = tempBuildingCategoryAdjustments.findIndex(
			(item) => item.tempId === id
		);
		return state
			.setIn(["buildingCategoryAdjustments", findIndex, fieldKey], value)
			.setIn(
				["buildingCategoryAdjustments", findIndex, "valid"],
				!!String(value).trim() ? true : false
			);
	};

	const removeTempBuildingCategoryAdjustment = (id) => {
		const buildingCategoryAdjustments = state.get(
			"buildingCategoryAdjustments"
		);
		let tempBuildingCategoryAdjustments = buildingCategoryAdjustments
			? buildingCategoryAdjustments?.toJS()
			: [];

		tempBuildingCategoryAdjustments = tempBuildingCategoryAdjustments?.filter(
			(tempPriceAdjustment) => tempPriceAdjustment?.tempId !== id
		);
		return state.set(
			"buildingCategoryAdjustments",
			tempBuildingCategoryAdjustments
				? fromJS(tempBuildingCategoryAdjustments)
				: Map()
		);
	};

	const addTempBuildingCategoryAdjustment = () => {
		const buildingCategoryAdjustments = state.get(
			"buildingCategoryAdjustments"
		);
		const inputFormats = state.get("inputFormat");

		let tempBuildingCategoryAdjustments = buildingCategoryAdjustments
			? buildingCategoryAdjustments?.toJS()
			: [];

		let tempBuildingCategoryAdjustmentsSort = buildingCategoryAdjustments
			? buildingCategoryAdjustments?.toJS()?.sort((a, b) => {
					return a.tempId - b.tempId;
				})
			: [];

		const lastPriceAdjustmentId =
			tempBuildingCategoryAdjustmentsSort?.length > 0
				? +tempBuildingCategoryAdjustmentsSort[
						tempBuildingCategoryAdjustmentsSort.length - 1
					]?.tempId + 1
				: 0;
		const tempAdjustments = [];

		inputFormats &&
			inputFormats.map((item) => [
				tempAdjustments.push({
					id: null,
					tempId: null,
					adjustment: "",
					valid: false,
					inputFormat: item?.get("id"),
				}),
			]);

		tempBuildingCategoryAdjustments.push({
			tempId: lastPriceAdjustmentId,
			id: null,
			name: "",
			type: null,
			valid: false,
			buildingTypeInputFormatAdjustments: tempAdjustments,
		});

		return state.set(
			"buildingCategoryAdjustments",
			fromJS(tempBuildingCategoryAdjustments)
		);
	};

	//---------------------------REPETITIVENESS ADJUSTMENT---------------
	const setTempRepetitivenessAdjustment = (data) => {
		const repetitivenessAdjustments = data;
		const tempRepetitivenessAdjustments = [];

		repetitivenessAdjustments &&
			repetitivenessAdjustments.map((rAdjustments) => {
				return tempRepetitivenessAdjustments.push({
					id: rAdjustments.id,
					tempId: rAdjustments.id,
					repetitivenessConstant: rAdjustments?.repetitivenessConstant,
					timeRepetitivenessConstant: rAdjustments?.timeRepetitivenessConstant,
					name: rAdjustments.inputFormat.type,
					valid: true,
				});
			});

		return state.set(
			"repetitivenessAdjustments",
			fromJS(tempRepetitivenessAdjustments)
		);
	};

	const updateTempRepetitivenessAdjustment = (data) => {
		const { value, id, fieldKey } = data;
		const repetitivenessAdjustments = state.get("repetitivenessAdjustments");
		let tempRepetitivenessAdjustments = repetitivenessAdjustments
			? repetitivenessAdjustments?.toJS()
			: [];

		const findIndex = tempRepetitivenessAdjustments.findIndex(
			(item) => item.tempId === id
		);

		return state
			.setIn(["repetitivenessAdjustments", findIndex, fieldKey], value)
			.setIn(
				["repetitivenessAdjustments", findIndex, "valid"],
				!!String(value).trim() ? true : false
			);
	};

	const removeTempRepetitivenessAdjustment = (id) => {
		const repetitivenessAdjustments = state.get("repetitivenessAdjustments");
		let tempRepetitivenessAdjustments = repetitivenessAdjustments
			? repetitivenessAdjustments?.toJS()
			: [];

		tempRepetitivenessAdjustments = tempRepetitivenessAdjustments?.filter(
			(tempRAdjustment) => tempRAdjustment?.tempId !== id
		);
		return state.set(
			"repetitivenessAdjustments",
			tempRepetitivenessAdjustments
				? fromJS(tempRepetitivenessAdjustments)
				: Map()
		);
	};

	const addTempRepetitivenessAdjustment = () => {
		const repetitivenessAdjustments = state.get("repetitivenessAdjustments");

		let tempRepetitivenessAdjustments = repetitivenessAdjustments
			? repetitivenessAdjustments?.toJS()
			: [];

		let tempRepetitivenessAdjustmentsSort = repetitivenessAdjustments
			? repetitivenessAdjustments?.toJS()?.sort((a, b) => {
					return a.tempId - b.tempId;
				})
			: [];

		const lastRepetitivenessAdjustmentId =
			tempRepetitivenessAdjustmentsSort?.length > 0
				? +tempRepetitivenessAdjustmentsSort[
						tempRepetitivenessAdjustmentsSort.length - 1
					]?.tempId + 1
				: 0;

		tempRepetitivenessAdjustments.push({
			tempId: lastRepetitivenessAdjustmentId,
			id: null,
			name: "",
			type: null,
			valid: false,
			adjustment: null,
		});

		return state.set(
			"repetitivenessAdjustments",
			fromJS(tempRepetitivenessAdjustments)
		);
	};
	//---------------------------OTHERS----------------------------

	const setTempOthers = (data) => {
		const tempOthers = [];

		data &&
			data.map((item) => {
				return tempOthers.push({
					id: item.id,
					tempId: item.id,
					type: item.type,
					price: item.price,
					children: item.children,
					valid: true,
				});
			});

		return state.set("others", tempOthers ? fromJS(tempOthers) : Map());
	};

	const addTempOthers = () => {
		const others = state.get("others");
		let tempOthers = others ? others?.toJS() : [];

		let tempOthersSort = others
			? others?.toJS()?.sort((a, b) => {
					return a.tempId - b.tempId;
				})
			: [];

		const lastTempOtherId =
			tempOthersSort[tempOthersSort.length - 1]?.tempId + 1;

		tempOthers.push({
			tempId: lastTempOtherId,
			id: null,
			type: "",
			price: null,
			valid: false,
		});

		return state.set("others", fromJS(tempOthers));
	};

	const removeTempOthers = (id) => {
		const others = state.get("others");
		let tempOthers = others ? others?.toJS() : [];

		tempOthers = tempOthers?.filter((tempOther) => tempOther?.tempId !== id);
		return state.set("others", fromJS(tempOthers));
	};

	const updateTempOthers = (data) => {
		const { fieldKey, id, value } = data;
		const others = state.get("others");
		let tempOthers = others ? others?.toJS() : [];

		const findIndex = tempOthers.findIndex((item) => item.tempId === id);

		let tempValid = false;
		if (fieldKey === "type" && !!value) {
			tempValid = !!String(value).trim() ? true : false;
		} else if (fieldKey === "price" && !!String(value).trim()) {
			tempValid = true;
		}

		return state
			.setIn(["others", findIndex, fieldKey], value)
			.setIn(
				["others", findIndex, "valid"],
				!!value && tempValid ? true : false
			);
	};

	//-----------------------------------------------------

	// const setTempLodContent = (data) => {
	// 	const { disciplines } = data;

	// 	return state.set(
	// 		"lodDescriptions",
	// 		disciplines ? fromJS(disciplines) : null
	// 	);
	// };

	// const updateTempLodContent = (data) => {
	// 	const { disciplineId, componentCategoryId, lodId, description } = data;
	// 	const lodDescriptions = state.get("lodDescriptions");

	// 	const lodDescriptionsJS = lodDescriptions ? lodDescriptions?.toJS() : null;

	// 	//Find DISCIPLINE index
	// 	const findIndexDiscipline = lodDescriptionsJS.findIndex(
	// 		(lodDescription) => lodDescription.id === disciplineId
	// 	);

	// 	//Find CATGEORY index
	// 	const disciplineComponentCategories =
	// 		lodDescriptionsJS[findIndexDiscipline]?.disciplineComponentCategories;

	// 	const findIndexCategory =
	// 		disciplineComponentCategories &&
	// 		disciplineComponentCategories.findIndex(
	// 			(disciplineComponentCategory) =>
	// 				disciplineComponentCategory.componentCategory.id ===
	// 				componentCategoryId
	// 		);

	// 	//Find LOD index
	// 	const disciplineComponentCategoryLodsDescription =
	// 		disciplineComponentCategories[findIndexCategory]
	// 			?.disciplineComponentCategoryLodsDescription;

	// 	const findIndexLod =
	// 		disciplineComponentCategoryLodsDescription &&
	// 		disciplineComponentCategoryLodsDescription.findIndex(
	// 			(item) => item.lod?.id === lodId
	// 		);

	// 	return state.setIn(
	// 		[
	// 			"lodDescriptions",
	// 			findIndexDiscipline,
	// 			"disciplineComponentCategories",
	// 			findIndexCategory,
	// 			"disciplineComponentCategoryLodsDescription",
	// 			findIndexLod,
	// 			"description",
	// 		],
	// 		description
	// 	);
	// };

	//---------------------------NORMALISATION MANUAL -------------------------------
	const setTempNormalisationManual = (normalisationManuals) => {
		let tempNormalisationManuals = [];

		normalisationManuals &&
			normalisationManuals.map((item) => {
				return tempNormalisationManuals.push({
					id: item.id,
					tempId: item.id,
					inputFormatId: item.inputFormat.id,
					inputFormatType: item.inputFormat.type,
					manual: item.manualDescription,
					valid: true,
				});
			});

		return state.set("normalisationManuals", fromJS(tempNormalisationManuals));
	};

	const addTempNormalisationManual = () => {
		const normalisationManuals = state.get("normalisationManuals");
		let tempNormalisationManuals = normalisationManuals
			? normalisationManuals?.toJS()
			: [];

		const lastId =
			tempNormalisationManuals.length > 0
				? tempNormalisationManuals[tempNormalisationManuals.length - 1]
						?.tempId + 1
				: 1;

		tempNormalisationManuals.push({
			id: null,
			tempId: lastId,
			inputFormatId: null,
			manual: "",
			valid: false,
		});

		return state.set("normalisationManuals", fromJS(tempNormalisationManuals));
	};

	const updateTempNormalisationManual = (data) => {
		const { id, fieldKey, value } = data;
		const normalisationManuals = state.get("normalisationManuals");
		let tempNormalisationManuals = normalisationManuals
			? normalisationManuals?.toJS()
			: [];
		const findIndex = tempNormalisationManuals?.findIndex(
			(item) => item.tempId === id
		);

		return state.setIn(["normalisationManuals", findIndex, fieldKey], value);
	};

	//---------------------------CUSTOMISATION MANUAL-------------------------------
	const setTempCustomisationManual = (customisationManuals) => {
		let tempCustomisationManuals = [];

		customisationManuals &&
			customisationManuals.map((item) => {
				return tempCustomisationManuals.push({
					id: item.id,
					tempId: item.id,
					deliveryFormatId: item.deliveryFormat.id,
					deliveryFormatType: item.deliveryFormat.type,
					manual: item.manualDescription,
					valid: true,
				});
			});

		return state.set("customisationManuals", fromJS(tempCustomisationManuals));
	};

	const updateTempCustomisationManual = (data) => {
		const { id, fieldKey, value } = data;
		const customisationManuals = state.get("customisationManuals");
		let tempCustomisationManuals = customisationManuals
			? customisationManuals?.toJS()
			: [];
		const findIndex = tempCustomisationManuals?.findIndex(
			(item) => item.tempId === id
		);

		return state.setIn(["customisationManuals", findIndex, fieldKey], value);
	};
	//---------------------------QA MANUAL-------------------------------
	const setTempQAManual = (qaManuals) => {
		let tempQAManuals = [];

		qaManuals &&
			qaManuals.map((item) => {
				return tempQAManuals.push({
					id: item.id,
					tempId: item.id,
					deliveryFormatId: item.deliveryFormat.id,
					deliveryFormatType: item.deliveryFormat.type,
					manual: item.manualDescription,
					valid: true,
				});
			});

		return state.set("qaManuals", fromJS(tempQAManuals));
	};
	const updateTempQAManual = (data) => {
		const { id, fieldKey, value } = data;
		const qaManuals = state.get("qaManuals");
		let tempQAManuals = qaManuals ? qaManuals?.toJS() : [];
		const findIndex = tempQAManuals?.findIndex((item) => item.tempId === id);

		return state.setIn(["qaManuals", findIndex, fieldKey], value);
	};

	//---------------------------SPECIFICATION PARAMETERS-----------------------

	const addTempSpecificationParameters = () => {
		const specificationParameters = state.get("specificationParameters");
		let tempSpecificationParameters = specificationParameters
			? specificationParameters?.toJS()
			: [];

		let tempSpecificationParametersSort = specificationParameters
			? specificationParameters?.toJS()?.sort((a, b) => {
					return a.tempId - b.tempId;
				})
			: [];

		const lastSpecificationParameterId =
			tempSpecificationParametersSort[
				tempSpecificationParametersSort.length - 1
			]?.tempId + 1 || 1;

		tempSpecificationParameters.push({
			tempId: lastSpecificationParameterId,
			id: null,
			name: "",
			fieldType: "",
			category: "",
			valid: false,
		});

		return state.set(
			"specificationParameters",
			fromJS(tempSpecificationParameters)
		);
	};
	const setTempSpecificationParameters = (specificationParameters) => {
		let tempSpecificationParameters = [];

		const sortedSpecificationParameters = specificationParameters;

		sortedSpecificationParameters &&
			sortedSpecificationParameters.map((item) => {
				return tempSpecificationParameters.push({
					id: item.id,
					tempId: item.id,
					name: item.name,
					fieldType: item.inputFieldType?.id || "",
					category: item.parent?.id || "",
					valid: true,
				});
			});

		return state.set(
			"specificationParameters",
			fromJS(tempSpecificationParameters)
		);
	};
	const updateTempSpecificationParameters = (data) => {
		const { id, fieldKey, value } = data;
		const specificationParameters = state.get("specificationParameters");
		const findIndex =
			specificationParameters &&
			specificationParameters
				?.toJS()
				?.findIndex(
					(specificationParameter) => specificationParameter?.tempId === id
				);

		return state.setIn(["specificationParameters", findIndex, fieldKey], value);
	};
	const removeTempSpecificationParameters = ({ id }) => {
		const specificationParameters = state.get("specificationParameters");
		let tempSpecificationParameters = specificationParameters
			? specificationParameters?.toJS()
			: [];

		tempSpecificationParameters = tempSpecificationParameters?.filter(
			(tempSpecificationParameter) => tempSpecificationParameter?.tempId !== id
		);

		return state.set(
			"specificationParameters",
			fromJS(tempSpecificationParameters)
		);
	};
	const updateTempSpecificationParametersSort = (data) => {
		const { dragId, overId } = data;
		const specificationParameters = state.get("specificationParameters");
		let tempSpecificationParameters = specificationParameters
			? specificationParameters?.toJS()
			: [];

		const findIndexOver = tempSpecificationParameters.findIndex(
			(specificationParameter) => specificationParameter?.tempId === overId
		);
		const findIndexDrag = tempSpecificationParameters.findIndex(
			(specificationParameter) => specificationParameter?.tempId === dragId
		);

		if (findIndexDrag !== -1 && findIndexOver !== -1)
			if (findIndexOver > findIndexDrag) {
				tempSpecificationParameters.splice(
					findIndexOver + 1,
					0,
					tempSpecificationParameters[findIndexDrag]
				);
				tempSpecificationParameters.splice(findIndexDrag, 1);
			} else if (findIndexOver < findIndexDrag) {
				tempSpecificationParameters.splice(
					findIndexOver,
					0,
					tempSpecificationParameters[findIndexDrag]
				);
				tempSpecificationParameters.splice(findIndexDrag + 1, 1);
			}

		return state.set(
			"specificationParameters",
			fromJS(tempSpecificationParameters)
		);
	};

	//---------------------------SPECIFICATION CATEGORIES-----------------------

	const addTempSpecificationCategories = () => {
		const specificationCategories = state.get("specificationCategories");
		let tempSpecificationCategories = specificationCategories
			? specificationCategories?.toJS()
			: [];

		let tempSpecificationCategoriesSort = specificationCategories
			? specificationCategories?.toJS()?.sort((a, b) => {
					return a.tempId - b.tempId;
				})
			: [];

		const lastSpecificationCategoryId =
			tempSpecificationCategoriesSort[
				tempSpecificationCategoriesSort.length - 1
			]?.tempId + 1 || 1;

		tempSpecificationCategories.push({
			tempId: lastSpecificationCategoryId,
			id: null,
			name: "",
			discipline: null,
			valid: false,
		});

		return state.set(
			"specificationCategories",
			fromJS(tempSpecificationCategories)
		);
	};
	const setTempSpecificationCategories = (specificationCategories) => {
		let tempSpecificationCategories = [];
		const sortedSpecificationCategories = specificationCategories;

		sortedSpecificationCategories &&
			sortedSpecificationCategories.map((item) => {
				return tempSpecificationCategories.push({
					id: item.id,
					tempId: item.id,
					name: item.name,
					discipline: item.discipline?.id || null,
					valid: true,
				});
			});

		return state.set(
			"specificationCategories",
			fromJS(tempSpecificationCategories)
		);
	};
	const updateTempSpecificationCategories = (data) => {
		const { fieldKey, id, value } = data;
		const specificationCategories = state.get("specificationCategories");
		const findIndex =
			specificationCategories &&
			specificationCategories
				?.toJS()
				?.findIndex(
					(specificationCategory) => specificationCategory?.tempId === id
				);

		return state.setIn(["specificationCategories", findIndex, fieldKey], value);
	};

	const removeTempSpecificationCategories = ({ id }) => {
		const specificationCategories = state.get("specificationCategories");
		let tempSpecificationCategories = specificationCategories
			? specificationCategories?.toJS()
			: [];

		tempSpecificationCategories = tempSpecificationCategories?.filter(
			(tempSpecificationCategory) => tempSpecificationCategory?.tempId !== id
		);

		return state.set(
			"specificationCategories",
			fromJS(tempSpecificationCategories)
		);
	};
	const updateTempSpecificationCategoriesSort = (data) => {
		const { dragId, overId } = data;
		const specificationCategories = state.get("specificationCategories");
		let tempSpecificationCategories = specificationCategories
			? specificationCategories?.toJS()
			: [];

		const findIndexOver = tempSpecificationCategories.findIndex(
			(specificationCategory) => specificationCategory?.tempId === overId
		);
		const findIndexDrag = tempSpecificationCategories.findIndex(
			(specificationCategory) => specificationCategory?.tempId === dragId
		);

		if (findIndexDrag !== -1 && findIndexOver !== -1)
			if (findIndexOver > findIndexDrag) {
				tempSpecificationCategories.splice(
					findIndexOver + 1,
					0,
					tempSpecificationCategories[findIndexDrag]
				);
				tempSpecificationCategories.splice(findIndexDrag, 1);
			} else if (findIndexOver < findIndexDrag) {
				tempSpecificationCategories.splice(
					findIndexOver,
					0,
					tempSpecificationCategories[findIndexDrag]
				);
				tempSpecificationCategories.splice(findIndexDrag + 1, 1);
			}

		return state.set(
			"specificationCategories",
			fromJS(tempSpecificationCategories)
		);
	};

	//---------------------------DXF_SERVER_SETTINGS-----------------------

	/**
	 * Set TEMP DXF Server components
	 *
	 * @param {object} data - disciplineId, result
	 *
	 */
	const setTempDXFServerComponents = (data) => {
		const { disciplineId, result } = data;
		const components = [];

		//Set components with id, tempId, componentId
		result &&
			result.map((item, index) => {
				components.push({
					id: item.disciplineComponentCategory.id,
					deleteId: item.id,
					tempId: uuid(),
					componentId: item.disciplineComponentCategory.id,
					type: item.disciplineComponentCategory.componentCategory.type,
				});

				return components;
			});

		return state
			.setIn(
				["DXFServerSettings", `discipline_${disciplineId}`, "components"],
				fromJS(components)
			)
			.setIn(
				[
					"DXFServerSettings",
					`discipline_${disciplineId}`,
					"initialComponents",
				],
				fromJS(components)
			);
	};

	/**
	 * ADD TEMP DXF Server component
	 *
	 * @param {object} data - disciplineId
	 *
	 */
	const addTempDXFServerComponent = (data) => {
		const { disciplineId } = data;

		const tempComponents = state.getIn([
			"DXFServerSettings",
			`discipline_${disciplineId}`,
			"components",
		]);
		const tempComponentsJS = tempComponents ? tempComponents?.toJS() : [];

		//Add a new TEMP component to the components array
		tempComponentsJS.push({
			id: null,
			tempId: uuid(),
			componentId: null,
		});

		return state.setIn(
			["DXFServerSettings", `discipline_${disciplineId}`, "components"],
			fromJS(tempComponentsJS)
		);
	};

	/**
	 * Update TEMP DXF Server component
	 *
	 * @param {object} data - disciplineId, value, tempId
	 *
	 */
	const updateTempDXFServerComponent = (data) => {
		const { disciplineId, value, tempId } = data;

		const tempComponents = state.getIn([
			"DXFServerSettings",
			`discipline_${disciplineId}`,
			"components",
		]);
		const tempComponentsJS = tempComponents ? tempComponents?.toJS() : [];

		//Find the index of the component
		const findIndex = tempComponentsJS.findIndex(
			(item) => item.tempId === tempId
		);

		return state.setIn(
			[
				"DXFServerSettings",
				`discipline_${disciplineId}`,
				"components",
				findIndex,
				"componentId",
			],
			value
		);
	};

	/**
	 * Reset TEMP DXF Server component
	 * @param {object} data - disciplineId
	 *
	 */
	const resetTempDXFServerComponent = (data) => {
		const { disciplineId } = data;

		return state.setIn(
			["DXFServerSettings", `discipline_${disciplineId}`, `components`],
			state.getIn([
				"DXFServerSettings",
				`discipline_${disciplineId}`,
				"initialComponents",
			])
		);
	};

	/**
	 * Remove TEMP DXF Server component
	 * @param {object} data - tempId, disciplineId
	 * @returns
	 */
	const removeTempDXFServerComponent = (data) => {
		const { tempId, disciplineId } = data;

		const components = state.getIn([
			"DXFServerSettings",
			`discipline_${disciplineId}`,
			`components`,
		]);
		const componentsJS = components ? components?.toJS() : [];
		const tempComponents = componentsJS?.filter(
			(comp) => comp?.tempId !== tempId
		);

		return state.setIn(
			["DXFServerSettings", `discipline_${disciplineId}`, `components`],
			fromJS(tempComponents)
		);
	};

	//---------------------------DXF_SERVER_SETTINGS_COMPONENT_PARAMETERS-----------------------

	/**
	 * Set TEMP DXF Server component parameters
	 *
	 * @param {object} data - disciplineId, componentId, result
	 *
	 */
	const setTempDXFServerComponentParameters = (data) => {
		const { disciplineId, componentId, result } = data;
		const componentParameters = [];

		result &&
			result.map((item) => {
				const id = item?.parameterCategory?.id || null;
				const name = item?.parameterCategory?.name || null;
				const dxModelProperties = item?.dxModelProperties || [];

				const parameterOptions = [];
				const settings = [];

				//Set settings and parameterOptions from dxModelProperties (Min, Max, etc.)
				dxModelProperties.map((dxfModel) => {
					settings.push(dxfModel?.dxfModelProperty?.id);
					parameterOptions.push({
						id: dxfModel?.dxfModelProperty?.id,
						optionId: dxfModel?.id,
						tempId: uuid(),
						value: dxfModel?.value,
						type: dxfModel?.dxfModelProperty?.name,
						valid: true,
					});

					return parameterOptions;
				});

				//sort parameterOptions min, max, etc.
				parameterOptions.sort((a, b) => {
					return -a.type.localeCompare(b.type);
				});

				//Set componentParameters with settings and parameterOptions
				componentParameters.push({
					id,
					tempId: id,
					disciplineComponentCategoryParameterCategoryId: item?.id || null,
					parameterId: id,
					parameterName: name,
					settings: settings,
					parameterValidity: true,
					parameterOptions: parameterOptions,
				});

				return componentParameters;
			});

		return state
			.setIn(
				[
					"DXFServerSettings",
					`discipline_${disciplineId}`,
					`componentParameters_${componentId}`,
				],
				fromJS(componentParameters)
			)
			.setIn(
				[
					"DXFServerSettings",
					`discipline_${disciplineId}`,
					`initialComponentParameters_${componentId}`,
				],
				fromJS(componentParameters)
			);
	};

	/**
	 * Add TEMP DXF Server component parameter
	 *
	 * @param {object} data - disciplineId, componentId
	 *
	 */
	const addTempDXFServerComponentParameter = (data) => {
		const { disciplineId, componentId } = data;

		const parameters = state.getIn([
			"DXFServerSettings",
			`discipline_${disciplineId}`,
			`componentParameters_${componentId}`,
		]);

		const parametersJS = parameters ? parameters?.toJS() : [];

		//Add a new TEMP component parameter to the componentParameters array
		parametersJS.push({
			id: null,
			tempId: uuid(),
			parameterId: null,
			parameterName: null,
			settings: [],
			parameterValidity: true,
			parameterOptions: [],
		});

		return state.setIn(
			[
				"DXFServerSettings",
				`discipline_${disciplineId}`,
				`componentParameters_${componentId}`,
			],
			fromJS(parametersJS)
		);
	};

	/**
	 * Update TEMP DXF Server component parameter
	 *
	 * @param {object} data - disciplineId, componentId, tempId, fieldKey, values, settingsId, settingsOptions
	 *
	 */
	const updateTempDXFServerComponentParameter = (data) => {
		const {
			disciplineId,
			componentId,
			tempId,
			fieldKey,
			values,
			settingsId = null,
			settingsOptions = [],
		} = data;

		const parameters = state.getIn([
			"DXFServerSettings",
			`discipline_${disciplineId}`,
			`componentParameters_${componentId}`,
		]);
		const parametersJS = parameters ? parameters?.toJS() : [];
		//Find the index of the componentParameter
		const findIndex = parametersJS.findIndex((item) => item.tempId === tempId);

		//----------updating settings field----------
		if (fieldKey === "settings") {
			const tempParameterOptions = state.getIn([
				"DXFServerSettings",
				`discipline_${disciplineId}`,
				`componentParameters_${componentId}`,
				findIndex,
				"parameterOptions",
			]);

			const tempParameterOptionsJS = tempParameterOptions
				? tempParameterOptions?.toJS()
				: [];
			//Get the ids of the existing parameterOptions
			const tempOptionsIds = tempParameterOptionsJS.map((item) => item.id);

			const parameterOptions = [];

			values.map((value) => {
				//If the value is already in the parameterOptions, then just add it to the parameterOptions
				//Otherwise, create a new parameterOption
				//with the value and the corresponding key from settingsOptions
				//If the key is not found, then set it to an empty string

				if (tempOptionsIds.includes(value)) {
					const findIndex = tempParameterOptionsJS.findIndex(
						(item) => +item.id === +value
					);
					parameterOptions.push(tempParameterOptionsJS[findIndex]);
					return parameterOptions;
				} else {
					const key =
						settingsOptions.find((item) => +item.value === +value)?.label || "";

					parameterOptions.push({
						id: value,
						optionId: null,
						tempId: uuid(),
						value: "",
						type: key,
						valid: true,
					});
				}

				return parameterOptions;
			});

			//sort parameterOptions min, max, etc.
			parameterOptions.sort((a, b) => {
				return -a.type.localeCompare(b.type);
			});

			return state
				.setIn(
					[
						"DXFServerSettings",
						`discipline_${disciplineId}`,
						`componentParameters_${componentId}`,
						findIndex,
						"settings",
					],
					fromJS(values)
				)
				.setIn(
					[
						"DXFServerSettings",
						`discipline_${disciplineId}`,
						`componentParameters_${componentId}`,
						findIndex,
						"parameterOptions",
					],
					fromJS(parameterOptions)
				);
		}
		//----------end of updating settings---------

		//----------updating fields from parameterOptions array----------
		if (!!settingsId) {
			const tempParameterOptions = state.getIn([
				"DXFServerSettings",
				`discipline_${disciplineId}`,
				`componentParameters_${componentId}`,
				findIndex,
				"parameterOptions",
			]);

			const tempParameterOptionsJS = tempParameterOptions
				? tempParameterOptions?.toJS()
				: [];

			//Find the index of the parameterOption
			const parameterOptionsIndex = tempParameterOptionsJS.findIndex(
				(item) => +item.id === +settingsId
			);

			let validParameter = true;
			const findParameter = tempParameterOptionsJS[parameterOptionsIndex];

			if (findParameter.type === "min") {
				const findMaxValue = tempParameterOptionsJS.find(
					(item) => item.type === "max"
				);
				validParameter = +values >= +findMaxValue?.value ? false : true;
			} else if (findParameter.type === "max") {
				const findMinValue = tempParameterOptionsJS.find(
					(item) => item.type === "min"
				);
				validParameter = +values <= +findMinValue?.value ? false : true;
			}

			return state
				.setIn(
					[
						"DXFServerSettings",
						`discipline_${disciplineId}`,
						`componentParameters_${componentId}`,
						findIndex,
						"parameterOptions",
						parameterOptionsIndex,
						"value",
					],
					values
				)
				.setIn(
					[
						"DXFServerSettings",
						`discipline_${disciplineId}`,
						`componentParameters_${componentId}`,
						findIndex,
						"parameterValidity",
					],
					validParameter
				);
		}
		//----------end of updating fields from parameterOptions array---------

		return state.setIn(
			[
				"DXFServerSettings",
				`discipline_${disciplineId}`,
				`componentParameters_${componentId}`,
				findIndex,
				fieldKey,
			],
			values
		);
	};

	/**
	 * Reset TEMP DXF Server component parameters
	 *
	 * @param {object} data - disciplineId, componentId
	 *
	 */
	const resetTempDXFServerComponentParameter = (data) => {
		const { disciplineId, componentId } = data;

		return state.setIn(
			[
				"DXFServerSettings",
				`discipline_${disciplineId}`,
				`componentParameters_${componentId}`,
			],
			state.getIn([
				"DXFServerSettings",
				`discipline_${disciplineId}`,
				`initialComponentParameters_${componentId}`,
			])
		);
	};

	/**
	 * Remove TEMP DXF Server component parameter
	 * @param {object} data - disciplineId, componentId, tempId
	 * @returns
	 */
	const removeTempDXFServerComponentParameter = (data) => {
		const { tempId, disciplineId, componentId } = data;

		const parameters = state.getIn([
			"DXFServerSettings",
			`discipline_${disciplineId}`,
			`componentParameters_${componentId}`,
		]);
		const parametersJS = parameters ? parameters?.toJS() : [];
		const tempParameters = parametersJS?.filter(
			(param) => param?.tempId !== tempId
		);

		return state.setIn(
			[
				"DXFServerSettings",
				`discipline_${disciplineId}`,
				`componentParameters_${componentId}`,
			],
			fromJS(tempParameters)
		);
	};

	switch (action.type) {
		case tempAdministrationConstants.GET_TEMP_INPUT_FORMATS:
			return state.set("inputFormat", fromJS(action.data));
		//---------------------------DISCIPLINES----------------------------

		case tempAdministrationConstants.SET_TEMP_DISCIPLINES:
			return setDisciplines(action.data);
		case tempAdministrationConstants.UPDATE_TEMP_DISCIPLINES:
			return updateDiscipline(action.data);
		case tempAdministrationConstants.ADD_TEMP_DISCIPLINE:
			return addDisciplines();
		case tempAdministrationConstants.REMOVE_TEMP_DISCIPLINE:
			return removeDiscipline(action.data);
		case tempAdministrationConstants.UPDATE_TEMP_DISCIPLINE_ORDER:
			return updateTempDisciplineSortOrder(action.data);

		//------------------------------LODS---------------------------------

		case tempAdministrationConstants.SET_TEMP_LODS:
			return setTempLods(action.data);
		case tempAdministrationConstants.UPDATE_TEMP_LOD:
			return updateLod(action.data);
		case tempAdministrationConstants.ADD_TEMP_LOD:
			return addLods();
		case tempAdministrationConstants.REMOVE_TEMP_LOD:
			return removeLod(action.data);
		case tempAdministrationConstants.UPDATE_TEMP_LOD_SORT_ORDER:
			return updateTempLODSortOrder(action.data);

		//---------------------------BUILDING_COMPONENTS----------------------

		case tempAdministrationConstants.SET_TEMP_BUILDING_COMPONENTS:
			return setBuildingComponents(action.data);
		case tempAdministrationConstants.ADD_TEMP_BUILDING_COMPONENTS:
			return addBuildingComponents();
		case tempAdministrationConstants.UPDATE_TEMP_BUILDING_COMPONENTS:
			return updateBuildingComponents(action.data);
		case tempAdministrationConstants.REMOVE_TEMP_BUILDING_COMPONENTS:
			return removeBuildingComponents(action.data);
		case tempAdministrationConstants.UPDATE_TEMP_BUILDING_COMPONENTS_SORT_ORDER:
			return updateTempBuildingComponentsSortOrder(action.data);

		//---------------------------BUILDING_CATEGORIES-----------------------

		case tempAdministrationConstants.SET_TEMP_BUILDING_CATEGORIES:
			return setTempBuildingCategories(action.data);
		case tempAdministrationConstants.ADD_TEMP_BUILDING_CATEGORIES:
			return addBuildingCategory();
		case tempAdministrationConstants.REMOVE_TEMP_BUILDING_CATEGORIES:
			return removeBuildingCategory(action.data);
		case tempAdministrationConstants.UPDATE_TEMP_BUILDING_CATEGORIES:
			return updateBuildingCategory(action.data);
		case tempAdministrationConstants.UPDATE_TEMP_BUILDING_CATEGORIES_SORT_ORDER:
			return updateTempBuildingCategoriesSortOrder(action.data);

		//---------------------------BUILDING TYPES-----------------------

		case tempAdministrationConstants.SET_TEMP_BUILDING_TYPES:
			return setTempBuildingTypes(action.data);
		case tempAdministrationConstants.ADD_TEMP_BUILDING_TYPES:
			return addBuildingType();
		case tempAdministrationConstants.REMOVE_TEMP_BUILDING_TYPES:
			return removeBuildingType(action.data);
		case tempAdministrationConstants.UPDATE_TEMP_BUILDING_TYPES:
			return updateBuildingType(action.data);
		case tempAdministrationConstants.UPDATE_TEMP_BUILDING_TYPES_SORT_ORDER:
			return updateTempBuildingTypesSortOrder(action.data);

		//---------------------------PRICING-----------------------------

		case tempAdministrationConstants.SET_TEMP_MODELING_DATA:
			return setTempModeling(action.data);
		case tempAdministrationConstants.UPDATE_TEMP_MODELING_DATA:
			return updateTempModelingPrice(action.data);
		case tempAdministrationConstants.REMOVE_TEMP_MODELING_DATA:
			return removeTempModelingPrice(action.data);
		case tempAdministrationConstants.ADD_TEMP_MODELING_DATA:
			return addTempModelingPrice(action.data);

		case tempAdministrationConstants.SET_TEMP_LOD_ADJUSTMENT:
			return setTempLODAdjustment(action.data);
		case tempAdministrationConstants.UPDATE_TEMP_LOD_ADJUSTMENT:
			return updateTempLODAdjustment(action.data);
		case tempAdministrationConstants.REMOVE_TEMP_LOD_ADJUSTMENT:
			return removeTempLODAdjustment(action.data);
		case tempAdministrationConstants.ADD_TEMP_LOD_ADJUSTMENT:
			return addTempLODAdjustment(action.data);

		case tempAdministrationConstants.SET_TEMP_BUILDING_CATEGORY_ADJUSTMENT:
			return setTempBuildingCategoryAdjustment(action.data);
		case tempAdministrationConstants.UPDATE_TEMP_BUILDING_CATEGORY_ADJUSTMENT:
			return updateTempBuildingCategoryAdjustment(action.data);
		case tempAdministrationConstants.REMOVE_TEMP_BUILDING_CATEGORY_ADJUSTMENT:
			return removeTempBuildingCategoryAdjustment(action.data);
		case tempAdministrationConstants.ADD_TEMP_BUILDING_CATEGORY_ADJUSTMENT:
			return addTempBuildingCategoryAdjustment(action.data);

		case tempAdministrationConstants.SET_TEMP_REPETITIVENESS_ADJUSTMENT:
			return setTempRepetitivenessAdjustment(action.data);
		case tempAdministrationConstants.UPDATE_TEMP_REPETITIVENESS_ADJUSTMENT:
			return updateTempRepetitivenessAdjustment(action.data);
		case tempAdministrationConstants.REMOVE_TEMP_REPETITIVENESS_ADJUSTMENT:
			return removeTempRepetitivenessAdjustment(action.data);
		case tempAdministrationConstants.ADD_TEMP_REPETITIVENESS_ADJUSTMENT:
			return addTempRepetitivenessAdjustment(action.data);

		//---------------------------OTHERS-----------------------

		case tempAdministrationConstants.SET_TEMP_OTHERS:
			return setTempOthers(action.data);
		case tempAdministrationConstants.ADD_TEMP_OTHERS:
			return addTempOthers();
		case tempAdministrationConstants.REMOVE_TEMP_OTHERS:
			return removeTempOthers(action.data);
		case tempAdministrationConstants.UPDATE_TEMP_OTHERS:
			return updateTempOthers(action.data);

		//---------------------------LOD_CONTENT-----------------------

		// case tempAdministrationConstants.SET_TEMP_LOD_CONTENT:
		// 	return setTempLodContent(action.data);
		// case tempAdministrationConstants.UPDATE_TEMP_LOD_CONTENT:
		// 	return updateTempLodContent(action.data);

		//---------------------------NORMALISATION_MANUAL-----------------------

		case tempAdministrationConstants.SET_TEMP_NORMALISATION_MANUAL:
			return setTempNormalisationManual(action.data);
		case tempAdministrationConstants.ADD_TEMP_NORMALISATION_MANUAL:
			return addTempNormalisationManual();
		case tempAdministrationConstants.UPDATE_TEMP_NORMALISATION_MANUAL:
			return updateTempNormalisationManual(action.data);

		//---------------------------CUSTOMISATION MANUAL-----------------------
		case tempAdministrationConstants.SET_TEMP_CUSTOMISATION_MANUAL:
			return setTempCustomisationManual(action.data);
		case tempAdministrationConstants.UPDATE_TEMP_CUSTOMISATION_MANUAL:
			return updateTempCustomisationManual(action.data);

		//---------------------------QA MANUAL-----------------------

		case tempAdministrationConstants.SET_TEMP_QA_MANUAL:
			return setTempQAManual(action.data);
		case tempAdministrationConstants.UPDATE_TEMP_QA_MANUAL:
			return updateTempQAManual(action.data);

		case tempAdministrationConstants.SET_TERMS_AND_CONDITION:
			return state.set("termsAndConditions", action.data);

		case tempAdministrationConstants.SET_MANUAL_LOADER:
			return state.set("manualLoader", action.data);

		case tempAdministrationConstants.CLEAR_TEMP_MANUALS:
			return state
				.set("normalisationManuals", Map())
				.set("qaManuals", Map())
				.set("customisationManuals", Map());

		//---------------------------SPECIFICATION PARAMETERS-----------------------

		case tempAdministrationConstants.SET_SPECIFICATION_PARAMETERS:
			return setTempSpecificationParameters(action.data);

		case tempAdministrationConstants.UPDATE_SPECIFICATION_PARAMETERS:
			return updateTempSpecificationParameters(action.data);

		case tempAdministrationConstants.ADD_SPECIFICATION_PARAMETERS:
			return addTempSpecificationParameters();

		case tempAdministrationConstants.REMOVE_SPECIFICATION_PARAMETERS:
			return removeTempSpecificationParameters(action.data);

		case tempAdministrationConstants.SORT_SPECIFICATION_PARAMETERS:
			return updateTempSpecificationParametersSort(action.data);

		//---------------------------SPECIFICATION_CATEGORIES-----------------------

		case tempAdministrationConstants.SET_SPECIFICATION_CATEGORIES:
			return setTempSpecificationCategories(action.data);

		case tempAdministrationConstants.ADD_SPECIFICATION_CATEGORIES:
			return addTempSpecificationCategories();

		case tempAdministrationConstants.UPDATE_SPECIFICATION_CATEGORIES:
			return updateTempSpecificationCategories(action.data);

		case tempAdministrationConstants.REMOVE_SPECIFICATION_CATEGORIES:
			return removeTempSpecificationCategories(action.data);

		case tempAdministrationConstants.SORT_SPECIFICATION_CATEGORIES:
			return updateTempSpecificationCategoriesSort(action.data);

		//---------------------------DXF_SERVER_SETTINGS-----------------------

		case tempAdministrationConstants.SET_DXF_SERVER_COMPONENTS:
			return setTempDXFServerComponents(action.data);

		case tempAdministrationConstants.ADD_DXF_SERVER_COMPONENT:
			return addTempDXFServerComponent(action.data);

		case tempAdministrationConstants.UPDATE_DXF_SERVER_COMPONENT:
			return updateTempDXFServerComponent(action.data);

		case tempAdministrationConstants.RESET_DXF_SERVER_COMPONENT:
			return resetTempDXFServerComponent(action.data);

		case tempAdministrationConstants.REMOVE_DXF_SERVER_COMPONENT:
			return removeTempDXFServerComponent(action.data);

		case tempAdministrationConstants.CLEAR_TEMP_DXF_SERVER_COMPONENTS:
			return state.set("DXFServerSettings", Map());

		//---------------------------DXF_SERVER_SETTINGS_COMPONENT_PARAMETERS-----------------------

		case tempAdministrationConstants.SET_DXF_SERVER_COMPONENT_PARAMETERS:
			return setTempDXFServerComponentParameters(action.data);

		case tempAdministrationConstants.ADD_DXF_SERVER_COMPONENT_PARAMETER:
			return addTempDXFServerComponentParameter(action.data);

		case tempAdministrationConstants.UPDATE_DXF_SERVER_COMPONENT_PARAMETER:
			return updateTempDXFServerComponentParameter(action.data);

		case tempAdministrationConstants.REMOVE_DXF_SERVER_COMPONENT_PARAMETER:
			return removeTempDXFServerComponentParameter(action.data);

		case tempAdministrationConstants.RESET_DXF_SERVER_COMPONENT_PARAMETER:
			return resetTempDXFServerComponentParameter(action.data);

		// case tempAdministrationConstants.UPDATE_SPECIFICATION_CATEGORIES:
		// 	return updateTempSpecificationCategories(action.data);

		// case tempAdministrationConstants.REMOVE_SPECIFICATION_CATEGORIES:
		// 	return removeTempSpecificationCategories(action.data);

		// case tempAdministrationConstants.SORT_SPECIFICATION_CATEGORIES:
		// 	return updateTempSpecificationCategoriesSort(action.data);

		default:
			return state;
	}
};
