import React, { useMemo } from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form/immutable";
import FooterLayout from "../../../../layout/content/FooterLayout";
import FormSubmitButtons from "../../../../../shared/components/FormComponents/FormSubmitButtons";
import localize, { LocKeys } from "../../../../../constants/localizations";
import HeaderFamilyForm from "./parts/HeaderFamilyForm";
import BodyFamilyForm from "./parts/BodyFamilyForm";

const FamilyForm = (props) => {
	const {
		tempFamily,
		initialValues,
		formName,
		editView = false,
		onCancel,
		onSubmit,
	} = props;

	const fieldKeys = useMemo(() => ["name", "category"], []);

	const onSubmitForm = () => {
		try {
			const data = tempFamily?.toJS();

			onSubmit({
				name: data.name,
				disciplineComponentCategoryId: data.disciplineComponentCategory,
			});
		} catch (error) {
			return;
		}
	};

	const isValid = useMemo(() => {
		return Object.keys(tempFamily?.toJS()).some(
			(key) => fieldKeys.includes(key) && !tempFamily.get(key)
		);
	}, [tempFamily, fieldKeys]);

	return (
		<div className="page-content--large">
			<form
				className={`form d-flex h-100 w-100 flex-column flex-auto standardized-form card  ${
					editView
						? "bg-primary--lighter card--primary"
						: "card--border--none  card--2 bg-n000"
				}	`}
				onSubmit={props.handleSubmit(onSubmitForm)}
				autoComplete="off"
				noValidate
			>
				<HeaderFamilyForm editView={editView} />

				<div className="pt-regular px-24 page-content--medium overflow-y-auto">
					<BodyFamilyForm initialValues={initialValues} formName={formName} />
				</div>

				<FooterLayout wrapperClasses="px-24 pt-24">
					<FormSubmitButtons
						wrapperClasses="w-100"
						onCancel={onCancel}
						onSubmit={props.handleSubmit(onSubmitForm)}
						cancelLabel={localize(LocKeys.CANCEL)}
						submitClassName={!editView ? "btn--success" : ""}
						submitLabel={
							editView ? localize(LocKeys.SAVE) : localize(LocKeys.CREATE)
						}
						submitDisabled={isValid}
					/>
				</FooterLayout>
			</form>
		</div>
	);
};

const mapStateToProps = (state, ownProps) => {
	const { formName } = ownProps;

	return {
		form: formName,
		tempFamily: state.getIn(["families", "tempFamily"]),
		initialValues: {
			name: state.getIn(["families", "tempFamily", "name"]),
			disciplineComponentCategory: state.getIn([
				"families",
				"tempFamily",
				"disciplineComponentCategory",
			]),
		},
	};
};

export default connect(
	mapStateToProps,
	{}
)(
	reduxForm({
		touchOnBlur: false,
		destroyOnUnmount: false,
		enableReinitialize: true,
	})(FamilyForm)
);
