import React from "react";

/**
 * Content Layout - Custom component for BODY layout
 *
 * @param {string} wrapperClasses
 * @param {boolean} isTableView
 * @param {*} children
 *
 */
const BodyLayout = ({
	id = "bodyLayout",
	wrapperClasses = "",
	isTableView = false,
	children = <></>,
}) => {
	return (
		<div
			id={id}
			className={`w-100 ${
				isTableView ? "" : "page-content--small overflow-y-auto"
			}  ${wrapperClasses}`}
		>
			{children}
		</div>
	);
};

export default BodyLayout;
