/**
 * Function for validating fields
 */

import localize, { LocKeys } from "../../constants/localizations";

export default (formValues) => {
	const emailRegex =
		"^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$";
	const passRegex = `^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[*.!@$%^&:;<>,.?/~_+-=|]).{8,32}$`;
	const errors = {};
	if (!formValues.get("email")) {
		errors.email = "Invalid credentials";
	} else if (!formValues.get("email").match(emailRegex)) {
		errors.email = "Invalid credentials";
	}
	if (!formValues.get("password")) {
		errors.password = "Invalid credentials";
	}
	if (!formValues.get("rePassword")) {
		errors.rePassword = "Password is empty";
	}
	if (!formValues.get("reConfirmPassword")) {
		errors.reConfirmPassword = "Password is empty";
	}
	if (!formValues.get("privacyPolicy")) {
		errors.privacyPolicy = localize(LocKeys.ACCEPT_PRIVACY_POLICY);
	}

	if (
		formValues.get("rePassword") &&
		!formValues.get("rePassword").match(passRegex)
	) {
		errors.rePassword =
			"Password needs to be at least 8 characters long and contain at least 1 capital letter, 1 number and 1 symbol.";
	} else if (
		formValues.get("rePassword") !== formValues.get("reConfirmPassword")
	) {
		errors.reConfirmPassword = "Password does not match";
	}

	return errors;
};
