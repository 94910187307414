import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import localize, { LocKeys } from "../../constants/localizations";
import BimifyHeartIcon from "../../shared/icons/BimifyHeartIcon";
import ErrorIcon from "../../shared/icons/misc/ErrorIcon";
import Modal from "../Modal";
import { clearUserRequestState } from "../../actions";
import CustomSnackbar from "../../shared/CustomSnackbar/CustomSnackbar";
import {
	alertTypeConstants,
	responseContentTypeConstants,
	responseDeliveryTypeConstants,
} from "../../constants/constants";
import ReactMarkdown from "react-markdown";

/**
 * Snackbar messages for user actions
 * @param {*} props
 * @returns
 */
const UserRequestPopups = (props) => {
	const {
		triggerCloseLoadingModal,
		// Actions
		clearUserRequestState,
		// From state
		request,
		action,
		responseType = responseDeliveryTypeConstants.SNACKBAR,
		responseContentType = responseContentTypeConstants.DEFAULT,
		errorMessage,
		successMessage,
	} = props;

	const [showError, setShowError] = useState(!action && request);
	const [showSuccess, setShowSuccess] = useState(action && request);

	useEffect(() => {
		if (request) {
			triggerCloseLoadingModal && triggerCloseLoadingModal();
			if (!action) {
				setShowError(true);
			} else {
				setShowSuccess(true);
			}
		}
	}, [action, request, triggerCloseLoadingModal]);

	const errorOnClose = () => {
		setShowError(false);
		clearUserRequestState();
	};

	const successOnClose = () => {
		setShowSuccess(false);
		clearUserRequestState();
	};

	const renderModalContent = () => {
		return (
			<>
				<div className="justify-content-center mb-regular">
					<BimifyHeartIcon />
				</div>
				{successMessage && (
					<p className="pt-24">
						<ReactMarkdown>{successMessage}</ReactMarkdown>
					</p>
				)}
			</>
		);
	};

	return (
		<>
			{responseType === responseDeliveryTypeConstants.MODAL ? (
				<>
					<Modal
						show={showSuccess}
						title={
							responseContentType === responseContentTypeConstants.DEFAULT
								? `${localize(LocKeys.THANK_YOU)}!`
								: localize(LocKeys.SUCCESS)
						}
						onClose={successOnClose}
					>
						<div className="text-center">{renderModalContent()}</div>
					</Modal>
					<Modal
						show={showError}
						title={`${localize(LocKeys.ERROR)}!`}
						onClose={errorOnClose}
					>
						<div className="text-center">
							<div className="justify-content-center mb-regular">
								<ErrorIcon />
							</div>
							{errorMessage && (
								<div className="text-center mb-regular">
									<span className="color--red">{localize(LocKeys.ERROR)}:</span>{" "}
									{errorMessage}
								</div>
							)}
							<i></i>
						</div>
					</Modal>
				</>
			) : (
				<>
					<CustomSnackbar
						open={showSuccess}
						onClose={successOnClose}
						title={localize(LocKeys.SUCCESS)}
					>
						{successMessage}
					</CustomSnackbar>
					<CustomSnackbar
						open={showError}
						onClose={errorOnClose}
						type={alertTypeConstants.ERROR}
						title={localize(LocKeys.ERROR)}
					>
						<ReactMarkdown>{errorMessage}</ReactMarkdown>
					</CustomSnackbar>
				</>
			)}
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		action: state.getIn(["user", "action"]),
		request: state.getIn(["user", "request"]),
		errorMessage: state.getIn(["user", "errorMessage"]),
		successMessage: state.getIn(["user", "successMessage"]),
		responseType: state.getIn(["user", "responseType"]),
		responseContentType: state.getIn(["user", "responseContentType"]),
	};
};
export default connect(mapStateToProps, {
	clearUserRequestState,
})(UserRequestPopups);
