import React from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import FormControl from "@mui/material/FormControl";
import clsx from "clsx";
import renderError from "./renderError";
import CustomLabel from "./CustomLabel";
import { useState } from "react";

/**
 * Component for password field
 */

export default ({
	input,
	label = "",
	placeholder = "",
	labelInfoContent = "",
	labelNote = "",
	required = true, //Password field should always be true
	values,
	meta: { touched, error, value },
	classField,
	size = "w-100",
	inputAdornmentClasses = {
		root: "password-adornment__root",
	},
}) => {
	const [showPassword, setShowPassword] = useState(false);

	const icon = () => {
		if (touched && error) {
			inputAdornmentClasses = {
				...inputAdornmentClasses,
				root: "password-adornment__root password-adornment__root--error",
			};
		}

		let passIcon = showPassword ? <Visibility /> : <VisibilityOff />;
		return (
			<InputAdornment
				classes={{
					root: "adornment__root adornment__root--end",
				}}
				position="end"
			>
				<IconButton
					classes={inputAdornmentClasses}
					aria-label="toggle password visibility"
					onClick={() => setShowPassword(!showPassword)}
					onMouseDown={(e) => e.preventDefault()}
				>
					{passIcon}
				</IconButton>
			</InputAdornment>
		);
	};

	// TODO: Better input field ID handling
	const id = input.name ? input.name : "standard-adornment-password";

	let errorExist = false;
	if (touched && error) {
		errorExist = true;
	}
	return (
		<FormControl
			className={clsx(
				`form__input
        ${errorExist && "form__input--error"}`,
				size,
				classField
			)}
			error={errorExist}
		>
			{label && (
				<CustomLabel
					label={label}
					required={required}
					infoContent={labelInfoContent}
					note={labelNote}
				/>
			)}
			<TextField
				id={id}
				variant="standard"
				{...input}
				type={showPassword ? "text" : "password"}
				placeholder={placeholder}
				required
				InputProps={{
					classes: {
						input: `field__root
                ${!!input.value ? "field__root--complete" : ""}
                ${errorExist ? "field__root--error" : ""}`,
					},
					endAdornment: icon(),
				}}
			/>
			{renderError(error, touched)}
		</FormControl>
	);
};
