export const familyConstants = {
	GET_FAMILIES: "GET_FAMILIES",
	GET_FAMILY: "GET_FAMILY",
	SET_TEMP_FAMILY: "SET_TEMP_FAMILY",
	UPDATE_TEMP_FIELD_FAMILY: "UPDATE_TEMP_FIELD_FAMILY",
	FAMILY_ACTION_GET_SUCCESS: "FAMILY_ACTION_GET_SUCCESS",
	FAMILY_ACTION_GET_FAILURE: "FAMILY_ACTION_GET_FAILURE",
	FAMILY_ACTION_FAILURE: "FAMILY_ACTION_FAILURE",
	CLEAR_SINGLE_FAMILY: "CLEAR_SINGLE_FAMILY",
	CLEAR_TEMP_FAMILY: "CLEAR_TEMP_FAMILY",
	CLEAR_FAMILY_REQUEST_STATE: "CLEAR_FAMILY_REQUEST_STATE",
	CHECK_FAMILY_ACTIVITY: "CHECK_FAMILY_ACTIVITY",
};
