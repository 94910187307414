import React from "react";

export default (props) => {
	return (
		<div className={`icon ${props.iconClass}`}>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="69.785"
				height="69.785"
				viewBox="0 0 69.785 69.785"
			>
				<g id="bimify-hearth-icon" transform="translate(34.893) rotate(45)">
					<path
						id="Path_1027"
						data-name="Path 1027"
						d="M32.9,0H16.449V16.449H32.9V32.9H16.449V16.449H1.692A1.691,1.691,0,0,0,0,18.142V47.656a1.69,1.69,0,0,0,1.692,1.69H47.653a1.69,1.69,0,0,0,1.692-1.69V1.692A1.691,1.691,0,0,0,47.653,0Z"
						transform="translate(0)"
						fill="#f37e00"
					/>
				</g>
			</svg>
		</div>
	);
};
