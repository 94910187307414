import React from "react";

export default (props) => {
	return (
		<span className={`icon ${props.iconClass ? props.iconClass : ""}`}>
			<svg
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M14.135 7.13504C9.73997 9.48004 7.98997 20.5 7.98997 20.5H4.49997C4.24497 20.5 4.01497 20.405 3.83997 20.25C3.83997 20.25 3.83997 20.25 3.83997 20.245C3.89997 20.04 5.73497 13.87 8.81497 8.83504C9.47997 7.75504 11.105 5.29004 12.445 5.29004C13.045 5.29004 13.61 5.99504 14.135 7.13504Z"
					fill="#82B1FF"
				/>
				<path
					d="M20.5 4.5V19.5C20.5 20.05 20.05 20.5 19.5 20.5H16.995C16.615 16.57 15.555 10.245 14.135 7.135C13.61 5.995 13.045 5.29 12.445 5.29C11.105 5.29 9.48 7.755 8.815 8.835C5.735 13.87 3.9 20.04 3.84 20.245C3.63 20.065 3.5 19.795 3.5 19.5V16.5C5.01 13.19 8.705 6.625 12.135 3.5H19.5C20.05 3.5 20.5 3.95 20.5 4.5Z"
					fill="#E3F2FD"
				/>
				<path
					d="M12.135 3.5C8.705 6.625 5.01 13.69 3.5 17V4.5C3.5 3.95 3.95 3.5 4.5 3.5H12.135Z"
					fill="#1E88E5"
				/>
				<path
					d="M16.995 20.5H7.98999C7.98999 20.5 9.73999 9.48001 14.135 7.13501C15.555 10.245 16.615 16.57 16.995 20.5Z"
					fill="#1E88E5"
				/>
				<path
					d="M19.5 21H4.5C3.673 21 3 20.327 3 19.5V4.5C3 3.673 3.673 3 4.5 3H19.5C20.327 3 21 3.673 21 4.5V19.5C21 20.327 20.327 21 19.5 21ZM4.5 4C4.224 4 4 4.2245 4 4.5V19.5C4 19.7755 4.224 20 4.5 20H19.5C19.776 20 20 19.7755 20 19.5V4.5C20 4.2245 19.776 4 19.5 4H4.5Z"
					fill="#E3F2FD"
				/>
			</svg>
		</span>
	);
};
