import React, { Suspense } from "react";
import CanPerform from "../../roles/CanPerform";
import Sidebar from "../sidebar/Sidebar";
import { isLogin } from "../../utils/local-storage.util";
import Page404 from "../../shared/components/default/Page404";
import Topbar from "../topbar/Topbar";
import SuspenseLoader from "../loader/SuspenseLoader";
import { CompatRoute, Navigate } from "react-router-dom-v5-compat";

/**
 * Private route component - only for logged user
 *
 * @param {string} title - Route title
 * @param {Array} requiredPermissions - required permissions array
 * @param {Array} permission - permissions array
 * @param {React.ReactNode} component - Component
 * @param {boolean} hideOutletPadding - Used to trigger hide/show outlet, Default: false
 * @param {React.ReactNode} rest - Route Details
 *
 * @return {React.FC} Private Route component
 *
 */
const PrivateRoute = ({
	hideOutletPadding = false,
	requiredPermissions,
	component: Component,
	title,
	permission,
	priorityAccess = false,
	...rest
}) => {
	document.title = title ? title + " - Bimify" : "Bimify";

	return (
		<CompatRoute
			{...rest}
			render={(props) =>
				isLogin() ? (
					<>
						<Sidebar />
						<CanPerform
							permission={permission}
							requiredPermissions={requiredPermissions}
							priorityAccess={priorityAccess}
							yes={() => {
								return (
									<>
										<main className="main main--has-sidebar">
											<Topbar />
											<Suspense fallback={<SuspenseLoader />}>
												<div className="outlet">
													{!hideOutletPadding ? (
														<Component {...props} />
													) : (
														<Component {...props} />
													)}
												</div>
											</Suspense>
										</main>
									</>
								);
							}}
							no={() => {
								return (
									<>
										<main className="main main--has-sidebar">
											<Topbar />
											<Page404 />
										</main>
									</>
								);
							}}
						/>
					</>
				) : (
					<Navigate to="/login" />
				)
			}
		/>
	);
};

export default PrivateRoute;
