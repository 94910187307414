import React from "react";
import { Link } from "react-router-dom";
import logo from "../../../assets/images/logo-bimify.svg";
import "../login.scss";
import BimifyIcon from "../../../shared/icons/companyTypes/BimifyIcon";

/**
 * Container component for login, register and reset password
 */
const Container = (props) => {
	const { pageSubTitle, resetEmail, contentClassName = "content" } = props;

	return (
		<main className="w-100 mh-100vh auth-wrapper">
			<section className="d-flex flex-column flex-tablet-row h-100 w-100">
				<div className="w-100 w-tablet-50 align-items-center justify-content-center d-flex h-100">
					<div className={`${contentClassName} mx-auto px-regular py-48`}>
						<BimifyIcon iconClass="icon--xl d-tablet-none mb-regular text-center" />
						<div className="mb-32">
							<h1 className="text-center">{props.pageTitle}</h1>
							{(!!pageSubTitle || !!resetEmail) && (
								<h6 className="text-center mt-regular">
									{pageSubTitle} {resetEmail && <span>{resetEmail}</span>}
								</h6>
							)}
						</div>
						<div className="content__inner">{props.children}</div>
					</div>
				</div>
				<div className="w-100 d-none w-tablet-50 bg d-tablet-flex align-items-center justify-content-center">
					<Link
						to="/"
						className="pos-rel w-100 d-flex align-items-center justify-content-center"
					>
						<img src={logo} alt="Bimify" className="auth-wrapper__logo" />
					</Link>
				</div>
			</section>
		</main>
	);
};

export default Container;
