export const createImage = (url) =>
	new Promise((resolve, reject) => {
		const image = new Image();
		image.addEventListener("load", () => resolve(image));
		image.addEventListener("error", (error) => reject(error));
		image.setAttribute("crossOrigin", "anonymous");
		image.src = url;
	});

export default async function getRotatedImage(
	imageSrc,
	rotation = 0,
	fileName
) {
	const image = await createImage(imageSrc);
	const canvas = document.createElement("canvas");
	const ctx = canvas.getContext("2d");
	const width = image.width;
	const height = image.height;
	const w = rotation % 180 === 0 ? width : height;
	const h = rotation % 180 === 0 ? height : width;
	const obj = {
		x: w / 2,
		y: h / 2,
		w: width,
		h: height,
	};

	if (!ctx) {
		return null;
	}

	canvas.width = w;
	canvas.height = h;

	ctx.translate(obj.x, obj.y);
	ctx.rotate((rotation * Math.PI) / 180);
	ctx.translate(-obj.x, -obj.y);

	ctx.drawImage(image, obj.x - obj.w / 2, obj.y - obj.h / 2, obj.w, obj.h);

	// As a blob
	return new Promise((resolve, reject) => {
		canvas.toBlob((file) => {
			var fileData = new File([file], fileName, {
				type: "image/*",
			});

			resolve(fileData);
		}, "image/*");
	});
}
