import React from "react";
import { connect } from "react-redux";
import localize, { LocKeys } from "../../../../../constants/localizations";
import { renderNumberFormat } from "../../../../../utils/number-formatter/NumberFormatter";
import FooterLayout from "../../../../layout/content/FooterLayout";
import OfferLabel from "../../../../orders/single-view/parts/OfferLabel";
import OrderAction from "./OrderAction";

export const renderPackagePrice = (price = null) => {
	return (
		<>
			{!!price ? (
				renderNumberFormat(price, 2)
			) : (
				<span className="loading-icon"></span>
			)}{" "}
			&nbsp; €/m<sup>2</sup>
		</>
	);
};

const FooterView = ({ inputFormats, isForPublicView }) => {
	return (
		<FooterLayout
			wrapperClasses={`px-48 d-flex align-items-center justify-content-end w-100`}
		>
			<div className="row row--m-gutters">
				<div className="col col-desktop-50   align-items-center justify-content-start">
					<h3 className="d-flex align-items-center color-n300">
						{localize(LocKeys.PRICE_FROM)}
					</h3>
				</div>
				<div className="col col-desktop-50 col-80">
					<div className="w-85 d-flex align-items-center">
						{inputFormats &&
							inputFormats.map((inputFormat, i) => {
								return (
									<div key={i} className={`d-flex flex-column w-30`}>
										<OfferLabel
											className="mb-xs"
											label={inputFormat?.getIn(["inputFormat", "type"])}
											value={renderPackagePrice(
												inputFormat?.get("estimatedPricePerSquare")
											)}
										/>
									</div>
								);
							})}
					</div>

					<div className="d-flex w-15 align-items-center justify-content-end">
						<OrderAction isForPublicView={isForPublicView} />
					</div>
				</div>
			</div>
		</FooterLayout>
	);
};

const mapStateToProps = (state) => {
	return {
		inputFormats: state.getIn([
			"dashboard",
			"salesPackage",
			"packageInputFormats",
		]),
	};
};

export default connect(mapStateToProps, {})(FooterView);
