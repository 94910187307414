/**
 * Returns rounded number always on upper value
 * @param value - number
 */
export const roundUpNumber = (num) => {
	return Math.ceil(num);
};

export const getDiffPercentageBetweenTwoNumbers = (newValue, oldValue) => {
	if (oldValue <= 0) {
		return 0;
	}

	const result =
		((parseFloat(newValue) - parseFloat(oldValue)) / parseFloat(oldValue)) *
		100;

	return result.toFixed(2);
};

export const getNewValueFromDiffPercentage = (oldValue, percentage) => {
	if (!oldValue) {
		return oldValue;
	}

	const result =
		(parseFloat(oldValue) * percentage) / 100 + parseFloat(oldValue);

	return result;
};

export const getTotalModelSavingPercentage = (
	totalEstimate,
	totalCustomisation
) => {
	return (
		(1 -
			parseFloat(totalCustomisation).toFixed(1) /
				parseFloat(totalEstimate).toFixed(1)) *
		100
	);
};
