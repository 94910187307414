import React from "react";
import { generalConstants } from "../../constants/constants";
import CheckboxOffIcon from "../icons/misc/CheckboxOffIcon";
import CheckboxOnIcon from "../icons/misc/CheckboxOnIcon";
import RadioButtonOffIcon from "../icons/misc/RadioButtonOffIcon";
import RadioButtonOnIcon from "../icons/misc/RadioButtonOnIcon";
import XMarkIcon from "../icons/misc/XMarkIcon";
import CheckIcon from "../icons/misc/CheckIcon";

const SelectionCheckmark = ({
	selected = false,
	type,
	radioButtonClass = "icon--24",
	isForView = false,
}) => {
	if (isForView) {
		return (
			<>
				{!selected ? (
					<XMarkIcon iconClass="d-flex icon--24" />
				) : (
					<CheckIcon iconClass="d-flex icon--24" />
				)}
			</>
		);
	}
	return (
		<div>
			{!selected ? (
				type === generalConstants.MULTI ? (
					<CheckboxOffIcon iconClass="d-flex icon--24 icon--n300 target--fill" />
				) : (
					<RadioButtonOffIcon
						iconClass={`d-flex ${radioButtonClass} icon--n300 target--fill`}
					/>
				)
			) : type === generalConstants.MULTI ? (
				<CheckboxOnIcon iconClass="d-flex icon--24 icon--primary target--fill" />
			) : (
				<RadioButtonOnIcon
					iconClass={`d-flex  ${radioButtonClass} icon--primary target--fill`}
				/>
			)}
		</div>
	);
};

export default SelectionCheckmark;
