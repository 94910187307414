import { Snackbar } from "@mui/material";
import { AlertTitle } from "@mui/material";
import Alert from "@mui/material/Alert";
import React from "react";
import { alertTypeConstants } from "../../constants/constants";
import ErrorIcon from "../icons/misc/ErrorIcon";
import InfoIcon from "../icons/misc/InfoIcon";
import SuccessIcon from "../icons/misc/SuccessIcon";
import WarningIcon from "../icons/misc/WarningIcon";

/**
 * Retrieves snackbar
 * @param {Object.<string, any>} props
 *
 * @param {boolean} props.open - Is snackbar visible, Default: false
 * @param {string} [props.type=success|error|warning|info]  - One of 4 snackbar types, from alertTypeConstants: [success|warning|info|error], Default: success
 * @param {function} props.onClose - Triggers when snackbar is closed
 * @param {string} props.title - Snackbar title content
 * @param {React.ReactNode} props.children - Additional snackbar content
 * @param {number} props.id - Used to distinguish snackbars, Default: 0,
 * @param {number} props.autoHideDuration - MS value after which snackbar should disappear, Default: 4000
 *
 * @return {React.FC} Snackbar component
 */
const CustomSnackbar = ({
	open = false,
	type = alertTypeConstants.SUCCESS,
	onClose,
	title = "",
	children,
	id = 0,
	autoHideDuration = 4000,
}) => {
	return (
		<Snackbar
			open={open}
			autoHideDuration={autoHideDuration}
			onClose={onClose}
			anchorOrigin={{
				vertical: "top",
				horizontal: "right",
			}}
			key={id}
			classes={{
				root: "snackbar",
			}}
		>
			<Alert
				variant="outlined"
				classes={{
					root: "alert",
					outlinedSuccess: "alert--success",
					outlinedInfo: "alert--info",
					outlinedWarning: "alert--warning",
					outlinedError: "alert--error",
					icon: "alert__icon",
					action: "close",
				}}
				iconMapping={{
					success: <SuccessIcon iconClass="d-flex align-items-center" />,
					info: <InfoIcon iconClass="d-flex align-items-center" />,
					error: <ErrorIcon iconClass="d-flex align-items-center" />,
					warning: <WarningIcon iconClass="d-flex align-items-center" />,
				}}
				onClose={onClose}
				severity={type}
			>
				{title && (
					<AlertTitle>
						<h4 className="small">{title}</h4>
					</AlertTitle>
				)}
				{children}
			</Alert>
		</Snackbar>
	);
};

export default CustomSnackbar;
