import { List, Map, fromJS } from "immutable";
import { lodSpecificationConstants } from "../constants/lodSpecificationConstants";

/**
 * LOD SPECIFICATION reducer redux
 */

const initialState = Map({
	lodSpecification: Map(),
	lodSpecificationPdf: Map(),
	action: false,
	request: false,
	errorResponse: "",
	lodSpecificationList: Map(),
	lodSpecificationMeta: Map(),
	tempLodSpecification: Map({
		name: null,
		disciplineComponentCategoryLods: [
			{
				disciplineId: null,
				componentCategoryId: null,
				lodId: 1,
				isSupported: false,
				valid: false,
			},
		],

		validity: Map({
			name: false,
		}),
	}),
	tempLodSpecificationPdf: Map({
		name: null,
		disciplineComponentCategoryLods: [
			{
				disciplineId: null,
				componentCategoryId: null,
				lodId: 1,
				isSupported: false,
				valid: false,
			},
		],

		validity: Map({
			name: false,
		}),
	}),
	// lodSpecificationSingleAccordion: null,
	stats: null,
	tempSpecificationContent: List(),
	successMessage: "Success!",
	errorMessage: "Something went wrong",
});

export default (state = initialState, action) => {
	const getLodSpecificationDisciplineComponentCategoryLodParamCategories = (
		data,
		lod
	) => {
		const {
			discipline,
			componentCategory,
			disciplineComponentCategoryLodsDescription,
		} = data;

		const lodSpecificationState = [];
		const tempStateDisciplines = [];

		// const lodSpecificationDisciplineComponentCategoryLods =
		// 	data?.lodSpecificationDisciplineComponentCategoryLods;

		let tempParameterCategories = [];
		const content = disciplineComponentCategoryLodsDescription[0];

		const parameterCategories = content?.parameterCategories || null;

		//get all parent parameter categories
		const parentParameterCategories = parameterCategories
			?.filter((pc) => pc?.parameterCategory?.parentId === null)
			?.sort(
				(a, b) =>
					a?.parameterCategory?.sortOrder - b?.parameterCategory?.sortOrder
			);

		//go through all parent parameter categories and get all child parameter categories
		parentParameterCategories &&
			parentParameterCategories.forEach((pc) => {
				//get all child parameter categories
				const childParameterCategories = parameterCategories
					?.filter(
						(cpc) =>
							cpc?.parameterCategory?.parentId === pc?.parameterCategory?.id
					)
					?.sort((a, b) => a?.sortOrder - b?.sortOrder);

				//update temp parameter categories
				tempParameterCategories.push({
					id: pc?.parameterCategory?.id,
					name: pc?.parameterCategory?.name,
					parameters: childParameterCategories,
				});
			});

		lodSpecificationState.push({
			componentCategory: componentCategory,
			discipline: discipline,
			lod: {
				id: lod?.id,
				type: lod?.type,
			},
			content: { ...content, parameterCategories: tempParameterCategories },
		});

		tempStateDisciplines.push({
			disciplineId: discipline?.id,
			componentCategoryId: componentCategory?.id,
			lodId: lod?.id,
			valid: lod?.id > 1 ? true : false,
		});

		const tempData = {
			...data,
			lodSpecificationDisciplineComponentCategoryLods: lodSpecificationState,
		};

		return state
			.setIn(
				[
					"lodSpecificationSingleAccordion",
					`${`discipline-${discipline.id}`}`,
					`component-category-${componentCategory.id}`,
				],
				tempData
			)
			.setIn(
				[
					"tempLodSpecificationSingleAccordion",
					"disciplineComponentCategoryLodsSingleAccordion",
				],
				tempStateDisciplines
			);
	};

	const getLodSpecificationPdf = (data) => {
		const lodSpecificationState = [];
		const tempStateDisciplines = [];
		const lodSpecificationDisciplineComponentCategoryLods =
			data?.lodSpecificationDisciplineComponentCategoryLods;

		lodSpecificationDisciplineComponentCategoryLods &&
			lodSpecificationDisciplineComponentCategoryLods.forEach((element) => {
				const componentCategoryId = element?.componentCategory?.id || null;
				const disciplineId = element?.discipline?.id || null;

				const disciplineComponentCategoryLodsDescription =
					element?.lod?.disciplineComponentCategoryLodsDescription;

				const content = disciplineComponentCategoryLodsDescription?.find(
					(dccld) =>
						dccld?.disciplineComponentCategory?.componentCategory?.id ===
							componentCategoryId &&
						dccld?.disciplineComponentCategory?.discipline?.id === disciplineId
				);

				let tempParameterCategories = [];
				const parameterCategories = content?.parameterCategories || null;

				//get all parent parameter categories
				const parentParameterCategories = parameterCategories
					?.filter((pc) => pc?.parameterCategory?.parentId === null)
					?.sort(
						(a, b) =>
							a?.parameterCategory?.sortOrder - b?.parameterCategory?.sortOrder
					);

				//go through all parent parameter categories and get all child parameter categories
				parentParameterCategories &&
					parentParameterCategories.forEach((pc) => {
						//get all child parameter categories
						const childParameterCategories = parameterCategories
							?.filter(
								(cpc) =>
									cpc?.parameterCategory?.parentId === pc?.parameterCategory?.id
							)
							?.sort((a, b) => a?.sortOrder - b?.sortOrder);

						//update temp parameter categories
						tempParameterCategories.push({
							id: pc?.parameterCategory?.id,
							name: pc?.parameterCategory?.name,
							parameters: childParameterCategories,
						});
					});

				lodSpecificationState.push({
					componentCategory: element?.componentCategory,
					discipline: element?.discipline,
					lod: {
						id: element?.lod?.id,
						type: element?.lod?.type,
					},
					content: { ...content, parameterCategories: tempParameterCategories },
				});

				tempStateDisciplines.push({
					disciplineId: element?.discipline?.id,
					componentCategoryId: element?.componentCategory?.id,
					lodId: element?.lod?.id,
					valid: element?.lod?.id > 1 ? true : false,
				});
			});

		const tempData = {
			...data,
			lodSpecificationDisciplineComponentCategoryLods: lodSpecificationState,
		};

		return state.set("lodSpecificationPdf", fromJS(tempData));
	};

	const getLodSpecification = (data) => {
		const lodSpecificationState = [];
		const tempStateDisciplines = [];
		const lodSpecificationDisciplineComponentCategoryLods =
			data?.lodSpecificationDisciplineComponentCategoryLods;

		lodSpecificationDisciplineComponentCategoryLods &&
			lodSpecificationDisciplineComponentCategoryLods.forEach((element) => {
				const componentCategoryId = element?.componentCategory?.id || null;
				const disciplineId = element?.discipline?.id || null;

				const disciplineComponentCategoryLodsDescription =
					element?.lod?.disciplineComponentCategoryLodsDescription;

				const content = disciplineComponentCategoryLodsDescription?.find(
					(dccld) =>
						dccld?.disciplineComponentCategory?.componentCategory?.id ===
							componentCategoryId &&
						dccld?.disciplineComponentCategory?.discipline?.id === disciplineId
				);

				let tempParameterCategories = [];
				const parameterCategories = content?.parameterCategories || null;

				//get all parent parameter categories
				const parentParameterCategories = parameterCategories
					?.filter((pc) => pc?.parameterCategory?.parentId === null)
					?.sort(
						(a, b) =>
							a?.parameterCategory?.sortOrder - b?.parameterCategory?.sortOrder
					);

				//go through all parent parameter categories and get all child parameter categories
				parentParameterCategories &&
					parentParameterCategories.forEach((pc) => {
						//get all child parameter categories
						const childParameterCategories = parameterCategories
							?.filter(
								(cpc) =>
									cpc?.parameterCategory?.parentId === pc?.parameterCategory?.id
							)
							?.sort((a, b) => a?.sortOrder - b?.sortOrder);

						//update temp parameter categories
						tempParameterCategories.push({
							id: pc?.parameterCategory?.id,
							name: pc?.parameterCategory?.name,
							parameters: childParameterCategories,
						});
					});

				lodSpecificationState.push({
					componentCategory: element?.componentCategory,
					discipline: element?.discipline,
					lod: {
						id: element?.lod?.id,
						type: element?.lod?.type,
					},
					content: { ...content, parameterCategories: tempParameterCategories },
				});

				tempStateDisciplines.push({
					disciplineId: element?.discipline?.id,
					componentCategoryId: element?.componentCategory?.id,
					lodId: element?.lod?.id,
					lodType: element?.lod?.type,
					valid: element?.lod?.id > 1 ? true : false,
				});
			});

		const tempData = {
			...data,
			lodSpecificationDisciplineComponentCategoryLods: lodSpecificationState,
		};

		return state
			.set("lodSpecification", fromJS(tempData))
			.setIn(
				["tempLodSpecification", "disciplineComponentCategoryLods"],
				tempStateDisciplines
			)
			.setIn(
				["tempLodSpecification", "initialDisciplineComponentCategoryLods"],
				tempStateDisciplines
			);
	};

	/**
	 * Set initial component category and lods per decipline inside TEMP LOD SPECIFICATION object
	 * @param {*} data - { disciplines }
	 * @returns
	 */
	const setDisciplineLodsDescription = (data) => {
		const { disciplines } = data;

		const stateDisciplines = [];

		disciplines &&
			disciplines.forEach((discipline) => {
				const disciplineComponentCategories =
					discipline?.disciplineComponentCategories;

				disciplineComponentCategories &&
					disciplineComponentCategories.forEach((element) => {
						stateDisciplines.push({
							disciplineId: discipline.id,
							disciplineType: discipline.type,
							isSupported: element.isSupported,
							componentCategoryId: element?.componentCategory?.id,
							componentCategoryType: element?.componentCategory?.type,
							lodId: element.isSupported ? 2 : 1,
							valid: element.isSupported ? true : false,
						});
					});
			});

		return state.setIn(
			["tempLodSpecification", "disciplineComponentCategoryLods"],
			stateDisciplines
		);
	};

	/**
	 * Update lods,component category per decipline inside TEMP LOD  SPECIFICATION object
	 * @param {*} data - {componentCategoryId, lodValueId, disciplineId}
	 * @returns
	 */
	const updateLods = (data) => {
		const { componentCategoryId, lodValueId, disciplineId, lodValueType } =
			data;
		const disciplineComponentCategoryLods = state.getIn([
			"tempLodSpecification",
			"disciplineComponentCategoryLods",
		]);

		const index =
			disciplineComponentCategoryLods &&
			disciplineComponentCategoryLods.findIndex(
				(item) =>
					item.disciplineId === disciplineId &&
					item.componentCategoryId === componentCategoryId
			);
		return state
			.setIn(
				[
					"tempLodSpecification",
					"disciplineComponentCategoryLods",
					index,
					"lodId",
				],
				lodValueId
			)
			.setIn(
				[
					"tempLodSpecification",
					"disciplineComponentCategoryLods",
					index,
					"lodType",
				],
				lodValueType
			)
			.setIn(
				[
					"tempLodSpecification",
					"disciplineComponentCategoryLods",
					index,
					"valid",
				],
				+lodValueId > 1 ? true : false
			);
	};

	//---------------------------------NEW STATE FUNCTION-------------------------------------

	const setTempSpecificationContent = (data) => {
		const {
			id,
			componentCategory,
			disciplineComponentCategoryLodsDescription,
		} = data;

		const tempSpecificationContent =
			state.get("tempSpecificationContent")?.toJS() || [];

		const findSpecifcationContent = tempSpecificationContent.find(
			(item) => item?.id === id
		);

		if (findSpecifcationContent) {
			return state.set(
				"tempSpecificationContent",
				fromJS(tempSpecificationContent)
			);
		}

		//Filter and sort lods
		let disciplineComponentCategoryLodsDescriptionSorted =
			disciplineComponentCategoryLodsDescription
				.filter((item) => item?.lod && +item?.lod?.type !== +0)
				.sort((a, b) => a.lod?.sortOrder - b.lod?.sortOrder);

		let images3D = [];
		let images2D = [];
		let summaries = [];

		let lodParameterCategories = [];

		const parameterCategories =
			disciplineComponentCategoryLodsDescriptionSorted[0]
				?.parameterCategories || [];

		//Set all categories
		for (const pc of parameterCategories) {
			if (!pc?.parameterCategory?.parent && !!pc?.parameterCategory) {
				lodParameterCategories.push({
					id: pc?.parameterCategory.id,
					name: pc?.parameterCategory.name,
					sortOrder: pc?.parameterCategory.sortOrder,
					lodParameters: [],
				});
			}
		}
		lodParameterCategories = lodParameterCategories?.sort(
			(a, b) => a.sortOrder - b.sortOrder
		);
		let lods = [];
		let disciplineComponentCategoryLodsDescriptionIds = [];

		//---------------------------------------------
		disciplineComponentCategoryLodsDescriptionSorted &&
			disciplineComponentCategoryLodsDescriptionSorted.forEach((item) => {
				if (item.lod && +item.lod?.type !== +0) {
					disciplineComponentCategoryLodsDescriptionIds.push({
						id: item.id,
						lodId: item.lod.id,
					});

					//Set all 3d images
					images3D.push({
						id: item.id,
						lodId: item.lod.id,
						lodType: item.lod.type,
						sortOrder: item.lod.sortOrder,
						value: item.threeDFile?.publicUrl || null,
						fileId: item.threeDFile?.id || null,
						tempFileId: item.threeDFile?.id || null,
					});

					//Set all 2d images
					images2D.push({
						id: item.id,
						lodId: item.lod.id,
						sortOrder: item.lod.sortOrder,
						lodType: item.lod.type,
						value: item.twoDFile?.publicUrl || null,
						fileId: item.twoDFile?.id || null,
						tempFileId: item.twoDFile?.id || null,
					});

					//Set all 2d summaries
					summaries.push({
						id: item.id,
						lodId: item.lod.id,
						lodType: item.lod.type,
						sortOrder: item.lod.sortOrder,
						summary: item?.description || null,
					});

					const parameterCategories =
						item?.parameterCategories?.sort(
							(a, b) => a.sortOrder - b.sortOrder
						) || [];

					//Set all children parameter categories
					parameterCategories &&
						parameterCategories.forEach((pc) => {
							if (!!pc.parameterCategory?.parent && !!pc?.parameterCategory) {
								lods.push({
									id: pc.id,
									disciplineComponentCategoryLodDescriptionId: item.id,
									txtValue: pc.txtValue,
									boolValue: pc.boolValue,
									parameterCategoryId: pc.parameterCategory.id,
									parameterCategoryName: pc.parameterCategory.name,
									inputFieldType: pc.parameterCategory.inputFieldType,
									parameterCategoryParentId: pc.parameterCategory.parent?.id,
									lodId: item.lod.id,
									sortOrder: item.lod.sortOrder,
									lodType: item.lod.type,
								});
							}
						});
				}
			});

		//Go through all categories and set all parameters and lods
		lodParameterCategories.forEach((lpc) => {
			let parameters = [];
			let lodParameters = [];

			//Filter all lods by category and sort them
			const filter = lods
				.filter((l) => l.parameterCategoryParentId === lpc.id)
				?.sort((a, b) => a.sortOrder - b.sortOrder);

			//Set all parameters
			filter &&
				filter.forEach((f) => {
					if (!parameters.includes(f.parameterCategoryId))
						parameters.push(f.parameterCategoryId);
				});

			//Go through all parameters and set all lods
			parameters &&
				parameters.forEach((p) => {
					const filterParameters = filter?.filter(
						(item) => item.parameterCategoryId === p
					);
					let values = {
						name: filterParameters[0]?.parameterCategoryName,
						id: filterParameters[0]?.parameterCategoryId,
						lods: [],
					};

					//Set lods for each parameter
					let tempLods = [];
					filterParameters &&
						filterParameters.forEach((fp) => {
							tempLods.push({
								disciplineComponentCategoryLodDescriptionId:
									fp.disciplineComponentCategoryLodDescriptionId,
								txtValue: fp.txtValue,
								boolValue: fp.boolValue,
								lodId: fp.lodId,
								lodType: fp.lodType,
								inputFieldType: fp.inputFieldType,
								id: fp.id,
							});
						});

					//set all lods to parameter
					values.lods = tempLods;

					lodParameters.push(values);
				});
			//set all parameters to category
			lpc.lodParameters = lodParameters;
		});

		let lodContent = { images3D, images2D, summaries };

		const specifciationContent = {
			id,
			lodContent,
			componentCategory,
			lodParameterCategories,
		};

		tempSpecificationContent.push(specifciationContent);

		return state.set(
			"tempSpecificationContent",
			fromJS(tempSpecificationContent)
		);
	};
	switch (action.type) {
		case lodSpecificationConstants.GET_LOD_SPECIFICATIONS:
			return state
				.set("lodSpecificationList", fromJS(action.data.result))
				.set("lodSpecificationMeta", fromJS(action.data.meta));
		// .set("lodSpecification", Map())
		// .set("tempLodSpecification", initialState.get("tempLodSpecification"));

		case lodSpecificationConstants.SET_DISCIPLINE_LODS_DESCRIPTION:
			return setDisciplineLodsDescription(action.data);

		case lodSpecificationConstants.UPDATE_LODS:
			return updateLods(action.data);

		case lodSpecificationConstants.LOD_SPECIFICATION_ACTION_SUCCESS:
			return state.set("action", true).set("request", true);

		case lodSpecificationConstants.LOD_SPECIFICATION_ACTION_FAILURE:
			return state
				.set("action", false)
				.set("request", true)
				.set("errorResponse", fromJS(action.error));

		case lodSpecificationConstants.SET_SUCCESS_MESSAGE:
			return state.set("successMessage", fromJS(action.data));

		case lodSpecificationConstants.SET_ERROR_MESSAGE:
			return state.set("errorMessage", fromJS(action.data));

		case lodSpecificationConstants.CLEAR_LOD_SPECIFICATION_REQUEST_STATE:
			return state
				.set("action", false)
				.set("request", false)
				.set("errorMessage", "")
				.set("successMessage", "");

		case lodSpecificationConstants.CLEAR_TEMP_LOD_SPECIFICATION:
			return state.set(
				"tempLodSpecification",
				initialState.get("tempLodSpecification")
			);

		case lodSpecificationConstants.CLEAR_LOD_SPECIFICATION:
			return state.set("lodSpecification", Map());

		case lodSpecificationConstants.LOD_SPECIFICATION_STATS:
			return state.set("stats", fromJS(action.data.result));

		case lodSpecificationConstants.GET_LOD_SPECIFICATION:
			if (action.useForPdf) {
				return getLodSpecificationPdf(action.data.result);
			} else {
				return getLodSpecification(action.data.result);
			}

		case lodSpecificationConstants.GET_DISCIPLINE_COMPONENT_CATEGORY_LOD_PARAM_CATEGORIES:
			return getLodSpecificationDisciplineComponentCategoryLodParamCategories(
				action.data.result,
				action.lod
			);

		// return getLodSpecification(action.data.result);

		//---------------------------------NEW STATE FUNCTION-------------------------------------
		case lodSpecificationConstants.SET_TEMP_SPECIFICATION_CONTENT:
			return setTempSpecificationContent(action.data);

		default:
			return state;
	}
};
