/**
 * Format number(id) to user friendly id:
 * For id 1 expected output is #00000000001
 * @param {number} id
 * @returns formatted id
 */
export const formatId = (id) => {
	if (!id) {
		return "";
	}
	const stringId = id.toString();
	const prefix = "#";
	const formatted = stringId.padStart(9, "0");
	return prefix + formatted;
};
