import React from "react";
import { fileSizes } from "../../constants/constants";
import { useFileInput } from "../../hooks/useFileInput";
import IconWrapper from "../../shared/icons/IconWrapper";
import UploadIcon from "../../shared/icons/misc/UploadIcon";

const FileInputButton = ({
	id,
	disabled = false,
	customOnChange,
	multipleFilesAllowed = true,
	buttonLabel = false,
	buttonWrapperClasses = "btn--primary ml-m",
	allowedSize = fileSizes.GB_5,
	icon = <UploadIcon />,
}) => {
	const { onChange } = useFileInput({ id, customOnChange, allowedSize });

	return (
		<>
			<input
				id={id ? id : "file-input"}
				hidden
				type="file"
				name={id ? id : "file-input"}
				multiple={multipleFilesAllowed}
				onChange={onChange}
				disabled={disabled}
			/>
			<label
				htmlFor={id ? id : "file-input"}
				className={`btn ${buttonWrapperClasses} ${
					buttonLabel ? " " : "btn--icon btn--icon--solo"
				}     ${disabled ? "btn--disabled" : " "}`}
			>
				{!buttonLabel && <IconWrapper icon={icon} size={24} />}
				{buttonLabel && buttonLabel}
			</label>
		</>
	);
};

export default FileInputButton;
