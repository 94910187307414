import { destroy } from "redux-form";
import { symbolConstants } from "../constants/symbolConstants";
import { api, apiDO } from "../api";
import { getToken } from "../utils/local-storage.util";
import localize, { LocKeys } from "../constants/localizations";
import { statusConstants } from "../constants/constants";
import { getPagination, getSymbolFilters } from "../utils/table-filter";

/**
 * Get symbols endpoint with pagination and search, filter
 *
 * @param {number} page - page number
 * @param {string} searchQuery - search query
 * @param {Array<string>} searchFilters - search filters
 * @param {number} limit - limit
 * @returns
 */
export const getSymbols = ({ page = 1, searchQuery, searchFilters, limit }) => {
	return (dispatch, getState) => {
		const state = getState();

		const symbolQueryParameter = state.getIn([
			"queryParameter",
			"symbolQueryParameter",
		]);
		const tablePagination = state.getIn(["form", "tablePagination", "values"]);

		const { filters, search } = getSymbolFilters({
			queryParameter: symbolQueryParameter,
			searchFilters,
			searchQuery,
		});
		const { currentPage, currentLimit } = getPagination({
			tablePagination,
			page,
			limit,
		});

		const requestOptions = {
			method: "PUT",
			url: `/family-file`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
			data: {
				page: currentPage,
				limit: currentLimit,
				filters,
				search,
			},
		};
		return api(requestOptions).then(
			(res) => {
				dispatch({
					type: symbolConstants.GET_SYMBOLS,
					data: res.data,
				});
			},
			(err) => {
				dispatch(symbolActionFailure(err.response.data.result));
			}
		);
	};
};

/**
 * GET single symbol
 * @param {number} id  - unique symbol identifier
 * @returns
 */
export const getSymbol = (id) => {
	return (dispatch) => {
		const requestOptions = {
			method: "GET",
			url: `/family-file/${id}`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
		};
		return api(requestOptions).then(
			(res) => {
				dispatch({
					type: symbolConstants.GET_SYMBOL,
					data: res.data?.result,
				});

				dispatch({
					type: symbolConstants.SET_TEMP_SYMBOL,
					data: res.data?.result,
				});
			},
			(err) => {
				dispatch(symbolActionFailure(err.response.data.result));
			}
		);
	};
};

/**
 * UPDATE symbol endpoint
 *
 * @param {number} id - unique symbol identifier
 * @param {object} data - symbol form data
 * @param {Function} cb - callback function
 * @returns
 */
export const updateSymbol = (id, data, cb = () => {}) => {
	return (dispatch) => {
		const requestOptions = {
			method: "PUT",
			url: `/family-file/${id}`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
			data,
		};
		return api(requestOptions).then(
			(res) => {
				cb();
				dispatch(
					symbolActionSuccess(
						localize(LocKeys.ITEM_UPDATED, [localize(LocKeys.SYMBOL)])
					)
				);
			},
			(err) => {
				dispatch(symbolActionFailure(err.response.data.result));
			}
		);
	};
};

/**
 * GET symbol file upload url
 *
 * @param {number} id - unique symbol identifier
 * @param {File} file - symbol file
 * @param {Function} cb - callback function
 * @returns
 */
export const getSymbolFileUploadUrl = (id, file, cb = () => {}) => {
	return (dispatch) => {
		const requestOptions = {
			method: "GET",
			url: `/family-file-upload-url/${id}`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
		};
		return api(requestOptions).then(
			(res) => {
				const url = res.data?.result;
				dispatch(uploadSymbolFileToDO({ url, file, cb }));
			},
			(err) => {
				dispatch(symbolActionFailure(err.response.data.result));
			}
		);
	};
};

/**
 * UPLOAD symbol file to DigitalOcean
 *
 * @param {object} urlData - url link & fields
 * @param {File} file - symbol file
 * @param {Function} cb - callback function
 * @returns
 */
export const uploadSymbolFileToDO = ({ url, file, cb }) => {
	return (dispatch) => {
		// let form = new FormData();
		// Object.keys(fields).forEach((key) => form.append(key, fields[key]));

		// form.append("file", file);

		const requestOptions = {
			method: "PUT",
			url,
			//data: form,
			data: new Blob([file], { type: file.type }),
		};

		return apiDO(requestOptions).then(
			(_) => {
				cb();
			},
			(err) => {
				console.log(err);
			}
		);
	};
};

/**
 * SET symbol file details to database
 *
 * @param {object} data - {fileName}
 * @returns
 */
export const setSymbolFileDetails = (data) => {
	return (dispatch) => {
		const requestOptions = {
			method: "POST",
			url: "/family-file-details",
			headers: {
				"Content-Type": "application/json",
				Accept: "*/*",
			},
			data,
		};
		return api(requestOptions).catch(
			(res) => {
				console.log({ res });
			},
			(err) => {
				console.log({ err });
			}
		);
	};
};

/**
 * Update symbol status (single & mutliple symbol)
 *
 * SUCCESS:
 * 		1. symbolActionSuccess
 * 		2. getSymbols
 *
 * @param {Array<number>} ids -  unique symbols identifier
 * @param {Array<string>} status - symbol status
 * @returns
 */
export const updateSymbolStatus = (ids, status) => {
	return (dispatch) => {
		const isArchived = status === statusConstants.ARCHIVED;
		const requestOptions = {
			method: "PUT",
			url: isArchived ? "/family-file-archive" : `/family-status-multiple`,
			headers: {
				"Content-Type": "application/json",
				Accept: "*/*",
				Authorization: "Bearer " + getToken(),
			},
			data: { ids, status },
		};
		return api(requestOptions).then(
			() => {
				dispatch(getSymbols({}));
				switch (status) {
					case statusConstants.ARCHIVED:
						dispatch(
							symbolActionSuccess(
								localize(LocKeys.ITEM_ARCHIVE_SUCCESS, [
									localize(LocKeys.SYMBOL),
								])
							)
						);
						break;

					default:
						dispatch(symbolActionSuccess(localize(LocKeys.STATUS_UPDATED)));
						break;
				}
			},

			(err) => {
				dispatch(symbolActionFailure(err.response.data.result));
			}
		);
	};
};

/**
 * DELETE symbol endpoint
 *
 * @param {number} id - unique symbol identifier
 * @param {Function} cb - callback function
 * @returns
 */
export const deleteSymbol = (ids, cb = null) => {
	return (dispatch) => {
		const requestOptions = {
			method: "DELETE",
			url: `/family-file`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
			data: { ids },
		};
		return api(requestOptions).then(
			(res) => {
				cb
					? cb()
					: dispatch(
							getSymbols({
								pageIndex: 1,
								limit: 7,
								searchFilters: { statuses: [statusConstants.ARCHIVED] },
							})
						);
				dispatch(
					symbolActionSuccess(
						localize(LocKeys.ITEM_DELETED, [localize(LocKeys.SYMBOL)])
					)
				);
			},
			(err) => {
				dispatch(
					symbolActionFailure(
						localize(LocKeys.ERROR_ITEM_DELETED, [localize(LocKeys.SYMBOL)])
					)
				);
			}
		);
	};
};

//---------------------------------MANUAL STATE FUNCTION-------------------------------------

/**
 * UPDATE temp symbol field
 *
 * @param {string} fieldKey - field key
 * @param {string} value - field value
 * @returns
 */
export const updateTempFieldSymbol = (fieldKey, value) => {
	return (dispatch) => {
		dispatch({
			type: symbolConstants.UPDATE_TEMP_FIELD_SYMBOL,
			data: { fieldKey, value },
		});
	};
};

/**
 * SUCCESS  action
 *
 * @param {string} data - success message
 * @returns
 */
export const symbolActionSuccess = (data) => {
	return {
		type: symbolConstants.SYMBOL_ACTION_GET_SUCCESS,
		data,
	};
};
/**
 * FAILURE action
 *
 * @param {string} data - error message
 * @returns
 */
export const symbolActionFailure = (data) => {
	return {
		type: symbolConstants.SYMBOL_ACTION_GET_FAILURE,
		data,
	};
};

/**
 * CLEAR single symbol
 * @returns
 */
export const clearSingleSymbol = () => {
	return (dispatch) => {
		dispatch({ type: symbolConstants.CLEAR_SINGLE_SYMBOL });
	};
};

/**
 * CLEAR temp symbol
 * @param {string} form - form name
 * @returns
 */
export const clearTempSymbol = (form = "createSymbolForm") => {
	return (dispatch) => {
		dispatch(destroy(form));
		dispatch({ type: symbolConstants.CLEAR_TEMP_SYMBOL });
	};
};

export const clearSymbolRequestState = () => {
	return {
		type: symbolConstants.CLEAR_SYMBOL_REQUEST_STATE,
	};
};

/**
 * SET symbol temp file
 *
 * @param {FilE} file  - symbol file
 * @returns
 */
export const setSymbolTempFile = (file) => {
	return (dispatch) => {
		dispatch({
			type: symbolConstants.SET_SYMBOL_TEMP_FILE,
			data: file,
		});
	};
};

/**
 * SET symbol temp file url
 *
 * @param {string} url - symbol file url
 * @returns
 */
export const setSymbolTempFileURL = (url) => {
	return (dispatch) => {
		dispatch({
			type: symbolConstants.SET_SYMBOL_TEMP_FILE_URL,
			data: url,
		});
	};
};
