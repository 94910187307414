import React from "react";

export default (props) => {
	return (
		<span className={`icon ${props.iconClass ? props.iconClass : ""}`}>
			<svg
				width="8"
				height="5"
				viewBox="0 0 8 5"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M1.12094e-08 4.06L0.94 5L4 1.94667L7.06 5L8 4.06L4 0.06L1.12094e-08 4.06Z"
					fill="#7F909F"
				/>
			</svg>
		</span>
	);
};
