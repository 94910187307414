export const manualConstants = {
	GET_MANUALS: "GET_MANUALS",
	GET_MANUAL: "GET_MANUAL",
	SET_TEMP_MANUAL: "SET_TEMP_MANUAL",
	SET_TEMP_MANUAL_CONTENT: "SET_TEMP_MANUAL_CONTENT",
	MANUAL_ACTION_GET_SUCCESS: "MANUAL_ACTION_GET_SUCCESS",
	MANUAL_ACTION_GET_FAILURE: "MANUAL_ACTION_GET_FAILURE",
	CLEAR_TEMP_MANUAL: "CLEAR_TEMP_MANUAL",
	UPDATE_TEMP_FIELD_MANUAL: "UPDATE_TEMP_FIELD_MANUAL",
	CLEAR_SINGLE_MANUAL: "CLEAR_SINGLE_MANUAL",
	CLEAR_MANUAL_REQUEST_STATE: "CLEAR_MANUAL_REQUEST_STATE",
};
