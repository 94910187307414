import React from "react";

export default (props) => {
	return (
		<div className={`icon ${props.iconClass ? props.iconClass : ""}`}>
			<svg
				width="32"
				height="32"
				viewBox="0 0 32 32"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M26.666 4H5.33268C3.86602 4 2.66602 5.2 2.66602 6.66667V25.3333C2.66602 26.8 3.86602 28 5.33268 28H26.666C28.1327 28 29.3327 26.8 29.3327 25.3333V6.66667C29.3327 5.2 28.1327 4 26.666 4ZM5.33268 25.3333V6.66667H26.666V25.3333H5.33268ZM25.8793 13.8933L23.986 12L19.7593 16.2267L17.8793 14.3333L15.9993 16.2133L19.7593 20L25.8793 13.8933ZM6.66602 9.33333H13.3327V12H6.66602V9.33333ZM13.3327 14.6667H6.66602V17.3333H13.3327V14.6667ZM6.66602 20H13.3327V22.6667H6.66602V20Z"
					fill="#1b2024"
				/>
			</svg>
		</div>
	);
};
