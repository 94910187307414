import { destroy } from "redux-form";
import { api } from "../api";
import { statusConstants } from "../constants/constants";
import { familyConstants } from "../constants/familyConstants";
import localize, { LocKeys } from "../constants/localizations";
import { getToken } from "../utils/local-storage.util";
import { getManualFilters, getPagination } from "../utils/table-filter";

/**
 * Get all families action
 *
 * @param {object} options - options object
 * @param {string} options.searchQuery - search query
 * @param {number} options.page - page number
 * @param {number} options.limit - limit number
 * @param {string[]} options.statuses - statuses array
 * @param {boolean} options.updateStatus - update status flag
 *
 * @returns {Function}
 *
 */
export const getFamilies = ({
	searchQuery = "",
	page = 1,
	limit,
	statuses,
	updateStatus = false,
}) => {
	return (dispatch, getState) => {
		let queryPath = null;
		const state = getState();

		const tableFilters = state.getIn(["form", "tableFilters", "values"]);
		const tablePagination = state.getIn(["form", "tablePagination", "values"]);

		const { status, search } = getManualFilters({
			tableFilters,
			statuses,
			searchQuery,
			updateStatus,
		});
		const { currentPage, currentLimit } = getPagination({
			tablePagination,
			page,
			limit,
		});
		const searchPath = search ? `search=${search}&` : ``;
		const pagePath = currentPage ? `page=${currentPage}&` : ``;
		const limitPath = currentLimit ? `limit=${currentLimit}&` : ``;
		const statusesPath = status ? `statuses=${JSON.stringify([status])}&` : ``;

		queryPath = `${pagePath}${limitPath}${searchPath}${statusesPath}`;

		const requestOptions = {
			method: "GET",
			url: `/family?usePagination=true&${queryPath || ""}`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
		};
		return api(requestOptions).then(
			(res) => {
				dispatch({
					type: familyConstants.GET_FAMILIES,
					data: res.data,
				});
			},
			(err) => {
				dispatch(familyActionFailure(err.response.data.result));
			}
		);
	};
};

/**
 * Get single family action
 *
 * @param {number} id - family unique identifier
 *
 */
export const getFamily = (id) => {
	return (dispatch) => {
		const requestOptions = {
			method: "GET",
			url: `/family/${id}`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
		};
		return api(requestOptions).then(
			(res) => {
				dispatch({
					type: familyConstants.GET_FAMILY,
					data: res.data?.result,
				});

				dispatch({
					type: familyConstants.SET_TEMP_FAMILY,
					data: res.data?.result,
				});
			},
			(err) => {
				dispatch(familyActionFailure(err.response.data.result));
			}
		);
	};
};

/**
 * Delete family action
 *
 * @param {number[]} ids - family unique identifiers
 * @param {Function} cb - callback function
 *
 */
export const deleteFamily = (ids, cb = null) => {
	return (dispatch) => {
		const requestOptions = {
			method: "DELETE",
			url: `/family`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
			data: { ids },
		};
		return api(requestOptions).then(
			(res) => {
				cb();
				dispatch(
					familyActionSuccess(
						localize(LocKeys.ITEM_DELETED, [localize(LocKeys.COMPONENT_TYPE)])
					)
				);
			},
			(err) => {
				dispatch(
					familyActionFailure(
						localize(LocKeys.ERROR_ITEM_DELETED, [
							localize(LocKeys.COMPONENT_TYPE),
						])
					)
				);
			}
		);
	};
};

/**
 * Update family action
 *
 * @param {number} id - family unique identifier
 * @param {object} data - family data
 * @param {Function} cb - callback function
 *
 */
export const updateFamily = (id, data, cb = () => {}) => {
	return (dispatch) => {
		const requestOptions = {
			method: "PUT",
			url: `/family/${id}`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
			data,
		};
		return api(requestOptions).then(
			(res) => {
				cb();
				dispatch(
					familyActionSuccess(
						localize(LocKeys.ITEM_UPDATED, [localize(LocKeys.COMPONENT_TYPE)])
					)
				);
			},
			(err) => {
				dispatch(familyActionFailure(err.response.data.result));
			}
		);
	};
};

/**
 * Create family action
 *
 * @param {object} data - family data
 * @param {Function} cb - callback function
 *
 */
export const createFamily = (data, cb = () => {}) => {
	return (dispatch) => {
		const requestOptions = {
			method: "POST",
			url: `/family`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
			data,
		};
		return api(requestOptions).then(
			(res) => {
				cb();
				dispatch(
					familyActionSuccess(
						localize(LocKeys.ITEM_CREATED, [localize(LocKeys.COMPONENT_TYPE)])
					)
				);
			},
			(err) => {
				dispatch(familyActionFailure(err.response.data.result));
			}
		);
	};
};

/**
 * Update family status
 *
 * @param {number[]} ids - family unique identifiers
 * @param {string} status - family status
 * @param {boolean} isSingleView - single view flag
 *
 */
export const updateFamilyStatus = (ids, status, isSingleView = false) => {
	return (dispatch) => {
		const requestOptions = {
			method: "PUT",
			url: `/family-status-multiple`,
			headers: {
				"Content-Type": "application/json",
				Accept: "*/*",
				Authorization: "Bearer " + getToken(),
			},
			data: { status, ids },
		};
		return api(requestOptions).then(
			() => {
				switch (status) {
					case statusConstants.ARCHIVED:
						dispatch(
							familyActionSuccess(
								localize(LocKeys.ITEM_ARCHIVE_SUCCESS, [
									localize(LocKeys.COMPONENT_TYPE),
								])
							)
						);
						break;
					default:
						dispatch(familyActionSuccess(localize(LocKeys.STATUS_UPDATED)));
						break;
				}
				if (isSingleView) {
					dispatch(getFamily(ids[0]));
				} else {
					dispatch(getFamilies({}));
				}
			},

			(err) => dispatch(familyActionFailure(err.response.data.message))
		);
	};
};

/**
 * Check family activity (if family is used in any symbol)
 *
 * @param {number} id - family unique identifier
 * @param {Function} cb - callback function
 *
 */
export const checkFamilyActivity = (id, cb = () => {}) => {
	return (dispatch) => {
		const requestOptions = {
			method: "GET",
			url: `/family-symbols/${id}`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
		};
		return api(requestOptions).then(
			(res) => {
				cb();
				dispatch({
					type: familyConstants.CHECK_FAMILY_ACTIVITY,
					data: res.data.result,
				});
			},
			(err) => {
				dispatch(familyActionFailure(err.response.data.result));
			}
		);
	};
};

//--------------------------------- STATE ACTIONS ---------------------------------

/**
 * CLEAR single family
 */
export const clearSingleFamily = () => {
	return (dispatch) => {
		dispatch({ type: familyConstants.CLEAR_SINGLE_FAMILY });
	};
};

/**
 * CLEAR temp family state and form
 * @param {string} form - form name
 * @returns
 */
export const clearTempFamily = (form = "createFamilyForm") => {
	return (dispatch) => {
		dispatch(destroy(form));
		dispatch({ type: familyConstants.CLEAR_TEMP_FAMILY });
	};
};

/**
 * Update temp family field
 *
 * @param {string} fieldKey - name of the field
 * @param {string} value - value to update
 * @returns
 */
export const updateTempFieldFamily = (fieldKey, value) => {
	return (dispatch) => {
		dispatch({
			type: familyConstants.UPDATE_TEMP_FIELD_FAMILY,
			data: { fieldKey, value },
		});
	};
};

/**
 * Family action failure
 *
 * @param {string} data - message for snackbar error notification
 *
 */
export const familyActionFailure = (data) => {
	return {
		type: familyConstants.FAMILY_ACTION_GET_FAILURE,
		data,
	};
};

/**
 * Family action success
 *
 * @param {string} data - message for snackbar success notification
 *
 */
export const familyActionSuccess = (data) => {
	return {
		type: familyConstants.FAMILY_ACTION_GET_SUCCESS,
		data,
	};
};

/**
 * Clear family request state
 */
export const clearFamilyRequestState = () => {
	return {
		type: familyConstants.CLEAR_FAMILY_REQUEST_STATE,
	};
};
