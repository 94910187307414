import React from "react";

export default (props) => {
	return (
		<span className={`icon ${props.iconClass ? props.iconClass : ""}`}>
			<svg
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M16.59 7.58008L10 14.1701L7.41 11.5901L6 13.0001L10 17.0001L18 9.00008L16.59 7.58008Z"
					fill="#48BF92"
				/>
			</svg>
		</span>
	);
};
