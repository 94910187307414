import React from "react";

export default (props) => {
	return (
		<div className={`icon ${props.iconClass ? props.iconClass : ""}`}>
			<svg
				width="32"
				height="32"
				viewBox="0 0 32 32"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M22.6667 14.6667V4H9.33333V9.33333H4V28H14.6667V22.6667H17.3333V28H28V14.6667H22.6667ZM9.33333 25.3333H6.66667V22.6667H9.33333V25.3333ZM9.33333 20H6.66667V17.3333H9.33333V20ZM9.33333 14.6667H6.66667V12H9.33333V14.6667ZM14.6667 20H12V17.3333H14.6667V20ZM14.6667 14.6667H12V12H14.6667V14.6667ZM14.6667 9.33333H12V6.66667H14.6667V9.33333ZM20 20H17.3333V17.3333H20V20ZM20 14.6667H17.3333V12H20V14.6667ZM20 9.33333H17.3333V6.66667H20V9.33333ZM25.3333 25.3333H22.6667V22.6667H25.3333V25.3333ZM25.3333 20H22.6667V17.3333H25.3333V20Z"
					fill="#1b2024"
				/>
			</svg>
		</div>
	);
};
