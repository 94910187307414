import React, { useState } from "react";
import { connect } from "react-redux";
import { Field, reduxForm, formValueSelector } from "redux-form/immutable";
import { login } from "../../actions";
import validate from "../fields/validateAuth";
import renderTextField from "../fields/renderTextField";
import renderPasswordField from "../fields/renderPasswordField";
import localize, { LocKeys } from "../../constants/localizations";
import { Link } from "react-router-dom";
import Container from "./parts/Container";
import ArrowLeft from "../../shared/icons/ArrowLeft";
import Modal from "../Modal";
import AuthenticationCode from "./2fa/AuthenticationCode";

/**
 * Render LOGIN component (email, password)
 * @param {*} props
 * @returns
 */
const Login = (props) => {
	const { login } = props;
	const [authenticationCodeModal, setAuthenticationCodeModal] = useState(false);

	const onSubmit = (formValues) => {
		if (formValues)
			try {
				let formValuesJS = formValues.toJS();

				let data = { ...formValuesJS, email: formValuesJS.email.toLowerCase() };

				return login(data, () => setAuthenticationCodeModal(true));
			} catch (error) {
				console.log({ error });
				return;
			}
	};

	return (
		<>
			<Modal show={authenticationCodeModal} hideClose={true}>
				<AuthenticationCode
					handleClose={() => setAuthenticationCodeModal(false)}
				/>
			</Modal>
			<Container pageTitle={localize(LocKeys.WELCOME_TO_BIMIFY)}>
				<form
					className={"form standardized-form"}
					onSubmit={props.handleSubmit(onSubmit)}
					noValidate
					autoComplete="off"
				>
					<a
						className="btn--stripped return-link d-flex flex-wrap align-items-center"
						href="https://bimify.com"
					>
						<ArrowLeft iconClass="mr-10 d-flex" />
						<h6 className="text--uppercase">
							{localize(LocKeys.BACK_TO_HOME)}
						</h6>
					</a>
					<div className="form__input-wrapper mb-24">
						<Field
							name="email"
							id="email"
							component={renderTextField}
							required={true}
							label={localize(LocKeys.EMAIL)}
							placeholder={localize(LocKeys.EMAIL)}
						/>
					</div>
					<div className="form__input-wrapper mb-40">
						<Field
							id="password"
							name="password"
							component={renderPasswordField}
							label={localize(LocKeys.PASSWORD)}
							placeholder={localize(LocKeys.PASSWORD)}
						/>
					</div>
					<button
						type="submit"
						className="btn btn--primary btn--bg-primary w-100"
					>
						{localize(LocKeys.LOG_IN)}
					</button>
					<div className="d-flex align-items-center body-font--small">
						<Link
							to={{ pathname: "/password-reset", state: props.emailValue }}
							className="mt-32"
						>
							{localize(LocKeys.FORGOT_PASSWORD)}
						</Link>

						{/* <Link className="mt-32 ml-auto" to={"/signup"}>
						{localize(LocKeys.SIGN_UP)}
					</Link> */}
					</div>
				</form>
			</Container>
		</>
	);
};

const selector = formValueSelector("loginForm"); // <-- same as form name

const mapStateToProps = (state) => {
	return {
		emailValue: selector(state, "email"),
		redirectPath: state.getIn(["authentication", "redirectToPath"]),
	};
};

export default connect(mapStateToProps, { login })(
	reduxForm({
		form: "loginForm",
		validate,
		destroyOnUnmount: true,
		touchOnBlur: false,
	})(Login)
);
