const chatConstants = {
	ACTION_GET_FAILURE: "ACTION_GET_FAILURE",
	ACTION_SUCCESS: "ACTION_SUCCESS",
	ACTION_FAILURE: "ACTION_FAILURE",
	ROOM_CONVERSATION: "ROOM_CONVERSATION",
	UNREAD_TOTAL_MESSAGES: "UNREAD_TOTAL_MESSAGES",
	UNREAD_ROOM_MESSAGES: "UNREAD_ROOM_MESSAGES",
	SET_HAS_ACTIVE_CHAT_ROOM: "SET_HAS_ACTIVE_CHAT_ROOM",
	SET_ACTIVE_ROOM: "SET_ACTIVE_ROOM",
};

export default chatConstants;
