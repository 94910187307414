import React from "react";
import localize, { LocKeys } from "../../constants/localizations";
import ClientsIcon from "../../shared/icons/navigation/ClientsIcon";
import InvoicesIcon from "../../shared/icons/navigation/InvoicesIcon";
import JobNavIcon from "../../shared/icons/navigation/JobNavIcon";
import LodSpecificationIcon from "../../shared/icons/navigation/LodSpecificationIcon";
import MyOrganisationIcon from "../../shared/icons/navigation/MyOrganisationIcon";
import ProjectsIcon from "../../shared/icons/navigation/ProjectsIcon";
import QuotesIcon from "../../shared/icons/navigation/QuotesIcon";
import CodeOutlinedIcon from "@mui/icons-material/CodeOutlined";
import { getPermissions, tokenParams } from "../local-storage.util";
import { feature, permissions } from "../../constants/permissionConstants";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AdministrationIcon from "../../shared/icons/navigation/AdministrationIcon";
import {
	checkUserPriority,
	isBimify,
	isLoggedUserCustomizer,
	isLoggedUserNormalizer,
	isLoggedUserQA,
} from "../permission/user";
import { PRIORITY_USER_ROLE } from "../../constants/constants";
import TicketSidebar from "../../components/ticketing/TicketSidebar";

export const getPaths = () => {
	const isLoggedUser =
		!isLoggedUserCustomizer() && !isLoggedUserNormalizer() && !isLoggedUserQA();

	const dashboardPath = isLoggedUser ? ["/dashboard", "/"] : ["/dashboard"];
	const jobPath = isLoggedUser ? ["/jobs"] : ["/jobs", "/"];
	const buildingPath = ["/buildings"];

	return { buildingPath, jobPath, dashboardPath };
};
//Return menu items based on role type and company type
export const getMenu = () => {
	const loggedUser = tokenParams();
	const loggedUserPermissions = getPermissions(); //loggedUser.permissions;
	const isBimifyAdmin = loggedUser.isBimifyAdmin;
	const isPriorityUser = checkUserPriority(PRIORITY_USER_ROLE.PROJECT_LEAD);
	const items = [];
	const { buildingPath, jobPath, dashboardPath } = getPaths();

	for (const [, value] of Object.entries(feature)) {
		switch (value) {
			case feature.dashboard:
				if (
					isBimifyAdmin ||
					loggedUserPermissions?.dashboard?.permissions?.includes(
						permissions.view
					)
				) {
					items.push({
						route: "/dashboard",
						description: localize(LocKeys.DASHBOARD),
						alt: localize(LocKeys.DASHBOARD),
						image: <DashboardIcon style={{ fontSize: 32 }} />,
						activeRoutes: dashboardPath,
						wrapperClasses: isBimifyAdmin ? "mt-auto" : "",
					});
				}
				break;
			case feature.settings:
				if (
					isBimifyAdmin ||
					loggedUserPermissions?.accountSettings?.permissions?.includes(
						permissions.view
					)
				) {
					items.push({
						route: "/settings",
						description: localize(LocKeys.SETTINGS),
						alt: "Clients icon",
						image: <MyOrganisationIcon />,
					});
				}
				break;

			case feature.customers:
				if (
					isBimifyAdmin ||
					loggedUserPermissions?.customers?.permissions?.includes(
						permissions.view
					)
				) {
					items.push({
						route: "/customers",
						description: localize(LocKeys.CUSTOMERS),
						alt: "Clients icon",
						image: <ClientsIcon />,
					});
				}
				break;

			case feature.buildings:
				if (
					isBimifyAdmin ||
					loggedUserPermissions?.buildings?.permissions?.includes(
						permissions.view
					)
				) {
					items.push({
						route: "/buildings",
						description: localize(LocKeys.BUILDINGS),
						alt: "Orders icon",
						image: <ProjectsIcon />,
						activeRoutes: buildingPath,
					});
				}
				break;

			case feature.jobs:
				if (
					isBimifyAdmin ||
					loggedUserPermissions?.jobs?.permissions?.includes(permissions.view)
				) {
					items.push({
						route: "/jobs",
						description: localize(LocKeys.JOBS),
						alt: "Jobs icon",
						image: <JobNavIcon />,
						activeRoutes: jobPath,
					});
				}
				break;

			case feature.ticketing:
				items.push({
					route: "/support",
					description: localize(LocKeys.SUPPORT),
					alt: localize(LocKeys.SUPPORT),
					image: <TicketSidebar route="/support" />,
				});
				break;

			case feature.offers:
				if (
					isBimifyAdmin ||
					loggedUserPermissions?.offers?.permissions?.includes(permissions.view)
				) {
					items.push({
						route: "/offers",
						description: localize(LocKeys.OFFERS),
						alt: "Orders icon",
						image: <QuotesIcon />,
						activeRoutes: ["/offers"],
					});
				}
				break;

			case feature.invoices:
				if (
					isBimifyAdmin ||
					loggedUserPermissions?.invoices?.permissions?.includes(
						permissions.view
					)
				) {
					items.push({
						route: "/invoices",
						description: localize(LocKeys.INVOICES),
						alt: "Invoices icon",
						image: <InvoicesIcon />,
					});
				}
				break;

			case feature.lodSpecifications:
				if (
					isBimifyAdmin ||
					loggedUserPermissions?.lodSpecifications?.permissions?.includes(
						permissions.view
					)
				) {
					items.push({
						route: "/bim-model-specification",
						description: localize(LocKeys.LOD_SPECIFICATIONS),
						alt: localize(LocKeys.LOD_SPECIFICATIONS),
						image: <LodSpecificationIcon />,
					});
				}
				break;

			case feature.docs:
				if (isPriorityUser && !!isBimify()) {
					items.push({
						route: "/administration",
						description: "Administration",
						alt: "Administration icon",
						image: <AdministrationIcon />,
						wrapperClasses: "mt-auto",
					});
				}

				if (isBimifyAdmin) {
					items.push({
						route: "/docs",
						description: "Docs",
						alt: "Code icon",
						image: <CodeOutlinedIcon />,
						wrapperClasses: "mb-regular",
					});
				}
				break;

			default:
				break;
		}
	}

	return items;
};
