import { useState } from "react";
import { fileSizes, fileTransferConstants } from "../constants/constants";

export const useFileInput = ({
	id,
	customOnChange,
	allowedSize = fileSizes.GB_5,
}) => {
	const [isDragging, setIsDragging] = useState(false);
	const [selected, setSelected] = useState([]);
	const [filesTooLarge, setFilesTooLarge] = useState(false);

	let labelId = (id ? id : "file-input") + "__label";

	const onChange = (e, accessAttribute = "target") => {
		if (e[accessAttribute].files.length) {
			let totalSize = 0;

			let updatedFiles = Array.from(e[accessAttribute].files);

			selected.length > 0 &&
				selected.forEach((file) => {
					totalSize += file.size;
				});

			updatedFiles.forEach((file) => {
				totalSize += file.size;
			});

			if (totalSize <= allowedSize) {
				let tempFiles = [];
				let selectedTemp = Object.assign([], selected);
				updatedFiles.forEach((file) => {
					selectedTemp.push(file);
					tempFiles.push(file);
				});
				setSelected(Object.assign([], selectedTemp));

				customOnChange && customOnChange(Object.assign([], tempFiles));
				setFilesTooLarge(false);
				e.target.value = null;
			} else {
				setFilesTooLarge(true);
			}
		}
	};

	const dragOver = (e) => {
		e.preventDefault();
		if (!isDragging) {
			setIsDragging(true);
		}
	};

	const dragEnter = (e) => {
		e.preventDefault();
		if (!isDragging) {
			setIsDragging(true);
		}
	};

	const dragLeave = (e) => {
		e.preventDefault();
		if (isDragging) {
			setIsDragging(false);
		}
	};

	const fileDrop = (e) => {
		e.preventDefault();
		setIsDragging(false);
		e.dataTransfer.clearData();
		onChange(e, fileTransferConstants.DATA_TRANSFER);
	};

	const removeFile = (index) => {
		let tempFiles = Object.assign([], selected);

		tempFiles = tempFiles.filter((_, i) => {
			return i !== index;
		});
		setSelected(Object.assign([], tempFiles));
		customOnChange && customOnChange(tempFiles);
	};

	const handleKeyPress = (event) => {
		if (event.key === "Enter") {
			document.getElementById(labelId).click();
		}
	};

	const clearSelected = () => {
		setSelected([]);
	};

	return {
		labelId,
		isDragging,
		selected,
		filesTooLarge,
		removeFile,
		handleKeyPress,
		dragEnter,
		dragOver,
		dragLeave,
		fileDrop,
		onChange,
		clearSelected,
	};
};
