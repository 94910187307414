import React from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import renderError from "./renderError";
import { colorConstants } from "../../constants/constants";

export default ({
	input,
	checked,
	handleChange,
	name,
	label,
	disabled = false,
	meta: { touched, error },
	val,
	wrapperClassName = "",
}) => {
	return (
		<div className={wrapperClassName}>
			<FormControlLabel
				onClick={(event) => event.stopPropagation()}
				onFocus={(event) => event.stopPropagation()}
				classes={{
					root: "align-items-start ",
				}}
				control={
					<Checkbox
						{...input}
						value={val}
						checked={checked}
						disabled={disabled}
						onChange={handleChange}
						name={name}
						sx={{
							color: colorConstants.PRIMARY,
							"&.Mui-checked": {
								color: colorConstants.PRIMARY,
							},
						}}
					/>
				}
				label={<span className="body-font--small"> {label} </span>}
			/>
			{!checked && renderError(error, touched)}
		</div>
	);
};
