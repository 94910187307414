import React, { useMemo } from "react";
import { logoTypesConstants, sizeConstants } from "../../constants/constants";
import BackgroundImageFromLink from "./BackgroundImageFromLink";
import AvatarIcon from "../../assets/images/icons/avatar-icon.svg";
import AvatarFillIcon from "../../assets/images/icons/avatar-icon-fill.svg";
import CompanyIcon from "../../assets/images/icons/company-icon.svg";
import BimifySupportIcon from "../../assets/images/icons/bimify-support.svg";

/**
 * Logo component
 *
 * @param {string} link
 * @param {string} alt
 * @param {boolean} grayscale - Whether there should be a filter added to the image
 * @param {string} size - size of image/logo, from sizeConstants, Default is SMALL
 * @param {string} wrapperClasses
 * @param {string} type - type of image (shows appropriate placeholder based on this), from logoTypesConstants, default USER
 * @param {boolean} stripped
 *
 * @return {React.FC} Logo component
 */
const Logo = ({
	link = "",
	alt = "",
	grayscale = false,
	size = sizeConstants.SMALL,
	wrapperClasses = "",
	type = logoTypesConstants.USER,
	stripped = false,
	isSentFromBimify = false,
}) => {
	const source = useMemo(() => {
		if (isSentFromBimify) {
			return BimifySupportIcon;
		}

		return type === logoTypesConstants.USER
			? size === sizeConstants.LARGE
				? AvatarFillIcon
				: AvatarIcon
			: CompanyIcon;
	}, [isSentFromBimify, size, type]);
	return (
		<div
			className={`logo__wrapper  
            d-flex align-items-center justify-content-center
            logo__wrapper--${size}
            ${!!link && grayscale ? "logo__wrapper--grayscale" : ""}
            ${!!!link ? "logo__wrapper--placeholder" : ""}
			${stripped ? "logo__wrapper--stripped" : ""}
            ${wrapperClasses}
            `}
		>
			{!!link ? (
				<BackgroundImageFromLink
					imageClass={`logo
						${type === logoTypesConstants.USER ? "logo--cover" : ""}
					`}
					name={alt}
					imageLink={link}
				/>
			) : (
				<img className="h-100 w-100" src={source} alt={alt} />
			)}
		</div>
	);
};

export default Logo;
