import React, { useEffect } from "react";
import { getComponentCategoryContent } from "../../../actions";
import { connect } from "react-redux";
import SummaryImage from "../../../components/administration/lod-specification/lod-content/single-view/view/SummaryImage";
import ParameterCategory from "../../../components/administration/lod-specification/lod-content/single-view/view/ParameterCategory";

const ComponentCategoryContent = ({
	contentId,
	getComponentCategoryContent,
	id,
	images3D,
	images2D,
	summaries,
	selectedLodId,
	lodParameterCategories,
	editView = false,
}) => {
	useEffect(() => {
		if (!id) {
			getComponentCategoryContent(contentId, true);
		}
	}, [contentId, getComponentCategoryContent, id]);

	return (
		<div className="border-t-1 border-n150">
			<SummaryImage
				selectedLodId={selectedLodId}
				isForSpecification={true}
				images3D={images3D}
				images2D={images2D}
				summaries={summaries}
				isEditView={editView}
			/>
			<ParameterCategory
				isEditView={editView}
				lodParameterCategories={lodParameterCategories}
				selectedLodId={selectedLodId}
				isForSpecification={true}
			/>
		</div>
	);
};

const mapStateToProps = (state, ownProps) => {
	const { contentId } = ownProps;
	const tempSpecificationContent =
		state.getIn(["lodSpecification", "tempSpecificationContent"]) || [];

	const specifcationContent =
		tempSpecificationContent &&
		tempSpecificationContent
			.valueSeq()
			.find((item) => item?.get("id") === contentId);
	return {
		specifcationContent,
		id: specifcationContent?.get("id"),
		images3D: specifcationContent?.getIn(["lodContent", "images3D"]),
		images2D: specifcationContent?.getIn(["lodContent", "images2D"]),
		summaries: specifcationContent?.getIn(["lodContent", "summaries"]),
		lodParameterCategories: specifcationContent?.get("lodParameterCategories"),
		tempSpecificationContent: state.getIn([
			"lodSpecification",
			"tempSpecificationContent",
		]),
	};
};

export default connect(mapStateToProps, { getComponentCategoryContent })(
	ComponentCategoryContent
);
