import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import {
	CLEAR_EDITOR_COMMAND,
	COMMAND_PRIORITY_HIGH,
	KEY_ENTER_COMMAND,
} from "lexical";
import { useCallback, useEffect } from "react";

const SubmitPlugin = ({ onSubmit }) => {
	const [editor] = useLexicalComposerContext();

	const onEnter = useCallback(
		(event) => {
			if (
				!event.shiftKey &&
				!event.ctrlKey &&
				!event.metaKey &&
				!event.altKey
			) {
				event.preventDefault();

				onSubmit(JSON.stringify(editor.getEditorState()), null);

				editor.dispatchCommand(CLEAR_EDITOR_COMMAND, null);
			}

			return true;
		},
		[editor, onSubmit]
	);

	useEffect(() => {
		editor.registerCommand(KEY_ENTER_COMMAND, onEnter, COMMAND_PRIORITY_HIGH);
	}, [editor, onEnter]);

	return null;
};
export default SubmitPlugin;
