import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { clearDashboardRequestState } from "../../actions";
import { alertTypeConstants } from "../../constants/constants";
import localize, { LocKeys } from "../../constants/localizations";
import CustomSnackbar from "../../shared/CustomSnackbar/CustomSnackbar";

/**
 * Snackbar messages for company
 * @param {*} props
 * @returns
 */
const DashboardRequestPopups = (props) => {
	const {
		// Actions
		clearDashboardRequestState,
		// From state
		request,
		action,
		successMessage,
		errorResponse,
		errorMessage,
	} = props;

	const [showError, setShowError] = useState(!action && request);
	const [showSuccess, setShowSuccess] = useState(action && request);

	useEffect(() => {
		if (request) {
			if (!action) {
				setShowError(true);
			} else {
				setShowSuccess(true);
			}
		}
	}, [action, request]);

	const errorOnClose = () => {
		setShowError(false);
		clearDashboardRequestState();
	};

	const successOnClose = () => {
		setShowSuccess(false);
		clearDashboardRequestState();
	};

	return (
		<>
			<CustomSnackbar
				open={showSuccess}
				onClose={successOnClose}
				title={localize(LocKeys.SUCCESS)}
			>
				{successMessage}
			</CustomSnackbar>
			<CustomSnackbar
				open={showError}
				onClose={errorOnClose}
				type={alertTypeConstants.ERROR}
				title={localize(LocKeys.ERROR)}
			>
				{errorResponse || errorMessage}
			</CustomSnackbar>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		action: state.getIn(["dashboard", "action"]),
		request: state.getIn(["dashboard", "request"]),
		errorResponse: state.getIn(["dashboard", "errorResponse"]),
		errorMessage: state.getIn(["dashboard", "errorMessage"]),
		successMessage: state.getIn(["dashboard", "successMessage"]),
	};
};
export default connect(mapStateToProps, { clearDashboardRequestState })(
	DashboardRequestPopups
);
