import React from "react";

export default (props) => {
	return (
		<div className={`icon ${props.iconClass}`}>
			<svg
				width="20"
				height="13"
				viewBox="0 0 20 13"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M15.1219 8.58438L10.4563 4.79688L9.51875 5.65625C8.25 6.81563 6.80625 6.02813 6.34062 5.51875C5.5 4.6 5.56563 3.17812 6.47813 2.34062L9.03438 0H6.41563C6.15 0 5.89687 0.10625 5.70937 0.29375L4 2H0.5C0.225 2 0 2.225 0 2.5V8.49687C0 8.77187 0.225 8.99687 0.5 8.99687H4.57188L7.4 11.5562C8.25625 12.2531 9.51562 12.1219 10.2125 11.2656L10.2188 11.2594L10.7781 11.7437C11.275 12.15 12.0094 12.0719 12.4125 11.575L13.3938 10.3687L13.5625 10.5063C13.9906 10.8531 14.6219 10.7906 14.9688 10.3594L15.2656 9.99375C15.6156 9.5625 15.55 8.93438 15.1219 8.58438ZM19.5 2H16L14.2937 0.29375C14.1062 0.10625 13.8531 0 13.5875 0H10.9031C10.6531 0 10.4125 0.09375 10.2281 0.2625L7.15625 3.075C7.15312 3.07812 7.15 3.08438 7.14687 3.0875C6.625 3.57812 6.6375 4.35625 7.08125 4.8375C7.38125 5.16563 8.175 5.5125 8.83438 4.92188C8.8375 4.91875 8.84375 4.91875 8.84688 4.91563L10.5031 3.4L11.3469 2.62813C11.55 2.44375 11.8656 2.45625 12.0531 2.65938C12.2406 2.8625 12.225 3.17812 12.0219 3.36562L11.2063 4.1125L15.7563 7.80625C16.1375 8.11562 16.3656 8.54063 16.45 8.99063H19.5C19.775 8.99063 20 8.76562 20 8.49063V2.49687C20 2.22187 19.775 2 19.5 2Z"
					fill="#7F909F"
				/>
			</svg>
		</div>
	);
};
