const buildingConstants = {
	GET_BUILDINGS: "GET_BUILDINGS",
	GET_BUILDING: "GET_BUILDING",
	B_ACTION_SUCCESS: "B_ACTION_SUCCESS",
	B_ACTION_FAILURE: "B_ACTION_FAILURE",
	B_REQUEST_DONE: "B_REQUEST_DONE",
	SET_FULL_ADDRESS_BUILDING: "SET_FULL_ADDRESS_BUILDING",
	UPDATE_BUILDING_LEVELS: "UPDATE_BUILDING_LEVELS",
	UPDATE_BUILDING_LEVEL: "UPDATE_BUILDING_LEVEL",
	CLEAR_TEMP_BUILDING: "CLEAR_TEMP_BUILDING",
	SET_TEMP_BULDING_LEVELS: "SET_TEMP_BULDING_LEVELS",
	SET_TEMP_BULDING_INFO: "SET_TEMP_BULDING_INFO",
	GET_DISCIPLINE_FILES: "GET_DISCIPLINE_FILES",
	CLEAR_DISCIPLINE_FILE: "CLEAR_DISCIPLINE_FILE",
	SET_SUCCESS_MESSAGE: "SET_SUCCESS_MESSAGE",
	SET_ERROR_MESSAGE: "SET_ERROR_MESSAGE",
	SET_FORGE_FILE_NOT_SUPPORTED_MESSAGE: "SET_FORGE_FILE_NOT_SUPPORTED_MESSAGE",
	CLEAR_BUILDING_REQUEST_STATE: "CLEAR_BUILDING_REQUEST_STATE",
	SET_STATE_BUILDING_FIELD_VALUE: "SET_STATE_BUILDING_FIELD_VALUE",
	INITIAL_BUILDING_LEVELS: "INITIAL_BUILDING_LEVELS",
	CHANGE_FORGE_VIEWER_URN: "CHANGE_FORGE_VIEWER_URN",
	GET_FORGE_PUBLIC_TOKEN: "GET_FORGE_PUBLIC_TOKEN",
	BUILDING_STATS: "BUILDING_STATS",
	UPDATE_DISCIPLINE_FILES: "UPDATE_DISCIPLINE_FILES",
	CHANGE_FILE_URL: "CHANGE_FILE_URL",
	CLEAR_BUILDING: "CLEAR_BUILDING",
	GET_BUILDING_SPECIFICATION: "GET_BUILDING_SPECIFICATION",
	UPDATE_ASYNC_LOAD_BUILDINGS: "UPDATE_ASYNC_LOAD_BUILDINGS",
	CLEAR_BUILDING_LIST_STATE: "CLEAR_BUILDING_LIST_STATE",
	CLEAR_ALL_BUILDING_STATE: "CLEAR_ALL_BUILDING_STATE",
	GET_BUILDING_USERS: "GET_BUILDING_USERS",
	GET_BUILDING_COMPANIES: "GET_BUILDING_COMPANIES",
	GET_ALL_BUILDING_DISCIPLINE_FILES: "GET_ALL_BUILDING_DISCIPLINE_FILES",
	CLEAR_BUILDING_USERS: "CLEAR_BUILDING_USERS",
	UPDATE_TEMP_FILE_PREVIEW: "UPDATE_TEMP_FILE_PREVIEW",
};

export default buildingConstants;
