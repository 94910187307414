import React from "react";
import { connect } from "react-redux";
import { sizeConstants } from "../../../constants/constants";
import NotFoundIcon from "../../icons/NotFoundIcon";
import IconWrapper from "../../icons/IconWrapper";
import "./get404.scss";

/**
 * Component for simple text field. Component is taken from official page for usePlacesAutocomplete library.
 */

const Get404 = (props) => {
	const { customWrapperClass, children } = props;

	return (
		<div
			className={`not-found ${customWrapperClass ? customWrapperClass : ""}`}
		>
			<div className={`row mb-neg-regular`}>
				<div className="col col-100 col-desktop-25 mb-regular justify-content-desktop-end">
					<IconWrapper
						size={sizeConstants.X_LARGE}
						icon={<NotFoundIcon iconClass="" />}
					/>
				</div>
				<div className="col col-100 col-desktop-75 flex-column mb-regular">
					{children}
				</div>
			</div>
		</div>
	);
};

export default connect(null, {})(Get404);
