import React from "react";

/**
 * Content Layout - Custom component for FOOTER layout
 *
 * @param {string} wrapperClasses
 * @param {*} children
 *
 */
const FooterLayout = ({ wrapperClasses = "", children = <></> }) => {
	return (
		<div
			className={`w-100  h-100 max-h-88p  h-88p border-t-1 border-n150  ${wrapperClasses}`}
		>
			{children}
		</div>
	);
};

export default FooterLayout;
