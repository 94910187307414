import React from "react";

export default (props) => {
	return (
		<div className={`icon ${props.iconClass ? props.iconClass : ""}`}>
			<svg
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12 5C15.3376 5 18.1178 7.37875 18.7421 10.533C21.119 10.7026 23 12.6633 23 15.0833C23 17.6142 20.9476 19.6667 18.4167 19.6667H6.5C3.46217 19.6667 1 17.2045 1 14.1667C1 11.3305 3.14775 8.99667 5.906 8.69967C7.05275 6.50242 9.349 5 12 5ZM6.5 17.8333H18.4167C19.9328 17.8333 21.1667 16.5995 21.1667 15.0833C21.1667 13.6597 20.0447 12.4635 18.6119 12.3617L17.2158 12.2618L16.9436 10.8887C16.4779 8.53925 14.3989 6.83333 12 6.83333C10.1181 6.83333 8.40483 7.87375 7.53033 9.54758L7.07658 10.4175L6.10125 10.5229C4.23858 10.7227 2.83333 12.2893 2.83333 14.1667C2.83333 16.1888 4.47783 17.8333 6.5 17.8333ZM12 14.5C13.1046 14.5 14 13.6046 14 12.5C14 11.3954 13.1046 10.5 12 10.5C10.8954 10.5 10 11.3954 10 12.5C10 13.6046 10.8954 14.5 12 14.5Z"
					fill="#1B2024"
				/>
			</svg>
		</div>
	);
};
