import queryParameterConstants from "../constants/queryParameterConstants";

export const setJobQueryParameter = (data) => {
	return (dispatch) => {
		dispatch({
			type: queryParameterConstants.JOB_QUERY_PARAMETER,
			data,
		});
	};
};

export const setOfferQueryParameter = (data) => {
	return (dispatch) => {
		dispatch({
			type: queryParameterConstants.OFFER_QUERY_PARAMETER,
			data,
		});
	};
};

export const setCustomerQueryParameter = (data) => {
	return (dispatch) => {
		dispatch({
			type: queryParameterConstants.CUSTOMER_QUERY_PARAMETER,
			data,
		});
	};
};

export const setUserQueryParameter = (data) => {
	return (dispatch) => {
		dispatch({
			type: queryParameterConstants.USER_QUERY_PARAMETER,
			data,
		});
	};
};

export const clearOfferQueryParameter = () => {
	return (dispatch) => {
		dispatch({
			type: queryParameterConstants.CLEAR_OFFER_QUERY_PARAMETER,
		});
	};
};

export const clearSymbolQueryParameter = () => {
	return (dispatch) => {
		dispatch({
			type: queryParameterConstants.CLEAR_SYMBOL_QUERY_PARAMETER,
		});
	};
};

export const setSymbolQueryParameter = (data) => {
	return (dispatch) => {
		dispatch({
			type: queryParameterConstants.SYMBOL_QUERY_PARAMETER,
			data,
		});
	};
};

export const setTicketQueryParameter = (data) => {
	return (dispatch) => {
		dispatch({
			type: queryParameterConstants.TICKET_QUERY_PARAMETER,
			data,
		});
	};
};

export const setBuildingQueryParameter = (data) => {
	return (dispatch) => {
		dispatch({
			type: queryParameterConstants.BUILDING_QUERY_PARAMETER,
			data,
		});
	};
};

export const clearBuildingQueryParameter = () => {
	return (dispatch) => {
		dispatch({
			type: queryParameterConstants.CLEAR_BUILDING_QUERY_PARAMETER,
		});
	};
};

export const setRTemplateQueryParameter = (data) => {
	return (dispatch) => {
		dispatch({
			type: queryParameterConstants.REVIT_TEMPLATE_QUERY_PARAMETER,
			data,
		});
	};
};

export const clearRevitTemplateQueryParameter = () => {
	return (dispatch) => {
		dispatch({
			type: queryParameterConstants.CLEAR_REVIT_TEMPLATE_QUERY_PARAMETER,
		});
	};
};
