import React, { useState, useEffect, useCallback } from "react";
import { AccordionSummary } from "@mui/material";
import { AccordionDetails } from "@mui/material";
import { Accordion } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./customAccordion.scss";
import EditButton from "../components/buttons/EditButton";

/**
 * Custom accordion component
 *
 * @param {Object.<string, any>} props
 * @param {React.ReactNode} props.titleContent - Title element
 * @param {function} props.titleContentComponent - Title render function
 * @param {string} props.wrapperClasses - Classes to be applied to the accordion root, Default: "accordion card card--border--none bg-n100"
 * @param {boolean} props.initialValue - Used to trigger open/close through parent, Default: false
 * @param {function} props.customOnChange - Triggers when accordion state changes
 * @param {React.ReactNode} props.children - Accordion Details content
 * @param {Object} props.transitionProps - TransitionProps information - unmount on exit, timeout, mount on enter
 *
 * @return {React.FC} Custom accordion component
 *
 */
const CustomAccordion = ({
	titleContent,
	titleContentComponent,
	children = <></>,
	wrapperClasses = "accordion card card--border--none bg-n100",
	initialValue = false,
	customOnChange = () => {},
	transitionProps = {
		unmountOnExit: false,
		mountOnEnter: false,
		timeout: 500,
	},
	bgColor = null,
	titleColor = null,
	onlyIcon = false,
	editObject = { option: false, disabled: false },
}) => {
	const [expanded, setExpanded] = useState(initialValue);

	useEffect(() => {
		setExpanded(initialValue);
	}, [initialValue]);

	const handleChange = useCallback(
		(e) => {
			e.stopPropagation();

			setExpanded(!expanded);
			customOnChange(!expanded);
		},
		[setExpanded, customOnChange, expanded]
	);

	return (
		<Accordion
			classes={{
				root: ` ${expanded && bgColor ? bgColor : ""} ${wrapperClasses} ${
					!!!children ? "disabled" : ""
				} `,
			}}
			expanded={!!children ? expanded : false}
			//onChange={!onlyIcon ? handleChange : () => {}}
			onChange={handleChange}
			square={false}
			slotProps={{ transition: transitionProps }}
			//TransitionProps={transitionProps}
		>
			<AccordionSummary
				expandIcon={!!children ? <ExpandMoreIcon /> : null}
				aria-controls="panel1bh-content"
				id="panel1bh-header"
				// iconbuttonprops={{
				// 	onClick: handleChange,
				// }}
			>
				{titleContent ? (
					titleColor ? (
						<div className={`${expanded ? titleColor : ""} w-100`}>
							{titleContent}
						</div>
					) : (
						titleContent
					)
				) : (
					titleContentComponent && titleContentComponent()
				)}
				{editObject.option && (
					<EditButton
						wrapperClasses="ml-auto pos-abs right-0 bottom-7"
						disabled={editObject.disabled}
						onClick={(e) => {
							e.stopPropagation();
							setExpanded(true);
							editObject.onClick();
						}}
					/>
				)}
			</AccordionSummary>
			{!!children && <AccordionDetails>{children}</AccordionDetails>}
		</Accordion>
	);
};

export default CustomAccordion;
