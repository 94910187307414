import React from "react";
import ReactMarkdown from "react-markdown";
import "./TooltipWithHtml.scss";

/**
 *
 * @param {Object.<string, any>} props
 *
 * @param {function} props.tooltipContent - Tooltip content render function
 * @param {React.ReactChildren} props.children - HTML element that needs to have the tooltip
 * @param {string} [props.tooltipBodyClasses=""] - Classes applied to the body element wrapper
 * @param {string} [props.tooltipPlacement=] - Where the tooltip should be, relative to the content, Default: "bottom-end"
 * @param {boolean} [props.arrow=false] - Whether the tooltip should have a pointer arrow towards the content
 *
 * Note: When used as a button wrapper, if the button is disabled, the tooltip won't show, use btn--disabled class instead and handle action accordingly
 *
 * @returns
 */
const TooltipWithHtml = ({
	tooltipContent,
	children: tooltipBody,
	tooltipPlacement = "right",
	wrapperClasses = "",
	tooltiptextClasses = "white-space--nowrap",
}) => {
	return (
		<div className={`tooltip ${wrapperClasses}`}>
			{tooltipBody}
			{tooltipContent && (
				<span
					className={`tooltiptext tooltip--${tooltipPlacement} body-font body-font--small ${tooltiptextClasses}`}
				>
					<ReactMarkdown children={tooltipContent} />
				</span>
			)}
		</div>
	);
};

export default TooltipWithHtml;
