export const deepCopy = (value) => {
	if (value === null) {
		return null;
	}

	if (Array.isArray(value)) {
		return value.map((val) => deepCopy(val));
	} else if (typeof value === "object") {
		const accumulator = {};
		Object.keys(value).map((key) => (accumulator[key] = deepCopy(value[key])));
		return accumulator;
	}
	return value;
};

export const areDeeplyEqual = (valueA, valueB) => {
	if (valueA === valueB) {
		return true;
	}
	if (Array.isArray(valueA) && Array.isArray(valueB)) {
		if (valueA.length !== valueB.length) {
			return false;
		}
		return valueA.every((val) =>
			valueB.some((valB) => areDeeplyEqual(val, valB))
		);
	} else if (typeof valueA === "object" && typeof valueB === "object") {
		if (Object.keys(valueA).length !== Object.keys(valueB).length) {
			return false;
		}
		return Object.keys(valueA).every((key) =>
			areDeeplyEqual(valueA[key], valueB[key])
		);
	}

	return false;
};

/**
 * Returns boolean to distinguish whether provided variable is an array with
 * at least 1 member (can it be iterated through)
 *
 * @param {*} value
 * @returns {Boolean} T/F depending if value provided is an array w/ values
 */
export const isIterable = (value) => {
	try {
		return Array.isArray(value) && value.length > 0;
	} catch (_) {
		return false;
	}
};

/**
 * Sorts array by provided object param
 * @param {array} array
 * @param {string} param
 * @returns array
 */
export const sortArrayByObjectParam = (array = [], param = "") => {
	if (!array || array.length === 0 || param === "") {
		return [];
	}

	const compareObjectParam = (a, b) => {
		if (a[param] < b[param]) {
			return -1;
		}
		if (a[param] > b[param]) {
			return 1;
		}
		return 0;
	};

	return array.sort((a, b) => compareObjectParam(a, b));
};
