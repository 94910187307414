import React from "react";

export default (props) => {
	return (
		<div className={`icon ${props.iconClass}`}>
			<svg
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M10 12C12.21 12 14 10.21 14 8C14 5.79 12.21 4 10 4C7.79 4 6 5.79 6 8C6 10.21 7.79 12 10 12ZM10 6C11.1 6 12 6.9 12 8C12 9.1 11.1 10 10 10C8.9 10 8 9.1 8 8C8 6.9 8.9 6 10 6ZM4 18V17.35C4 17.01 4.16 16.69 4.41 16.54C6.1 15.53 8.03 15 10 15C10.03 15 10.05 15 10.08 15.01C10.18 14.31 10.38 13.64 10.67 13.03C10.45 13.01 10.23 13 10 13C7.58 13 5.32 13.67 3.39 14.82C2.51 15.34 2 16.32 2 17.35V20H11.26C10.84 19.4 10.51 18.72 10.29 18H4ZM20.6938 15.3962C20.7225 15.5967 20.75 15.7892 20.75 16C20.75 16.2108 20.7225 16.4033 20.6938 16.6038L20.69 16.63L21.83 17.64L20.83 19.37L19.38 18.88C19.06 19.15 18.7 19.36 18.3 19.51L18 21H16L15.7 19.51C15.3 19.36 14.94 19.15 14.62 18.88L13.17 19.37L12.17 17.64L13.31 16.63C13.3088 16.6212 13.3075 16.6125 13.3063 16.6037C13.2776 16.4033 13.25 16.2108 13.25 16C13.25 15.7892 13.2776 15.5967 13.3063 15.3963C13.3075 15.3875 13.3088 15.3788 13.31 15.37L12.17 14.36L13.17 12.63L14.62 13.12C14.94 12.85 15.3 12.64 15.7 12.49L16 11H18L18.3 12.49C18.7 12.64 19.06 12.85 19.38 13.12L20.83 12.63L21.83 14.36L20.69 15.37L20.6938 15.3962ZM15 16C15 17.1 15.9 18 17 18C18.1 18 19 17.1 19 16C19 14.9 18.1 14 17 14C15.9 14 15 14.9 15 16Z"
					fill="#7F909F"
				/>
			</svg>
		</div>
	);
};
