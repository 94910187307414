import React from "react";

export default (props) => {
	return (
		<div className={`icon ${props.iconClass ? props.iconClass : ""}`}>
			<svg
				width="32"
				height="32"
				viewBox="0 0 32 32"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M4 4V28H28V4H4ZM25.3333 25.3333H12V22.6667H17.3333V17.3333H22.6667V12H25.3333V25.3333ZM25.3333 9.33333H20V14.6667H14.6667V20H9.33333V25.3333H6.66667V6.66667H25.3333V9.33333Z"
					fill="#1B2024"
				/>
			</svg>
		</div>
	);
};
