import React, { useMemo, useCallback } from "react";
import ExportsComponent from "../../../../../shared/components/specification/ExportsComponent";
import { connect } from "react-redux";
import { reduxForm } from "redux-form/immutable";
import { setTempExports } from "../../../../../actions";

const PackageExportEdit = ({
	initialValues,
	isForPublicView,
	setTempExports,
	...props
}) => {
	const setValue = useCallback(
		(data) => {
			setTempExports(data);
		},
		[setTempExports]
	);

	const renderBody = useMemo(() => {
		return (
			<ExportsComponent
				isForPublicView={isForPublicView}
				hidePrice={true}
				showPriceInformation={true}
				hideHeader={true}
				updateSelected={(data) => setValue(data)}
				initialSelected={initialValues?.get("exports")?.toJS()}
				initialValues={initialValues}
				formName={"editPackageExportForm"}
			/>
		);
	}, [initialValues, isForPublicView, setValue]);
	return (
		<form
			className={`form d-flex flex-column flex-auto standardized-form	`}
			onSubmit={props.handleSubmit(() => {})}
			autoComplete="off"
			noValidate
		>
			{renderBody}
		</form>
	);
};

const mapStateToProps = (state) => {
	const exports = [];
	let initialValues = {};
	const tempExports = state
		.getIn(["dashboard", "tempSalesPackage", "editedExports"])
		?.toJS();

	//EXPORT
	tempExports &&
		tempExports.forEach((tempExport) => {
			exports.push(tempExport.id);
			initialValues[`exportVersion_${tempExport.id}`] = tempExport.exportId;
		});
	initialValues["exports"] = exports;

	return {
		tempExports,
		initialValues,
	};
};

export default connect(mapStateToProps, { setTempExports })(
	reduxForm({
		form: "editPackageExportForm",
		touchOnBlur: false,
		destroyOnUnmount: false,
		enableReinitialize: false,
	})(PackageExportEdit)
);
