import React from "react";
//import localize, { LocKeys } from "../../../constants/localizations";
import IconWrapper from "../../icons/IconWrapper";
import EditPenIcon from "../../icons/actions/EditPenIcon";

const EditButton = ({
	onClick,
	wrapperClasses = "",
	visibility = true,
	disabled = false,
}) => {
	return (
		<button
			disabled={disabled}
			type="button"
			onClick={(e) => onClick(e)}
			className={`btn btn--icon--solo btn--tertiary border-none bg-none p-s ${wrapperClasses} ${
				visibility ? "" : "visibility-hidden "
			}`}
		>
			<IconWrapper size="24" icon={<EditPenIcon iconClass="d-flex" />} />
		</button>
	);
	// return (
	// 	<button
	// 		disabled={disabled}
	// 		type="button"
	// 		onClick={() => onClick()}
	// 		className={`btn  btn--secondary btn--edit btn--icon btn--icon--before mr-4 ${wrapperClasses} ${
	// 			visibility ? "" : "visibility-hidden "
	// 		}`}
	// 	>
	// 		{localize(LocKeys.EDIT)}
	// 	</button>
	// );
};

export default EditButton;
