import React from "react";
import { Link } from "react-router-dom";
import localize, { LocKeys } from "../../../constants/localizations";
import Get404 from "./Get404";
import "./get404.scss";

const ServerError = () => {
	return (
		<div className="page-server-error mh-outlet align-items-center d-flex mh-100vh">
			<Get404>
				<h1>{localize(LocKeys.SERVER_ERROR)}</h1>
				<Link to="/">{localize(LocKeys.GO_TO_HOME)}</Link>
			</Get404>
		</div>
	);
};

export default ServerError;
