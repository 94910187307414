const authConstants = {
	LOGIN_REQUEST: "USERS_LOGIN_REQUEST",
	LOGIN_SUCCESS: "USERS_LOGIN_SUCCESS",
	LOGIN_FAILURE: "USERS_LOGIN_FAILURE",
	CLEAR_RESET_PASSWORD: "CLEAR_RESET_PASSWORD",
	RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
	REFRESH_TOKEN_FAILED: "REFRESH_TOKEN_FAILED",
	CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
	CHANGE_PASSWORD_FAILURE: "CHANGE_PASSWORD_FAILURE",
	SET_USER_AUTH: "SET_USER_AUTH",
	SET_USER_PROFILE_IMAGE: "SET_USER_PROFILE_IMAGE",
	ACTION_GET_FAILURE: "ACTION_GET_FAILURE",
	REDIRECT_TO_PATH: "REDIRECT_TO_PATH",
	ACTION_SUCCESS: "ACTION_SUCCESS",
	ACTION_FAILURE: "ACTION_FAILURE",
	CLEAR_AUTH_REQUEST_STATE: "CLEAR_AUTH_REQUEST_STATE",
	SET_PERMISSIONS: "SET_PERMISSIONS",
};

export default authConstants;
