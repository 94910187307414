import { permissionsArray } from "../constants/permissionConstants";
import { isBimifyAdmin } from "../utils/permission/user";
/**
 * Main component for checking user permission. This component has two main props: yes - if user has required permission, and no - if not.
 */
const check = (permissionsArray, role, requiredPermissions) => {
	if (isBimifyAdmin()) {
		return true;
	}

	if (!permissionsArray?.includes(role)) {
		return false;
	} else if (requiredPermissions?.includes(role)) {
		return true;
	}
	return false;
};

const CanPerform = (props) =>
	check(permissionsArray, props.permission, props.requiredPermissions)
		? props.yes()
		: props.priorityAccess
		? props.yes()
		: props.no();

CanPerform.defaultProps = {
	yes: () => null,
	no: () => null,
};

export default CanPerform;
