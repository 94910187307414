import React from "react";

export default (props) => {
	return (
		<div className={`icon ${props.iconClass}`}>
			<svg
				width="32"
				height="32"
				viewBox="0 0 32 32"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M18.6673 2.66699H8.00065C6.53398 2.66699 5.33398 3.86699 5.33398 5.33366V26.667C5.33398 28.1337 6.53398 29.3337 8.00065 29.3337H24.0007C25.4673 29.3337 26.6673 28.1337 26.6673 26.667V10.667L18.6673 2.66699ZM8.00065 26.667V5.33366H17.334V10.667H24.0007V26.667H8.00065ZM14.6673 25.3337H17.334V24.0003H18.6673C19.4007 24.0003 20.0007 23.4003 20.0007 22.667V18.667C20.0007 17.9337 19.4007 17.3337 18.6673 17.3337H14.6673V16.0003H20.0007V13.3337H17.334V12.0003H14.6673V13.3337H13.334C12.6007 13.3337 12.0007 13.9337 12.0007 14.667V18.667C12.0007 19.4003 12.6007 20.0003 13.334 20.0003H17.334V21.3337H12.0007V24.0003H14.6673V25.3337Z"
					fill="#1b2024"
				/>
			</svg>
		</div>
	);
};
