const orderConstants = {
	GET_PROJECTS: "GET_PROJECTS",
	ACTION_GET_FAILURE: "ACTION_GET_FAILURE",
	P_ACTION_FAILURE: "P_ACTION_FAILURE",
	GET_PROJECT: "GET_PROJECT",
	P_REQUEST_DONE: "P_REQUEST_DONE",
	UPDATE_BUILDING_LODS: "UPDATE_BUILDING_LODS",
	GET_PROJECT_FILES: "GET_PROJECT_FILES",
	CLEAR_PROJECT: "CLEAR_PROJECT",
	CLEAR_PROJECT_REQUEST_STATE: "CLEAR_PROJECT_REQUEST_STATE",
	PROJECT_ACTION_GET_FAILURE: "PROJECT_ACTION_GET_FAILURE",
	PROJECT_ACTION_GET_SUCCESS: "PROJECT_ACTION_GET_SUCCESS",
	PROJECT_SET_ERROR_MESSAGE: "PROJECT_SET_ERROR_MESSAGE",
	PROJECT_SET_SUCCESS_MESSAGE: "PROJECT_SET_SUCCESS_MESSAGE",
	PROJECT_ACTION_SUCCESS: "PROJECT_ACTION_SUCCESS",
	PROJECT_ACTION_ERROR: "PROJECT_ACTION_ERROR",
	ORDER_STATS: "ORDER_STATS",
	GET_PROJECT_LOD_SPECIFICATION: "GET_PROJECT_LOD_SPECIFICATION",
	OFFER_MODAL_ACTION_SUCCESS: "OFFER_MODAL_ACTION_SUCCESS",
	CLEAR_OFFER_MODAL_REQUEST_STATE: "CLEAR_OFFER_MODAL_REQUEST_STATE",
	SET_HTML_ADDITIONAL_INFO: "SET_HTML_ADDITIONAL_INFO",
	UPDATE_ASYNC_LOAD_OFFERS: "UPDATE_ASYNC_LOAD_OFFERS",
	GET_ORDER_USERS: "GET_ORDER_USERS",
	GET_ORDER_COMPANIES: "GET_ORDER_COMPANIES",
	CLEAR_ORDER_USERS: "CLEAR_ORDER_USERS",
};

export default orderConstants;
