const queryParameterConstants = {
	JOB_QUERY_PARAMETER: "JOB_QUERY_PARAMETER",
	OFFER_QUERY_PARAMETER: "OFFER_QUERY_PARAMETER",
	CLEAR_OFFER_QUERY_PARAMETER: "CLEAR_OFFER_QUERY_PARAMETER",
	CUSTOMER_QUERY_PARAMETER: "CUSTOMER_QUERY_PARAMETER",
	USER_QUERY_PARAMETER: "USER_QUERY_PARAMETER",
	SYMBOL_QUERY_PARAMETER: "SYMBOL_QUERY_PARAMETER",
	CLEAR_SYMBOL_QUERY_PARAMETER: "CLEAR_SYMBOL_QUERY_PARAMETER",
	TICKET_QUERY_PARAMETER: "TICKET_QUERY_PARAMETER",
	BUILDING_QUERY_PARAMETER: "BUILDING_QUERY_PARAMETER",
	CLEAR_BUILDING_QUERY_PARAMETER: "CLEAR_BUILDING_QUERY_PARAMETER",
	REVIT_TEMPLATE_QUERY_PARAMETER: "REVIT_TEMPLATE_QUERY_PARAMETER",
	CLEAR_REVIT_TEMPLATE_QUERY_PARAMETER: "CLEAR_REVIT_TEMPLATE_QUERY_PARAMETER",
};

export default queryParameterConstants;
