export const templateConstants = {
	UPDATE_TEMP_TICKET: "UPDATE_TEMP_TICKET",
	CLEAR_STATE: "CLEAR_STATE",
	GET_REVIT_TEMPLATES: "GET_REVIT_TEMPLATES",
	TEMPLATE_ACTION_FAILURE: "TEMPLATE_ACTION_FAILURE",
	CLEAR_TEMPLATE_REQUEST_STATE: "CLEAR_TEMPLATE_REQUEST_STATE",
	TEMPLATE_ACTION_SUCCESS: "TEMPLATE_ACTION_SUCCESS",

	GET_REVIT_TEMPLATE: "GET_REVIT_TEMPLATE",
	GET_REVIT_TEMPLATE_FAMILIES: "GET_REVIT_TEMPLATE_FAMILIES",
	GET_REVIT_TEMPLATE_FAMILY_MAPPING: "GET_REVIT_TEMPLATE_FAMILY_MAPPING",

	SET_TEMP_REVIT_TEMPLATE_FILE: "SET_TEMP_REVIT_TEMPLATE_FILE",
	REMOVE_TEMP_REVIT_TEMPLATE_FAMILY_MAPPING:
		"REMOVE_TEMP_REVIT_TEMPLATE_FAMILY_MAPPING",
	UPDATE_TEMP_REVIT_TEMPLATE_FAMILY_MAPPING:
		"UPDATE_TEMP_REVIT_TEMPLATE_FAMILY_MAPPING",
	ADD_TEMP_REVIT_TEMPLATE_FAMILY_MAPPING:
		"ADD_TEMP_REVIT_TEMPLATE_FAMILY_MAPPING",
	SET_TEMP_REVIT_TEMPLATE_FAMILY_MAPPING:
		"SET_TEMP_REVIT_TEMPLATE_FAMILY_MAPPING",
	REMOVE_TEMP_REVIT_TEMPLATE_FILE: "REMOVE_TEMP_REVIT_TEMPLATE_FILE",
	SET_TEMP_REVIT_TEMPLATE_FILE_DATA: "SET_TEMP_REVIT_TEMPLATE_FILE_DATA",
	UPDATE_TEMP_REVIT_TEMPLATE_DATA: "UPDATE_TEMP_REVIT_TEMPLATE_DATA",
	CLEAR_SINGLE_REVIT_TEMPLATE: "CLEAR_SINGLE_REVIT_TEMPLATE",
	CLEAR_TEMP_REVIT_TEMPLATE: "CLEAR_TEMP_REVIT_TEMPLATE",
	SET_TEMP_REVIT_TEMPLATE: "SET_TEMP_REVIT_TEMPLATE",
	RESET_TEMP_REVIT_TEMPLATE: "RESET_TEMP_REVIT_TEMPLATE",
	CLEAR_REVIT_TEMPLATES: "CLEAR_REVIT_TEMPLATES",
	UPDATE_ALL_FAMILY_MAPPING_CHECK_DEFAULT:
		"UPDATE_ALL_FAMILY_MAPPING_CHECK_DEFAULT",
};
