import React from "react";

export default (props) => {
	return (
		<div className={`icon ${props.iconClass}`}>
			<svg
				width="11"
				height="20"
				viewBox="0 0 11 20"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M7.9906 9.11719L3.86472 7.88281C3.38718 7.74219 3.05482 7.28516 3.05482 6.77734C3.05482 6.14062 3.5591 5.625 4.1818 5.625H6.71463C7.18071 5.625 7.63914 5.76953 8.02116 6.03516C8.2542 6.19531 8.56746 6.15625 8.76612 5.95703L10.0956 4.62891C10.3668 4.35938 10.3286 3.91016 10.0268 3.67188C9.09084 2.92188 7.92184 2.50391 6.72227 2.5V0.625C6.72227 0.28125 6.44722 0 6.11103 0H4.88855C4.55236 0 4.27731 0.28125 4.27731 0.625V2.5H4.1818C1.74829 2.5 -0.207686 4.63672 0.0177095 7.17188C0.178161 8.97266 1.52289 10.4375 3.21909 10.9453L7.13486 12.1172C7.6124 12.2617 7.94476 12.7148 7.94476 13.2227C7.94476 13.8594 7.44048 14.375 6.81778 14.375H4.28495C3.81887 14.375 3.36044 14.2305 2.97842 13.9648C2.74538 13.8047 2.43212 13.8438 2.23346 14.043L0.904011 15.3711C0.632772 15.6406 0.670975 16.0898 0.972776 16.3281C1.90874 17.0781 3.07774 17.4961 4.27731 17.5V19.375C4.27731 19.7188 4.55236 20 4.88855 20H6.11103C6.44722 20 6.72227 19.7188 6.72227 19.375V17.4922C8.50252 17.457 10.172 16.375 10.7603 14.6523C11.5817 12.2461 10.2025 9.77734 7.9906 9.11719Z"
					fill="#7F909F"
				/>
			</svg>
		</div>
	);
};
