import React from "react";

export default (props) => {
	return (
		<div className={`icon ${props.iconClass ? props.iconClass : ""}`}>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="123.489"
				height="141"
				viewBox="0 0 123.489 141"
			>
				<g
					id="project-empty-state-icon"
					transform="translate(-383.12 -313.501)"
				>
					<path
						id="Union_136"
						data-name="Union 136"
						d="M76.906,140H0V0H76.906V42.262h45.583V140Z"
						transform="translate(383.62 314.001)"
						fill="#d0d0d0"
						stroke="#3b3b3b"
						strokeLinejoin="round"
						strokeWidth="1"
					/>
					<g
						id="Group_4045"
						data-name="Group 4045"
						transform="translate(-43 -9)"
					>
						<g
							id="Group_4037"
							data-name="Group 4037"
							transform="translate(0 7)"
						>
							<circle
								id="Ellipse_1986"
								data-name="Ellipse 1986"
								cx="4.5"
								cy="4.5"
								r="4.5"
								transform="translate(463.859 383.078)"
								fill="#3b3b3b"
							/>
							<circle
								id="Ellipse_1987"
								data-name="Ellipse 1987"
								cx="4.5"
								cy="4.5"
								r="4.5"
								transform="translate(503.785 383.078)"
								fill="#3b3b3b"
							/>
						</g>
						<line
							id="Line_1716"
							data-name="Line 1716"
							x2="50.301"
							transform="translate(462.984 427.965)"
							fill="#fff"
							stroke="#3b3b3b"
							strokeLinecap="round"
							strokeWidth="3"
						/>
					</g>
				</g>
			</svg>
		</div>
	);
};
