import React from "react";
import { connect } from "react-redux";
import localize, { LocKeys } from "../../../../../../constants/localizations";
import EditButton from "../../../../../../shared/components/buttons/EditButton";
import FormSubmitButtons from "../../../../../../shared/components/FormComponents/FormSubmitButtons";
import {
	deleteContentTempImage,
	deleteFile,
	getPresignedPostUploadUrl,
	resetLodContent,
	resetTempLodContent,
	updateLODContent,
	uploadToDigitalOcean,
} from "../../../../../../actions";
import LodEditor from "../../parts/LodEditor";
import TooltipWithHtml from "../../../../../../shared/htmlTooltip/TooltipWithHtml";
import IconWrapper from "../../../../../../shared/icons/IconWrapper";
import TrashIcon from "../../../../../../shared/icons/actions/TrashIcon";
import ImageInputFormatIcon from "../../../../../../shared/icons/inputFormats/ImageInputFormatIcon";
import { uploadFile } from "../../../../../../shared/components/UploadFile/uploadFile";
import FileInput from "../../../../../fields/FileInput";
import ComponentTooltip from "../../../../../../shared/components/component-tooltip/ComponentTooltip";
import { useParams } from "react-router-dom-v5-compat";

/**
 * Render custom component for image upload
 *
 */
const renderImageContent = ({
	id,
	accept,
	onChange,
	multipleFilesAllowed,
	disabled,
	value,
	deleteTempFile,
}) => {
	return (
		<div className="w-100 d-flex align-items-center justify-content-center flex-column p-32">
			{value ? (
				<div className="ml-xs w-120p h-120p pos-rel px-32 py-xs border-1 border-n150 border-radius-regular bg-n000">
					<div className="pos-abs right-xs top-xs z-index-2">
						<TooltipWithHtml
							tooltipContent={localize(LocKeys.REMOVE_IMAGE)}
							tooltipPlacement="right"
						>
							<button
								type="button"
								onClick={(e) => {
									e.preventDefault();
									deleteTempFile();
								}}
								className="btn btn--error btn--icon btn--icon--solo btn--icon--solo--small"
							>
								<IconWrapper
									icon={
										<TrashIcon iconClass="d-flex icon--target-fill fill--n000" />
									}
									size={24}
								/>
							</button>
						</TooltipWithHtml>
					</div>
					<img src={value} alt={"Default"} className="h-100 w-100 of-contain" />
				</div>
			) : (
				<IconWrapper
					icon={
						<ImageInputFormatIcon iconClass="d-flex icon--target-fill fill--n300" />
					}
					size={36}
				/>
			)}
			<div className="pt-m d-flex  align-items-center">
				{!value && localize(LocKeys.DRAG_AND_DROP)}
				<div className="ml-s">
					<input
						id={id ? id : "file-input"}
						hidden
						type="file"
						name={id ? id : "file-input"}
						accept={accept ? accept : "*"}
						multiple={multipleFilesAllowed}
						onChange={onChange}
						disabled={disabled ? disabled : false}
					/>
					<label
						htmlFor={id ? id : "file-input"}
						className={`btn btn--secondary btn--small body-font--small semi ${
							disabled ? "btn--disabled" : " "
						}`}
					>
						{!value
							? localize(LocKeys.ADD_IMAGE)
							: localize(LocKeys.REUPLOAD_IMAGE)}
					</label>
				</div>
			</div>
		</div>
	);
};

/**
 * Component for editing summary and images
 * @param {object} props
 * @returns
 */
const SumaryImageEdit = (props) => {
	const { lodContentId } = useParams();
	const {
		findLOD,
		//findImage2D,
		findImage3D,
		findSummary,
		//Actions
		onCancel,
		uploadToDigitalOcean,
		getPresignedPostUploadUrl,
		deleteFile,
		deleteContentTempImage,
		resetTempLodContent,
		resetLodContent,
		updateLODContent,
	} = props;

	const [loading, setLoading] = React.useState(false);

	const uploadImage = (file, id, fieldKey) => {
		//------------------
		let dbFileData = {
			file: {
				name: file.name,
				size: file.size,
			},
			path: null,
			disciplineComponentCategoryLodDescriptionId: id,
		};
		dbFileData[fieldKey] = fieldKey;

		let preSignedPostUploadUrlBody = {
			fileName: file.name,
			disciplineComponentCategoryLodDescriptionId: id,
		};
		preSignedPostUploadUrlBody[fieldKey] = fieldKey;

		//------------------
		uploadFile({
			dbFileData,
			file: file,
			preSignedPostUploadUrlBody,
			uploadToDigitalOcean,
			getPresignedPostUploadUrl,
			cb: () => {
				setLoading(false);
			},
		});
	};

	const onSubmit = () => {
		try {
			setLoading(true);

			let values = {
				id: findSummary.get("id"),
			};
			const data = [];

			if (findImage3D) {
				values["threeDFileId"] = findImage3D.get("fileId") || null;

				if (
					+findImage3D.get("fileId") !== +findImage3D.get("tempFileId") &&
					+findImage3D.get("tempFileId") > 0
				)
					deleteFile({ fileId: findImage3D.get("tempFileId") });
			}

			// if (findImage2D) {
			// 	values["twoDFileId"] = findImage2D.get("fileId") || null;

			// 	if (
			// 		+findImage2D.get("fileId") !== +findImage2D.get("tempFileId") &&
			// 		+findImage2D.get("tempFileId") > 0
			// 	)
			// 		deleteFile(findImage2D.get("tempFileId"));
			// }

			if (findSummary) {
				values["description"] = findSummary.get("summary");
			}

			data.push(values);

			updateLODContent(data, lodContentId, () => {
				resetLodContent();
				onCancel();
			});
		} catch (error) {
			console.log("error", error);
			return;
		}
	};

	const fileInputOnChange = ({ files, fieldKey, id }) => {
		if (files[0]) {
			setLoading(true);
			const file = files[0];

			uploadImage(file, id, fieldKey);
		}
	};

	const deleteTempFile = ({ lodId, threeDFile, twoDFile }) => {
		deleteContentTempImage({ lodId, threeDFile, twoDFile });
	};

	const cancel = () => {
		resetTempLodContent();
		onCancel();
	};

	return (
		<div className="bg-primary--lighter border-t-1  border-l-1 border-primary-light">
			<div className="border-b-1 border-primary-light border-r-1">
				<div className="d-flex py-xs">
					<div className="d-flex col col-10 align-items-center">
						<h5 className="small color-n300 pl-regular">
							{localize(LocKeys.PARAMETERS)}
						</h5>
					</div>

					<div className="col col-90">
						<div className="d-flex align-items-center pl-regular">
							<h5 className="small color-n300">
								{localize(LocKeys.LOD)} {findLOD.get("type")}
							</h5>
							<EditButton wrapperClasses="ml-xs" onClick={onCancel} />
						</div>
					</div>
				</div>
			</div>

			<div className="border-b-1 border-primary-light">
				<div className="d-flex">
					<div className="col col-10 border-r-1 border-primary-light">
						<div className="px-regular py-m">
							<ComponentTooltip
								content={localize(LocKeys.VISUAL_REPRESENTATION)}
								direction={"top"}
							>
								<div className={`text-overflow-ellipsis`}>
									{localize(LocKeys.VISUAL_REPRESENTATION)}
								</div>
							</ComponentTooltip>
						</div>
					</div>

					<div className="col col-90 max-h-390p">
						<div className="py-24 w-100 h-100 px-regular border-r-1 border-primary-light">
							<FileInput
								accept="image/png, image/jpeg, image/jpg"
								id={`image3D-${findImage3D?.get("lodId")}`}
								showFileList={false}
								customOnChange={(files) =>
									fileInputOnChange({
										files,
										fieldKey: "threeDFile",
										id: findImage3D.get("id"),
									})
								}
								multipleFilesAllowed={false}
								renderContent={(data) =>
									renderImageContent({
										...data,
										value: findImage3D.get("value"),
										deleteTempFile: () =>
											deleteTempFile({
												lodId: findImage3D.get("lodId"),
												threeDFile: true,
											}),
									})
								}
							/>
						</div>
					</div>
				</div>
			</div>

			{/* <div className="border-b-1 border-primary-light">
				<div className="d-flex">
					<div className="col col-10 border-r-1 border-primary-light">
						<div className="px-regular py-m">{localize(LocKeys.IMAGE_2D)}</div>
					</div>

					<div className="col col-90">
						<div className="py-24 w-100 px-regular border-r-1 border-primary-light">
							<FileInput
								accept="image/png, image/jpeg, image/jpg"
								showFileList={false}
								id={`image2D-${findImage2D?.get("lodId")}`}
								customOnChange={(files) =>
									fileInputOnChange({
										files,
										fieldKey: "twoDFile",
										id: findImage2D?.get("id"),
									})
								}
								multipleFilesAllowed={false}
								renderContent={(data) =>
									renderImageContent({
										...data,
										value: findImage2D?.get("value"),
										deleteTempFile: () =>
											deleteTempFile({
												lodId: findImage2D?.get("lodId"),
												twoDFile: true,
											}),
									})
								}
							/>
						</div>
					</div>
				</div>
			</div> */}

			<div className="border-b-1 border-primary-light">
				<div className="d-flex">
					<div className="col col-10 border-r-1 border-primary-light">
						<div className="px-regular py-m">
							{localize(LocKeys.SUMMARY_LABEL)}
						</div>
					</div>

					<div className="col col-90">
						<div className="py-s w-100 px-regular h-100 border-r-1 border-primary-light">
							<LodEditor
								isEdit={true}
								placeholder={`${localize(LocKeys.ENTER_SUMMARY)}...`}
								lodId={findSummary.get("lodId")}
								summary={findSummary?.get("summary") || ""}
							/>
						</div>
					</div>
				</div>
			</div>

			<div className="border-r-1 border-primary-light px-regular">
				<FormSubmitButtons
					submitType="button"
					wrapperClasses="w-100 py-regular"
					onCancel={() => cancel()}
					onSubmit={onSubmit}
					loading={loading}
					submitDisabled={loading}
				/>
			</div>
		</div>
	);
};

const mapStateToProps = (state, ownProps) => {
	const { lodId } = ownProps;
	const images2D = state.getIn([
		"administration",
		"tempComponentCategoryContent",
		"lodContent",
		"images2D",
	]);
	const images3D = state.getIn([
		"administration",
		"tempComponentCategoryContent",
		"lodContent",
		"images3D",
	]);
	const summaries = state.getIn([
		"administration",
		"tempComponentCategoryContent",
		"lodContent",
		"summaries",
	]);
	const lods = state.getIn(["administration", "lods"]);

	const findImage2D =
		images2D && images2D.find((image) => image.get("lodId") === lodId);
	const findImage3D =
		images3D && images3D.find((image) => image.get("lodId") === lodId);
	const findSummary =
		summaries && summaries.find((summary) => summary.get("lodId") === lodId);
	const findLOD = lods && lods.find((lod) => lod.get("id") === lodId);

	return {
		findLOD,
		findImage2D,
		findImage3D,
		findSummary,
	};
};

export default connect(mapStateToProps, {
	uploadToDigitalOcean,
	getPresignedPostUploadUrl,
	deleteFile,
	deleteContentTempImage,
	resetTempLodContent,
	resetLodContent,
	updateLODContent,
})(SumaryImageEdit);
