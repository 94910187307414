import { jwtDecode } from "jwt-decode";

/**
 * Return data from the token
 */

export const tokenParams = () => {
	const token = localStorage.getItem("Session");

	if (!token) return null;
	return jwtDecode(token);
};

/**
 * Return logged user token
 */
export const getToken = () => localStorage.getItem("Session");

/**
 * Return user permissions
 */
export const getPermissions = () =>
	!!localStorage.getItem("Permissions")
		? JSON.parse(localStorage.getItem("Permissions")) || null
		: null;

/**
 * Return global customer
 */
export const getCustomer = () => localStorage.getItem("Customer");

export const setItemToLocalStorage = (name, value) =>
	localStorage.setItem(name, value);

export const removeItemFromLocalStorage = (name) =>
	localStorage.removeItem(name);

export const getItemFromLocalStorage = (name) => localStorage.getItem(name);

export const isLogin = () => {
	if (getItemFromLocalStorage("Session")) return true;
	return false;
};
