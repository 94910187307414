import "../assets/styles/editorTheme.scss";

const EditorTheme = {
	characterLimit: "editorTheme__characterLimit",
	code: "editorTheme__code",
	codeHighlight: {
		atrule: "editorTheme__tokenAttr",
		attr: "editorTheme__tokenAttr",
		boolean: "editorTheme__tokenProperty",
		builtin: "editorTheme__tokenSelector",
		cdata: "editorTheme__tokenComment",
		char: "editorTheme__tokenSelector",
		class: "editorTheme__tokenFunction",
		"class-name": "editorTheme__tokenFunction",
		comment: "editorTheme__tokenComment",
		constant: "editorTheme__tokenProperty",
		deleted: "editorTheme__tokenProperty",
		doctype: "editorTheme__tokenComment",
		entity: "editorTheme__tokenOperator",
		function: "editorTheme__tokenFunction",
		important: "editorTheme__tokenVariable",
		inserted: "editorTheme__tokenSelector",
		keyword: "editorTheme__tokenAttr",
		namespace: "editorTheme__tokenVariable",
		number: "editorTheme__tokenProperty",
		operator: "editorTheme__tokenOperator",
		prolog: "editorTheme__tokenComment",
		property: "editorTheme__tokenProperty",
		punctuation: "editorTheme__tokenPunctuation",
		regex: "editorTheme__tokenVariable",
		selector: "editorTheme__tokenSelector",
		string: "editorTheme__tokenSelector",
		symbol: "editorTheme__tokenProperty",
		tag: "editorTheme__tokenProperty",
		url: "editorTheme__tokenOperator",
		variable: "editorTheme__tokenVariable",
	},
	embedBlock: {
		base: "editorTheme__embedBlock",
		focus: "editorTheme__embedBlockFocus",
	},
	hashtag: "editorTheme__hashtag",
	heading: {
		h1: "editorTheme__h1",
		h2: "editorTheme__h2",
		h3: "editorTheme__h3",
		h4: "editorTheme__h4",
		h5: "editorTheme__h5",
		h6: "editorTheme__h6",
	},
	image: "editor-image",
	link: "editorTheme__link",
	list: {
		listitem: "editorTheme__listItem",
		listitemChecked: "editorTheme__listItemChecked",
		listitemUnchecked: "editorTheme__listItemUnchecked",
		nested: {
			listitem: "editorTheme__nestedListItem",
		},
		olDepth: [
			"editorTheme__ol1",
			"editorTheme__ol2",
			"editorTheme__ol3",
			"editorTheme__ol4",
			"editorTheme__ol5",
		],
		ul: "editorTheme__ul",
	},
	ltr: "editorTheme__ltr",
	mark: "editorTheme__mark",
	markOverlap: "editorTheme__markOverlap",
	paragraph: "editorTheme__paragraph",
	quote: "editorTheme__quote",
	rtl: "editorTheme__rtl",
	table: "editorTheme__table",
	tableAddColumns: "editorTheme__tableAddColumns",
	tableAddRows: "editorTheme__tableAddRows",
	tableCell: "editorTheme__tableCell",
	tableCellActionButton: "editorTheme__tableCellActionButton",
	tableCellActionButtonContainer: "editorTheme__tableCellActionButtonContainer",
	tableCellEditing: "editorTheme__tableCellEditing",
	tableCellHeader: "editorTheme__tableCellHeader",
	tableCellPrimarySelected: "editorTheme__tableCellPrimarySelected",
	tableCellResizer: "editorTheme__tableCellResizer",
	tableCellSelected: "editorTheme__tableCellSelected",
	tableCellSortedIndicator: "editorTheme__tableCellSortedIndicator",
	tableResizeRuler: "editorTheme__tableCellResizeRuler",
	tableSelected: "editorTheme__tableSelected",
	text: {
		bold: "editorTheme__textBold",
		code: "editorTheme__textCode",
		italic: "editorTheme__textItalic",
		strikethrough: "editorTheme__textStrikethrough",
		subscript: "editorTheme__textSubscript",
		superscript: "editorTheme__textSuperscript",
		underline: "editorTheme__textUnderline",
		underlineStrikethrough: "editorTheme__textUnderlineStrikethrough",
	},
};

export default EditorTheme;
