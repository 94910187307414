import React from "react";
import { connect } from "react-redux";
import localize, { LocKeys } from "../../../../../constants/localizations";
import { updateTempLodContentSummary } from "../../../../../actions";
import LexicalEditor from "../../../../../shared/components/TextEditor/Lexical/LexicalEditor";

/**
 * Lexical text editor for LOD content
 * @param {*} props
 * @returns
 */
const LodEditor = (props) => {
	const {
		isEdit,
		summary = "",
		lodId = null,
		updateTempLodContentSummary,
		placeholder = `${localize(LocKeys.SUMMARY_LABEL)}...`,
		wrapperEditorClasses = "",
	} = props;

	const setValue = (data) => {
		if (!!isEdit) {
			updateTempLodContentSummary(lodId, data);
		}
	};

	if (!isEdit && !summary) {
		return null;
	}

	return (
		<div
			className={`${
				isEdit
					? "border-radius-regular border-1  w-100  border-n150 bg-n000"
					: ""
			}  `}
		>
			<LexicalEditor
				scrollerClasses="max-h-470p"
				placeholder={placeholder}
				editorState={summary?.toString().startsWith('{"root":') ? summary : ""}
				onChange={setValue}
				hideTableAndImage={true}
				hideQuoteAndCode={true}
				editable={isEdit}
				wrapperEditorClasses={wrapperEditorClasses}
			/>
		</div>
	);
};

const mapStateToProps = (state, ownProps) => {
	const { isEdit = false, lodId = null, summary = "" } = ownProps;

	let summaries = null;

	if (isEdit) {
		summaries = state.getIn([
			"administration",
			"tempComponentCategoryContent",
			"lodContent",
			"summaries",
		]);
	} else {
		summaries = state.getIn([
			"administration",
			"componentCategoryContent",
			"lodContent",
			"summaries",
		]);
	}

	const findLOD =
		summaries && summaries?.find((lod) => lod.get("lodId") === lodId);

	return {
		summary: findLOD?.summary || summary,
	};
};

export default connect(mapStateToProps, { updateTempLodContentSummary })(
	LodEditor
);
