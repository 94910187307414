import React, { useState } from "react";
import { connect } from "react-redux";
import EditButton from "../../../../../../shared/components/buttons/EditButton";
import ParameterCategoryEdit from "../edit/ParameterCategoryEdit";
import {
	fieldTypeConstants,
	generalConstants,
} from "../../../../../../constants/constants";
import SelectionCheckmark from "../../../../../../shared/components/SelectionCheckmark";
import TooltipWithHtml from "../../../../../../shared/htmlTooltip/TooltipWithHtml";

/**
 * Depending on the type of the parameter, render the value
 * @returns
 */
export const renderParameterValue = ({
	type,
	boolValue,
	txtValue,
	isForSpecification,
	textLength = 20,
}) => {
	let tempTextValue = txtValue;
	if (txtValue?.length > textLength) {
		tempTextValue = "";
		for (let index = 0; index < txtValue.length / textLength; index++) {
			tempTextValue += `${txtValue.substring(
				index * textLength,
				(index + 1) * textLength
			)}\n`;
		}
	}

	switch (type) {
		case fieldTypeConstants.CHECKBOX:
			return (
				<div className="w-100 d-flex justify-content-center">
					<SelectionCheckmark
						isForView={isForSpecification}
						selected={boolValue}
						type={generalConstants.MULTI}
					/>
				</div>
			);

		default:
			return (
				<TooltipWithHtml
					tooltipContent={
						tempTextValue?.length > textLength
							? tempTextValue?.replaceAll("\n", "  \n")
							: null
					}
					tooltipPlacement={"top"}
					wrapperClasses="w-100"
				>
					<div className={`text-overflow-ellipsis text-center`}>{txtValue}</div>
				</TooltipWithHtml>
			);
	}
};
/**
 * ParameterCategory view component
 *
 * @returns
 */
const ParameterCategory = (props) => {
	const {
		isForSpecification = false,
		selectedLodId = null,
		lodParameterCategories = null,
		isEditView = false,
	} = props;
	const [editView, setEditView] = useState({ edit: false, categoryId: null });
	const firstColClassName = isForSpecification ? "col-20" : "col-10";
	const secondColClassName = isForSpecification ? "col-16" : "col-18";

	return (
		<>
			{lodParameterCategories &&
				lodParameterCategories.valueSeq().map((lpc, index) => {
					const lodParameters = lpc?.get("lodParameters") || null;
					const canHideCategory =
						!lodParameters?.size > 0 && isForSpecification;

					if (lpc.get("id") === editView.categoryId && editView.edit) {
						return (
							<div key={index}>
								<ParameterCategoryEdit
									categoryId={editView.categoryId}
									onCancel={() =>
										setEditView({
											edit: !editView.edit,
											categoryId: null,
										})
									}
								/>
							</div>
						);
					}

					if (!canHideCategory) {
						return (
							<div key={index}>
								<div
									className={`d-flex max-h-48p h-48p align-items-center ${
										isEditView
											? `bg-primary--light ${
													lodParameters?.size > 0
														? ""
														: "border-n150 border-b-1"
											  }`
											: "bg-blue--light border-n150 border-b-1"
									} px-regular py-m `}
								>
									<div className="body-font semi">{lpc.get("name")} </div>

									{!isForSpecification && (
										<EditButton
											wrapperClasses="ml-auto"
											disabled={editView.edit}
											onClick={() =>
												setEditView({
													edit: !editView.edit,
													categoryId: lpc.get("id"),
												})
											}
										/>
									)}
								</div>

								{lodParameters &&
									lodParameters.valueSeq().map((lp, index) => {
										const lodsValue = lp?.get("lods") || null;

										return (
											<div
												key={index}
												className={`max-h-48p h-48p ${
													index === lodParameters?.size - 1 && isEditView
														? "border-primary"
														: "border-n150"
												} border-b-1`}
											>
												<div className="d-flex h-100">
													<div
														className={`col ${firstColClassName} border-r-1 border-n150`}
													>
														<div className="pl-regular py-m">
															<TooltipWithHtml
																tooltipContent={lp.get("name")}
																tooltipPlacement={"top"}
																wrapperClasses="w-100"
															>
																<div className={`text-overflow-ellipsis`}>
																	{lp.get("name")}
																</div>
															</TooltipWithHtml>
														</div>
													</div>
													{lodsValue &&
														lodsValue.valueSeq().map((lod, lodIndex) => {
															return (
																<div
																	key={lodIndex}
																	className={`col ${secondColClassName}`}
																>
																	<div
																		className={`py-m w-100 h-100 px-regular ${
																			selectedLodId === lod.get("lodId")
																				? ` ${
																						index === lodParameters?.size - 1 &&
																						!isEditView
																							? "border-1"
																							: "border-r-1 border-t-1 border-l-1"
																				  } border-primary bg-primary--selected`
																				: "border-n150 border-r-1"
																		} `}
																	>
																		{renderParameterValue({
																			type: lod.getIn([
																				"inputFieldType",
																				"type",
																			]),
																			txtValue: lod.get("txtValue"),
																			boolValue: lod.get("boolValue"),
																			isForSpecification,
																		})}
																	</div>
																</div>
															);
														})}
												</div>
											</div>
										);
									})}
							</div>
						);
					} else {
						return "";
					}
				})}
		</>
	);
};

const mapStateToProps = (state, ownProps) => {
	const { isForSpecification, lodParameterCategories } = ownProps;
	return {
		lodParameterCategories: !isForSpecification
			? state.getIn([
					"administration",
					"componentCategoryContent",
					"lodParameterCategories",
			  ]) || null
			: lodParameterCategories,
		lods: state.getIn(["administration", "lods"]),
	};
};

export default connect(mapStateToProps, {})(ParameterCategory);
