import React, { useEffect, useState } from "react";
import FormSubmitButtons from "../../../../../../shared/components/FormComponents/FormSubmitButtons";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form/immutable";
import {
	addNewTempParameter,
	getSpecificationCategoryParameters,
} from "../../../../../../actions";
import CustomAutocomplete from "../../../../../fields/CustomAutocomplete";
import localize, { LocKeys } from "../../../../../../constants/localizations";

const AddParameter = (props) => {
	const {
		categoryId,
		specificationParameters,
		onCancel,
		getSpecificationCategoryParameters,
		addNewTempParameter,
		excludeIds = [],
	} = props;
	const [parameters, setParameters] = useState([]);

	useEffect(() => {
		if (categoryId)
			getSpecificationCategoryParameters(true, categoryId, excludeIds);
	}, [getSpecificationCategoryParameters, categoryId, excludeIds]);

	const onSubmit = () => {
		try {
			addNewTempParameter(categoryId, parameters);
			onCancel();
		} catch (error) {
			return;
		}
	};

	let options = [];
	specificationParameters &&
		specificationParameters.map((specificationParameter) => {
			return options.push({
				value: specificationParameter.get("id"),
				label: specificationParameter.get("name"),
			});
		});

	return (
		<form
			className={`form d-flex flex-column flex-auto standardized-form`}
			onSubmit={props.handleSubmit(() => onSubmit())}
			autoComplete="off"
			noValidate
		>
			<Field
				name="parameters"
				id="parameters"
				classField="mb-24"
				disableClearable={true}
				component={CustomAutocomplete}
				multiple={true}
				label={localize(LocKeys.NEW_PARAMETER)}
				placeholder={localize(LocKeys.SELECT_NEW_PARAMETER) + "..."}
				showPlaceholder={true}
				dropdownHeight="350px"
				options={options}
				customOnChange={(value) => setParameters(value)}
			/>
			<FormSubmitButtons
				wrapperClasses="w-100"
				onCancel={onCancel}
				submitDisabled={parameters.length === 0}
				onSubmit={() => onSubmit}
			/>
		</form>
	);
};

const mapStateToProps = (state) => {
	return {
		specificationParameters: state.getIn([
			"administration",
			"specificationParameters",
		]),
	};
};
export default connect(mapStateToProps, {
	getSpecificationCategoryParameters,
	addNewTempParameter,
})(reduxForm({ form: "addParameterForm", touchOnBlur: false })(AddParameter));
