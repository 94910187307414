export const symbolConstants = {
	GET_SYMBOLS: "GET_SYMBOLS",
	GET_SYMBOL: "GET_SYMBOL",
	SET_TEMP_SYMBOL: "SET_TEMP_SYMBOL",
	SYMBOL_ACTION_GET_SUCCESS: "SYMBOL_ACTION_GET_SUCCESS",
	SYMBOL_ACTION_GET_FAILURE: "SYMBOL_ACTION_GET_FAILURE",
	CLEAR_TEMP_SYMBOL: "CLEAR_TEMP_SYMBOL",
	UPDATE_TEMP_FIELD_SYMBOL: "UPDATE_TEMP_FIELD_SYMBOL",
	CLEAR_SINGLE_SYMBOL: "CLEAR_SINGLE_SYMBOL",
	CLEAR_SYMBOL_REQUEST_STATE: "CLEAR_SYMBOL_REQUEST_STATE",
	SET_SYMBOL_TEMP_FILE: "SET_SYMBOL_TEMP_FILE",
	SET_SYMBOL_TEMP_FILE_URL: "SET_SYMBOL_TEMP_FILE_URL",
};
