import React from "react";

export default (props) => {
	return (
		<span className={`icon ${props.iconClass ? props.iconClass : ""}`}>
			<svg
				width="9"
				height="6"
				viewBox="0 0 9 6"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M6.86199 1.19535L3.99999 4.05735L1.13799 1.19535C1.01225 1.07391 0.84385 1.00671 0.669052 1.00823C0.494255 1.00975 0.327046 1.07986 0.203441 1.20347C0.0798355 1.32707 0.00972286 1.49428 0.00820391 1.66908C0.00668497 1.84388 0.0738812 2.01228 0.19532 2.13801L3.52865 5.47135C3.65367 5.59633 3.82321 5.66654 3.99999 5.66654C4.17676 5.66654 4.3463 5.59633 4.47132 5.47135L7.80465 2.13801C7.86833 2.07652 7.91912 2.00295 7.95405 1.92162C7.98899 1.84028 8.00738 1.7528 8.00815 1.66428C8.00892 1.57576 7.99205 1.48797 7.95853 1.40604C7.92501 1.32411 7.87551 1.24968 7.81292 1.18708C7.75032 1.12449 7.67589 1.07499 7.59396 1.04147C7.51203 1.00795 7.42424 0.991077 7.33572 0.991846C7.2472 0.992616 7.15972 1.01101 7.07838 1.04595C6.99705 1.08089 6.92348 1.13167 6.86199 1.19535Z"
					fill="#7F909F"
				/>
			</svg>
		</span>
	);
};
