import React from "react";

export default (props) => {
	return (
		<span className={`icon ${props.iconClass ? props.iconClass : ""}`}>
			<svg
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M14.4471 6.27293C14.8051 6.7778 14.9983 7.38106 15 8C15.0015 8.55035 14.8517 9.09052 14.5668 9.5614C14.2819 10.0323 13.8729 10.4157 13.3847 10.6697C12.8965 10.9238 12.3478 11.0386 11.7987 11.0017C11.2496 10.9647 10.7212 10.7775 10.2714 10.4603C9.82159 10.1432 9.46768 9.70849 9.2484 9.20371C9.02913 8.69893 8.95293 8.14355 9.02816 7.59836C9.10338 7.05317 9.32713 6.53918 9.67492 6.11264C10.0227 5.68611 10.4811 5.36345 11 5.18V3H13V5.18C13.5835 5.38631 14.089 5.76807 14.4471 6.27293ZM11.4444 8.83147C11.6089 8.94135 11.8022 9 12 9C12.2652 9 12.5196 8.89464 12.7071 8.70711C12.8946 8.51957 13 8.26522 13 8C13 7.80222 12.9413 7.60888 12.8315 7.44443C12.7216 7.27998 12.5654 7.15181 12.3827 7.07612C12.1999 7.00043 11.9989 6.98063 11.8049 7.01921C11.6109 7.0578 11.4327 7.15304 11.2929 7.29289C11.153 7.43275 11.0578 7.61093 11.0192 7.80491C10.9806 7.99889 11.0004 8.19996 11.0761 8.38268C11.1518 8.56541 11.28 8.72159 11.4444 8.83147ZM6.35999 18.78L6.60999 21L8.22998 19.46L11 11.86C10.3301 11.6862 9.71683 11.3417 9.21999 10.86L6.35999 18.78ZM14.77 10.88C14.276 11.3602 13.6663 11.7047 13 11.88L15.77 19.48L17.38 21L17.64 18.78L14.77 10.88Z"
					fill="#1B2024"
				/>
			</svg>
		</span>
	);
};
