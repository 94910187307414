export const permissions = {
	view: "view",
};

export const permissionsArray = [permissions.view];

export const feature = {
	dashboard: "dashboard",
	settings: "settings",
	customers: "customers",
	buildings: "buildings",
	jobs: "jobs",
	offers: "offers",
	invoices: "invoices",
	lodSpecifications: "lodSpecifications",
	ticketing: "ticketing",
	docs: "docs",
};
