import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
	closeSnackbar,
	getJobStatusFile,
	updateBimifyProgress,
} from "../../../../actions";
import { snackbarTypeConstants } from "../../../../constants/constants";
import { getSocket } from "../../../../utils/web-socket";

const BimifyProgress = (props) => {
	const {
		//state
		jobId,
		jobStatusId,
		//actions
		getJobStatusFile,
		closeSnackbar,
		updateBimifyProgress,
	} = props;

	useEffect(() => {
		const socket = getSocket();
		socket.connect();
		const event = `job-status-read-${jobId}-${jobStatusId}`;

		const listener = (statusData) => {
			const {
				statusesTotal,
				currentStatusNumber,
				message,
				uploadedRevitFile,
				status = false,
			} = statusData;

			const incrementedStatusesTotal = Number(statusesTotal) + 1;

			const progress = (100 / incrementedStatusesTotal) * currentStatusNumber;

			let data = {
				progress,
				message,
				inProgress: true,
				jobId,
				jobStatusId,
				errorResponse: false,
				successResponse: false,
			};

			if (!!status && status === "Error") {
				data.open = false;
				data.type = null;
				data.inProgress = false;
				data.errorResponse = true;
				closeSnackbar(snackbarTypeConstants.BIMIFY_PROGRESS);
			}

			if (uploadedRevitFile) {
				data.progress = 100;
				data.open = false;
				data.type = null;
				data.inProgress = false;
				data.successResponse = true;

				closeSnackbar(snackbarTypeConstants.BIMIFY_PROGRESS);
				getJobStatusFile({ jobId, jobStatusId });
			}

			updateBimifyProgress(data);
		};
		socket.on(event, listener);

		return () => {
			socket.off(event, listener);
			socket.disconnect();
		};
	}, [
		jobId,
		jobStatusId,
		getJobStatusFile,
		closeSnackbar,
		updateBimifyProgress,
	]);

	return <div></div>;
};
const mapStateToProps = (state) => {
	return {
		progress: state.getIn(["globalWidget", "bimifyProgress", "progress"]),
		jobId: state.getIn(["globalWidget", "bimifyProgress", "jobId"]),
		jobStatusId: state.getIn(["globalWidget", "bimifyProgress", "jobStatusId"]),
		message: state.getIn(["globalWidget", "bimifyProgress", "message"]),
	};
};

export default connect(mapStateToProps, {
	getJobStatusFile,
	updateBimifyProgress,
	closeSnackbar,
})(BimifyProgress);
