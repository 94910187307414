const fileConstants = {
	SET_SELECTED_FILES: "SET_SELECTED_FILES",
	CLEAR_FILE_REQUEST_STATE: "CLEAR_FILE_REQUEST_STATE",
	DELETE_SUCCESS: "DELETE_SUCCESS",
	DELETE_ERROR: "DELETE_ERROR",
	FILE_ACTION_SUCCESS: "FILE_ACTION_SUCCESS",
	FILE_ACTION_FAILURE: "FILE_ACTION_FAILURE",
};

export default fileConstants;
