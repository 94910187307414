import { statusConstants, eventType } from "../constants/constants";

import localize, { LocKeys } from "../constants/localizations";
import { formatId } from "./formatid.util";

export const getEventDefaultMessage = (type, orderId, quoteId) => {
	const link = `<a href="/offers/${orderId}/quote">${formatId(quoteId)}</a>`;
	switch (type) {
		case eventType.ACCEPTED_QUOTE:
			return localize(LocKeys.ACCEPTED_QUOTE, [link]);

		case eventType.REJECTED_QUOTE:
			return localize(LocKeys.REJECTED_QUOTE, [link]);

		case eventType.ORDER_CANCELED:
			return localize(LocKeys.ORDER_CANCELED);

		case eventType.NEW_ORDER_CREATE:
			return localize(LocKeys.NEW_ORDER_CREATE);

		case eventType.SEND_FOR_APPROVAL:
			return localize(LocKeys.SEND_FOR_APPROVAL_MESSAGE, [link]);

		default:
			return "";
	}
};

/**
 * Set first letter of string to uppercase
 * example -> Example
 * @param {string} str
 * @returns
 */
export const setFirstLetterUppercase = (str) => {
	return str?.charAt(0)?.toUpperCase() + str?.slice(1);
};

export const returnJobStatusLabel = (type) => {
	switch (type) {
		case statusConstants.NORMALISATION:
			return localize(LocKeys.NORMALISATION);
		case statusConstants.CUSTOMISATION:
			return localize(LocKeys.CUSTOMISATION);
		default:
			return localize(LocKeys.QUALITY_ASSURANCE);
	}
};

export const componentCategoryLabelConstants = {
	levelsAndGrids: localize(LocKeys.LEVELS_AND_GRIDS),
	exteriorWalls: localize(LocKeys.EXTERIOR_WALLS),
	interiorWalls: localize(LocKeys.INTERIOR_WALLS),
	curtainWalls: localize(LocKeys.CURTAIN_WALLS),
	columns: localize(LocKeys.COLUMNS),
	floors: localize(LocKeys.FLOORS),
	beamsBracesTrusses: localize(LocKeys.BEAMS_BRACES_TRUSSES),
	roofs: localize(LocKeys.ROOFS),
	roofAccesssories: localize(LocKeys.ROOFS_ACCESSSORIES),
	ceilings: localize(LocKeys.CEILINGS),
	stairs: localize(LocKeys.STAIRS),
	railings: localize(LocKeys.RAILINGS),
	doors: localize(LocKeys.DOORS),
	windows: localize(LocKeys.WINDOWS),
	sunProtectionShuttersBlinds: localize(LocKeys.SUNPROTECTION_SHUTTERS_BLINDS),
	MEP: localize(LocKeys.MEP),
	sanitaryEquipment: localize(LocKeys.SANITARY_EQUIPMENT),
	appliances: localize(LocKeys.APPLIANCES),
	casework: localize(LocKeys.CASEWORK),
	looseFurniture: localize(LocKeys.LOOSE_FURNITURE),
	rooms: localize(LocKeys.ROOMS),
	openings: localize(LocKeys.OPENINGS),
	foundation: localize(LocKeys.FOUNDATION),
	ducts: localize(LocKeys.DUCTS),
	ductFittings: localize(LocKeys.DUCT_FITTINGS),
	mechanicalEquipment: localize(LocKeys.MECHANICAL_EQUIPMENT),
	pipes: localize(LocKeys.PIPES),
	pipeFittings: localize(LocKeys.PIPE_FITTINGS),
	plumbingFixtures: localize(LocKeys.PLUMBING_FIXTURES),
	cableTrays: localize(LocKeys.CABLE_TRAYS),
	cableTrayFittings: localize(LocKeys.CABLE_TRAY_FITTINGS),
	lightingFixture: localize(LocKeys.LIGHTING_FIXTURE),
	electricalConduits: localize(LocKeys.ELECTRICAL_CONDUITS),
};
