import React from "react";

const CloseIcon = (props) => {
	return (
		<div className={`icon ${props.iconClass ? props.iconClass : ""}`}>
			<svg
				width="10"
				height="10"
				viewBox="0 0 10 10"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M9.73146 0.268539C9.37341 -0.0895129 8.83633 -0.0895129 8.47828 0.268539L5 3.74682L1.52172 0.268539C1.16367 -0.0895129 0.62659 -0.0895129 0.268539 0.268539C-0.0895129 0.62659 -0.0895129 1.16367 0.268539 1.52172L3.74682 5L0.268539 8.47828C-0.0895129 8.83633 -0.0895129 9.37341 0.268539 9.73146C0.447565 9.91049 0.62659 10 0.895129 10C1.16367 10 1.34269 9.91049 1.52172 9.73146L5 6.25318L8.47828 9.73146C8.65731 9.91049 8.92585 10 9.10487 10C9.2839 10 9.55244 9.91049 9.73146 9.73146C10.0895 9.37341 10.0895 8.83633 9.73146 8.47828L6.25318 5L9.73146 1.52172C10.0895 1.16367 10.0895 0.62659 9.73146 0.268539Z"
					fill="#46535D"
				/>
			</svg>
		</div>
	);
};

export default CloseIcon;
