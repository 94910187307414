import { Map, fromJS } from "immutable";
import dashboardConstants from "../constants/dashboardConstants";

/**
 * Dashboard reducer redux
 */

const initialState = Map({
	action: false,
	request: false,
	salesPackageList: Map(),
	salesPackageMeta: Map(),
	salesPackage: Map({}),
	stats: null,
	tempSalesPackage: Map({
		modelLink: "",
		initPackageLOD: true,
		customer: null,
		packageName: "",
		imageData: null,
		buildingType: null,
		imageURL: null,
		inputFormat: null,
		deliveryFormat: null,
		exports: null,
		lodSpecification: null,
		file: null,
		categories: null,
		summary: "",
	}),
	successMessage: "",
	errorMessage: "Something went wrong",
});

export default (state = initialState, action) => {
	const setTempPackageInformation = (salesPackage) => {
		const editedExports = [];
		const exports = [];
		const categories = [];
		const deliveryFormats = [];
		const inputFormats = [];
		if (!!salesPackage) {
			const {
				packageExports,
				packageDeliveryFormats,
				packageInputFormats,
				packageCategories,
			} = salesPackage;

			packageExports &&
				packageExports.map((item) => {
					if (item?.export.id) return exports.push(item?.export.id);

					return "";
				});
			packageExports &&
				packageExports.map((item) => {
					const exportChildren = item?.export?.children;
					return editedExports.push({
						exportId:
							exportChildren && exportChildren?.length > 0
								? exportChildren[0]?.id
								: item?.export?.id,
						id: item?.export?.id,
						price: item?.export?.price,
						valid: true,
					});
				});
			packageDeliveryFormats &&
				packageDeliveryFormats.map((item) => {
					if (item?.deliveryFormat.id)
						return deliveryFormats.push(item?.deliveryFormat.id);

					return "";
				});
			packageInputFormats &&
				packageInputFormats.map((item) => {
					if (item?.inputFormat.id)
						return inputFormats.push(item?.inputFormat.id);

					return "";
				});
			packageCategories &&
				packageCategories.map((item) => {
					if (item?.packageCategory.id)
						return categories.push(item?.packageCategory.id);

					return "";
				});
		}

		return state
			.setIn(
				["tempSalesPackage", "modelLink"],
				salesPackage?.threeHundredSixtyUrl || ""
			)
			.setIn(["tempSalesPackage", "packageName"], salesPackage?.name || "")
			.setIn(
				["tempSalesPackage", "customer"],
				salesPackage?.company?.id || null
			)
			.setIn(["tempSalesPackage", "imageData"], null)
			.setIn(
				["tempSalesPackage", "buildingType"],
				salesPackage?.buildingType?.id || null
			)
			.setIn(
				["tempSalesPackage", "imageURL"],
				salesPackage?.previewLink || null
			)
			.setIn(
				["tempSalesPackage", "inputFormat"],
				inputFormats.length > 0 ? inputFormats : null
			)
			.setIn(
				["tempSalesPackage", "deliveryFormat"],
				deliveryFormats.length > 0 ? deliveryFormats : null
			)
			.setIn(
				["tempSalesPackage", "lodSpecification"],
				salesPackage?.lodSpecification?.id || null
			)
			.setIn(["tempSalesPackage", "summary"], salesPackage?.summary || null)
			.setIn(
				["tempSalesPackage", "exports"],
				exports.length > 0 ? exports : null
			)
			.setIn(["tempSalesPackage", "editedExports"], fromJS(editedExports || []))
			.setIn(["tempSalesPackage", "initialEditedExports"], editedExports || [])
			.setIn(
				["tempSalesPackage", "categories"],
				categories.length > 0 ? categories : null
			)
			.setIn(["tempSalesPackage", "file"], salesPackage?.file || null)
			.setIn(
				["tempSalesPackage", "modelFile"],
				fromJS(salesPackage?.revitModel) || null
			);
	};

	const setTempExports = (data) => {
		const { id, exportId, isSelected } = data;

		const exports =
			state.getIn(["tempSalesPackage", "editedExports"])?.toJS() || [];
		const findIndex = exports.findIndex((item) => item.id === id);

		if (findIndex !== -1) {
			if (!isSelected) {
				const tempFilterExports = exports.filter((item) => +item.id !== +id);

				return state.setIn(
					["tempSalesPackage", "editedExports"],
					fromJS(tempFilterExports)
				);
			} else {
				return state
					.setIn(
						["tempSalesPackage", "editedExports", findIndex, "exportId"],
						exportId
					)
					.setIn(
						["tempSalesPackage", "editedExports", findIndex, "valid"],
						exportId ? true : false
					);
			}
		}

		let tempExports = [
			{
				id: id,
				exportId: exportId,
				price: null,
				valid: exportId ? true : false,
			},
		];
		if (exports.length > 0) {
			tempExports = tempExports.concat(exports);
		}

		return state.setIn(
			["tempSalesPackage", "editedExports"],
			fromJS(tempExports)
		);
	};

	switch (action.type) {
		case dashboardConstants.GET_SALES_PACKAGES:
			return state
				.set("salesPackageList", fromJS(action.data?.result))
				.set("salesPackageTotal", fromJS(action.data?.total))
				.set("salesPackage", Map({}));

		case dashboardConstants.SET_TEMP_FIELD_VALUE:
			const { fieldKey, value } = action.data;
			return state.setIn(["tempSalesPackage", fieldKey], fromJS(value));

		case dashboardConstants.SET_TEMP_IMAGE:
			return state.setIn(["tempSalesPackage", "imageData"], action.data);

		case dashboardConstants.SET_TEMP_IMAGE_URL:
			return state.setIn(["tempSalesPackage", "imageURL"], action.data);

		case dashboardConstants.SET_TEMP_FILE:
			return state.setIn(["tempSalesPackage", "file"], fromJS(action.data));

		case dashboardConstants.SET_TEMP_MODEL_FILE:
			return state.setIn(
				["tempSalesPackage", "modelFile"],
				fromJS(action.data)
			);

		case dashboardConstants.GET_SINGLE_PACKAGE:
			return state.set("salesPackage", fromJS(action.data));

		case dashboardConstants.GET_PACKAGE_STATS:
			return state.set("stats", fromJS(action.data.result));

		case dashboardConstants.SET_TEMP_SALES_PACKAGE:
			return setTempPackageInformation(action.data);

		case dashboardConstants.SET_TEMP_EXPORTS:
			return setTempExports(action.data);

		case dashboardConstants.RE_CONFIGURATION_PACKAGE:
			return state.setIn(
				["salesPackage", "packageInputFormats"],
				fromJS(action.data)
			);

		case dashboardConstants.CLEAR_TEMP_PACKAGE:
			return state.set(
				"tempSalesPackage",
				initialState.get("tempSalesPackage")
			);

		case dashboardConstants.SET_CANCEL_FORM_ORDER:
			return state.setIn(["salesPackage", "cancelFormOrder"], action.data);

		case dashboardConstants.CLEAR_DASHBAORD_REQUEST_STATE:
			return state
				.set("action", false)
				.set("request", false)
				.set("errorMessage", "")
				.set("successMessage", "");

		case dashboardConstants.DASHBOARD_ACTION_FAILURE:
			return state
				.set("action", false)
				.set("request", true)
				.set("errorMessage", fromJS(action.data));

		case dashboardConstants.DASHBOARD_ACTION_SUCCESS:
			return state
				.set("successMessage", fromJS(action.data))
				.set("action", true)
				.set("request", true);

		default:
			return state;
	}
};
