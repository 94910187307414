import React from "react";

const NormalizeIcon = (props) => {
	return (
		<div className={`icon ${props.iconClass ? props.iconClass : ""}`}>
			<svg
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M4.5 10.75C3.79688 10.75 3.25 11.3359 3.25 12C3.25 12.7031 3.79688 13.25 4.5 13.25C5.16406 13.25 5.75 12.7031 5.75 12C5.75 11.3359 5.16406 10.75 4.5 10.75ZM10.75 12C10.75 12.7031 11.2969 13.25 12 13.25C12.6641 13.25 13.25 12.7031 13.25 12C13.25 11.3359 12.6641 10.75 12 10.75C11.2969 10.75 10.75 11.3359 10.75 12ZM11.3359 9.22656C11.4141 9.34375 11.6484 9.5 12 9.5C12.3125 9.5 12.5469 9.34375 12.625 9.22656L15.4375 6.41406C15.6328 6.25781 15.75 6.02344 15.75 5.75C15.75 5.24219 15.2812 4.8125 14.8125 4.8125C14.5391 4.8125 14.3047 4.92969 14.1484 5.125L12.9375 6.33594V2.9375C12.9375 2.42969 12.5078 2 12 2C11.4531 2 11.0625 2.42969 11.0625 2.9375V6.33594L9.8125 5.125C9.65625 4.92969 9.42188 4.8125 9.1875 4.8125C8.64062 4.8125 8.25 5.28125 8.25 5.75C8.25 6.02344 8.32812 6.25781 8.52344 6.41406L11.3359 9.22656ZM15.75 10.75C15.0469 10.75 14.5 11.3359 14.5 12C14.5 12.7031 15.0469 13.25 15.75 13.25C16.4141 13.25 17 12.7031 17 12C17 11.3359 16.4141 10.75 15.75 10.75ZM19.5 10.75C18.7969 10.75 18.25 11.3359 18.25 12C18.25 12.7031 18.7969 13.25 19.5 13.25C20.1641 13.25 20.75 12.7031 20.75 12C20.75 11.3359 20.1641 10.75 19.5 10.75ZM12.625 14.7734C12.3906 14.5391 12.1172 14.5 12 14.5C11.8438 14.5 11.5703 14.5391 11.3359 14.8125L8.52344 17.625C8.32812 17.7812 8.25 18.0156 8.25 18.25C8.25 18.7969 8.67969 19.1875 9.1875 19.1875C9.42188 19.1875 9.65625 19.1094 9.8125 18.9141L11.0625 17.7031V21.0625C11.0625 21.6094 11.4531 22 12 22C12.5078 22 12.9375 21.6094 12.9375 21.0625V17.7031L14.1484 18.9141C14.3047 19.1094 14.5391 19.1875 14.8125 19.1875C15.5156 19.1875 15.75 18.5234 15.75 18.25C15.75 18.0156 15.6328 17.7812 15.4375 17.625L12.625 14.7734ZM9.5 12C9.5 11.3359 8.91406 10.75 8.25 10.75C7.54688 10.75 7 11.3359 7 12C7 12.7031 7.54688 13.25 8.25 13.25C8.91406 13.25 9.5 12.7031 9.5 12Z"
					fill="black"
				/>
			</svg>
		</div>
	);
};

export default NormalizeIcon;
