import React from "react";

const OtherIcon = (props) => {
	return (
		<div className={`icon ${props.iconClass}`}>
			<svg
				width="40"
				height="40"
				viewBox="0 0 40 40"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<g clipPath="url(#clip0_336_454)">
					<path
						d="M19.9993 4.39792L2.3918 11.2264V35.6014H37.6075V11.2264L19.9993 4.39792Z"
						fill="#FFC350"
					/>
					<path
						d="M37.6072 36.0002H2.39211C2.28785 36.0002 2.18786 35.9582 2.11414 35.8833C2.04042 35.8084 1.999 35.7069 1.999 35.601V11.2267C1.99902 11.146 2.02314 11.0671 2.06817 11.0006C2.11321 10.9341 2.17704 10.883 2.25125 10.8541L19.8588 4.02686C19.9489 3.99202 20.0485 3.99202 20.1386 4.02686L37.7474 10.8541C37.8217 10.8829 37.8857 10.9339 37.9309 11.0005C37.976 11.067 38.0002 11.1459 38.0003 11.2267V35.6023C38 35.708 37.9584 35.8092 37.8847 35.8838C37.811 35.9584 37.7112 36.0002 37.6072 36.0002ZM2.78392 35.2017H37.2154V11.5008L19.999 4.82537L2.78392 11.5008V35.2017Z"
						fill="#EE6712"
					/>
					<path
						d="M19.9993 30.7219C24.9755 30.7219 29.0095 26.6249 29.0095 21.5711C29.0095 16.5172 24.9755 12.4202 19.9993 12.4202C15.0231 12.4202 10.9892 16.5172 10.9892 21.5711C10.9892 26.6249 15.0231 30.7219 19.9993 30.7219Z"
						fill="white"
					/>
					<path
						d="M19.9993 31.1192C18.1398 31.119 16.3221 30.5589 14.776 29.5095C13.2299 28.4602 12.0249 26.9688 11.3135 25.2239C10.602 23.4791 10.4159 21.5591 10.7788 19.7069C11.1417 17.8546 12.0373 16.1532 13.3523 14.8179C14.6672 13.4826 16.3426 12.5733 18.1664 12.205C19.9902 11.8367 21.8806 12.0259 23.5986 12.7487C25.3165 13.4716 26.7848 14.6956 27.8178 16.2659C28.8508 17.8363 29.4021 19.6825 29.4019 21.5711C29.399 24.1027 28.4074 26.5297 26.6446 28.3197C24.8819 30.1097 22.492 31.1165 19.9993 31.1192ZM19.9993 12.8201C18.2949 12.8201 16.6287 13.3335 15.2115 14.2952C13.7943 15.257 12.6897 16.624 12.0375 18.2233C11.3853 19.8227 11.2147 21.5825 11.5473 23.2804C11.8799 24.9782 12.7008 26.5377 13.9062 27.7616C15.1115 28.9856 16.6471 29.8191 18.3189 30.1566C19.9907 30.4942 21.7234 30.3207 23.2981 29.658C24.8727 28.9954 26.2185 27.8734 27.1653 26.4339C28.1121 24.9944 28.6173 23.3021 28.617 21.5711C28.6146 19.2505 27.7059 17.0257 26.0903 15.3848C24.4747 13.7438 22.2842 12.8208 19.9993 12.8181V12.8201Z"
						fill="#EE6712"
					/>
					<path
						d="M18.9327 23.6678C18.929 23.3981 18.9632 23.1292 19.0342 22.8693C19.0962 22.6461 19.2018 22.4379 19.3448 22.2571C19.4563 22.1131 19.5802 21.9795 19.715 21.8579C19.8224 21.7647 19.9716 21.6494 20.1625 21.5118C20.3534 21.3743 20.4966 21.2634 20.5923 21.1791C20.7033 21.0744 20.7961 20.9513 20.8668 20.8151C20.9551 20.6541 21.0003 20.4724 20.9978 20.2881C21.0034 20.1418 20.9774 19.9961 20.9218 19.861C20.8661 19.726 20.782 19.6049 20.6755 19.5063C20.4641 19.3097 20.1863 19.2034 19.8997 19.2095C19.6064 19.206 19.3218 19.3115 19.0998 19.5063C18.9842 19.6073 18.8921 19.7331 18.8301 19.8746C18.7682 20.0162 18.7379 20.1699 18.7414 20.3247H17.1034C17.099 19.7939 17.2485 19.2734 17.5332 18.8282C17.7872 18.4252 18.1481 18.1032 18.5743 17.8993C18.9936 17.7043 19.4498 17.6051 19.9109 17.6085C20.6327 17.5918 21.3344 17.8514 21.8765 18.3358C22.1473 18.5786 22.3612 18.8798 22.5027 19.2173C22.6442 19.5549 22.7096 19.9203 22.6941 20.2868C22.6974 20.5362 22.6674 20.7849 22.605 21.0261C22.5482 21.2385 22.4487 21.4368 22.3128 21.6083C22.2064 21.7455 22.0906 21.875 21.9662 21.9956C21.871 22.0843 21.7317 22.2056 21.5482 22.3596C21.3495 22.5131 21.2025 22.6342 21.1073 22.7229C20.9939 22.8361 20.8992 22.9671 20.8268 23.1108C20.7348 23.2817 20.6874 23.4737 20.6893 23.6685V23.9346H18.9327V23.6678ZM18.8849 24.6859H20.7345V26.5763H18.8849V24.6859Z"
						fill="#EE6712"
					/>
				</g>
				<defs>
					<clipPath id="clip0_336_454">
						<rect
							width="36"
							height="32"
							fill="white"
							transform="translate(2 4)"
						/>
					</clipPath>
				</defs>
			</svg>
		</div>
	);
};

export default OtherIcon;
