import { Map, List, fromJS } from "immutable";
import authConstants from "../constants/authConstants";

/**
 * Authentication reducer redux
 */

const initialState = Map({
	action: false,
	request: false,
	loggedIn: null,
	loggingIn: false,
	loggingError: List(),
	user: Map(),
	resetPasswordEmail: "",
	resetPasswordSuccess: false,
	redirectToPath: null,
	errorResponse: "",
	errorMessage: "Something went wrong",
});

export default (state = initialState, action) => {
	switch (action.type) {
		case authConstants.LOGIN_REQUEST:
			return Map({ loggingIn: true, user: action.user });

		case authConstants.LOGIN_SUCCESS:
			return Map({ loggedIn: true, user: action.user });

		case authConstants.LOGIN_FAILURE:
			return Map({ loggedIn: false, loggingError: action.error });

		case authConstants.CLEAR_RESET_PASSWORD:
			return Map({
				resetPasswordEmail: "",
				resetPasswordSuccess: false,
			});

		case authConstants.RESET_PASSWORD_SUCCESS:
			return Map({
				resetPasswordEmail: action.email,
				resetPasswordSuccess: true,
			});

		case authConstants.SET_USER_AUTH:
			const { user, permissions } = action.data;
			return state
				.set("user", fromJS(user))
				.setIn(["user", "permissions"], fromJS(permissions));

		case authConstants.SET_PERMISSIONS:
			return state.setIn(
				["user", "permissions"],
				fromJS(action.data?.permissions || null)
			);

		case authConstants.REDIRECT_TO_PATH:
			return state.set("redirectToPath", fromJS(action.path));

		case authConstants.ACTION_SUCCESS:
			return state.set("action", true).set("request", true);

		case authConstants.ACTION_FAILURE:
			return state
				.set("action", false)
				.set("request", true)
				.set("errorResponse", fromJS(action.error));

		case authConstants.CLEAR_AUTH_REQUEST_STATE:
			return state.set("action", false).set("request", false);

		default:
			return state;
	}
};
