import { Map, fromJS } from "immutable";
import { familyConstants } from "../constants/familyConstants";

/**
 * Family reducer redux
 */
const initialState = Map({
	familiesList: Map(),
	familiesMeta: Map(),
	family: Map(),
	tempFamily: Map({
		name: "",
		disciplineComponentCategory: "",
	}),
	action: false,
	request: false,
	errorResponse: "",
	stats: null,
	successMessage: "Success!",
	errorMessage: "Something went wrong",
});

export default (state = initialState, action) => {
	/**
	 * Set temp family data
	 *
	 * @param {object} data - family data
	 *
	 */
	const setTempFamily = (data) => {
		const tempFamily = {
			id: data.id,
			name: data.name,
			disciplineComponentCategory: data.disciplineComponentCategory?.id || "",
		};
		return state.set("tempFamily", fromJS(tempFamily));
	};

	/**
	 * Check family activity
	 *
	 * @param {object} data - family symbol data
	 *
	 */
	const checkFamilyActivity = (data) => {
		const { files } = data;

		return state.setIn(
			["family", "familyActivity"],
			files?.length > 0 ? true : false
		);
	};
	switch (action.type) {
		case familyConstants.GET_FAMILIES:
			return state
				.set("familiesList", fromJS(action.data.result))
				.set("familiesMeta", fromJS(action.data.meta));

		case familyConstants.GET_FAMILY:
			return state.set("family", fromJS(action.data));

		case familyConstants.SET_TEMP_FAMILY:
			return setTempFamily(action.data);

		case familyConstants.UPDATE_TEMP_FIELD_FAMILY:
			const { fieldKey, value } = action.data;

			return state.setIn(["tempFamily", fieldKey], value);

		case familyConstants.CLEAR_TEMP_FAMILY:
			return state.set("tempFamily", initialState.get("tempFamily"));

		case familyConstants.CLEAR_SINGLE_FAMILY:
			return state.set("family", initialState.get("family"));

		case familyConstants.CHECK_FAMILY_ACTIVITY:
			return checkFamilyActivity(action.data);

		case familyConstants.CLEAR_FAMILY_REQUEST_STATE:
			return state
				.set("action", false)
				.set("request", false)
				.set("errorMessage", "")
				.set("successMessage", "");

		case familyConstants.FAMILY_ACTION_GET_SUCCESS:
			return state
				.set("successMessage", fromJS(action.data))
				.set("action", true)
				.set("request", true);

		case familyConstants.FAMILY_ACTION_FAILURE:
			return state
				.set("action", false)
				.set("request", true)
				.set("errorMessage", fromJS(action.data));

		default:
			return state;
	}
};
