import React, { useEffect } from "react";
import SymbolForm from "./SymbolForm";
import {
	getSymbol,
	getSymbolFileUploadUrl,
	updateSymbol,
} from "../../../../../actions";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom-v5-compat";

const EditSymbol = (props) => {
	const { file, updateSymbol, getSymbolFileUploadUrl, getSymbol } = props;
	const { symbolId } = useParams();
	const navigate = useNavigate();

	useEffect(() => {
		getSymbol(symbolId);
	}, [symbolId, getSymbol]);

	const onCancel = () => {
		navigate(-1);
	};

	const onSubmit = (data) => {
		updateSymbol(symbolId, data, () => {
			if (!!file) {
				getSymbolFileUploadUrl(symbolId, file, () => {
					onCancel();
				});
			} else {
				onCancel();
			}
		});
	};

	return (
		<SymbolForm
			formName="editSymbolForm"
			onSubmit={onSubmit}
			onCancel={onCancel}
			editView={true}
		/>
	);
};

const mapStateToProps = (state) => {
	return {
		file: state.getIn(["symbol", "tempSymbol", "fileData"]),
		tempSymbol: state.getIn(["symbol", "tempSymbol"]),
	};
};
export default connect(mapStateToProps, {
	updateSymbol,
	getSymbolFileUploadUrl,
	getSymbol,
})(EditSymbol);
