import { globalConstants } from "../constants/globalConstants";
import {
	removeItemFromLocalStorage,
	setItemToLocalStorage,
} from "../utils/local-storage.util";

/**
 * SET sleceted rows in global state
 *
 * @param {Array} selectedRows
 * @param {string} view - name of the list
 *
 */
export const setSelectedRows = ({ selectedRows, view }) => {
	return (dispatch) => {
		dispatch({
			type: globalConstants.SET_SELECTED_ROWS,
			data: { selectedRows: selectedRows, view: view },
		});
	};
};

/**
 * SET global CUSTOMER in state and local storage
 * @param {*} data
 * @returns
 */
export const setCustomer = (data) => {
	return (dispatch) => {
		if (!data) {
			removeItemFromLocalStorage("Customer");
		} else {
			setItemToLocalStorage("Customer", data);
		}

		dispatch({
			type: globalConstants.SET_CUSTOMER,
			data,
		});
	};
};

/**
 * SET room for global customer chat
 * @param {*} data
 * @returns
 */
export const setRoom = (data) => {
	return (dispatch) => {
		dispatch({
			type: globalConstants.SET_ROOM,
			data,
		});
	};
};
