import React from "react";

export default (props) => {
	return (
		<div className={`icon ${props.iconClass}`}>
			<svg
				width="7"
				height="12"
				viewBox="0 0 7 12"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M6.70703 0.293C6.5195 0.105529 6.26519 0.000213623 6.00003 0.000213623C5.73487 0.000213623 5.48056 0.105529 5.29303 0.293L0.293031 5.293C0.105559 5.48053 0.000244141 5.73484 0.000244141 6C0.000244141 6.26516 0.105559 6.51947 0.293031 6.707L5.29303 11.707C5.48163 11.8892 5.73423 11.99 5.99643 11.9877C6.25863 11.9854 6.50944 11.8802 6.69485 11.6948C6.88026 11.5094 6.98543 11.2586 6.9877 10.9964C6.98998 10.7342 6.88919 10.4816 6.70703 10.293L2.41403 6L6.70703 1.707C6.8945 1.51947 6.99982 1.26516 6.99982 1C6.99982 0.734836 6.8945 0.480528 6.70703 0.293Z"
					fill="#7F909F"
				/>
			</svg>
		</div>
	);
};
