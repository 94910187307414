import React, { useEffect, useMemo } from "react";

import { connect } from "react-redux";
import localize, { LocKeys } from "../../../../../../constants/localizations";
import { Field } from "redux-form/immutable";
import renderTextField from "../../../../../fields/renderTextField";
import {
	getDisciplines,
	updateTempFieldFamily,
} from "../../../../../../actions";
import CustomAutocomplete from "../../../../../fields/CustomAutocomplete";

const BodyFamilyForm = (props) => {
	const { disciplines, initialValues, updateTempFieldFamily, getDisciplines } =
		props;

	useEffect(() => {
		getDisciplines({});
	}, [getDisciplines]);

	const setValue = (fieldKey, value) => {
		updateTempFieldFamily(fieldKey, value);
	};

	const disciplineCategoryOptions = useMemo(() => {
		const options = [];
		if (disciplines && disciplines.size > 0) {
			disciplines.map((discipline, i) => {
				const children = discipline.get("disciplineComponentCategories");

				let subHeader = (
					<div
						className={`dropdown-subheader body-font--small  semi  ${+i === 0 ? "" : "dropdown-subheader--border"}`}
					>
						<div className="d-flex align-items-center w-100">
							{discipline.get("type")}
						</div>
					</div>
				);

				children &&
					children.map((item) => {
						return options.push({
							subHeader,
							label: item.getIn(["componentCategory", "type"]),
							value: item.get("id"),
						});
					});

				return options;
			});

			return options;
		}

		return options;
	}, [disciplines]);

	return (
		<div className="row row--m-gutters">
			<div className="col col-desktop-50 col-100">
				<Field
					name="name"
					id="name"
					component={renderTextField}
					classField="mb-regular"
					label={localize(LocKeys.NAME)}
					placeholder={localize(LocKeys.ENTER_NAME).concat("....")}
					onKeyDown={(value) => setValue("name", value)}
				/>
			</div>
			<div className="col col-desktop-50 col-100">
				<Field
					name="disciplineComponentCategory"
					id="disciplineComponentCategory"
					component={CustomAutocomplete}
					classField="mb-regular"
					groupBy={true}
					disableClearable={true}
					initialValue={
						initialValues?.get("disciplineComponentCategory") || null
					}
					label={localize(LocKeys.COMPONENT)}
					placeholder={localize(LocKeys.SELECT_COMPONENT).concat("....")}
					dropdownHeight="400px"
					options={disciplineCategoryOptions}
					customOnChange={(value) =>
						setValue("disciplineComponentCategory", value)
					}
				/>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		disciplines: state.getIn(["administration", "disciplines"]),
	};
};

export default connect(mapStateToProps, {
	updateTempFieldFamily,
	getDisciplines,
})(BodyFamilyForm);
