import React from "react";

export default (props) => {
	return (
		<span className={`icon ${props.iconClass ? props.iconClass : ""}`}>
			<svg
				width="18"
				height="16"
				viewBox="0 0 18 16"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M17.8995 14.8551L9.6488 0.353096C9.57755 0.244469 9.48106 0.155411 9.36788 0.0938182C9.25471 0.0322251 9.12834 0 9 0C8.87165 0 8.74529 0.0322251 8.63212 0.0938182C8.51894 0.155411 8.42245 0.244469 8.35119 0.353096L0.10049 14.8551C0.0346904 14.9711 3.38101e-05 15.1026 2.47256e-08 15.2365C-3.37606e-05 15.3705 0.0345564 15.502 0.100297 15.618C0.166038 15.734 0.260615 15.8304 0.374533 15.8974C0.48845 15.9645 0.617697 15.9999 0.749295 16H17.2507C17.3823 15.9999 17.5116 15.9645 17.6255 15.8974C17.7394 15.8304 17.834 15.734 17.8997 15.618C17.9654 15.502 18 15.3705 18 15.2365C18 15.1026 17.9653 14.9711 17.8995 14.8551ZM9.90458 5.31431L9.5498 10.3518H8.4502L8.11567 5.31431H9.90458ZM9 13.7102C8.78237 13.7102 8.56963 13.6445 8.38868 13.5215C8.20773 13.3985 8.0667 13.2236 7.98341 13.019C7.90013 12.8144 7.87834 12.5893 7.9208 12.3721C7.96325 12.1549 8.06805 11.9553 8.22194 11.7987C8.37582 11.6422 8.57189 11.5355 8.78533 11.4923C8.99878 11.4491 9.22002 11.4713 9.42108 11.556C9.62214 11.6408 9.79399 11.7843 9.9149 11.9684C10.0358 12.1526 10.1003 12.369 10.1003 12.5905C10.1003 12.8875 9.98441 13.1723 9.77806 13.3823C9.57171 13.5922 9.29183 13.7102 9 13.7102Z"
					fill="#F7CF00"
				/>
			</svg>
		</span>
	);
};
