import { Map } from "immutable";
import textEditorConstants from "../constants/textEditorConstants";

/**
 * Text editor redux
 */

const initialState = Map({
	file: Map({
		url: null,
		type: null,
	}),
});

export default (state = initialState, action) => {
	switch (action.type) {
		case textEditorConstants.SET_FILE_DATA:
			const { urlLink, type } = action.data;
			return state
				.setIn(["file", "url"], urlLink)
				.setIn(["file", "type"], type);

		case textEditorConstants.CLEAR_STATE:
			return initialState;
		default:
			return state;
	}
};
