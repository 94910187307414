import { Map } from "immutable";
import queryParameterConstants from "../constants/queryParameterConstants";

/**
 * Invoice reducer redux
 */

const initialState = Map({
	jobQueryParameter: Map({
		page: 0,
		search: "",
		status: [],
		types: [],
		offers: [],
	}),
	offerQueryParameter: Map({
		page: 0,
		search: "",
		status: [],
		types: [],
	}),
	customerQueryParameter: Map({
		page: 0,
		search: "",
		status: [],
		types: [],
		countries: [],
	}),
	userQueryParameter: Map({
		page: 0,
		search: "",
		status: [],
		roles: [],
	}),
	symbolQueryParameter: Map({
		page: 0,
		search: "",
		status: [],
		components: [],
		family: [],
	}),
	ticketQueryParameter: Map({
		page: 0,
		search: "",
		status: [],
		priority: [],
		offers: [],
		jobs: [],
		buildings: [],
	}),
	buildingQueryParameter: Map({
		page: 0,
		search: "",
		status: [],
		types: [],
		offers: [],
	}),
	revitTemplateQueryParameter: Map({
		page: 0,
		search: "",
		status: [],
		customers: [],
	}),
});

//

export default (state = initialState, action) => {
	switch (action.type) {
		case queryParameterConstants.JOB_QUERY_PARAMETER:
			return state.set("jobQueryParameter", action.data);
		case queryParameterConstants.OFFER_QUERY_PARAMETER:
			return state.set("offerQueryParameter", action.data);
		case queryParameterConstants.CUSTOMER_QUERY_PARAMETER:
			return state.set("customerQueryParameter", action.data);
		case queryParameterConstants.USER_QUERY_PARAMETER:
			return state.set("userQueryParameter", action.data);
		case queryParameterConstants.SYMBOL_QUERY_PARAMETER:
			return state.set("symbolQueryParameter", action.data);
		case queryParameterConstants.TICKET_QUERY_PARAMETER:
			return state.set("ticketQueryParameter", action.data);
		case queryParameterConstants.BUILDING_QUERY_PARAMETER:
			return state.set("buildingQueryParameter", action.data);
		case queryParameterConstants.CLEAR_OFFER_QUERY_PARAMETER:
			return state.set(
				"offerQueryParameter",
				initialState.get("offerQueryParameter")
			);
		case queryParameterConstants.CLEAR_SYMBOL_QUERY_PARAMETER:
			return state.set(
				"symbolQueryParameter",
				initialState.get("symbolQueryParameter")
			);

		case queryParameterConstants.CLEAR_BUILDING_QUERY_PARAMETER:
			return state.set(
				"buildingQueryParameter",
				initialState.get("buildingQueryParameter")
			);

		case queryParameterConstants.REVIT_TEMPLATE_QUERY_PARAMETER:
			return state.set("revitTemplateQueryParameter", action.data);

		case queryParameterConstants.CLEAR_REVIT_TEMPLATE_QUERY_PARAMETER:
			return state.set(
				"revitTemplateQueryParameter",
				initialState.get("revitTemplateQueryParameter")
			);

		default:
			return state;
	}
};
