import React from "react";

export default (props) => {
	return (
		<div className={`icon ${props.iconClass ? props.iconClass : ""}`}>
			<svg
				width="32"
				height="32"
				viewBox="0 0 32 32"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M23.3346 14.6668V5.3335H8.66797V14.6668H2.66797V26.6668H14.668H17.3346H29.3346V14.6668H23.3346ZM11.3346 8.00016H20.668V14.6668H17.3346H14.668H11.3346V8.00016ZM14.668 24.0002H5.33464V17.3335H8.66797H14.668V24.0002ZM26.668 24.0002H17.3346V17.3335H23.3346H26.668V24.0002Z"
					fill="#1B2024"
				/>
			</svg>
		</div>
	);
};
