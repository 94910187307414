import React from "react";
import FileIcon from "../../shared/icons/files/FileIcon";

import IconWrapper from "../../shared/icons/IconWrapper";
import { isIterable } from "../../utils/object.util";

const FileList = ({ files = [], handleRemove }) => {
	const removeFile = (key) => handleRemove && handleRemove(key);

	if (isIterable(files)) {
		return (
			<div className="col col-100 mb-24 flex-column">
				{files.map((file, key) => {
					return (
						<div key={key} className="row row--xs-gutters mb-24">
							<div className="col col-90">
								<IconWrapper
									wrapperClasses="mr-s"
									icon={<FileIcon className="d-flex" />}
								/>
								{file.name}
							</div>
							<div className="col col-10 justify-content-end">
								{!file.id && (
									<button
										type="button"
										onClick={() => removeFile(key)}
										className="btn btn--stripped btn--close btn--icon btn--icon--before"
									></button>
								)}
							</div>
						</div>
					);
				})}
			</div>
		);
	}
	return "";
};

export default FileList;
