import React, { useCallback, useEffect, useState } from "react";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import { ClearEditorPlugin } from "@lexical/react/LexicalClearEditorPlugin";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { MarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin";
import { TRANSFORMERS } from "@lexical/markdown";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { TablePlugin } from "@lexical/react/LexicalTablePlugin";
import "./assets/styles/editor.scss";
import { editorNodes } from "./nodes/nodes";
import {
	TableContext,
	// TablePlugin as CustomTablePlugin,
} from "./plugins/TablePlugin";
import TableCellActionMenuPlugin from "./plugins/TableActionMenuPlugin";
import AutoLinkPlugin from "./plugins/AutoLinkPlugin";
import ImagesPlugin from "./plugins/ImagesPlugin";
import Toolbar from "./Toolbar";
import EditorTheme from "./themes/EditorTheme";
import TableCellResizer from "./plugins/TableCellResizer";
import CustomLabel from "../../../../components/fields/CustomLabel";
import { $generateHtmlFromNodes, $generateNodesFromDOM } from "@lexical/html";
import { $getRoot, $insertNodes } from "lexical";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import VideoPlugin from "./plugins/VideoPlugin";
import SubmitPlugin from "./plugins/SubmitPlugin";

const Placeholder = ({ placeholder }) => {
	return <div className="Placeholder__root">{placeholder}</div>;
};
const SetEditorPlugin = ({ setEditor }) => {
	const [editor] = useLexicalComposerContext();

	useEffect(() => {
		try {
			if (!setEditor) return;
			setEditor(editor);
		} catch (error) {
			console.log(error);
			return;
		}
	}, [editor, setEditor]);

	return null;
};
const LexicalEditor = ({
	scrollerClasses = "",
	wrapperEditorClasses = "",
	onChange,
	updateStateOnEnter = null,
	getHTMLData = null,
	editorState,
	editable = true,
	label = false,
	placeholder = "Enter some rich text...",
	convertFromHTML = false,
	required = true,
	hideTableAndImage = false,
	hideQuoteAndCode = true,
}) => {
	const [editor, setEditor] = useState(null);

	useEffect(() => {
		try {
			if (editor) {
				if (!convertFromHTML) {
					// editor.update(() => {
					// 	const initialEditorState = editor.parseEditorState(editorState);
					// 	editor.setEditorState(initialEditorState);
					// });
				} else {
					editor.update(() => {
						/* eslint-disable no-useless-escape */
						let newEditorState = editorState?.replace(/<br\s*[\/]?>/gi, "");
						// In the browser you can use the native DOMParser API to parse the HTML string.
						const parser = new DOMParser();
						const dom = parser.parseFromString(newEditorState, "text/html");

						// Once you have the DOM instance it's easy to generate LexicalNodes.
						const nodes = $generateNodesFromDOM(editor, dom);

						// Select the root
						$getRoot().select();

						// Insert them at a selection.
						$insertNodes(nodes);
					});
				}
			}
		} catch (error) {
			console.log(error);
			return;
		}
	}, [editorState, editor, convertFromHTML]);

	let editorConfig = {
		// The editor theme
		editable: editable,
		// editorState: !!editorState && !convertFromHTML && editorState,
		theme: EditorTheme,
		// Handling of errors during update
		onError(error) {
			console.log(error);
			//throw error;
		},
		// Any custom nodes go here
		nodes: [...editorNodes],
	};

	if (!convertFromHTML) {
		const parseContent =
			(editorState && JSON.parse(editorState)?.root?.children) || null;

		editorConfig["editorState"] =
			!!parseContent && !!parseContent?.length > 0 ? editorState : null;
	}

	// const tableConfig = {
	// 	namespace: "table",
	// 	editable: editable,
	// 	theme: EditorTheme,
	// 	// Handling of errors during update
	// 	onError(error) {
	// 		throw error;
	// 	},
	// 	nodes: [...tableNodes],
	// };

	const change = useCallback(
		(editorState, editor) => {
			try {
				if (!convertFromHTML) {
					if (!!getHTMLData) {
						editor.update(() => {
							try {
								let htmlString = $generateHtmlFromNodes(editor);

								getHTMLData && getHTMLData(htmlString);
							} catch (error) {
								console.error(error);
							}
						});
					}

					onChange && onChange(JSON.stringify(editorState), null);
				} else {
					editor.update(() => {
						try {
							const htmlString = $generateHtmlFromNodes(editor, null);

							onChange && onChange(htmlString);
						} catch (error) {
							console.error(error);
						}
					});
				}
			} catch (error) {
				console.log(error);
				return;
			}
		},
		[onChange, convertFromHTML, getHTMLData]
	);

	const [floatingAnchorElem, setFloatingAnchorElem] = useState(null);

	const onRef = (_floatingAnchorElem) => {
		if (_floatingAnchorElem !== null) {
			setFloatingAnchorElem(_floatingAnchorElem);
		}
	};

	return (
		<div className="w-100">
			{label && <CustomLabel label={label} required={required} />}
			<div className={`w-100 ${wrapperEditorClasses}`}>
				<LexicalComposer initialConfig={editorConfig}>
					<TableContext>
						<div className="LexicalEditor-shell">
							{editable && (
								<Toolbar
									hideTableAndImage={hideTableAndImage}
									hideQuoteAndCode={hideQuoteAndCode}
								/>
							)}
							<div className="editor-container">
								<AutoFocusPlugin />

								<AutoLinkPlugin />

								<RichTextPlugin
									contentEditable={
										<div
											className={` ${
												editable
													? `LexicalEditor__editor-scroller ${scrollerClasses}`
													: ""
											}`}
										>
											<div className="LexicalEditor__editor" ref={onRef}>
												<ContentEditable
													className={`ContentEditable__root  ${
														editable ? "" : "p-0"
													}  `}
												/>
											</div>
										</div>
									}
									placeholder={<Placeholder placeholder={placeholder} />}
									ErrorBoundary={LexicalErrorBoundary}
								/>
								<TablePlugin />
								<TableCellResizer editable={editable} />

								{/* <CustomTablePlugin cellEditorConfig={tableConfig}>
									<AutoFocusPlugin />
									<RichTextPlugin
										contentEditable={
											<ContentEditable
												className={"TableNode__contentEditable"}
											/>
										}
									/>

									<HistoryPlugin />
									<LinkPlugin />
									<ImagesPlugin />
									 
								</CustomTablePlugin> */}

								{updateStateOnEnter && (
									<>
										<SubmitPlugin onSubmit={updateStateOnEnter} />
										<ClearEditorPlugin />
									</>
								)}

								<SetEditorPlugin setEditor={setEditor} />
								<HistoryPlugin />
								<ListPlugin />
								<ImagesPlugin />
								<VideoPlugin />
								<LinkPlugin />
								{/* <YouTubePlugin /> */}
								<OnChangePlugin onChange={change} />
								<MarkdownShortcutPlugin transformers={TRANSFORMERS} />
								{floatingAnchorElem && editable && (
									<>
										{/* <DraggableBlockPlugin anchorElem={floatingAnchorElem} /> */}
										<TableCellActionMenuPlugin
											anchorElem={floatingAnchorElem}
											cellMerge={true}
										/>
									</>
								)}
							</div>
						</div>
					</TableContext>
				</LexicalComposer>
			</div>
		</div>
	);
};

export default LexicalEditor;
