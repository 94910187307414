import React from "react";

export default (props) => {
	return (
		<div className={`icon ${props.iconClass ? props.iconClass : ""}`}>
			<svg
				width="24"
				height="25"
				viewBox="0 0 24 25"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M21.8259 6.59668C21.7477 6.08845 21.3566 5.7366 20.8874 5.58022C20.379 5.46294 19.8707 5.61931 19.5578 5.93207L17.2507 8.23866L15.9602 8.04319L15.7647 6.75306L18.0719 4.48557C18.4238 4.13372 18.5411 3.62548 18.4238 3.15635C18.2674 2.68721 17.9154 2.29626 17.4071 2.17898C15.3346 1.67075 13.2229 2.25717 11.6979 3.78186C10.2119 5.22837 9.62534 7.33948 10.0946 9.37241L2.8994 16.5267C2.31283 17.1523 2 17.9732 2 18.7942C2 19.6152 2.31283 20.4362 2.8994 21.0617C3.44686 21.6481 4.34626 22 5.16745 22C5.98864 22 6.80983 21.6872 7.4355 21.0617L14.5916 13.9074C16.625 14.3765 18.7366 13.7901 20.2226 12.3045C21.7086 10.8189 22.3342 8.62961 21.8259 6.59668ZM18.8931 10.9753C17.7981 12.109 16.1558 12.5 14.6698 11.9918L14.1223 11.7963L6.14505 19.7325C5.6758 20.2407 4.7764 20.2407 4.30715 19.7325C4.07253 19.4979 3.91611 19.1852 3.91611 18.7942C3.91611 18.4424 4.07253 18.1296 4.30715 17.856L12.2844 9.91973L12.128 9.37241C11.5414 7.84771 11.8934 6.20574 13.0274 5.11108C13.8095 4.29009 14.8653 3.86005 15.9211 3.86005H15.9602L13.7704 6.12755L14.357 9.64607L17.8763 10.2716L20.1444 8.00409C20.1444 9.09874 19.7142 10.1934 18.8931 10.9753ZM5.40207 17.9341C5.05013 17.9341 4.81551 18.2078 4.81551 18.5597C4.81551 18.8724 5.08924 19.1852 5.40207 19.1852C5.71491 19.1852 5.98864 18.8724 5.98864 18.5597C6.02774 18.2469 5.75401 17.9341 5.40207 17.9341Z"
					fill="black"
				/>
			</svg>
		</div>
	);
};
