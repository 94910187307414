import React from "react";
import { sizeConstants } from "../../constants/constants";

const IconWrapper = ({
	icon,
	round = false,
	size = sizeConstants.SMALL,
	wrapperClasses = "",
}) => {
	if (!icon) {
		return "";
	}
	return (
		<div
			className={`
            icon-wrapper 
            icon-wrapper--${size}
            ${round ? "icon-wrapper--round" : ""}
            ${wrapperClasses}
        `}
		>
			{icon}
		</div>
	);
};

export default IconWrapper;
