import React, { useMemo } from "react";
import { Field } from "redux-form/immutable";
import localize, { LocKeys } from "../../../../../../constants/localizations";
import renderTextField from "../../../../../fields/renderTextField";
import CustomAutocomplete from "../../../../../fields/CustomAutocomplete";
import { updateTempRevitTemplateData } from "../../../../../../actions";
import { connect } from "react-redux";
import TemplateBody from "./TemplateBody";

const TemplateHeader = ({
	isSettingsView = false,
	editView = false,
	companies,
	initialValues,
	updateTempRevitTemplateData,
}) => {
	const setValue = (fieldKey, value) => {
		updateTempRevitTemplateData({ fieldKey, value });
	};

	const companyOptions = useMemo(() => {
		const options = [];

		companies &&
			companies.map((company) => {
				let value = company.get("id");
				let label = company.get("name");
				return options.push({ value, label });
			});
		return options;
	}, [companies]);

	return (
		<div className="row row--6p-gutters align-items-end">
			<div className="col col-33">
				<Field
					name="name"
					id="name"
					label={localize(LocKeys.NAME)}
					placeholder={localize(LocKeys.ENTER_NAME) + "..."}
					required={true}
					component={renderTextField}
					onKeyDown={(value) => setValue("name", value)}
					min="0"
					type="text"
					showPlaceholder={true}
				/>
			</div>
			{!isSettingsView && (
				<div className="col col-33">
					<Field
						name="customer"
						id="customer"
						component={CustomAutocomplete}
						label={localize(LocKeys.CUSTOMER)}
						placeholder={localize(LocKeys.SELECT_CUSTOMER) + "..."}
						showPlaceholder={true}
						initialValue={initialValues?.get("customer") || null}
						options={companyOptions}
						customOnChange={(value) => {
							setValue("customer", value);
						}}
					/>
				</div>
			)}
			<div className="col col-33">
				<TemplateBody editView={editView} />
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		companies: state.getIn(["user", "companies"]),
	};
};

export default connect(mapStateToProps, { updateTempRevitTemplateData })(
	TemplateHeader
);
