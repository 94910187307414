import React from "react";

/**
 * Render error for form fields
 */

export default (
	error,
	touched,
	customError = null,
	customErrorLabel = null
) => {
	if ((touched && error) || (customError && customErrorLabel)) {
		return (
			<span className={`form__error`}>{error ? error : customErrorLabel}</span>
		);
	}
};
