import { AlertTitle } from "@mui/material";
import Alert from "@mui/material/Alert";
import React from "react";
import { alertTypeConstants } from "../../../constants/constants";
import ErrorIcon from "../../icons/misc/ErrorIcon";
import InfoIcon from "../../icons/misc/InfoIcon";
import SuccessIcon from "../../icons/misc/SuccessIcon";
import WarningIcon from "../../icons/misc/WarningIcon";

/**
 * Retrieves info block component
 * @param {Object.<string, any>} props
 *
 * @param {string} [props.type=success|error|warning|info]  - One of 4 snackbar types, from alertTypeConstants: [success|warning|info|error], Default: success
 * @param {string} props.title - Snackbar title content
 * @param {React.ReactNode} props.children - Additional snackbar content
 * @param {string} props.wrapperClasses - Classes applied to wrapper element
 *
 * @return {React.FC} Info block component
 */
const InfoBlock = ({
	type = alertTypeConstants.SUCCESS,
	onClose,
	title = "",
	children,
	wrapperClasses = "",
}) => {
	return (
		<div className={`snackbar ${wrapperClasses}`}>
			<Alert
				variant="outlined"
				classes={{
					root: "alert",
					outlinedSuccess: "alert--success",
					outlinedInfo: "alert--info",
					outlinedWarning: "alert--warning",
					outlinedError: "alert--error",
					icon: "alert__icon",
					action: "close",
				}}
				iconMapping={{
					success: <SuccessIcon iconClass="d-flex align-items-center" />,
					info: <InfoIcon iconClass="d-flex align-items-center" />,
					error: <ErrorIcon iconClass="d-flex align-items-center" />,
					warning: <WarningIcon iconClass="d-flex align-items-center" />,
				}}
				onClose={onClose}
				severity={type}
			>
				{title && (
					<AlertTitle>
						<h4 className="small">{title}</h4>
					</AlertTitle>
				)}
				{children}
			</Alert>
		</div>
	);
};

export default InfoBlock;
