const delay = (milliseconds) =>
	new Promise((resolve) => {
		setTimeout(resolve, milliseconds);
	});

/**
 * Create anchor element, set href attribute, append it on document body,
 * do click on anchor element, remove anchor from document body
 * @param {string} url
 */
export const download = (url) => {
	const a = document.createElement("a");
	a.href = url;
	a.style.display = "none";
	document.body.append(a);
	a.click();
	a.remove();
};

/**
 * Create anchor elements from array of urls, set href attribute, append it on document body,
 * do click on anchor elements, remove anchor elements from document body
 * @param {array} urls
 */
export const multiDownload = async (urls) => {
	if (!urls) {
		throw new Error("urls required");
	}

	for (const [index, url] of urls.entries()) {
		await delay(index * 1000);
		download(url);
	}
};
