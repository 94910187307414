import tempAdministrationConstants from "../constants/tempAdministrationConstants";

//---------------------DISCIPLINES-----------------------------

export const setTempDisciplines = (data) => {
	return (dispatch) => {
		dispatch({
			type: tempAdministrationConstants.SET_TEMP_DISCIPLINES,
			data: data,
		});
	};
};
export const updateTempDisciplines = (id, type) => {
	return (dispatch) => {
		dispatch({
			type: tempAdministrationConstants.UPDATE_TEMP_DISCIPLINES,
			data: { id, type },
		});
	};
};
export const addTempDiscipline = () => {
	return (dispatch) => {
		dispatch({
			type: tempAdministrationConstants.ADD_TEMP_DISCIPLINE,
		});
	};
};
export const removeTempDiscipline = (id) => {
	return (dispatch) => {
		dispatch({
			type: tempAdministrationConstants.REMOVE_TEMP_DISCIPLINE,
			data: id,
		});
	};
};

export const updateTempDisciplineSortOrder = (dragId, overId) => {
	return (dispatch) => {
		dispatch({
			type: tempAdministrationConstants.UPDATE_TEMP_DISCIPLINE_ORDER,
			data: { dragId, overId },
		});
	};
};

//---------------------------LOD-------------------------------

export const setTempLods = (data) => {
	return (dispatch) => {
		dispatch({
			type: tempAdministrationConstants.SET_TEMP_LODS,
			data: data,
		});
	};
};
export const updateTempLod = (id, type) => {
	return (dispatch) => {
		dispatch({
			type: tempAdministrationConstants.UPDATE_TEMP_LOD,
			data: { id, type },
		});
	};
};
export const addTempLod = () => {
	return (dispatch) => {
		dispatch({
			type: tempAdministrationConstants.ADD_TEMP_LOD,
		});
	};
};

export const removeTempLod = (id) => {
	return (dispatch) => {
		dispatch({
			type: tempAdministrationConstants.REMOVE_TEMP_LOD,
			data: id,
		});
	};
};

export const updateTempLODSortOrder = (dragId, overId) => {
	return (dispatch) => {
		dispatch({
			type: tempAdministrationConstants.UPDATE_TEMP_LOD_SORT_ORDER,
			data: { dragId, overId },
		});
	};
};
//---------------------------BUILDING_COMPONENTS-------------------------

export const setTempBuildingComponents = (data) => {
	return (dispatch) => {
		dispatch({
			type: tempAdministrationConstants.SET_TEMP_BUILDING_COMPONENTS,
			data: data,
		});
	};
};

export const updateTempBuildingComponents = (fieldKey, id, value) => {
	return (dispatch) => {
		dispatch({
			type: tempAdministrationConstants.UPDATE_TEMP_BUILDING_COMPONENTS,
			data: { fieldKey, id, value },
		});
	};
};
export const addTempBuildingComponents = () => {
	return (dispatch) => {
		dispatch({
			type: tempAdministrationConstants.ADD_TEMP_BUILDING_COMPONENTS,
		});
	};
};
export const removeTempBuildingComponents = (id) => {
	return (dispatch) => {
		dispatch({
			type: tempAdministrationConstants.REMOVE_TEMP_BUILDING_COMPONENTS,
			data: id,
		});
	};
};

export const updateTempBuildingComponentsSortOrder = (dragId, overId) => {
	return (dispatch) => {
		dispatch({
			type: tempAdministrationConstants.UPDATE_TEMP_BUILDING_COMPONENTS_SORT_ORDER,
			data: { dragId, overId },
		});
	};
};
//---------------------------BUILDING_CATEGORIES-------------------------

export const setTempBuildingCategories = (data) => {
	return (dispatch) => {
		dispatch({
			type: tempAdministrationConstants.SET_TEMP_BUILDING_CATEGORIES,
			data: data,
		});
	};
};

export const updateTempBuildingCategories = (id, value) => {
	return (dispatch) => {
		dispatch({
			type: tempAdministrationConstants.UPDATE_TEMP_BUILDING_CATEGORIES,
			data: { id, value },
		});
	};
};

export const removeTempBuildingCategories = (id) => {
	return (dispatch) => {
		dispatch({
			type: tempAdministrationConstants.REMOVE_TEMP_BUILDING_CATEGORIES,
			data: id,
		});
	};
};

export const addTempBuildingCategories = () => {
	return (dispatch) => {
		dispatch({
			type: tempAdministrationConstants.ADD_TEMP_BUILDING_CATEGORIES,
		});
	};
};

export const updateTempBuildingCategoriesSortOrder = (dragId, overId) => {
	return (dispatch) => {
		dispatch({
			type: tempAdministrationConstants.UPDATE_TEMP_BUILDING_CATEGORIES_SORT_ORDER,
			data: { dragId, overId },
		});
	};
};

//---------------------------BUILDING TYPES-------------------------

export const setTempBuildingTypes = (data) => {
	return (dispatch) => {
		dispatch({
			type: tempAdministrationConstants.SET_TEMP_BUILDING_TYPES,
			data: data,
		});
	};
};

export const removeTempBuildingTypes = (id) => {
	return (dispatch) => {
		dispatch({
			type: tempAdministrationConstants.REMOVE_TEMP_BUILDING_TYPES,
			data: id,
		});
	};
};

export const addTempBuildingTypes = () => {
	return (dispatch) => {
		dispatch({
			type: tempAdministrationConstants.ADD_TEMP_BUILDING_TYPES,
		});
	};
};

export const updateTempBuildingTypes = (fieldKey, id, value) => {
	return (dispatch) => {
		dispatch({
			type: tempAdministrationConstants.UPDATE_TEMP_BUILDING_TYPES,
			data: { fieldKey, id, value },
		});
	};
};

export const updateTempBuildingTypesSortOrder = (dragId, overId) => {
	return (dispatch) => {
		dispatch({
			type: tempAdministrationConstants.UPDATE_TEMP_BUILDING_TYPES_SORT_ORDER,
			data: { dragId, overId },
		});
	};
};

//---------------------------PRICING MODEL-------------------------

//---------------------------MODELING DATA-------------------------

export const setTempModelingPrices = (modelingPrices, disciplines) => {
	return (dispatch) => {
		dispatch({
			type: tempAdministrationConstants.SET_TEMP_MODELING_DATA,
			data: { modelingData: modelingPrices, disciplines },
		});
	};
};
export const addTempModelingPrice = () => {
	return (dispatch) => {
		dispatch({
			type: tempAdministrationConstants.ADD_TEMP_MODELING_DATA,
		});
	};
};
export const updateTempModelingData = (
	fieldKey,
	value,
	id,
	inputFormat = null
) => {
	return (dispatch) => {
		dispatch({
			type: tempAdministrationConstants.UPDATE_TEMP_MODELING_DATA,
			data: { fieldKey, value, id, inputFormat },
		});
	};
};
export const removeTempModelingPrice = (id) => {
	return (dispatch) => {
		dispatch({
			type: tempAdministrationConstants.REMOVE_TEMP_MODELING_DATA,
			data: id,
		});
	};
};

//---------------------------LOD_ADJUSTMENT-------------------------

export const setTempLODAdjustment = (data) => {
	return (dispatch) => {
		dispatch({
			type: tempAdministrationConstants.SET_TEMP_LOD_ADJUSTMENT,
			data: data,
		});
	};
};
export const addTempLODAdjustment = () => {
	return (dispatch) => {
		dispatch({
			type: tempAdministrationConstants.ADD_TEMP_LOD_ADJUSTMENT,
		});
	};
};

export const updateTempLODAdjustment = (
	fieldKey,
	value,
	id,
	inputFormat = null
) => {
	return (dispatch) => {
		dispatch({
			type: tempAdministrationConstants.UPDATE_TEMP_LOD_ADJUSTMENT,
			data: { fieldKey, value, id, inputFormat },
		});
	};
};
export const removeTempLODAdjustment = (id) => {
	return (dispatch) => {
		dispatch({
			type: tempAdministrationConstants.REMOVE_TEMP_LOD_ADJUSTMENT,
			data: id,
		});
	};
};

//---------------------------PRICE_ADJUSTMENT---------------

export const setTempBuildingCategoryAdjustment = (data) => {
	return (dispatch) => {
		dispatch({
			type: tempAdministrationConstants.SET_TEMP_BUILDING_CATEGORY_ADJUSTMENT,
			data: data,
		});
	};
};
export const addTempBuildingCategoryAdjustment = () => {
	return (dispatch) => {
		dispatch({
			type: tempAdministrationConstants.ADD_TEMP_BUILDING_CATEGORY_ADJUSTMENT,
		});
	};
};

export const updateTempBuildingCategoryAdjustment = (
	fieldKey,
	value,
	id,
	inputFormat = null
) => {
	return (dispatch) => {
		dispatch({
			type: tempAdministrationConstants.UPDATE_TEMP_BUILDING_CATEGORY_ADJUSTMENT,
			data: { fieldKey, value, id, inputFormat },
		});
	};
};
export const removeTempBuildingCategoryAdjustment = (id) => {
	return (dispatch) => {
		dispatch({
			type: tempAdministrationConstants.REMOVE_TEMP_BUILDING_CATEGORY_ADJUSTMENT,
			data: id,
		});
	};
};

//-------------------------REPETITIVENESS ADJUSTMENT---------------

export const setTempRepetitivenessAdjustment = (data) => {
	return (dispatch) => {
		dispatch({
			type: tempAdministrationConstants.SET_TEMP_REPETITIVENESS_ADJUSTMENT,
			data: data,
		});
	};
};
export const addTempRepetitivenessAdjustment = () => {
	return (dispatch) => {
		dispatch({
			type: tempAdministrationConstants.ADD_TEMP_REPETITIVENESS_ADJUSTMENT,
		});
	};
};

export const updateTempRepetitivenessAdjustment = (fieldKey, value, id) => {
	return (dispatch) => {
		dispatch({
			type: tempAdministrationConstants.UPDATE_TEMP_REPETITIVENESS_ADJUSTMENT,
			data: { fieldKey, value, id },
		});
	};
};
export const removeTempRepetitivenessAdjustment = (id) => {
	return (dispatch) => {
		dispatch({
			type: tempAdministrationConstants.REMOVE_TEMP_REPETITIVENESS_ADJUSTMENT,
			data: id,
		});
	};
};

//----------------------------OTHERS---------------------------

export const removeTempOthers = (id) => {
	return (dispatch) => {
		dispatch({
			type: tempAdministrationConstants.REMOVE_TEMP_OTHERS,
			data: id,
		});
	};
};

export const addTempOthers = () => {
	return (dispatch) => {
		dispatch({
			type: tempAdministrationConstants.ADD_TEMP_OTHERS,
		});
	};
};

export const updateTempOthers = (fieldKey, id, value) => {
	return (dispatch) => {
		dispatch({
			type: tempAdministrationConstants.UPDATE_TEMP_OTHERS,
			data: { fieldKey, id, value },
		});
	};
};

//---------------------------NORMALISATION MANUAL -------------------------------
export const addTempNormalisationManual = () => {
	return (dispatch) => {
		dispatch({
			type: tempAdministrationConstants.ADD_TEMP_NORMALISATION_MANUAL,
		});
	};
};

export const updateTempNormalisationManual = (id, fieldKey, value) => {
	return (dispatch) => {
		dispatch({
			type: tempAdministrationConstants.UPDATE_TEMP_NORMALISATION_MANUAL,
			data: { id, fieldKey, value },
		});
	};
};

//---------------------------CUSTOMISATION MANUAL-------------------------------
export const addTempCustomisationManual = () => {
	return (dispatch) => {
		dispatch({
			type: tempAdministrationConstants.ADD_TEMP_CUSTOMISATION_MANUAL,
		});
	};
};

export const updateTempCustomisationManual = (id, fieldKey, value) => {
	return (dispatch) => {
		dispatch({
			type: tempAdministrationConstants.UPDATE_TEMP_CUSTOMISATION_MANUAL,
			data: { id, fieldKey, value },
		});
	};
};
//---------------------------QA MANUAL-------------------------------
export const addTempQAManual = () => {
	return (dispatch) => {
		dispatch({
			type: tempAdministrationConstants.ADD_TEMP_QA_MANUAL,
		});
	};
};

export const updateTempQAManual = (id, fieldKey, value) => {
	return (dispatch) => {
		dispatch({
			type: tempAdministrationConstants.UPDATE_TEMP_QA_MANUAL,
			data: { id, fieldKey, value },
		});
	};
};

export const setManualLoader = (data) => {
	return (dispatch) => {
		dispatch({
			type: tempAdministrationConstants.SET_MANUAL_LOADER,
			data,
		});
	};
};
//---------------------------TERMS AND CONDITION-------------------------------

export const setTempTermsAndCondition = (data) => {
	return (dispatch) => {
		dispatch({
			type: tempAdministrationConstants.SET_TERMS_AND_CONDITION,
			data: data,
		});
	};
};

//---------------------------SPECIFICATION_PARAMETERS-------------------------------

export const addTempSpecificationParameters = () => {
	return (dispatch) => {
		dispatch({
			type: tempAdministrationConstants.ADD_SPECIFICATION_PARAMETERS,
		});
	};
};

export const setTempSpecificationParameters = (data) => {
	return (dispatch) => {
		dispatch({
			type: tempAdministrationConstants.SET_SPECIFICATION_PARAMETERS,
			data: data,
		});
	};
};

export const updateTempSpecificationParameters = (id, fieldKey, value) => {
	return (dispatch) => {
		dispatch({
			type: tempAdministrationConstants.UPDATE_SPECIFICATION_PARAMETERS,
			data: { id, fieldKey, value },
		});
	};
};

export const removeTempSpecificationParameter = (id) => {
	return (dispatch) => {
		dispatch({
			type: tempAdministrationConstants.REMOVE_SPECIFICATION_PARAMETERS,
			data: { id },
		});
	};
};

export const updateTempSpecificationParametersSort = (dragId, overId) => {
	return (dispatch) => {
		dispatch({
			type: tempAdministrationConstants.SORT_SPECIFICATION_PARAMETERS,
			data: { dragId, overId },
		});
	};
};

//---------------------------SPECIFICATION_CATEGORIES-------------------------------

export const addTempSpecificationCategories = () => {
	return (dispatch) => {
		dispatch({
			type: tempAdministrationConstants.ADD_SPECIFICATION_CATEGORIES,
		});
	};
};

export const updateTempSpecificationCategories = (fieldKey, id, value) => {
	return (dispatch) => {
		dispatch({
			type: tempAdministrationConstants.UPDATE_SPECIFICATION_CATEGORIES,
			data: { fieldKey, id, value },
		});
	};
};

export const removeTempSpecificationCategory = (id) => {
	return (dispatch) => {
		dispatch({
			type: tempAdministrationConstants.REMOVE_SPECIFICATION_CATEGORIES,
			data: { id },
		});
	};
};

export const setTempSpecificationCategories = (data) => {
	return (dispatch) => {
		dispatch({
			type: tempAdministrationConstants.SET_SPECIFICATION_CATEGORIES,
			data: data,
		});
	};
};

export const updateTempSpecificationCategoriesSort = (dragId, overId) => {
	return (dispatch) => {
		dispatch({
			type: tempAdministrationConstants.SORT_SPECIFICATION_CATEGORIES,
			data: { dragId, overId },
		});
	};
};

//---------------------------CUSTOMISATION_TIME-------------------------------

export const updateTempCustomisationTimeByInputFormat = ({
	inputFormatId,
	disciplineId,
	categoryId,
	lodId,
	value,
}) => {
	return (dispatch) => {
		dispatch({
			type: tempAdministrationConstants.UPDATE_TEMP_CUSTOMISATION_TIME_BY_INPUT_FORMAT,
			data: { inputFormatId, disciplineId, categoryId, lodId, value },
		});
	};
};

export const resetTempCustomisationTimeByInputFormat = (inputFormatId) => {
	return (dispatch) => {
		dispatch({
			type: tempAdministrationConstants.RESET_TEMP_CUSTOMISATION_TIME_BY_INPUT_FORMAT,
			data: { inputFormatId },
		});
	};
};

//---------------------------EXPORTS ADJUSTMENT-------------------------------

export const updateTempExportAdjustmentInputFormat = ({
	fieldKey,
	id = null,
	value,
	exportId,
}) => {
	return (dispatch) => {
		dispatch({
			type: tempAdministrationConstants.UPDATE_TEMP_EXPORT_ADJUSTMENT_INPUT_FORMAT,
			data: { fieldKey, id, value, exportId },
		});
	};
};

export const resetTempExportAdjustmentInputFormat = () => {
	return (dispatch) => {
		dispatch({
			type: tempAdministrationConstants.RESET_TEMP_EXPORT_ADJUSTMENT_INPUT_FORMAT,
		});
	};
};

//---------------------------SERVICES ADJUSTMENT-------------------------------

export const updateTempServicesAdjustmentByInputFormat = ({
	fieldKey,
	id,
	value,
	manualId,
}) => {
	return (dispatch) => {
		dispatch({
			type: tempAdministrationConstants.UPDATE_TEMP_SERVICES_ADJUSTMENT_INPUT_FORMAT,
			data: { fieldKey, id, value, manualId },
		});
	};
};

export const resetTempServicesAdjustmentByInputFormat = () => {
	return (dispatch) => {
		dispatch({
			type: tempAdministrationConstants.RESET_TEMP_SERVICES_ADJUSTMENT_INPUT_FORMAT,
		});
	};
};

//---------------------------DXF_SERVER_SETTINGS-------------------------------

/**
 * SET TEMP dxf server components per discipline
 *
 * @param {number} disciplineId - unique discipline identifier
 * @param {object} result - object containing the dxf server components
 * @returns
 */
export const setTempDXFServerComponents = ({ disciplineId, result }) => {
	return (dispatch) => {
		dispatch({
			type: tempAdministrationConstants.SET_DXF_SERVER_COMPONENTS,
			data: { disciplineId, result },
		});
	};
};

/**
 * Remove TEMP dxf server component per discipline
 *
 * @param {number} disciplineId - unique discipline identifier
 * @param {number} tempId - unique temp identifier
 * @returns
 */
export const removeTempDXFServerComponent = ({ disciplineId, tempId }) => {
	return (dispatch) => {
		dispatch({
			type: tempAdministrationConstants.REMOVE_DXF_SERVER_COMPONENT,
			data: { disciplineId, tempId },
		});
	};
};

/**
 * ADD TEMP dxf server component per discipline
 *
 * @param {number} disciplineId - unique discipline identifier
 * @returns
 */
export const addTempDXFServerComponent = ({ disciplineId }) => {
	return (dispatch) => {
		dispatch({
			type: tempAdministrationConstants.ADD_DXF_SERVER_COMPONENT,
			data: { disciplineId },
		});
	};
};

/**
 * RESET TEMP dxf server component per discipline
 *
 * @param {number} disciplineId - unique discipline identifier
 * @returns
 */
export const resetTempDXFServerComponent = ({ disciplineId }) => {
	return (dispatch) => {
		dispatch({
			type: tempAdministrationConstants.RESET_DXF_SERVER_COMPONENT,
			data: { disciplineId },
		});
	};
};

/**
 * UPDATE TEMP dxf server component per discipline
 *
 * @param {number} disciplineId - unique discipline identifier
 * @param {number} tempId - unique temp identifier
 * @param {string} value - value to update
 * @returns
 */
export const updateTempDXFServerComponent = ({
	disciplineId,
	tempId,
	value,
}) => {
	return (dispatch) => {
		dispatch({
			type: tempAdministrationConstants.UPDATE_DXF_SERVER_COMPONENT,
			data: { disciplineId, tempId, value },
		});
	};
};

/**
 * SET TEMP dxf server component parameters
 *
 * @param {number} disciplineId - unique discipline identifier
 * @param {number} componentId - unique component identifier
 * @param {object} result - object containing the dxf server component parameters
 *
 */
export const setTempDXFServerComponentParameters = ({
	disciplineId,
	componentId,
	result,
}) => {
	return (dispatch) => {
		dispatch({
			type: tempAdministrationConstants.SET_DXF_SERVER_COMPONENT_PARAMETERS,
			data: { disciplineId, componentId, result },
		});
	};
};

/**
 * ADD TEMP dxf server component parameter
 *
 * @param {number} disciplineId - unique discipline identifier
 * @param {number} componentId - unique component identifier
 *
 */
export const addTempDXFServerComponentParameter = ({
	disciplineId,
	componentId,
}) => {
	return (dispatch) => {
		dispatch({
			type: tempAdministrationConstants.ADD_DXF_SERVER_COMPONENT_PARAMETER,
			data: { disciplineId, componentId },
		});
	};
};

/**
 * RESET TEMP dxf server component parameter
 *
 * @param {number} disciplineId - unique discipline identifier
 * @param {number} componentId - unique component identifier
 *
 */
export const resetTempDXFServerComponentParameter = ({
	disciplineId,
	componentId,
}) => {
	return (dispatch) => {
		dispatch({
			type: tempAdministrationConstants.RESET_DXF_SERVER_COMPONENT_PARAMETER,
			data: { disciplineId, componentId },
		});
	};
};

/**
 * UPDATE TEMP dxf server component parameter
 *
 * @param {number} disciplineId - unique discipline identifier
 * @param {number} componentId - unique component identifier
 * @param {number} tempId - unique temp identifier
 * @param {string} fieldKey - field key to update
 * @param {object} values - values for the field key
 * @param {number} settingsId - unique settings identifier
 * @param {array} settingsOptions - settings options
 *
 */
export const updateTempDXFServerComponentParameter = ({
	disciplineId,
	componentId,
	tempId,
	fieldKey,
	values,
	settingsId = null,
	settingsOptions = [],
}) => {
	return (dispatch) => {
		dispatch({
			type: tempAdministrationConstants.UPDATE_DXF_SERVER_COMPONENT_PARAMETER,
			data: {
				disciplineId,
				componentId,
				tempId,
				fieldKey,
				values,
				settingsId,
				settingsOptions,
			},
		});
	};
};

export const removeTempDXFServerComponentParameter = ({
	disciplineId,
	componentId,
	tempId,
}) => {
	return (dispatch) => {
		dispatch({
			type: tempAdministrationConstants.REMOVE_DXF_SERVER_COMPONENT_PARAMETER,
			data: { disciplineId, componentId, tempId },
		});
	};
};

/**
 * CLEAR TEMP dxf server components
 */
export const clearTempDXFServerComponents = () => {
	return (dispatch) => {
		dispatch({
			type: tempAdministrationConstants.CLEAR_TEMP_DXF_SERVER_COMPONENTS,
		});
	};
};
