const dashboardConstants = {
	SET_TEMP_SALES_PACKAGE: "SET_TEMP_SALES_PACKAGE",
	GET_SALES_PACKAGES: "GET_SALES_PACKAGES",
	SET_TEMP_FIELD_VALUE: "SET_TEMP_FIELD_VALUE",
	SET_TEMP_IMAGE_URL: "SET_TEMP_IMAGE_URL",
	SET_TEMP_IMAGE: "SET_TEMP_IMAGE",
	CLEAR_TEMP_PACKAGE: "CLEAR_TEMP_PACKAGE",
	DASHBOARD_ACTION_SUCCESS: "DASHBOARD_ACTION_SUCCESS",
	DASHBOARD_ACTION_FAILURE: "DASHBOARD_ACTION_FAILURE",
	SET_TEMP_FILE: "SET_TEMP_FILE",
	GET_SINGLE_PACKAGE: "GET_SINGLE_PACKAGE",
	CLEAR_DASHBAORD_REQUEST_STATE: "CLEAR_DASHBAORD_REQUEST_STATE",
	GET_PACKAGE_STATS: "GET_PACKAGE_STATS",
	SET_TEMP_EXPORTS: "SET_TEMP_EXPORTS",
	RE_CONFIGURATION_PACKAGE: "RE_CONFIGURATION_PACKAGE",
	SET_CANCEL_FORM_ORDER: "SET_CANCEL_FORM_ORDER",
	SET_TEMP_MODEL_FILE: "SET_TEMP_MODEL_FILE",
};

export default dashboardConstants;
