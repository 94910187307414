import React from "react";

export default (props) => {
	return (
		<div className={`icon ${props.iconClass}`}>
			<svg
				width="17"
				height="20"
				viewBox="0 0 17 20"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M8.49994 10C11.1828 10 13.3571 7.76172 13.3571 5C13.3571 2.23828 11.1828 0 8.49994 0C5.81713 0 3.6428 2.23828 3.6428 5C3.6428 7.76172 5.81713 10 8.49994 10ZM12.1352 11.2734L10.3214 18.75L9.10708 13.4375L10.3214 11.25H6.67851L7.8928 13.4375L6.67851 18.75L4.86467 11.2734C2.15909 11.4062 -6.10352e-05 13.6836 -6.10352e-05 16.5V18.125C-6.10352e-05 19.1602 0.815787 20 1.82137 20H15.1785C16.1841 20 16.9999 19.1602 16.9999 18.125V16.5C16.9999 13.6836 14.8408 11.4062 12.1352 11.2734Z"
					fill="#7F909F"
				/>
			</svg>
		</div>
	);
};
