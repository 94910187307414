import React, { useEffect, useMemo } from "react";
import localize, { LocKeys } from "../../../../../../constants/localizations";
import renderTextField from "../../../../../fields/renderTextField";
import { Field, formValueSelector } from "redux-form/immutable";
import CustomAutocomplete from "../../../../../fields/CustomAutocomplete";
import { connect } from "react-redux";
import {
	getDisciplines,
	getFamiliesByComponent,
	getSymbolFunctions,
	updateTempFieldSymbol,
} from "../../../../../../actions";
import ImageSymbolForm from "./ImageSymbolForm";

const BodySymbolForm = (props) => {
	const {
		symbolFunctions = null,
		families,
		initialValues,
		disciplineComponentCategoryId,
		//	disciplineId,

		disciplines,
		getDisciplines,
		updateTempFieldSymbol,
		getFamiliesByComponent,
		getSymbolFunctions,
	} = props;

	useEffect(() => {
		getFamiliesByComponent(disciplineComponentCategoryId);
	}, [disciplineComponentCategoryId, getFamiliesByComponent]);

	useEffect(() => {
		getDisciplines({});
		getSymbolFunctions();
	}, [getDisciplines, getSymbolFunctions]);

	const setValue = (fieldKey, value) => {
		updateTempFieldSymbol(fieldKey, value);
	};

	const disciplineCategoryOptions = useMemo(() => {
		const options = [];
		if (disciplines && disciplines.size > 0) {
			disciplines.map((discipline, i) => {
				const children = discipline.get("disciplineComponentCategories");

				let subHeader = (
					<div
						className={`dropdown-subheader body-font--small  semi  ${+i === 0 ? "" : "dropdown-subheader--border"}`}
					>
						<div className="d-flex align-items-center w-100">
							{discipline.get("type")}
						</div>
					</div>
				);

				children &&
					children.map((item) => {
						return options.push({
							subHeader,
							label: item.getIn(["componentCategory", "type"]),
							value: item.get("id"),
						});
					});

				return options;
			});

			return options;
		}

		return options;
	}, [disciplines]);

	// let disciplineOptions = [];
	// if (disciplines && disciplines.size > 0) {
	// 	disciplines.map((discipline) => {
	// 		return disciplineOptions.push({
	// 			value: discipline.get("id"),
	// 			label: discipline.get("type"),
	// 		});
	// 	});
	// }

	// let categoriesOptions = [];
	// if (categories && categories.length > 0) {
	// 	categories.map((category) => {
	// 		return categoriesOptions.push({
	// 			value: category.componentCategory.id,
	// 			label: category.componentCategory.type,
	// 		});
	// 	});
	// }

	let familyOptions = [];
	if (families && families.size > 0) {
		families.map((familiy) => {
			return familyOptions.push({
				value: familiy.get("id"),
				label: familiy.get("name"),
			});
		});
	}

	const functionOptions = useMemo(() => {
		const options = [];
		if (symbolFunctions && symbolFunctions.size > 0) {
			symbolFunctions.map((symbolFunction) => {
				return options.push({
					value: symbolFunction.get("id"),
					label: symbolFunction.get("name"),
				});
			});
			return options;
		}
		return options;
	}, [symbolFunctions]);

	return (
		<div className="row row--m-gutters">
			<div className="col col-desktop-50 col-100">
				<div className="d-flex flex-column w-100">
					<ImageSymbolForm />
				</div>
			</div>

			<div className="col col-desktop-50 col-100">
				<div className="d-flex flex-column w-100 mt-desktop-0 mt-48">
					<div className="d-flex w-100">
						<Field
							name="name"
							id="name"
							component={renderTextField}
							classField="mb-regular"
							label={localize(LocKeys.NAME)}
							placeholder={localize(LocKeys.ENTER_NAME).concat("....")}
							onKeyDown={(value) => setValue("name", value)}
						/>
					</div>
					{/* <div className="d-flex w-100">
						<Field
							name="discipline"
							id="discipline"
							component={CustomAutocomplete}
							disableClearable={true}
							classField="mb-regular"
							label={localize(LocKeys.DISCIPLINE)}
							placeholder={localize(LocKeys.SELECT_DISCIPLINE).concat("....")}
							dropdownHeight="300px"
							initialValue={initialValues?.get("discipline") || null}
							options={disciplineOptions}
							customOnChange={(value) => setValue("discipline", value)}
						/>
					</div> */}
					<div className="d-flex w-100">
						<Field
							name="disciplineComponentCategory"
							id="disciplineComponentCategory"
							component={CustomAutocomplete}
							classField="mb-regular"
							groupBy={true}
							disableClearable={true}
							initialValue={
								initialValues?.get("disciplineComponentCategory") || null
							}
							label={localize(LocKeys.COMPONENT)}
							placeholder={localize(LocKeys.SELECT_COMPONENT).concat("....")}
							dropdownHeight="300px"
							options={disciplineCategoryOptions}
							customOnChange={(value) => {
								setValue("disciplineComponentCategory", value);
								setValue("family", "");
							}}
						/>
					</div>
					<div className="d-flex w-100">
						<Field
							name="family"
							id="family"
							component={CustomAutocomplete}
							disableClearable={true}
							disabled={!disciplineComponentCategoryId}
							classField="mb-regular"
							label={localize(LocKeys.COMPONENT_TYPE)}
							placeholder={localize(LocKeys.COMPONENT_TYPE).concat("....")}
							dropdownHeight="300px"
							initialValue={initialValues?.get("family") || null}
							options={familyOptions}
							customOnChange={(value) => setValue("family", value)}
						/>
					</div>
					<div className="d-flex w-100">
						<Field
							name="symbolFunction"
							id="symbolFunction"
							component={CustomAutocomplete}
							disableClearable={true}
							classField="mb-regular"
							label={localize(LocKeys.FUNCTION)}
							placeholder={localize(LocKeys.SELECT_FUNCTION).concat("....")}
							dropdownHeight="300px"
							initialValue={initialValues?.get("symbolFunction") || null}
							options={functionOptions}
							customOnChange={(value) => setValue("symbolFunction", value)}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state, ownProps) => {
	const selector = formValueSelector(ownProps.formName);

	const disciplines = state.getIn(["administration", "disciplines"]);
	// const disciplineId =
	// 	selector(state, "discipline") ||
	// 	state.getIn(["symbol", "tempSymbol", "discipline"]);
	//const disciplineJS = disciplines?.toJS();
	//let categories = [];

	// if (Array.isArray(disciplineJS)) {
	// 	categories = disciplineJS?.filter(
	// 		(discipline) => +discipline.id === +disciplineId
	// 	);
	// }

	return {
		//categories: categories[0]?.disciplineComponentCategories,
		//disciplineId,
		disciplineComponentCategoryId: selector(
			state,
			"disciplineComponentCategory"
		),
		disciplines,
		families: state.getIn(["administration", "families"]),
		symbolFunctions: state.getIn(["administration", "symbolFunctions"]),
	};
};

export default connect(mapStateToProps, {
	getDisciplines,
	updateTempFieldSymbol,
	getFamiliesByComponent,
	getSymbolFunctions,
})(BodySymbolForm);
