import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { logout, getUser } from "../../actions";
import Modal from "../../components/Modal";
import { userRequestDone } from "../../actions";
import "./topbar.scss";
import LogoutIcon from "../../shared/icons/LogoutIcon";
import ProfileIcon from "../../shared/icons/ProfileIcon";
import PasswordIcon from "../../shared/icons/PasswordIcon";
import localize, { LocKeys } from "../../constants/localizations";
import Breadcrumbs from "./Breadcrumbs";
import ArrowDownIcon from "../../shared/icons/misc/ArrowDownIcon";
import Logo from "../../shared/Logo/Logo";
import { sizeConstants, logoTypesConstants } from "../../constants/constants";
import UserRequestPopups from "../users-management/UserRequestPopups";
import {
	MenuItem,
	ClickAwayListener,
	Grow,
	Paper,
	Popper,
	MenuList,
} from "@mui/material";
import ArrowUpIcon from "../../shared/icons/misc/ArrowUpIcon";
import ChangePassword from "../profile-management/ChangePassword";
import { useHistory } from "react-router-dom";
import GlobalCompany from "../company-management/GlobalCompany";

/**
 * TOPBAR Components
 * 	1. Breadcrumb
 * 	2. Global customer and menu
 *
 */
const Topbar = (props) => {
	//Local states used only in this component

	const { userRequestDone, logout, imageLink } = props;

	const [changePwModal, setChangePwModal] = useState(false);
	const [dropdownActive, setDropdownActive] = useState(false);
	const [open, setOpen] = React.useState(false);
	const history = useHistory();

	const anchorRef = useRef(null);
	const prevOpen = useRef(open);

	const { firstname, lastname } = props;

	//useEffect for dropdown
	useEffect(() => {
		if (prevOpen.current === true && open === false) {
			anchorRef.current.focus();
		}

		prevOpen.current = open;
	}, [open]);

	//Functions for dropdown component

	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen);
		setDropdownActive(!dropdownActive);
	};

	const handleClose = (event) => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}

		setOpen(false);
	};

	const handleListKeyDown = (event) => {
		if (event.key === "Tab") {
			event.preventDefault();
			setOpen(false);
		}
	};

	//change password modal
	const togglePwChangeModal = () => {
		userRequestDone();
		setChangePwModal(!changePwModal);
		setOpen(false);
	};

	const openMyProfile = () => {
		history.push("/settings/profile");
		setOpen(false);
	};

	const onClickLogout = () => {
		logout();
		setOpen(false);
	};

	return (
		<>
			<UserRequestPopups />
			<div className="topbar">
				<div className="row">
					<div className="col col-60 align-items-center">
						<div className="breadcrumb-items w-100">
							<Breadcrumbs />
						</div>
					</div>

					<div className="col col-40 d-flex align-items-center justify-content-end">
						<div className="mr-32 w-50">
							<GlobalCompany />
						</div>

						<div className="user-profile">
							<div
								className={`user-profile__info d-flex flex-row align-items-center ${
									dropdownActive ? "user-profile__info--active" : ""
								}`}
							>
								<Logo
									link={imageLink}
									alt={firstname + " " + lastname}
									size={sizeConstants.SMALL}
									type={logoTypesConstants.USER}
									stripped={true}
								/>
								<button
									className="user-profile__button cursor-pointer"
									ref={anchorRef}
									aria-controls={open ? "menu-list-grow" : undefined}
									aria-haspopup="true"
									onClick={handleToggle}
								>
									{firstname} {lastname}
									<span className={`user-profile__dropdown-toggle ml-10 `}>
										{!open ? <ArrowDownIcon /> : <ArrowUpIcon />}
									</span>
								</button>
							</div>
							<Popper
								style={{ top: "25px" }}
								open={open}
								anchorEl={anchorRef.current}
								transition
								disablePortal
								role={undefined}
								placement="bottom-start"
							>
								{({ TransitionProps, placement }) => (
									<Grow
										{...TransitionProps}
										style={{
											transformOrigin:
												placement === "bottom-start"
													? "left top"
													: "left bottom",
										}}
									>
										<Paper>
											<ClickAwayListener onClickAway={handleClose}>
												<MenuList
													autoFocusItem={open}
													id="menu-list-grow"
													onKeyDown={handleListKeyDown}
												>
													<MenuItem onClick={openMyProfile}>
														<div className="d-flex align-items-center body-font--small color-n500">
															<ProfileIcon iconClass="mr-m d-flex icon--target-fill fill--n500" />
															{localize(LocKeys.PROFILE)}
														</div>
													</MenuItem>
													<MenuItem
														onClick={() => {
															togglePwChangeModal();
														}}
													>
														<div className="d-flex align-items-center body-font--small color-n500">
															<PasswordIcon iconClass="mr-m d-flex icon--target-fill fill--n500" />
															{localize(LocKeys.CHANGE_PASSWORD)}
														</div>
													</MenuItem>

													<div className="small title-divider title-divider--bottom color-n300"></div>

													<MenuItem onClick={() => onClickLogout()}>
														<div className="d-flex align-items-center body-font--small color-n500">
															<LogoutIcon iconClass="mr-m d-flex icon--target-fill fill--n500" />

															{localize(LocKeys.LOG_OUT)}
														</div>
													</MenuItem>
												</MenuList>
											</ClickAwayListener>
										</Paper>
									</Grow>
								)}
							</Popper>
						</div>
					</div>
				</div>
			</div>
			<Modal
				title={localize(LocKeys.CHANGE_PASSWORD)}
				show={changePwModal}
				onClose={togglePwChangeModal}
			>
				<ChangePassword onClose={togglePwChangeModal} />
			</Modal>
			<div className="topbar--helper"></div>
		</>
	);
};

const MapStateToProps = (state) => {
	return {
		userId: state.getIn(["authentication", "user", "id"]),
		firstname: state.getIn(["authentication", "user", "firstName"]),
		lastname: state.getIn(["authentication", "user", "lastName"]),
		imageLink: state.getIn(["authentication", "user", "imageLink"]),
	};
};

export default connect(MapStateToProps, {
	getUser,
	logout,
	userRequestDone,
})(Topbar);
