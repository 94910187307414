import { useEffect, useState } from "react";

/**
 * HOOK for timer from HIGHER to LOWEST number
 *
 * @param {number} timer - default timer value (900)
 * @returns  {  timeToLeft, setTimeToLeft }
 */
export const useTimer = ({ timer = 900 }) => {
	const [timeToLeft, setTimeToLeft] = useState(timer); //Set timer

	useEffect(() => {
		const timerID = setInterval(() => {
			if (timeToLeft > 0) {
				setTimeToLeft(timeToLeft - 1);
			} else {
				setTimeToLeft(0);
			}
		}, 1000);

		return () => clearInterval(timerID);
	});

	return { timeToLeft, setTimeToLeft };
};
