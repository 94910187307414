import React, { useEffect, useState } from "react";
import { Snackbar } from "@mui/material";
import { connect } from "react-redux";
import "./widget.scss";
import { closeSnackbar } from "../../../actions";
import UploadFile from "./upload-file/UploadFile";
import { snackbarTypeConstants } from "../../../constants/constants";
import BimifyProgress from "./bimify-progress/BimifyProgress";
import DownloadZip from "./download-zip/DownloadZip";

/**
 * Global snackbar
 * @param {*} props
 * @returns
 */
const CustomWidget = (props) => {
	const {
		openSnackbar,
		contentType,
		//Actions
		closeSnackbar,
	} = props;
	const [state, setState] = useState(openSnackbar);
	const [type, setType] = useState(contentType);

	useEffect(() => {
		setState(openSnackbar);
		setType(contentType);
	}, [openSnackbar, contentType]);

	const handleClose = () => {
		setState(false);
		closeSnackbar();
	};

	const renderContent = () => {
		switch (type) {
			case snackbarTypeConstants.BIMIFY_PROGRESS:
				return <BimifyProgress handleClose={handleClose} />;

			case snackbarTypeConstants.DOWNLOAD_ZIP:
				return <DownloadZip handleClose={handleClose} />;

			default:
				return <UploadFile handleClose={handleClose} />;
		}
	};

	if (type === snackbarTypeConstants.BIMIFY_PROGRESS) {
		return renderContent();
	}
	return (
		<div className="global-snackbar">
			<Snackbar
				open={state}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
			>
				<div>{contentType && renderContent()}</div>
			</Snackbar>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		openSnackbar: state.getIn(["globalWidget", "open"]),
		contentType: state.getIn(["globalWidget", "type"]),
	};
};

export default connect(mapStateToProps, { closeSnackbar })(CustomWidget);
