const invoiceConstants = {
	I_ACTION_SUCCESS: "I_ACTION_SUCCESS",
	I_ACTION_FAILURE: "I_ACTION_FAILURE",
	ACTION_GET_FAILURE: "ACTION_GET_FAILURE",
	GET_INVOICES: "GET_INVOICES",
	GET_INVOICE: "GET_INVOICE",
	SET_INVOICE_TEMP_QUOTEID: "SET_INVOICE_TEMP_QUOTEID",
	SET_INVOICE_TEMP_FILE: "SET_INVOICE_TEMP_FILE",
	REMOVE_INVOICE_TEMP_FILE: "REMOVE_INVOICE_TEMP_FILE",
	RESET_TEMP_INVOICE: "RESET_TEMP_INVOICE",
	SET_NEW_INVOICE: "SET_NEW_INVOICE",
	I_REQUEST_DONE: "I_REQUEST_DONE",
	DOWNLOAD_INVOICE: "DOWNLOAD_INVOICE",
	CLEAR_INVOICE: "CLEAR_INVOICE",
	I_ACTION_RESET: "I_ACTION_RESET",
	SET_SUCCESS_MESSAGE: "SET_SUCCESS_MESSAGE",
	SET_ERROR_MESSAGE: "SET_ERROR_MESSAGE",
	CLEAR_INVOICE_REQUEST_STATE: "CLEAR_INVOICE_REQUEST_STATE",
};

export default invoiceConstants;
