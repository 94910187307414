import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getFamily, updateFamily } from "../../../../../actions";
import FamilyForm from "./FamilyForm";
import { useNavigate, useParams } from "react-router-dom-v5-compat";

const EditFamily = (props) => {
	const { getFamily, updateFamily } = props;
	const { familyId } = useParams();
	const navigate = useNavigate();

	useEffect(() => {
		getFamily(familyId);
	}, [familyId, getFamily]);

	const onCancel = () => {
		navigate(-1);
	};

	const onSubmit = (data) => {
		updateFamily(familyId, data, () => {
			onCancel();
		});
	};

	return (
		<FamilyForm
			formName="editFamilyForm"
			onSubmit={onSubmit}
			onCancel={onCancel}
			editView={true}
		/>
	);
};

const mapStateToProps = (state) => {
	return {};
};
export default connect(mapStateToProps, {
	getFamily,
	updateFamily,
})(EditFamily);
