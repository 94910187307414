import React, { useEffect } from "react";
import { connect } from "react-redux";
import { clearTextEditorState } from "../../../../../actions/textEditorAction";
import UploadFiles from "../../../UploadFile/UploadFiles";
import { INSERT_VIDEO_COMMAND } from "../Toolbar";

const InsertVideo = ({ activeEditor, ...props }) => {
	const { urlLink, type, clearTextEditorState } = props;

	useEffect(() => {
		if (urlLink && type === "video") {
			activeEditor.dispatchCommand(INSERT_VIDEO_COMMAND, {
				src: urlLink,
				width: 400,
				height: 300,
			});

			clearTextEditorState();
		}
	}, [urlLink, activeEditor, type, clearTextEditorState]);

	return (
		<UploadFiles
			uploadFileData={{
				isForTextEditor: true,
				textEditorData: {
					type: "video",
					accept: "video/*",
					id: "video-lexical",
				},
			}}
		/>
	);
};

const mapStateToProps = (state) => {
	return {
		urlLink: state.getIn(["textEditor", "file", "url"]),
		type: state.getIn(["textEditor", "file", "type"]),
	};
};

export default connect(mapStateToProps, { clearTextEditorState })(InsertVideo);
