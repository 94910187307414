import React from "react";
import {
	buildingTypeConstants,
	companyTypesConstants,
	componentCategoryConstants,
	deliveryFormatConstants,
	inputFormatConstants,
	subscriptionTypes,
	userRolesConstants,
} from "../constants/constants";
import CommercialIcon from "../shared/icons/buildingTypes/CommercialIcon";
import HealthcareIcon from "../shared/icons/buildingTypes/HealthcareIcon";
import PublicIcon from "../shared/icons/buildingTypes/PublicIcon";
import ResidentialIcon from "../shared/icons/buildingTypes/ResidentialIcon";
import TransportIcon from "../shared/icons/buildingTypes/TransportIcon";
import BimifyIcon from "../shared/icons/companyTypes/BimifyIcon";
import ClientIcon from "../shared/icons/companyTypes/ClientIcon";
import ModelingPartnerIcon from "../shared/icons/companyTypes/ModelingPartnerIcon";
import ResellerIcon from "../shared/icons/companyTypes/ResellerIcon";
import ScanningPartnerIcon from "../shared/icons/companyTypes/ScanningPartnerIcon";
import ImageIcon from "../shared/icons/ImageIcon";
import EnterpriseIcon from "../shared/icons/subscriptionPlans/EnterpriseIcon";
import ExclusiveIcon from "../shared/icons/subscriptionPlans/ExclusiveIcon";
import GoIcon from "../shared/icons/subscriptionPlans/GoIcon";
import MiniIcon from "../shared/icons/subscriptionPlans/MiniIcon";
import ProIcon from "../shared/icons/subscriptionPlans/ProIcon";
import AdminIcon from "../shared/icons/userTypes/AdminIcon";
import BusinessOwnerIcon from "../shared/icons/userTypes/BusinessOwnerIcon";
import ProjectLeadIcon from "../shared/icons/userTypes/ProjectLeadIcon";
import OtherIcon from "../shared/icons/buildingTypes/OtherIcon";
import RevitIcon from "../shared/icons/deliveryFormats/RevitIcon";
import ArchiCADIcon from "../shared/icons/deliveryFormats/ArchiCADIcon";
import PointCloudIcon from "../shared/icons/inputFormats/PointCloudIcon";
import CADIcon from "../shared/icons/inputFormats/CADIcon";
import ImageInputFormatIcon from "../shared/icons/inputFormats/ImageInputFormatIcon";
import DoorsIcon from "../shared/icons/lod-specification/architecture/DoorsIcon";
import WindowsIcon from "../shared/icons/lod-specification/architecture/WindowsIcon";
import RoofsIcon from "../shared/icons/lod-specification/architecture/RoofsIcon";
import RoomsIcon from "../shared/icons/lod-specification/architecture/RoomsIcon";
import WallsIcon from "../shared/icons/lod-specification/architecture/WallsIcon";
import StairsIcon from "../shared/icons/lod-specification/architecture/StairsIcon";
import NormalizeIcon from "../shared/icons/status/NormalizeIcon";
import CustomizeIcon from "../shared/icons/status/CustomizeIcon";
import QAIcon from "../shared/icons/status/QAIcon";

/**
 * Returns desired subscription plan icon
 * @param {string} plan
 * @param {string} iconClass
 * @param {boolean} skipDefaultCase
 * @returns HTML snippet or null if unmatched
 */
export const getPlanIcon = (plan, iconClass = "", skipDefaultCase = true) => {
	if (!plan & skipDefaultCase) {
		return null;
	}

	plan = plan.toLowerCase().split(" ")[0];

	switch (plan) {
		case subscriptionTypes.MINI:
			return <MiniIcon iconClass={iconClass} />;

		case subscriptionTypes.GO:
			return <GoIcon iconClass={iconClass} />;

		case subscriptionTypes.PRO:
			return <ProIcon iconClass={iconClass} />;

		case subscriptionTypes.ENTERPRISE:
			return <EnterpriseIcon iconClass={iconClass} />;

		case subscriptionTypes.EXCLUSIVE:
			return <ExclusiveIcon iconClass={iconClass} />;

		default:
			return skipDefaultCase ? null : <ImageIcon iconClass={iconClass} />;
	}
};

/**
 * Returns desired company type icon
 * @param {string} type
 * @param {string} iconClass
 * @returns HTML snippet or null if unmatched
 */
export const getCompanyTypeIcon = (type, iconClass = "") => {
	switch (type) {
		case companyTypesConstants.BIMIFY:
			return <BimifyIcon iconClass={iconClass} />;

		case companyTypesConstants.RESELLER:
			return <ResellerIcon iconClass={iconClass} />;

		case companyTypesConstants.CLIENT:
			return <ClientIcon iconClass={iconClass} />;

		case companyTypesConstants.PROPERTY_OWNER:
			return <ResellerIcon iconClass={iconClass} />;

		case companyTypesConstants.DEVELPER:
			return <ClientIcon iconClass={iconClass} />;

		case companyTypesConstants.CONSULATNT:
			return <ClientIcon iconClass={iconClass} />;

		case companyTypesConstants.MODELING_PARTNER:
			return <ModelingPartnerIcon iconClass={iconClass} />;

		case companyTypesConstants.SCANNING_PARTNER:
			return <ScanningPartnerIcon iconClass={iconClass} />;

		default:
			return null;
	}
};

/**
 * Returns desired user role icon
 * @param {string} role
 * @param {string} iconClass
 * @returns HTML snippet or null if unmatched
 */
export const getUserRoleIcon = (role, iconClass = "") => {
	switch (role) {
		case userRolesConstants.BUSINESS_OWNER:
			return <BusinessOwnerIcon iconClass={iconClass} />;

		case userRolesConstants.PROJECT_LEAD:
			return <ProjectLeadIcon iconClass={iconClass} />;

		case userRolesConstants.ADMIN:
			return <AdminIcon iconClass={iconClass} />;

		case userRolesConstants.NORMALIZER:
			return <NormalizeIcon iconClass={iconClass} />;

		case userRolesConstants.CUSTOMIZER:
			return <CustomizeIcon iconClass={iconClass} />;

		case userRolesConstants.QUALITY_CONTROL:
			return <QAIcon iconClass={iconClass} />;

		default:
			return <ProjectLeadIcon iconClass={iconClass} />;
	}
};

export const getBuildingTypeIcon = (type) => {
	switch (type) {
		case buildingTypeConstants.COMMERCIAL:
			return <CommercialIcon iconClass={"d-flex icon--24"} />;

		case buildingTypeConstants.HEALTHCARE:
			return <HealthcareIcon iconClass={"d-flex"} />;

		case buildingTypeConstants.RESIDENTAL:
			return <ResidentialIcon iconClass={"d-flex"} />;

		case buildingTypeConstants.PUBLIC:
			return <PublicIcon iconClass={"d-flex"} />;

		case buildingTypeConstants.TRANSPORT:
			return <TransportIcon iconClass={"d-flex"} />;

		default:
			return <OtherIcon iconClass={"d-flex  icon--24"} />;
	}
};

export const getDeliveryFormatIcon = (type) => {
	switch (type) {
		case deliveryFormatConstants.REVIT:
			return <RevitIcon iconClass={"d-flex"} />;

		default:
			return <ArchiCADIcon iconClass={"d-flex"} />;
	}
};

/**
 *
 * @param {*} type
 * @param {*} status
 * @returns
 */
export const getInputFormatIcon = (
	type,
	status = null,
	fill = "fill--n300"
) => {
	switch (type) {
		case inputFormatConstants.POINT_CLOUD:
			return (
				<PointCloudIcon
					iconClass={`d-flex ${
						status ? `icon__status--${status}` : `icon--target-fill ${fill}`
					}`}
				/>
			);

		case inputFormatConstants.CAD:
			return (
				<CADIcon
					iconClass={`d-flex ${
						status ? `icon__status--${status}` : `icon--target-fill ${fill}`
					}`}
				/>
			);

		default:
			return (
				<ImageInputFormatIcon
					iconClass={`d-flex ${
						status ? `icon__status--${status}` : `icon--target-fill ${fill}`
					}`}
				/>
			);
	}
};

export const getComponentCategoryIcon = (type) => {
	switch (type) {
		case componentCategoryConstants.doors:
			return <DoorsIcon iconClass={"d-flex"} />;

		case componentCategoryConstants.windows:
			return <WindowsIcon iconClass={"d-flex"} />;

		case componentCategoryConstants.roofs:
			return <RoofsIcon iconClass={"d-flex"} />;

		case componentCategoryConstants.rooms:
			return <RoomsIcon iconClass={"d-flex"} />;

		case componentCategoryConstants.interiorWalls:
			return <WallsIcon iconClass={"d-flex"} />;

		case componentCategoryConstants.exteriorWalls:
			return <WallsIcon iconClass={"d-flex"} />;

		case componentCategoryConstants.curtainWalls:
			return <WallsIcon iconClass={"d-flex"} />;

		case componentCategoryConstants.stairs:
			return <StairsIcon iconClass={"d-flex"} />;

		default:
			return null;
	}
};
