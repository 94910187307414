import axios from "axios";

import { removeItemFromLocalStorage } from "../utils/local-storage.util";

/**
 * API creator, used in actions
 */
let baseUrl;

if (typeof window.__ENV__.REACT_APP_ENV !== "undefined") {
	if (typeof window.__ENV__.REACT_APP_API !== "undefined") {
		baseUrl = `${window.__ENV__.REACT_APP_API}/api/v1`;
	} else {
		console.log("REACT_APP_API environment variable is not defined ");
	}
} else {
	console.log("REACT_APP_ENV environment variable is not defined ");
}

export const baseURL = baseUrl;

export const api = axios.create({
	baseURL,
	headers: {
		"Content-Type": "application/json; charset=utf-8",
		Accept: "application/json",
	},
});

const digitalOceanApiConfig = {
	baseURL,
	headers: {
		Accept: "application/json",
		"Access-Control-Allow-Origin": "*",
	},
	crossDomain: true,
};

export const apiDO = axios.create(digitalOceanApiConfig);

// Response interceptor for API calls
api.interceptors.response.use(
	(response) => {
		return response;
	},
	(error) => {
		console.log({ error });
		if (axios.isCancel(error)) {
			return Promise.reject({ isCancel: true });
		}
		if (
			error.response &&
			error.response.status === 401 &&
			error.response.statusText === "Unauthorized"
		) {
			removeItemFromLocalStorage("Session");
			removeItemFromLocalStorage("redirectToPath");
			removeItemFromLocalStorage("Customer");
			removeItemFromLocalStorage("bimifyProgress");
			window.location = "/login";
		} else if (
			//error.message === "Network Error" ||
			error.response &&
			(error.response.status === 502 || error.response.status === 503)
		) {
			localStorage.clear();
			window.location.href = "/server-error";
		} else {
			return Promise.reject(error);
		}
	}
);

/**
 * Get cancel token source
 * @returns source
 */
export const getCancelSource = () => {
	const CancelToken = axios.CancelToken;
	return CancelToken.source();
};
