import React, { useCallback } from "react";
import { connect } from "react-redux";
import {
	getPresignedPostUploadUrl,
	setManualLoader,
	uploadToDigitalOcean,
} from "../../../../../actions";
import { uploadFile } from "../../../UploadFile/uploadFile";
import { INSERT_IMAGE_COMMAND } from "../Toolbar";
import FileInput from "./FileInput";

const InsertImage = ({ activeEditor, type, accept, id, ...props }) => {
	const { getPresignedPostUploadUrl, uploadToDigitalOcean, setManualLoader } =
		props;

	const insertFile = useCallback(
		(files) => {
			setManualLoader(true);

			if (files[0]) {
				const file = files[0];

				//------------------
				const dbFileData = {
					file: {
						name: file.name,
						size: file.size,
					},
					path: null,
					isForTextEditor: true,
				};
				const preSignedPostUploadUrlBody = {
					fileName: file.name,
					isForTextEditor: true,
				};
				//------------------
				uploadFile({
					dbFileData,
					file: file,
					preSignedPostUploadUrlBody,
					uploadToDigitalOcean,
					getPresignedPostUploadUrl,
					cbDBUpload: ({ urlLink }) => {
						activeEditor.dispatchCommand(INSERT_IMAGE_COMMAND, {
							src: urlLink,
							width: "auto",
							height: 100,
						});

						setManualLoader(false);
					},
				});
			}
		},
		[
			activeEditor,
			getPresignedPostUploadUrl,
			uploadToDigitalOcean,
			setManualLoader,
		]
	);

	return <FileInput customOnChange={insertFile} />;
};

export default connect(null, {
	getPresignedPostUploadUrl,
	uploadToDigitalOcean,
	setManualLoader,
})(InsertImage);
