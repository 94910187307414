import localize, { LocKeys } from "../../constants/localizations";
import { permissions } from "../../constants/permissionConstants";
import { getPermissions, tokenParams } from "../../utils/local-storage.util";

export const ticketItemKey = {
	offer: "offer",
	building: "building",
	job: "job",
};

export const ticketAssigneeKeyConstants = {
	company: "company",
	user: "user",
};

export const ticketPriorityConstants = {
	low: "low",
	high: "high",
	medium: "medium",
};

export const getPriorityLabel = (priority) => {
	switch (priority) {
		case ticketPriorityConstants.high:
			return localize(LocKeys.HIGH);

		case ticketPriorityConstants.medium:
			return localize(LocKeys.MEDIUM);

		default:
			return localize(LocKeys.LOW);
	}
};

export const getItemLabel = (item) => {
	switch (item) {
		case ticketItemKey.offer:
			return localize(LocKeys.OFFER);

		case ticketItemKey.building:
			return localize(LocKeys.BUILDING);

		default:
			return localize(LocKeys.JOB);
	}
};

export const getItemLink = (item) => {
	switch (item) {
		case ticketItemKey.offer:
			return "offers";

		case ticketItemKey.building:
			return "buildings";

		default:
			return "offers";
	}
};

export const getItemValue = (item, itemName, tempItem) => {
	if (item === tempItem) {
		return itemName?.value || null;
	}

	return null;
};

export const getTicketItemOptions = () => {
	const loggedUser = tokenParams();
	const isBimifyAdmin = loggedUser.isBimifyAdmin;
	const loggedUserPermissions = getPermissions(); //loggedUser.permissions;
	const itemOptions = [];

	if (
		isBimifyAdmin ||
		loggedUserPermissions?.offers?.permissions?.includes(permissions.view)
	) {
		itemOptions.push({
			value: ticketItemKey.offer,
			label: localize(LocKeys.OFFER),
		});
	}

	if (
		isBimifyAdmin ||
		loggedUserPermissions?.buildings?.permissions?.includes(permissions.view) ||
		loggedUserPermissions?.jobs?.permissions?.includes(permissions.view)
	) {
		itemOptions.push({
			value: ticketItemKey.building,
			label: localize(LocKeys.BUILDING),
		});
	}

	return itemOptions;
};
