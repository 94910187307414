import React from "react";
import { connect } from "react-redux";
import LexicalEditor from "../../../../shared/components/TextEditor/Lexical/LexicalEditor";

const SummaryEditor = (props) => {
	const {
		wrapperEditorClasses = "",
		label,
		placeholder,
		manual = "",
		updateEditorState,
		editable,
	} = props;

	if (!editable && !manual) {
		return null;
	}

	return (
		<LexicalEditor
			scrollerClasses="max-h-470p"
			placeholder={placeholder}
			editorState={manual}
			onChange={updateEditorState}
			hideTableAndImage={true}
			editable={editable}
			label={label}
			wrapperEditorClasses={wrapperEditorClasses}
		/>
	);
};

const mapStateToProps = (state) => {
	return {
		manual: state.getIn(["dashboard", "tempSalesPackage", "summary"]) || "",
	};
};

export default connect(mapStateToProps, {})(SummaryEditor);
