import React from "react";

const PublicIcon = (props) => {
	return (
		<div className={`icon ${props.iconClass}`}>
			<svg
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<g clipPath="url(#clip0_3899_23602)">
					<path
						d="M22.5672 20.791H1.42969V22.8004H22.5672V20.791Z"
						fill="#FFC350"
					/>
					<path
						d="M12 1.43262L17.2846 4.83818L22.5692 8.24374H1.43164L6.71621 4.83818L12 1.43262Z"
						fill="white"
					/>
					<path
						d="M22.5681 8.47681H1.42859C1.37855 8.47675 1.32987 8.46035 1.28983 8.43007C1.24979 8.39979 1.22054 8.35725 1.20645 8.30881C1.19252 8.26039 1.19448 8.20874 1.21202 8.16154C1.22957 8.11434 1.26176 8.07412 1.3038 8.04687L11.8726 1.23575C11.9098 1.21189 11.9529 1.19922 11.997 1.19922C12.041 1.19922 12.0842 1.21189 12.1214 1.23575L22.6925 8.04804C22.7345 8.07529 22.7667 8.1155 22.7842 8.16271C22.8018 8.20991 22.8037 8.26156 22.7898 8.30998C22.7755 8.35814 22.7463 8.40037 22.7063 8.43042C22.6664 8.46047 22.6179 8.47674 22.5681 8.47681ZM2.22217 8.00906H21.776L11.9989 1.71012L2.22217 8.00906Z"
						fill="#EE6712"
					/>
					<path
						d="M6.3492 9.41113H4.33203V20.7909H6.3492V9.41113Z"
						fill="#FFC350"
					/>
					<path
						d="M6.34858 21.0245H4.33143C4.26994 21.0245 4.21097 20.9999 4.1675 20.956C4.12403 20.9121 4.09961 20.8527 4.09961 20.7906V9.41161C4.09961 9.34958 4.12403 9.29009 4.1675 9.24623C4.21097 9.20237 4.26994 9.17773 4.33143 9.17773H6.34858C6.41006 9.17773 6.46903 9.20237 6.51251 9.24623C6.55598 9.29009 6.5804 9.34958 6.5804 9.41161V20.7914C6.58019 20.8533 6.55567 20.9126 6.51222 20.9563C6.46877 21 6.40993 21.0245 6.34858 21.0245ZM4.56285 20.5568H6.11754V9.64275H4.56285V20.5568Z"
						fill="#EE6712"
					/>
					<path
						d="M10.8785 9.41113H8.86133V20.7909H10.8785V9.41113Z"
						fill="#FFC350"
					/>
					<path
						d="M10.8779 21.0245H8.86072C8.79924 21.0245 8.74027 20.9999 8.6968 20.956C8.65332 20.9121 8.62891 20.8527 8.62891 20.7906V9.41161C8.62891 9.34958 8.65332 9.29009 8.6968 9.24623C8.74027 9.20237 8.79924 9.17773 8.86072 9.17773H10.8779C10.9394 9.17773 10.9983 9.20237 11.0418 9.24623C11.0853 9.29009 11.1097 9.34958 11.1097 9.41161V20.7914C11.1095 20.8533 11.085 20.9126 11.0415 20.9563C10.9981 21 10.9392 21.0245 10.8779 21.0245ZM9.09215 20.5568H10.6465V9.64275H9.09215V20.5568Z"
						fill="#EE6712"
					/>
					<path
						d="M15.4039 9.41113H13.3867V20.7909H15.4039V9.41113Z"
						fill="#FFC350"
					/>
					<path
						d="M15.4048 21.0245H13.3861C13.3246 21.0245 13.2657 20.9999 13.2222 20.956C13.1787 20.9121 13.1543 20.8527 13.1543 20.7906V9.41161C13.1543 9.34958 13.1787 9.29009 13.2222 9.24623C13.2657 9.20237 13.3246 9.17773 13.3861 9.17773H15.4033C15.4648 9.17773 15.5237 9.20237 15.5672 9.24623C15.6107 9.29009 15.6351 9.34958 15.6351 9.41161V20.7914C15.6349 20.853 15.6106 20.9121 15.5675 20.9557C15.5243 20.9994 15.4659 21.0241 15.4048 21.0245ZM13.6191 20.5568H15.1734V9.64275H13.6179L13.6191 20.5568Z"
						fill="#EE6712"
					/>
					<path
						d="M19.9332 9.41113H17.916V20.7909H19.9332V9.41113Z"
						fill="#FFC350"
					/>
					<path
						d="M19.9326 21.0245H17.9154C17.885 21.0245 17.8548 21.0185 17.8267 21.0067C17.7986 20.9949 17.773 20.9777 17.7515 20.956C17.73 20.9343 17.7129 20.9085 17.7012 20.8801C17.6896 20.8518 17.6836 20.8213 17.6836 20.7906V9.41161C17.6836 9.38089 17.6896 9.35048 17.7012 9.32211C17.7129 9.29373 17.73 9.26795 17.7515 9.24623C17.773 9.22452 17.7986 9.20729 17.8267 9.19554C17.8548 9.18378 17.885 9.17773 17.9154 9.17773H19.9326C19.963 9.17773 19.9932 9.18378 20.0213 9.19554C20.0494 9.20729 20.075 9.22452 20.0965 9.24623C20.118 9.26795 20.1351 9.29373 20.1467 9.32211C20.1584 9.35048 20.1644 9.38089 20.1644 9.41161V20.7914C20.1642 20.8533 20.1397 20.9126 20.0962 20.9563C20.0528 21 19.9939 21.0245 19.9326 21.0245ZM18.1468 20.5568H19.7015V9.64275H18.1468V20.5568Z"
						fill="#EE6712"
					/>
					<path
						d="M21.8271 9.6445H2.17127C2.10979 9.6445 2.05083 9.61986 2.00736 9.576C1.96388 9.53214 1.93945 9.47266 1.93945 9.41063C1.93945 9.3486 1.96388 9.28912 2.00736 9.24526C2.05083 9.2014 2.10979 9.17676 2.17127 9.17676H21.8271C21.8576 9.17676 21.8877 9.18281 21.9158 9.19456C21.944 9.20631 21.9695 9.22354 21.991 9.24526C22.0126 9.26697 22.0296 9.29276 22.0413 9.32113C22.0529 9.3495 22.0589 9.37992 22.0589 9.41063C22.0589 9.44134 22.0529 9.47175 22.0413 9.50013C22.0296 9.5285 22.0126 9.55429 21.991 9.576C21.9695 9.59772 21.944 9.61495 21.9158 9.6267C21.8877 9.63845 21.8576 9.6445 21.8271 9.6445Z"
						fill="#EE6712"
					/>
					<path
						d="M21.8271 21.0244H2.17127C2.10979 21.0244 2.05083 20.9997 2.00736 20.9559C1.96388 20.912 1.93945 20.8525 1.93945 20.7905C1.93945 20.7285 1.96388 20.669 2.00736 20.6251C2.05083 20.5813 2.10979 20.5566 2.17127 20.5566H21.8271C21.8576 20.5566 21.8877 20.5627 21.9158 20.5744C21.944 20.5862 21.9695 20.6034 21.991 20.6251C22.0126 20.6469 22.0296 20.6726 22.0413 20.701C22.0529 20.7294 22.0589 20.7598 22.0589 20.7905C22.0589 20.8212 22.0529 20.8516 22.0413 20.88C22.0296 20.9084 22.0126 20.9342 21.991 20.9559C21.9695 20.9776 21.944 20.9948 21.9158 21.0066C21.8877 21.0183 21.8576 21.0244 21.8271 21.0244Z"
						fill="#EE6712"
					/>
				</g>
				<defs>
					<clipPath id="clip0_3899_23602">
						<rect
							width="21.6"
							height="21.6"
							fill="white"
							transform="translate(1.19922 1.2002)"
						/>
					</clipPath>
				</defs>
			</svg>
		</div>
	);
};

export default PublicIcon;
