import React from "react";
import HeaderLayout from "../../../../../layout/content/HeaderLayout";
import localize, { LocKeys } from "../../../../../../constants/localizations";

const HeaderSymbolForm = ({ editView = false }) => {
	return (
		<HeaderLayout wrapperClasses={`d-flex align-items-center px-24 py-14`}>
			<h3>
				{editView
					? localize(LocKeys.EDIT_SYMBOL)
					: localize(LocKeys.CREATE_SYMBOL)}
			</h3>
		</HeaderLayout>
	);
};

export default HeaderSymbolForm;
