import React from "react";
import { baseURL } from "../../api";
import { useHistory, useParams } from "react-router-dom";
import { Labeling } from "@bimify-app/labeling-tool";
import { getToken } from "../../utils/local-storage.util";

const LabelingTool = (props) => {
	const { jobId, disciplineId } = useParams();
	const history = useHistory();

	return (
		<>
			<Labeling
				baseURL={baseURL}
				socketURL={window.__ENV__.REACT_APP_API}
				jobId={jobId}
				disciplineId={disciplineId}
				accessToken={getToken()}
				onCancel={() => history.goBack()}
			/>
		</>
	);
};

export default LabelingTool;
