import "../assets/styles/input.scss";
import * as React from "react";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import { colorConstants, fileSizes } from "../../../../../constants/constants";
import { useFileInput } from "../../../../../hooks/useFileInput";

export default function FileInput({
	customOnChange,
	accept = "image/*",
	id = "image-lexical",
	type = "image",
	allowedSize = fileSizes.GB_5,
}) {
	const { onChange } = useFileInput({ id, customOnChange, allowedSize });
	return (
		<div className="InputFile__wrapper toolbar-item spaced">
			<input
				id={id}
				hidden
				type="file"
				multiple={false}
				accept={accept}
				name={id}
				onChange={onChange}
			/>
			<label
				htmlFor={id}
				className={`InputFile__label d-flex align-items-center`}
			>
				{type === "image" ? (
					<i className="LexicalEditor__icon image" />
				) : (
					<PlayCircleOutlineIcon style={{ color: colorConstants.N500 }} />
				)}
				<span className="text">
					{type === "image" ? "Image" : <span className="ml-xs">Video</span>}
				</span>
			</label>
		</div>
	);
}
