import { reset } from "redux-form";

/**
 * Main redux-action file
 */
export * from "./authActions";
export * from "./companyActions";
export * from "./userActions";
export * from "./orderActions";
export * from "./buildingActions";
export * from "./administrationActions";
export * from "./invoiceActions";
export * from "./companySpecsActions";
export * from "./chatActions";
export * from "./orderTempActions";
export * from "./publicActions";
export * from "./fileActions";
export * from "./digitalOceanActions";
export * from "./globalWidgetActions";
export * from "./jobActions";
export * from "./globalActions";
export * from "./lodSpecificationActions";
export * from "./tempAdministrationAction";
export * from "./priceActions";
export * from "./dashboardActions";
export * from "./manualActions";
export * from "./symbolActions";
export * from "./queryParameterActions";
export * from "./ticketingActions";
export * from "./familyActions";
export * from "./templateActions";

export const resetForm = (formName) => {
	if (!formName) {
		return;
	}

	return reset(formName);
};
