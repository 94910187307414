import chatConstants from "../constants/chatConstants";
import { api } from "../api";
import { getToken } from "../utils/local-storage.util";

/**
 * Get messages by room
 * @param {string} room - room name
 */
export const getConversationsByRoom = (room, limit) => {
	return (dispatch) => {
		const requestOptions = {
			method: "PUT",
			url: `/message/by-room/${room}`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
			data: { limit },
		};
		return api(requestOptions).then(
			(res) => {
				let data = res.data;
				dispatch({ type: chatConstants.ROOM_CONVERSATION, data });
			},
			(err) => {
				dispatch(chatActionGetFailure(err.response.data.message));
			}
		);
	};
};

/**
 * Get unread messages by user
 */
export const getTotalUnreadMessages = () => {
	return (dispatch) => {
		const requestOptions = {
			method: "GET",
			url: "/message/count-unread",
			headers: {
				Authorization: "Bearer " + getToken(),
			},
		};
		return api(requestOptions).then(
			(res) => {
				let data = res.data;
				dispatch({
					type: chatConstants.UNREAD_TOTAL_MESSAGES,
					data,
				});
			},
			(err) => {
				dispatch(chatActionGetFailure(err.response.data.message));
			}
		);
	};
};

/**
 * Get unread messages by user for room
 */
export const getRoomUnreadMessages = (room) => {
	return (dispatch) => {
		const requestOptions = {
			method: "GET",
			url: `/message/count-unread-room/${room}`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
		};
		return api(requestOptions).then(
			(res) => {
				let data = res.data;
				data.room = room;
				dispatch({
					type: chatConstants.UNREAD_ROOM_MESSAGES,
					data,
				});
			},
			(err) => {
				dispatch(chatActionGetFailure(err.response.data.message));
			}
		);
	};
};

/**
 * Set has active chat room
 * @param {boolean} hasRoom
 */
export const setHasActiveChatRoom = (hasRoom) => {
	return { type: chatConstants.SET_HAS_ACTIVE_CHAT_ROOM, hasRoom };
};

/**
 * Set has active chat room
 * @param {boolean} hasRoom
 */
export const setActiveChatRoom = (data) => {
	return { type: chatConstants.SET_ACTIVE_ROOM, data };
};

//ALL METHODS DOWN - Used for creating custom action with type and payload

export const chatActionGetFailure = (error) => {
	return { type: chatConstants.ACTION_GET_FAILURE, error };
};
