import React from "react";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import "./linearProgress.scss";

const LinearProgressWithLabel = (props) => {
	const { value } = props;
	return (
		<Box display="flex" alignItems="center" className="pos-rel">
			<Box width="100%" mr={1}>
				<LinearProgress
					variant="determinate"
					{...props}
					classes={{
						root: `linear-progress ${
							+value === 100 ? "linear-progress--done" : ""
						}`,
					}}
				/>
			</Box>
		</Box>
	);
};

export default LinearProgressWithLabel;
