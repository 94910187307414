import React from "react";

const CommercialIcon = (props) => {
	return (
		<div className={`icon ${props.iconClass}`}>
			<svg
				width="40"
				height="40"
				viewBox="0 0 40 40"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<g clipPath="url(#clip0_336_0)">
					<path
						d="M24.5864 4.22729H15.4136C15.075 4.22729 14.8005 4.49835 14.8005 4.8327V6.3323C14.8005 6.66666 15.075 6.93771 15.4136 6.93771H24.5864C24.925 6.93771 25.1995 6.66666 25.1995 6.3323V4.8327C25.1995 4.49835 24.925 4.22729 24.5864 4.22729Z"
						fill="#FFC350"
					/>
					<path
						d="M28.5636 6.93832H11.4376C11.099 6.93832 10.8245 7.20937 10.8245 7.54373V37.0332C10.8245 37.3675 11.099 37.6386 11.4376 37.6386H28.5636C28.9023 37.6386 29.1768 37.3675 29.1768 37.0332V7.54373C29.1768 7.20937 28.9023 6.93832 28.5636 6.93832Z"
						fill="white"
					/>
					<path
						d="M28.8095 38.0006H11.193C10.9979 38.0006 10.8108 37.9241 10.6728 37.7878C10.5348 37.6516 10.4573 37.4668 10.4573 37.2741V7.30217C10.4573 7.1095 10.5348 6.92471 10.6728 6.78847C10.8108 6.65222 10.9979 6.57568 11.193 6.57568H28.8107C28.9073 6.57568 29.003 6.59447 29.0923 6.63098C29.1816 6.66749 29.2626 6.72101 29.331 6.78847C29.3993 6.85593 29.4535 6.93602 29.4905 7.02416C29.5274 7.1123 29.5465 7.20677 29.5465 7.30217V37.2753C29.5463 37.3707 29.5271 37.4652 29.49 37.5533C29.4529 37.6413 29.3985 37.7213 29.3301 37.7887C29.2617 37.856 29.1805 37.9094 29.0912 37.9458C29.0018 37.9821 28.9061 38.0008 28.8095 38.0006ZM11.193 7.30217V37.2753H28.8113V7.30217H11.193Z"
						fill="#EE6712"
					/>
					<path
						d="M37.6328 38H2.36667C2.2691 38 2.17554 37.9617 2.10655 37.8936C2.03756 37.8255 1.99878 37.7331 1.99878 37.6368C1.99878 37.5404 2.03756 37.448 2.10655 37.3799C2.17554 37.3118 2.2691 37.2735 2.36667 37.2735H37.6328C37.7304 37.2735 37.8239 37.3118 37.8929 37.3799C37.9619 37.448 38.0006 37.5404 38.0006 37.6368C38.0006 37.7331 37.9619 37.8255 37.8929 37.8936C37.8239 37.9617 37.7304 38 37.6328 38Z"
						fill="#EE6712"
					/>
					<path
						d="M28.5636 6.93832H11.4376C11.099 6.93832 10.8245 7.20937 10.8245 7.54373V11.5655C10.8245 11.8998 11.099 12.1709 11.4376 12.1709H28.5636C28.9023 12.1709 29.1768 11.8998 29.1768 11.5655V7.54373C29.1768 7.20937 28.9023 6.93832 28.5636 6.93832Z"
						fill="#FFC350"
					/>
					<path
						d="M28.8095 12.5329H11.193C10.9979 12.5329 10.8108 12.4564 10.6728 12.3201C10.5348 12.1839 10.4573 11.9991 10.4573 11.8064V7.30217C10.4573 7.1095 10.5348 6.92471 10.6728 6.78847C10.8108 6.65222 10.9979 6.57568 11.193 6.57568H28.8107C28.9073 6.57568 29.003 6.59447 29.0923 6.63098C29.1816 6.66749 29.2626 6.72101 29.331 6.78847C29.3993 6.85593 29.4535 6.93602 29.4905 7.02416C29.5274 7.1123 29.5465 7.20677 29.5465 7.30217V11.81C29.5455 12.0023 29.4674 12.1863 29.3292 12.3218C29.1911 12.4573 29.0042 12.5332 28.8095 12.5329ZM11.193 7.30217V11.81H28.8113V7.30217H11.193Z"
						fill="#EE6712"
					/>
					<path
						d="M16.1157 34.3579C16.0181 34.3579 15.9245 34.3196 15.8555 34.2515C15.7866 34.1834 15.7478 34.091 15.7478 33.9946V15.813C15.7478 15.7167 15.7866 15.6243 15.8555 15.5561C15.9245 15.488 16.0181 15.4498 16.1157 15.4498C16.2132 15.4498 16.3068 15.488 16.3758 15.5561C16.4448 15.6243 16.4835 15.7167 16.4835 15.813V33.9952C16.4834 34.0915 16.4446 34.1837 16.3756 34.2517C16.3066 34.3197 16.2131 34.3579 16.1157 34.3579Z"
						fill="#EE6712"
					/>
					<path
						d="M20.0009 34.3579C19.9526 34.3579 19.9048 34.3485 19.8601 34.3302C19.8155 34.312 19.775 34.2852 19.7408 34.2515C19.7066 34.2177 19.6795 34.1777 19.661 34.1336C19.6426 34.0896 19.6331 34.0423 19.6331 33.9946V15.813C19.6331 15.7653 19.6426 15.7181 19.661 15.674C19.6795 15.6299 19.7066 15.5899 19.7408 15.5561C19.775 15.5224 19.8155 15.4957 19.8601 15.4774C19.9048 15.4591 19.9526 15.4498 20.0009 15.4498C20.0985 15.4498 20.1921 15.488 20.2611 15.5561C20.33 15.6243 20.3688 15.7167 20.3688 15.813V33.9952C20.3686 34.0915 20.3298 34.1837 20.2608 34.2517C20.1919 34.3197 20.0984 34.3579 20.0009 34.3579Z"
						fill="#EE6712"
					/>
					<path
						d="M23.8844 34.3579C23.7868 34.3579 23.6933 34.3196 23.6243 34.2515C23.5553 34.1834 23.5165 34.091 23.5165 33.9946V15.813C23.5165 15.7167 23.5553 15.6243 23.6243 15.5561C23.6933 15.488 23.7868 15.4498 23.8844 15.4498C23.982 15.4498 24.0755 15.488 24.1445 15.5561C24.2135 15.6243 24.2522 15.7167 24.2522 15.813V33.9952C24.2521 34.0915 24.2133 34.1837 24.1443 34.2517C24.0753 34.3197 23.9819 34.3579 23.8844 34.3579Z"
						fill="#EE6712"
					/>
					<path
						d="M20.0009 7.30035C19.9526 7.30035 19.9048 7.29095 19.8601 7.2727C19.8155 7.25444 19.775 7.22768 19.7408 7.19395C19.7066 7.16022 19.6795 7.12018 19.661 7.07611C19.6426 7.03204 19.6331 6.9848 19.6331 6.9371V2.36263C19.6331 2.31493 19.6426 2.2677 19.661 2.22363C19.6795 2.17956 19.7066 2.13951 19.7408 2.10578C19.775 2.07205 19.8155 2.04529 19.8601 2.02704C19.9048 2.00879 19.9526 1.99939 20.0009 1.99939C20.0985 1.99939 20.1921 2.03766 20.2611 2.10578C20.33 2.1739 20.3688 2.2663 20.3688 2.36263V6.93771C20.3686 7.03394 20.3298 7.12618 20.2608 7.19417C20.1919 7.26216 20.0984 7.30035 20.0009 7.30035Z"
						fill="#EE6712"
					/>
				</g>
				<defs>
					<clipPath id="clip0_336_0">
						<rect
							width="36"
							height="36"
							fill="white"
							transform="translate(2 2)"
						/>
					</clipPath>
				</defs>
			</svg>
		</div>
	);
};

export default CommercialIcon;
