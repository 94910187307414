import { destroy } from "redux-form";
import { api } from "../api";
import localize, { LocKeys } from "../constants/localizations";
import { ticketingConstants } from "../constants/ticketingConstants";
import { getItemFromLocalStorage, getToken } from "../utils/local-storage.util";
import { statusConstants } from "../constants/constants";
import { getPagination, getTicketFilters } from "../utils/table-filter";
import { refreshToken } from "./authActions";
import { clearBuildingUsers } from "./buildingActions";
import { clearOrderUsers } from "./orderActions";

/**
 * GET all tickets
 *
 * ON SUCCESS:
 * 	- dispatch GET_TICKETS action - set tickets data
 * 	- dispatch GET_TICKET_STATS action - get tickets stats
 *
 * @param {number} page - current page
 * @param {number} limit - current limit
 * @param {string} searchQuery - search query
 * @param {Array<string>} searchFilters - filters
 * @param {number} customer - customer id
 * @returns
 */
export const getTickets = ({
	page,
	searchQuery = "",
	limit,
	searchFilters,
	customer = getItemFromLocalStorage("Customer"),
	controller = null,
}) => {
	return (dispatch, getState) => {
		const state = getState();

		const ticketQueryParameter = state.getIn([
			"queryParameter",
			"ticketQueryParameter",
		]);
		const tablePagination = state.getIn(["form", "tablePagination", "values"]);

		const { filters, search = "" } = getTicketFilters({
			queryParameter: ticketQueryParameter,
			searchFilters,
			searchQuery,
		});
		const { currentPage, currentLimit } = getPagination({
			tablePagination,
			page,
			limit,
		});

		const requestOptions = {
			method: "PUT",
			url: `/tickets`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
			data: {
				page: currentPage,
				search,
				limit: currentLimit,
				filters,
				selectedCompany: customer,
			},
			signal: controller?.signal || null,
		};
		return api(requestOptions).then(
			(res) => {
				dispatch({
					type: ticketingConstants.GET_TICKETS,
					data: res?.data,
				});

				dispatch(refreshToken());
				dispatch(
					getTicketStats({
						customer,
						filters,
						search,
					})
				);
			},
			(err) => {
				if (!err?.isCancel) {
					dispatch(ticketActionFailure(localize(LocKeys.TICKET)));
				}
			}
		);
	};
};

/**
 * GET single ticket
 *
 * ON SUCCESS:
 * 	- dispatch GET_TICKET action - set ticket data
 *
 * @param {number} id - ticket unique identifier
 * @returns
 */
export const getTicket = ({ id, cb = () => {}, cbError = () => {} }) => {
	return (dispatch) => {
		const requestOptions = {
			method: "GET",
			url: `/ticket/${id}`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
		};
		return api(requestOptions).then(
			(res) => {
				dispatch({
					type: ticketingConstants.GET_TICKET,
					data: res?.data?.result,
				});
				cb();
			},
			(err) => {
				cbError();

				dispatch(ticketActionFailure(err?.response?.data?.message));
			}
		);
	};
};

/**
 * GET all ticket comments
 * ON SUCCESS:
 * 	- dispatch GET_ALL_TICKET_COMMENTS action
 *
 */
export const getAllTicketComments = () => {
	return (dispatch) => {
		const requestOptions = {
			method: "GET",
			url: `/ticket-unread-comments`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
		};
		return api(requestOptions).then(
			(res) => {
				dispatch(setCommentsCounter(res?.data?.result));
			},
			(err) => {
				dispatch(ticketActionFailure(localize(LocKeys.TICKET)));
			}
		);
	};
};

/**
 * Mark messages as read
 * @param {number} id - ticket unique identifier
 * @returns
 */
export const markMessagesAsRead = (id) => {
	return (dispatch) => {
		const requestOptions = {
			method: "DELETE",
			url: `/ticket-unread-comments/${id}`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
		};
		return api(requestOptions).then(
			(res) => {},
			(err) => {
				dispatch(ticketActionFailure(localize(LocKeys.TICKET)));
			}
		);
	};
};

export const getActivityComments = (id, cb = () => {}) => {
	return (dispatch) => {
		const requestOptions = {
			method: "GET",
			url: `/ticket-comment/${id}`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
		};
		return api(requestOptions).then(
			(res) => {
				dispatch({
					type: ticketingConstants.GET_ACTIVITY_COMMENTS,
					data: res?.data?.result,
				});
			},
			(err) => {
				dispatch(ticketActionFailure(localize(LocKeys.ACTIVITY)));
			}
		);
	};
};

/**
 * UPDATE ticket
 *
 * @param {number} id - ticket unique identifier
 * @returns
 */
export const updateTicket = (id, data, cb = () => {}) => {
	return (dispatch) => {
		const requestOptions = {
			method: "PUT",
			url: `/ticket/${id}`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
			data,
		};
		return api(requestOptions).then(
			(res) => {
				dispatch(getTicket({ id, cb }));
				dispatch(
					ticketActionSuccess(
						localize(LocKeys.ITEM_UPDATED, [localize(LocKeys.TICKET)])
					)
				);
			},
			(err) => {
				dispatch(
					ticketActionFailure(
						localize(LocKeys.ERROR_ITEM_UPDATED, [localize(LocKeys.TICKET)])
					)
				);
			}
		);
	};
};

/**
 * CREATE ticket
 *
 * ON SUCCESS:
 * 	- dispatch TICKET_ACTION_SUCCESS action - set success message
 * 	- dispatch CLEAR_TEMP_TICKET action - clear temp ticket data
 *
 *
 * @param {object} data - ticket data key value pair
 * @param {function} cb - callback function
 * @returns
 */
export const createTicket = (data, cb = () => {}) => {
	return (dispatch) => {
		const requestOptions = {
			method: "POST",
			url: `/ticket`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
			data,
		};
		return api(requestOptions).then(
			(res) => {
				const result = res?.data?.result;

				cb(+result?.id, () => {
					dispatch(
						ticketActionSuccess(
							localize(LocKeys.ITEM_CREATED, [localize(LocKeys.TICKET)])
						)
					);
					dispatch(clearTempTicket("createTicketForm"));
				});
			},
			(err) => {
				dispatch(
					ticketActionFailure(
						localize(LocKeys.ERROR_ITEM_CREATED, [localize(LocKeys.TICKET)])
					)
				);
			}
		);
	};
};

export const createActivityComment = ({
	ticketId,
	data,
	cb = () => {},
	isForFile = false,
}) => {
	return (dispatch) => {
		const requestOptions = {
			method: "POST",
			url: `/ticket-comment/${ticketId}`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
			data,
		};
		return api(requestOptions).then(
			(res) => {
				if (!!isForFile) {
					cb(res?.data?.result?.id);
				} else {
					dispatch(getActivityComments(ticketId));
				}
			},
			(err) => {
				dispatch(
					ticketActionFailure(
						localize(LocKeys.ERROR_ITEM_CREATED, [localize(LocKeys.TICKET)])
					)
				);
			}
		);
	};
};

/**
 * Update tickets status (single & multiple tickets)
 *
 * @param {Array<number>} ids -  offer unique identifier
 * @param {Array<string>} status - ticket status
 * @param {boolean} isSingleView - true - get single ticket , false - get tickets
 * @returns
 */
export const updateTicketStatus = (ids, status, isSingleView = false) => {
	return (dispatch) => {
		const requestOptions = {
			method: "PUT",
			url: `/ticket-status-multiple`,
			headers: {
				"Content-Type": "application/json",
				Accept: "*/*",
				Authorization: "Bearer " + getToken(),
			},
			data: { status, ids },
		};
		return api(requestOptions).then(
			() => {
				switch (status) {
					case statusConstants.ARCHIVED:
						dispatch(
							ticketActionSuccess(
								localize(LocKeys.ITEM_ARCHIVE_SUCCESS, [
									localize(LocKeys.TICKET),
								])
							)
						);
						break;
					default:
						dispatch(ticketActionSuccess(localize(LocKeys.STATUS_UPDATED)));
						break;
				}
				if (isSingleView) {
					dispatch(getTicket({ id: ids[0] }));
				} else {
					dispatch(getTickets({}));
				}
			},

			(err) => dispatch(ticketActionFailure(err.response.data.message))
		);
	};
};

/**
 * GET ticket stats
 *
 * ON SUCCESS:
 * 	- dispatch TICKET_STATS action - set ticket stats data
 *
 * @param {customer} customer - customer id
 * @param {object} filters - filters
 * @param {string} search - search query
 * @returns
 */
export const getTicketStats = ({
	customer = getItemFromLocalStorage("Customer"),
	filters,
	search,
}) => {
	return (dispatch) => {
		const requestOptions = {
			method: "PUT",
			url: "/ticket-stats",
			headers: {
				Authorization: "Bearer " + getToken(),
				"Content-Type": "application/json",
			},
			data: {
				selectedCompany: customer,
				filters,
				search,
			},
		};
		return api(requestOptions).then(
			(res) => {
				let data = res.data?.result;
				dispatch({ type: ticketingConstants.TICKET_STATS, data });
			},
			(err) => {
				dispatch(ticketActionFailure(err.response.data.message));
			}
		);
	};
};

/**
 * SOFT DELETE ticket
 *
 * ON SUCCESS:
 * 	- dispatch GET_TICKETS action - get tickets data
 * 	- dispatch TICKET_ACTION_SUCCESS action - set success message
 *
 * @param {Array<number>} ids - ticket unique identifiers
 * @returns
 */
export const softDeleteTicket = (ids) => {
	return (dispatch) => {
		const requestOptions = {
			method: "PUT",
			url: `/ticket-soft-delete`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
			data: { ids: ids },
		};
		return api(requestOptions).then(
			(res) => {
				dispatch(getTickets({}));
				dispatch(
					ticketActionSuccess(
						localize(LocKeys.ITEM_DELETED, [localize(LocKeys.TICKET)])
					)
				);
			},
			(err) => {
				dispatch(
					ticketActionFailure(
						localize(LocKeys.ERROR_ITEM_DELETED, [localize(LocKeys.TICKET)])
					)
				);
			}
		);
	};
};

///------------------------------------------------------------------------------------------------------------------------

export const incrementCommentsCounter = () => {
	return (dispatch) => {
		dispatch({
			type: ticketingConstants.INCREMENT_COMMENTS_COUNTER,
		});
	};
};

export const setCommentsCounter = (counter) => {
	return (dispatch) => {
		dispatch({
			type: ticketingConstants.SET_COMMENTS_COUNTER,
			data: counter,
		});
	};
};

export const decrementCommentsCounter = (id) => {
	return (dispatch) => {
		dispatch({
			type: ticketingConstants.DECREMENT_COMMENTS_COUNTER,
			data: id,
		});
	};
};

/**
 * UPDATE temp ticket values
 *
 * @param {string} fieldKey - field key
 * @param {string|object} value - field value
 * @returns
 */
export const updateTempTicket = (fieldKey, value) => {
	return (dispatch) => {
		dispatch({
			type: ticketingConstants.UPDATE_TEMP_TICKET,
			data: { fieldKey, value },
		});
	};
};

/**
 * UPDATE temp ticket files
 *
 * @param {Array} files - files array
 * @returns
 */
export const updateTempTicketFiles = (files) => {
	return (dispatch) => {
		dispatch({
			type: ticketingConstants.UPDATE_TEMP_TICKET_FILES,
			data: files,
		});
	};
};

/**
 * REMOVE temp ticket file
 *
 * @param {number} fileId - file id
 * @returns
 */
export const removeTempTicketFile = (fileId) => {
	return (dispatch) => {
		dispatch({
			type: ticketingConstants.REMOVE_TEMP_TICKET_FILE,
			data: fileId,
		});
	};
};

/**
 * CLEAR temp ticket state
 *
 * @param {string} formName - form name
 * @returns
 */
export const clearTempTicket = (formName = null) => {
	return (dispatch) => {
		formName && dispatch(destroy(formName));
		dispatch({ type: ticketingConstants.CLEAR_TEMP_TICKET });

		//Clear
		dispatch(clearBuildingUsers());
		dispatch(clearOrderUsers());
	};
};

/**
 * CLEAR single ticket state
 *
 * @returns
 */
export const clearSingleTicket = () => {
	return (dispatch) => {
		dispatch({ type: ticketingConstants.CLEAR_SINGLE_TICKET });
	};
};

/**
 * RESET temp ticket name state
 */
export const resetTempTicketName = () => {
	return (dispatch) => {
		dispatch({ type: ticketingConstants.RESET_TEMP_TICKET_NAME });
	};
};

/**
 * RESET temp ticket description state
 */
export const resetTempTicketDescription = () => {
	return (dispatch) => {
		dispatch({ type: ticketingConstants.RESET_TEMP_TICKET_DESCRIPTION });
	};
};
/**
 * RESET temp ticket information state
 */
export const resetTempTicketInformation = () => {
	return (dispatch) => {
		dispatch({ type: ticketingConstants.RESET_TEMP_TICKET_INFORMATION });
	};
};

/**
 * RESET temp ticket information state
 */
export const resetTempTicketAttachments = () => {
	return (dispatch) => {
		dispatch({ type: ticketingConstants.RESET_TEMP_TICKET_ATTACHMENTS });
	};
};

/**
 * Snackbar Action Success
 * @param {string} message
 * @returns
 */
export const ticketActionSuccess = (message) => {
	return { type: ticketingConstants.TICKET_ACTION_SUCCESS, data: message };
};

/**
 * Snackbar Action Failure
 * @param {string} message
 * @returns
 */
export const ticketActionFailure = (message) => {
	return { type: ticketingConstants.TICKET_ACTION_FAILURE, data: message };
};

/**
 * CLEAR ticket request state
 */
export const clearTicketRequestState = () => {
	return { type: ticketingConstants.CLEAR_TICKET_REQUEST_STATE };
};

export const clearActivityComment = () => {
	return { type: ticketingConstants.CLEAR_ACTIVITY_COMMENT };
};
