import React from "react";
import { NavLink } from "react-router-dom";
import "./sidebar.scss";
import TooltipWithHtml from "../../shared/htmlTooltip/TooltipWithHtml";
import bimifyLogo from "../../assets/images/bimify-logo.png";
import { getMenu } from "../../utils/sidebar";
import { isBimifyAdmin } from "../../utils/permission/user";

/**
 * Sidebar Component
 */

//Generate menu items based on role type and company type
const getMenuItems = () => {
	const menuItems = getMenu();

	const loopItems = (menuItems) => {
		return menuItems.map((item) => {
			return (
				<div
					key={item.route}
					className={`sidebar__link-wrapper ${item?.wrapperClasses}`}
				>
					<TooltipWithHtml
						tooltipPlacement="right"
						tooltipContent={item.description}
					>
						<NavLink
							isActive={(match, location) => {
								if (match) {
									return true;
								}
								return item?.activeRoutes?.includes(location.pathname);
							}}
							to={item.route}
							className={item.class}
						>
							{item.image}
						</NavLink>
					</TooltipWithHtml>
				</div>
			);
		});
	};
	return loopItems(menuItems);
};

const Sidebar = () => {
	return (
		<div className="sidebar d-flex flex-column align-items-center pos-fix">
			<a
				href="https://bimify.com"
				target="_blank"
				className={`sidebar__home-link  ${isBimifyAdmin() ? "" : "mb-150"}`}
				rel="noopener noreferrer"
			>
				<TooltipWithHtml
					tooltipPlacement="right-start"
					tooltipContent={"Bimify home"}
				>
					<img src={bimifyLogo} alt="Bimify Home" />
				</TooltipWithHtml>
			</a>
			<nav className="sidebar__navigation d-flex flex-column justify-content-top h-100">
				{getMenuItems()}
			</nav>
		</div>
	);
};

export default Sidebar;
