import React from "react";
import TooltipWithHtml from "../../../../../../shared/htmlTooltip/TooltipWithHtml";
import localize, { LocKeys } from "../../../../../../constants/localizations";
import CustomLabel from "../../../../../fields/CustomLabel";
import IconWrapper from "../../../../../../shared/icons/IconWrapper";
import TrashIcon from "../../../../../../shared/icons/actions/TrashIcon";
import { connect } from "react-redux";
import FileInput from "../../../../../fields/FileInput";
import {
	setSymbolTempFile,
	setSymbolTempFileURL,
} from "../../../../../../actions";
import Rotate90DegreesCcwIcon from "@mui/icons-material/Rotate90DegreesCcw";
import getRotatedImage from "./rotateImage";

const ImageSymbolForm = (props) => {
	const { fileName, imageURL, setSymbolTempFile, setSymbolTempFileURL } = props;

	const fileInputOnChange = (files) => {
		if (files[0]) {
			const file = files[0];
			//------------------
			setSymbolTempFile(file);
			setSymbolTempFileURL(URL.createObjectURL(file));
		}
	};

	const removeThumbnail = () => {
		setSymbolTempFile(null);
		setSymbolTempFileURL(null);
	};

	const rotateLeft = async () => {
		const file = await getRotatedImage(imageURL, -90, fileName);

		setSymbolTempFile(file);
		setSymbolTempFileURL(URL.createObjectURL(file));
	};

	return (
		<div className="h-280p w-100 pos-rel">
			<CustomLabel label={localize(LocKeys.IMAGE)} required={true} />
			{!!imageURL ? (
				<>
					<div className="pos-abs right-s top-32 z-index-2">
						<TooltipWithHtml
							tooltipContent={localize(LocKeys.REMOVE_THUMBNAIL)}
							tooltipPlacement="left"
						>
							<button
								type="button"
								onClick={() => removeThumbnail()}
								className="btn btn--error btn--icon  btn--icon--solo"
							>
								<IconWrapper
									icon={
										<TrashIcon iconClass="d-flex icon--target-fill fill--n000" />
									}
									size={24}
								/>
							</button>
						</TooltipWithHtml>
					</div>

					<div className="pos-abs right-s top-80 z-index-2">
						<button
							type="button"
							onClick={() => rotateLeft()}
							className="btn btn--primary btn--icon  btn--icon--solo"
						>
							<IconWrapper icon={<Rotate90DegreesCcwIcon />} size={24} />
						</button>
					</div>

					<img
						id="temp-img"
						src={imageURL}
						alt={"Default"}
						className="h-100 w-100 border-1 border-n150 border-radius-regular pos-abs of-contain"
					/>
				</>
			) : (
				<FileInput
					accept="image/png, image/jpeg, image/jpg"
					showFileList={false}
					customOnChange={fileInputOnChange}
					multipleFilesAllowed={false}
				/>
			)}
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		imageURL: state.getIn(["symbol", "tempSymbol", "imageURL"]),
		fileName: state.getIn(["symbol", "tempSymbol", "file", "name"]),
	};
};

export default connect(mapStateToProps, {
	setSymbolTempFile,
	setSymbolTempFileURL,
})(ImageSymbolForm);
