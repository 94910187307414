import React from "react";

export default (props) => {
	return (
		<div className={`icon ${props.iconClass}`}>
			<svg
				width="32"
				height="32"
				viewBox="0 0 32 32"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M26 4.66699L24 2.66699L22 4.66699L20 2.66699L18 4.66699L16 2.66699L14 4.66699L12 2.66699L10 4.66699L8 2.66699V21.3337H4V25.3337C4 27.547 5.78667 29.3337 8 29.3337H24C26.2133 29.3337 28 27.547 28 25.3337V2.66699L26 4.66699ZM8 26.667C7.26667 26.667 6.66667 26.067 6.66667 25.3337V24.0003H20V26.667H8ZM25.3333 25.3337C25.3333 26.067 24.7333 26.667 24 26.667C23.2667 26.667 22.6667 26.067 22.6667 25.3337V21.3337H10.6667V6.66699H25.3333V25.3337ZM12 9.33366V12.0003H20V9.33366H12ZM21.3333 9.33366H24V12.0003H21.3333V9.33366ZM12 13.3337V16.0003H20V13.3337H12ZM21.3333 13.3337H24V16.0003H21.3333V13.3337Z"
					fill="#1b2024"
				/>
			</svg>
		</div>
	);
};
