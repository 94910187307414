import React from "react";
import { connect } from "react-redux";
import { changePassword } from "../../actions";
import "../users-management/users.scss";
import { userRequestDone } from "../../actions";
import { tokenParams } from "../../utils/local-storage.util";
import ChangePasswordForm from "./ChangePasswordForm";
import "./profile.scss";
import localize, { LocKeys } from "../../constants/localizations";

/**
 * Change Password Component - Topbar
 */
const ChangePassword = (props) => {
	const user = tokenParams();
	const id = user.id;
	const { changePassword, onClose, errorMessage, initialValues } = props;

	const onSubmit = (data) => {
		const oldPassword = data.oldPassword;
		const newPassword = data.rePassword;
		const repeatPassword = data.reConfirmPassword;

		return changePassword(oldPassword, newPassword, repeatPassword, id).then(
			() => {
				onClose();
			}
		);
	};

	//Render form fields
	const renderForm = () => {
		return (
			<>
				<ChangePasswordForm
					onClose={onClose}
					onSubmit={onSubmit}
					buttonText={localize(LocKeys.SAVE)}
					initialValues={initialValues}
					successMessage={localize(LocKeys.CHANGE_PASSWORD_SUCCESS)}
					errorMessage={errorMessage}
				></ChangePasswordForm>
			</>
		);
	};

	return renderForm();
};

const MapStateToProps = (state) => {
	return {
		initialValues: state.getIn(["user", "user"]),
		errorMessage: state.getIn(["user", "errorMessage"]),
	};
};

export default connect(MapStateToProps, {
	changePassword,
	userRequestDone,
})(ChangePassword);
