import React from "react";
import SetPassword from "./SetPassword";

/**
 * Component render SetPassword Component with pageTitle and pageSubTitle
 */

//All logic is inside --> SetPassword <-- component
const Registration = () => {
	const pageTitle = "Welcome to Bimify";
	let pageSubTitle = "Enter your password and complete registration.";

	return <SetPassword pageTitle={pageTitle} pageSubTitle={pageSubTitle} />;
};

export default Registration;
