import React, { useState } from "react";
import { Field, reduxForm } from "redux-form/immutable";
import renderTextField from "../../../../../components/fields/renderTextField";
import localize, { LocKeys } from "../../../../../constants/localizations";
import FormSubmitButtons from "../../../FormComponents/FormSubmitButtons";

const TableModal = ({ onCancel, onSubmit, ...props }) => {
	const [rows, setRows] = useState(0);
	const [columns, setColumns] = useState(0);

	return (
		<form
			className={`form d-flex flex-column flex-auto standardized-form`}
			onSubmit={props.handleSubmit(onSubmit)}
			autoComplete="off"
			noValidate
		>
			<Field
				name={`rows`}
				id={`rows`}
				placeholder={localize(LocKeys.ROWS) + "..."}
				component={renderTextField}
				classField="mb-24"
				onKeyDown={(value) => setRows(value)}
				min="0"
				type="number"
				size={"w-100"}
				showPlaceholder={true}
			/>
			<Field
				name={`columns`}
				id={`columns`}
				placeholder={localize(LocKeys.COLUMNS) + "..."}
				component={renderTextField}
				onKeyDown={(value) => setColumns(value)}
				min="0"
				type="number"
				size={"w-100"}
				showPlaceholder={true}
			/>

			<FormSubmitButtons
				classField="mb-24"
				submitType="button"
				wrapperClasses="w-100 pt-24"
				onCancel={onCancel}
				onSubmit={() => onSubmit({ columns, rows })}
			/>
		</form>
	);
};

export default reduxForm({
	form: "tableModalForm",
	touchOnBlur: false,
	destroyOnUnmount: true,
	enableReinitialize: false,
})(TableModal);
