import React, { useMemo } from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form/immutable";
import HeaderSymbolForm from "./parts/HeaderSymbolForm";
import BodySymbolForm from "./parts/BodySymbolForm";
import FooterLayout from "../../../../layout/content/FooterLayout";
import FormSubmitButtons from "../../../../../shared/components/FormComponents/FormSubmitButtons";
import localize, { LocKeys } from "../../../../../constants/localizations";

const SymbolForm = (props) => {
	const {
		tempSymbol,
		initialValues,
		formName,
		editView = false,
		onCancel,
		onSubmit,
	} = props;

	const fieldKeys = useMemo(
		() => ["name", "family", "disciplineComponentCategory", "imageURL"],
		[]
	);

	const onSubmitForm = () => {
		try {
			const data = tempSymbol?.toJS();

			onSubmit({
				name: data.name,
				familyId: data.family,
				disciplineComponentCategoryId: data.disciplineComponentCategory,
				componentCategoryFunctionId: data.symbolFunction,
			});
		} catch (error) {
			return;
		}
	};

	const isValid = useMemo(() => {
		return Object.keys(tempSymbol?.toJS()).some(
			(key) => fieldKeys.includes(key) && !tempSymbol.get(key)
		);
	}, [tempSymbol, fieldKeys]);

	return (
		<div className="page-content--large">
			<form
				className={`form d-flex h-100 w-100 flex-column flex-auto standardized-form card  ${
					editView
						? "bg-primary--lighter card--primary"
						: "card--border--none  card--2 bg-n000"
				}	`}
				onSubmit={props.handleSubmit(onSubmitForm)}
				autoComplete="off"
				noValidate
			>
				<HeaderSymbolForm editView={editView} />

				<div className="pt-regular px-24 page-content--medium overflow-y-auto">
					<BodySymbolForm initialValues={initialValues} formName={formName} />
				</div>

				<FooterLayout wrapperClasses="px-24 pt-24">
					<FormSubmitButtons
						wrapperClasses="w-100"
						onCancel={onCancel}
						onSubmit={props.handleSubmit(onSubmitForm)}
						cancelLabel={localize(LocKeys.CANCEL)}
						submitClassName={!editView ? "btn--success" : ""}
						submitLabel={
							editView ? localize(LocKeys.SAVE) : localize(LocKeys.CREATE)
						}
						submitDisabled={isValid}
					/>
				</FooterLayout>
			</form>
		</div>
	);
};

const mapStateToProps = (state, ownProps) => {
	const { formName } = ownProps;

	return {
		form: formName,
		tempSymbol: state.getIn(["symbol", "tempSymbol"]),
		initialValues: {
			name: state.getIn(["symbol", "tempSymbol", "name"]),
			family: state.getIn(["symbol", "tempSymbol", "family"]),
			discipline: state.getIn(["symbol", "tempSymbol", "discipline"]),
			category: state.getIn(["symbol", "tempSymbol", "category"]),
			disciplineComponentCategory: state.getIn([
				"symbol",
				"tempSymbol",
				"disciplineComponentCategory",
			]),
			symbolFunction: state.getIn(["symbol", "tempSymbol", "symbolFunction"]),
		},
	};
};

export default connect(
	mapStateToProps,
	{}
)(
	reduxForm({
		touchOnBlur: false,
		destroyOnUnmount: false,
		enableReinitialize: true,
	})(SymbolForm)
);
