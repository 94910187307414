import React from "react";

/**
 * Content Layout - Custom component for HEADER layout
 *
 * @param {string} wrapperClasses
 * @param {*} children
 *
 */
const HeaderLayout = ({ wrapperClasses = "", children = <></> }) => {
	return (
		<div
			className={`w-100  mh-100p h-100p border-b-1 border-n150 ${wrapperClasses}`}
		>
			{children}
		</div>
	);
};

export default HeaderLayout;
