export const lodSpecificationConstants = {
	GET_LOD_SPECIFICATIONS: "GET_LOD_SPECIFICATIONS",
	SET_DISCIPLINE_LODS_DESCRIPTION: "SET_DISCIPLINE_LODS_DESCRIPTION",
	UPDATE_LODS: "UPDATE_LODS",
	CLEAR_TEMP_LOD_SPECIFICATION: "CLEAR_TEMP_LOD_SPECIFICATION",
	SET_SUCCESS_MESSAGE: "SET_SUCCESS_MESSAGE",
	LOD_SPECIFICATION_ACTION_SUCCESS: "LOD_SPECIFICATION_ACTION_SUCCESS",
	LOD_SPECIFICATION_ACTION_FAILURE: "LOD_SPECIFICATION_ACTION_FAILURE",
	CLEAR_LOD_SPECIFICATION_REQUEST_STATE:
		"CLEAR_LOD_SPECIFICATION_REQUEST_STATE",
	SET_ERROR_MESSAGE: "SET_ERROR_MESSAGE",
	LOD_SPECIFICATION_STATS: "LOD_SPECIFICATION_STATS",
	GET_LOD_SPECIFICATION: "GET_LOD_SPECIFICATION",
	GET_DISCIPLINE_COMPONENT_CATEGORY_LOD_PARAM_CATEGORIES:
		"GET_DISCIPLINE_COMPONENT_CATEGORY_LOD_PARAM_CATEGORIES",
	CLEAR_LOD_SPECIFICATION: "CLEAR_LOD_SPECIFICATION",
	SET_TEMP_SPECIFICATION_CONTENT: "SET_TEMP_SPECIFICATION_CONTENT",
};
