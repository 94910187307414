import React from "react";
import { colorConstants } from "../../../constants/constants";
import CircularProgress from "@mui/material/CircularProgress";

/**
 * Custom circular progress
 *
 * @param {number} value
 * @param {number} size = 20
 * @param {number} thickness = 6
 * @returns
 */
const CustomCircularProgress = ({ value, size = 20, thickness = 6 }) => {
	const render = () => {
		return (
			<div className="pos-rel">
				<CircularProgress
					variant="determinate"
					size={size}
					thickness={thickness}
					value={100}
					sx={{
						color: colorConstants.N200,
					}}
				/>
				<CircularProgress
					variant="determinate"
					size={size}
					thickness={thickness}
					value={value}
					sx={{
						color: colorConstants.N500,
						animationDuration: "550ms",
						position: "absolute",
						left: 0,
						strokeLinecap: "round",
					}}
				/>
			</div>
		);
	};

	return render();
};

export default CustomCircularProgress;
