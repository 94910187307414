const orderTempConstants = {
	SET_STATE_FIELD_VALUE: "SET_STATE_FIELD_VALUE",
	CLEAR_TEMP_PROJECT: "CLEAR_TEMP_PROJECT",
	SET_TEMP_PROJECT: "SET_TEMP_PROJECT",
	SET_INIT_NUMBER_OF_BUILDINGS: "SET_INIT_NUMBER_OF_BUILDINGS",
	UPDATE_BUILDING: "UPDATE_BUILDING",
	UPDATE_BUILDING_DATA: "UPDATE_BUILDING_DATA",
	SET_EXPORTS: "SET_EXPORTS",
	UPDATE_PRICE: "UPDATE_PRICE",
	REMOVE_BUILDING: "REMOVE_BUILDING",
	SET_STATE_FROM_PACKAGE: "SET_STATE_FROM_PACKAGE",
	SET_TEMP_TERMS_AND_CONDITIONS: "SET_TEMP_TERMS_AND_CONDITIONS",
	SET_TEMP_ADDITIONAL_INFO: "SET_TEMP_ADDITIONAL_INFO",
	UPDATE_PRICE_PER_SQUARE_PERCENTAGE: "UPDATE_PRICE_PER_SQUARE_PERCENTAGE",
	UPDATE_EXPORT_PRICE_PERCENTAGE: "UPDATE_EXPORT_PRICE_PERCENTAGE",
	SET_COMPANY_STATE_FIELD_VALUE: "SET_COMPANY_STATE_FIELD_VALUE",
	CLEAR_COMPANY_STATE_VALUE: "CLEAR_COMPANY_STATE_VALUE",
	SET_TEMP_COMPANY_INFO: "SET_TEMP_COMPANY_INFO",
	UPDATE_BUILDING_PRICE: "UPDATE_BUILDING_PRICE",
	SET_STATE_FROM_PACKAGE_CONFIGURATION: "SET_STATE_FROM_PACKAGE_CONFIGURATION",
};

export default orderTempConstants;
