import React from "react";
import { isLogin } from "../../utils/local-storage.util";
import { CompatRoute, Navigate } from "react-router-dom-v5-compat";

/**
 * Public route component
 *
 * @param {string} title - Route title
 * @param {React.ReactNode} component - Component
 * @param {React.ReactNode} rest - Route Details
 *
 * @return {React.FC} Public Route component
 *
 */
const PublicRoute = ({
	component: Component,
	isPublic = false,
	title,
	...rest
}) => {
	document.title = title ? title + " - Bimify" : "Bimify";
	console.log(isPublic);
	return (
		<CompatRoute
			{...rest}
			render={(props) =>
				isLogin() && !isPublic ? <Navigate to="/" /> : <Component {...props} />
			}
		/>
	);
};

export default PublicRoute;
