import { Map, fromJS } from "immutable";
import fileConstants from "../constants/fileConstants";

/**
 * File reducer redux
 */
const initialState = Map({
	action: false,
	request: false,
	selectedFiles: null,
	successMessage: "",
	errorMessage: "",
});

export default (state = initialState, action) => {
	switch (action.type) {
		case fileConstants.SET_SELECTED_FILES:
			return state.setIn(["selectedFiles"], action.files);

		case fileConstants.CLEAR_FILE_REQUEST_STATE:
			return state
				.set("successMessage", "")
				.set("errorMessage", "")
				.set("action", false)
				.set("request", false);

		case fileConstants.DELETE_ERROR:
			return state.set("errorMessage", fromJS(action.message));

		case fileConstants.FILE_ACTION_FAILURE:
			return state
				.set("action", false)
				.set("request", true)
				.set("errorMessage", fromJS(action.message));

		case fileConstants.FILE_ACTION_SUCCESS:
			return state
				.set("action", true)
				.set("request", true)
				.set("successMessage", fromJS(action.message));
		default:
			return state;
	}
};
