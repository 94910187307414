import React from "react";

const ResidentialIcon = (props) => {
	return (
		<div className={`icon ${props.iconClass}`}>
			<svg
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<g clipPath="url(#clip0_3899_23439)">
					<path
						d="M20.3791 6.28223H3.62082C3.40631 6.28223 3.23242 6.45619 3.23242 6.67079V22.1786C3.23242 22.3932 3.40631 22.5671 3.62082 22.5671H20.3791C20.5936 22.5671 20.7675 22.3932 20.7675 22.1786V6.67079C20.7675 6.45619 20.5936 6.28223 20.3791 6.28223Z"
						fill="white"
					/>
					<path
						d="M20.5343 22.8H3.46413C3.34052 22.8 3.22198 22.7509 3.13457 22.6634C3.04716 22.576 2.99805 22.4574 2.99805 22.3337V6.5151C2.99805 6.39144 3.04716 6.27284 3.13457 6.1854C3.22198 6.09795 3.34052 6.04883 3.46413 6.04883H20.5339C20.6575 6.04883 20.7761 6.09795 20.8635 6.1854C20.9509 6.27284 21 6.39144 21 6.5151V22.3349C20.9997 22.4583 20.9505 22.5765 20.8632 22.6637C20.7759 22.7509 20.6576 22.7999 20.5343 22.8ZM3.46413 6.5151V22.3349H20.5347V6.5151H3.46413Z"
						fill="#EE6712"
					/>
					<path
						d="M20.3801 1.43359H10.2654C10.0508 1.43359 9.87695 1.60756 9.87695 1.82215V22.1799C9.87695 22.3945 10.0508 22.5685 10.2654 22.5685H20.3801C20.5946 22.5685 20.7685 22.3945 20.7685 22.1799V1.82215C20.7685 1.60756 20.5946 1.43359 20.3801 1.43359Z"
						fill="#FFC350"
					/>
					<path
						d="M20.5353 22.8H10.1087C9.98505 22.8 9.8665 22.7509 9.77909 22.6634C9.69168 22.576 9.64258 22.4574 9.64258 22.3337V1.66549C9.64258 1.54183 9.69168 1.42323 9.77909 1.33579C9.8665 1.24834 9.98505 1.19922 10.1087 1.19922H20.5353C20.6589 1.19922 20.7774 1.24834 20.8648 1.33579C20.9522 1.42323 21.0013 1.54183 21.0013 1.66549V22.3349C21.001 22.4583 20.9518 22.5766 20.8644 22.6638C20.777 22.751 20.6587 22.8 20.5353 22.8ZM10.1087 1.66549V22.3349H20.5356V1.66549H10.1087Z"
						fill="#EE6712"
					/>
					<path
						d="M16.7003 18.7773H13.8867V22.5685H16.7003V18.7773Z"
						fill="#EE6712"
					/>
					<path
						d="M16.7009 22.8006H13.8873C13.8255 22.8006 13.7663 22.776 13.7226 22.7323C13.6789 22.6886 13.6543 22.6293 13.6543 22.5675V18.7771C13.6543 18.7153 13.6789 18.656 13.7226 18.6122C13.7663 18.5685 13.8255 18.5439 13.8873 18.5439H16.7009C16.7627 18.5439 16.822 18.5685 16.8657 18.6122C16.9094 18.656 16.9339 18.7153 16.9339 18.7771V22.5683C16.9337 22.6299 16.9091 22.689 16.8654 22.7326C16.8217 22.7762 16.7626 22.8006 16.7009 22.8006ZM14.1196 22.3343H16.4683V19.0094H14.1196V22.3343Z"
						fill="#EE6712"
					/>
					<path
						d="M14.2485 3.44043H12.3535V5.33582H14.2485V3.44043Z"
						fill="white"
					/>
					<path
						d="M14.2487 5.56889H12.3541C12.2923 5.56889 12.233 5.54433 12.1893 5.50061C12.1456 5.45689 12.1211 5.39759 12.1211 5.33576V3.44114C12.1211 3.37931 12.1456 3.32001 12.1893 3.27629C12.233 3.23257 12.2923 3.20801 12.3541 3.20801H14.2487C14.3106 3.20801 14.3698 3.23257 14.4135 3.27629C14.4572 3.32001 14.4818 3.37931 14.4818 3.44114V5.33615C14.4817 5.39791 14.4571 5.45711 14.4134 5.50075C14.3697 5.54438 14.3105 5.56889 14.2487 5.56889ZM12.5864 5.10262H14.0161V3.67272H12.5864V5.10262Z"
						fill="#EE6712"
					/>
					<path
						d="M18.2329 3.44043H16.3379V5.33582H18.2329V3.44043Z"
						fill="white"
					/>
					<path
						d="M18.2327 5.56889H16.3366C16.2748 5.56889 16.2155 5.54433 16.1718 5.50061C16.1281 5.45689 16.1035 5.39759 16.1035 5.33576V3.44114C16.1035 3.37931 16.1281 3.32001 16.1718 3.27629C16.2155 3.23257 16.2748 3.20801 16.3366 3.20801H18.2316C18.2934 3.20801 18.3526 3.23257 18.3963 3.27629C18.44 3.32001 18.4646 3.37931 18.4646 3.44114V5.33615C18.4645 5.39771 18.4401 5.45673 18.3966 5.50034C18.3532 5.54394 18.2943 5.56858 18.2327 5.56889ZM16.5704 5.10262H18.0005V3.67272H16.5696L16.5704 5.10262Z"
						fill="#EE6712"
					/>
					<path
						d="M14.2485 7.07324H12.3535V8.96863H14.2485V7.07324Z"
						fill="white"
					/>
					<path
						d="M14.2487 9.20112H12.3541C12.3235 9.20112 12.2932 9.19509 12.265 9.18337C12.2367 9.17165 12.211 9.15448 12.1893 9.13283C12.1677 9.11118 12.1505 9.08548 12.1388 9.0572C12.1271 9.02891 12.1211 8.9986 12.1211 8.96798V7.07298C12.1211 7.01115 12.1456 6.95185 12.1893 6.90813C12.233 6.86441 12.2923 6.83984 12.3541 6.83984H14.2487C14.3106 6.83984 14.3698 6.86441 14.4135 6.90813C14.4572 6.95185 14.4818 7.01115 14.4818 7.07298V8.96876C14.4816 9.03045 14.4569 9.08955 14.4133 9.13311C14.3696 9.17666 14.3104 9.20112 14.2487 9.20112ZM12.5864 8.73485H14.0161V7.30534H12.5864V8.73485Z"
						fill="#EE6712"
					/>
					<path
						d="M18.2329 7.07324H16.3379V8.96863H18.2329V7.07324Z"
						fill="white"
					/>
					<path
						d="M18.2327 9.20112H16.3366C16.306 9.20112 16.2756 9.19509 16.2474 9.18337C16.2191 9.17165 16.1934 9.15448 16.1718 9.13283C16.1501 9.11118 16.133 9.08548 16.1213 9.0572C16.1095 9.02891 16.1035 8.9986 16.1035 8.96798V7.07298C16.1035 7.01115 16.1281 6.95185 16.1718 6.90813C16.2155 6.86441 16.2748 6.83984 16.3366 6.83984H18.2316C18.2934 6.83984 18.3526 6.86441 18.3963 6.90813C18.44 6.95185 18.4646 7.01115 18.4646 7.07298V8.96876C18.4644 9.03025 18.4399 9.08918 18.3965 9.1327C18.3531 9.17622 18.2942 9.20081 18.2327 9.20112ZM16.5704 8.73485H18.0005V7.30534H16.5696L16.5704 8.73485Z"
						fill="#EE6712"
					/>
					<path
						d="M14.2485 10.7051H12.3535V12.6005H14.2485V10.7051Z"
						fill="white"
					/>
					<path
						d="M14.2487 12.833H12.3541C12.2923 12.833 12.233 12.8084 12.1893 12.7647C12.1456 12.7209 12.1211 12.6616 12.1211 12.5998V10.7048C12.1211 10.643 12.1456 10.5837 12.1893 10.54C12.233 10.4962 12.2923 10.4717 12.3541 10.4717H14.2487C14.3106 10.4717 14.3698 10.4962 14.4135 10.54C14.4572 10.5837 14.4818 10.643 14.4818 10.7048V12.6006C14.4816 12.6623 14.4569 12.7214 14.4133 12.7649C14.3696 12.8085 14.3104 12.833 14.2487 12.833ZM12.5864 12.3667H14.0161V10.9372H12.5864V12.3667Z"
						fill="#EE6712"
					/>
					<path
						d="M18.2329 10.7051H16.3379V12.6005H18.2329V10.7051Z"
						fill="white"
					/>
					<path
						d="M18.2327 12.833H16.3366C16.2748 12.833 16.2155 12.8084 16.1718 12.7647C16.1281 12.7209 16.1035 12.6616 16.1035 12.5998V10.7048C16.1035 10.643 16.1281 10.5837 16.1718 10.54C16.2155 10.4962 16.2748 10.4717 16.3366 10.4717H18.2316C18.2934 10.4717 18.3526 10.4962 18.3963 10.54C18.44 10.5837 18.4646 10.643 18.4646 10.7048V12.6006C18.4644 12.6621 18.4399 12.721 18.3965 12.7645C18.3531 12.8081 18.2942 12.8326 18.2327 12.833ZM16.5704 12.3667H18.0005V10.9372H16.5696L16.5704 12.3667Z"
						fill="#EE6712"
					/>
					<path
						d="M14.2485 14.3369H12.3535V16.2323H14.2485V14.3369Z"
						fill="white"
					/>
					<path
						d="M14.2487 16.4648H12.3541C12.2923 16.4648 12.233 16.4402 12.1893 16.3965C12.1456 16.3528 12.1211 16.2935 12.1211 16.2316V14.3347C12.1211 14.2729 12.1456 14.2136 12.1893 14.1698C12.233 14.1261 12.2923 14.1016 12.3541 14.1016H14.2487C14.3106 14.1016 14.3698 14.1261 14.4135 14.1698C14.4572 14.2136 14.4818 14.2729 14.4818 14.3347V16.2301C14.482 16.2608 14.4761 16.2913 14.4645 16.3198C14.4529 16.3482 14.4357 16.3741 14.4141 16.3959C14.3924 16.4178 14.3667 16.4351 14.3383 16.4469C14.3099 16.4587 14.2795 16.4648 14.2487 16.4648ZM12.5864 15.9985H14.0161V14.5678H12.5864V15.9985Z"
						fill="#EE6712"
					/>
					<path
						d="M7.20973 9.24316H6.09152C5.87702 9.24316 5.70312 9.41713 5.70312 9.63172V10.75C5.70312 10.9646 5.87702 11.1386 6.09152 11.1386H7.20973C7.42423 11.1386 7.59813 10.9646 7.59813 10.75V9.63172C7.59813 9.41713 7.42423 9.24316 7.20973 9.24316Z"
						fill="#FFC350"
					/>
					<path
						d="M7.3656 11.2553H5.93551C5.8428 11.2553 5.75388 11.2184 5.68832 11.1528C5.62277 11.0873 5.58594 10.9983 5.58594 10.9056V9.47373C5.58594 9.38098 5.62277 9.29203 5.68832 9.22645C5.75388 9.16087 5.8428 9.12402 5.93551 9.12402H7.3656C7.45831 9.12402 7.54721 9.16087 7.61277 9.22645C7.67832 9.29203 7.71516 9.38098 7.71516 9.47373V10.9044C7.71531 10.9504 7.70638 10.996 7.68888 11.0386C7.67138 11.0811 7.64566 11.1198 7.61318 11.1524C7.58071 11.185 7.54212 11.2109 7.49963 11.2285C7.45714 11.2462 7.4116 11.2553 7.3656 11.2553ZM5.93551 9.35949C5.9046 9.35949 5.87496 9.37177 5.8531 9.39363C5.83125 9.41549 5.81899 9.44514 5.81899 9.47606V10.9067C5.81899 10.9376 5.83125 10.9673 5.8531 10.9892C5.87496 11.011 5.9046 11.0233 5.93551 11.0233H7.3656C7.3965 11.0233 7.42612 11.011 7.44798 10.9892C7.46983 10.9673 7.48211 10.9376 7.48211 10.9067V9.47373C7.48211 9.44281 7.46983 9.41316 7.44798 9.3913C7.42612 9.36944 7.3965 9.35716 7.3656 9.35716L5.93551 9.35949Z"
						fill="#FFC350"
					/>
					<path
						d="M7.20973 13.4268H6.09152C5.87702 13.4268 5.70312 13.6007 5.70312 13.8153V14.9336C5.70312 15.1482 5.87702 15.3221 6.09152 15.3221H7.20973C7.42423 15.3221 7.59813 15.1482 7.59813 14.9336V13.8153C7.59813 13.6007 7.42423 13.4268 7.20973 13.4268Z"
						fill="#FFC350"
					/>
					<path
						d="M7.3656 15.4385H5.93551C5.8428 15.4385 5.75388 15.4016 5.68832 15.3361C5.62277 15.2705 5.58594 15.1815 5.58594 15.0888V13.6593C5.58594 13.5665 5.62277 13.4776 5.68832 13.412C5.75388 13.3464 5.8428 13.3096 5.93551 13.3096H7.3656C7.45831 13.3096 7.54721 13.3464 7.61277 13.412C7.67832 13.4776 7.71515 13.5665 7.71515 13.6593V15.0896C7.71495 15.1822 7.67803 15.2709 7.6125 15.3363C7.54696 15.4018 7.45817 15.4385 7.3656 15.4385ZM5.93551 13.5431C5.9046 13.5431 5.87496 13.5554 5.8531 13.5772C5.83125 13.5991 5.81899 13.6287 5.81899 13.6597V15.0899C5.81899 15.1209 5.83125 15.1505 5.8531 15.1724C5.87496 15.1942 5.9046 15.2065 5.93551 15.2065H7.3656C7.3965 15.2065 7.42612 15.1942 7.44798 15.1724C7.46983 15.1505 7.48211 15.1209 7.48211 15.0899V13.6593C7.48211 13.6284 7.46983 13.5987 7.44798 13.5768C7.42612 13.555 7.3965 13.5427 7.3656 13.5427L5.93551 13.5431Z"
						fill="#FFC350"
					/>
					<path
						d="M7.20973 17.6104H6.09152C5.87702 17.6104 5.70312 17.7843 5.70312 17.9989V19.1172C5.70312 19.3318 5.87702 19.5057 6.09152 19.5057H7.20973C7.42423 19.5057 7.59813 19.3318 7.59813 19.1172V17.9989C7.59813 17.7843 7.42423 17.6104 7.20973 17.6104Z"
						fill="#FFC350"
					/>
					<path
						d="M7.3656 19.6221H5.93551C5.8428 19.6221 5.75388 19.5852 5.68832 19.5197C5.62277 19.4541 5.58594 19.3651 5.58594 19.2724V17.8429C5.58594 17.7501 5.62277 17.6612 5.68832 17.5956C5.75388 17.53 5.8428 17.4932 5.93551 17.4932H7.3656C7.45831 17.4932 7.54721 17.53 7.61277 17.5956C7.67832 17.6612 7.71515 17.7501 7.71515 17.8429V19.2732C7.71495 19.3658 7.67803 19.4545 7.6125 19.5199C7.54696 19.5853 7.45817 19.6221 7.3656 19.6221ZM5.93551 17.7267C5.9046 17.7267 5.87496 17.739 5.8531 17.7608C5.83125 17.7827 5.81899 17.8123 5.81899 17.8433V19.2735C5.81899 19.3045 5.83125 19.3341 5.8531 19.356C5.87496 19.3778 5.9046 19.3901 5.93551 19.3901H7.3656C7.3965 19.3901 7.42612 19.3778 7.44798 19.356C7.46983 19.3341 7.48211 19.3045 7.48211 19.2735V17.8429C7.48211 17.8119 7.46983 17.7823 7.44798 17.7604C7.42612 17.7386 7.3965 17.7263 7.3656 17.7263L5.93551 17.7267Z"
						fill="#FFC350"
					/>
					<path
						d="M18.2329 14.3369H16.3379V16.2323H18.2329V14.3369Z"
						fill="white"
					/>
					<path
						d="M18.2327 16.4648H16.3366C16.2748 16.4648 16.2155 16.4402 16.1718 16.3965C16.1281 16.3528 16.1035 16.2935 16.1035 16.2316V14.3347C16.1035 14.2729 16.1281 14.2136 16.1718 14.1698C16.2155 14.1261 16.2748 14.1016 16.3366 14.1016H18.2316C18.2934 14.1016 18.3526 14.1261 18.3963 14.1698C18.44 14.2136 18.4646 14.2729 18.4646 14.3347V16.2301C18.4648 16.2607 18.459 16.2911 18.4474 16.3195C18.4359 16.3479 18.4188 16.3737 18.3973 16.3955C18.3758 16.4173 18.3501 16.4347 18.3219 16.4465C18.2937 16.4584 18.2634 16.4646 18.2327 16.4648ZM16.5704 15.9985H18.0005V14.5678H16.5696L16.5704 15.9985Z"
						fill="#EE6712"
					/>
				</g>
				<defs>
					<clipPath id="clip0_3899_23439">
						<rect
							width="18"
							height="21.6"
							fill="white"
							transform="translate(3 1.2002)"
						/>
					</clipPath>
				</defs>
			</svg>
		</div>
	);
};

export default ResidentialIcon;
