import React from "react";

export default (props) => {
	return (
		<span className={`icon ${props.iconClass ? props.iconClass : ""}`}>
			<svg
				width="12"
				height="12"
				viewBox="0 0 12 12"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M5 1C5 0.447715 5.44771 0 6 0C6.55228 0 7 0.447715 7 1V11C7 11.5523 6.55228 12 6 12C5.44771 12 5 11.5523 5 11V1Z"
					fill="#1B2024"
				/>
				<path
					d="M1 7C0.447715 7 2.41411e-08 6.55228 0 6C-2.41411e-08 5.44772 0.447715 5 1 5L11 5C11.5523 5 12 5.44771 12 6C12 6.55228 11.5523 7 11 7L1 7Z"
					fill="#1B2024"
				/>
			</svg>
		</span>
	);
};
