export const uploadFile = async ({
	dbFileData,
	file,
	preSignedPostUploadUrlBody,
	uploadToDigitalOcean,
	getPresignedPostUploadUrl,
	cb = () => {},
	cbDBUpload = () => {},
	cbUploadProgress = () => {},
}) => {
	const presignedPostUrlData = await getPresignedPostUploadUrl(
		preSignedPostUploadUrlBody
	);
	dbFileData.path = presignedPostUrlData.fields.key;

	//OUTDATED
	// let form = new FormData();
	// Object.keys(presignedPostUrlData.fields).forEach((key) =>
	// 	form.append(key, presignedPostUrlData.fields[key])
	// );

	//form.append("file", file);

	uploadToDigitalOcean({
		url: presignedPostUrlData.url,
		//form,
		file,
		dbFileData,
		onUploadProgress: (progressEvent) => {
			let progress = 0;

			if (!!progressEvent.uploadDone) {
				progress = 100;
			} else {
				const { loaded, total } = progressEvent;
				progress = Math.round((100 * loaded) / total);
			}

			if (!!cbUploadProgress) {
				cbUploadProgress(progress);
			}

			if (progress === 100) {
				cb();
			}
		},
		cbDBUpload,
	});
};
