import { List, Map, fromJS } from "immutable";
import buildingConstants from "../constants/buildingConstants";

/**
 * BUILDING reducer redux
 */
const initialState = Map({
	action: false,
	request: false,
	building: Map(),
	fullAddress: null,
	buildingsList: Map(),
	buildingsMeta: Map(),
	tempBuilding: Map({
		initial: true,
		name: null,
		size: null,
		customer: null,
		type: null,
		address: null,
		additionalInfo: "",
		levelFrom: -2,
		levelTo: 2,
		defaultLevelHeight: 3000,
		floors: null,
		validity: Map({
			type: false,
			size: false,
			name: false,
			customer: false,
			levelFrom: false,
			levelTo: false,
			defaultLevelHeight: false,
		}),
	}),
	buildingCompanies: List(),
	buildingUsers: List(),
	stats: null,
	forgeFileNotSupportedMessage: null,
	successMessage: "Success!",
	errorMessage: "Something went wrong",
});

export default (state = initialState, action) => {
	/**
	 * UPDATE ASYNC LOAD BUILDINGS
	 *
	 * @param {object} data - { list, values, isForSingleView }
	 * @returns
	 */
	const updateAsyncLoadBuildings = (data) => {
		const { list, values = null, isForSingleView = false } = data;

		let findItems = [];
		const listJS = state.get("asyncLoadBuildings")?.toJS() || [];

		if (isForSingleView) {
			const id = values && [+values?.value];

			findItems =
				(listJS.length > 0 &&
					id?.length > 0 &&
					listJS.filter((item) => id.includes(+item.id))) ||
				[];

			if (findItems.length === 0 && !!values?.value) {
				findItems = [{ name: values?.label, id: values?.value }];
			}
		} else {
			const valuesIds = values && values.map((item) => +item?.value);
			if (valuesIds?.length > 0)
				findItems =
					(listJS.length > 0 &&
						listJS.filter((item) => valuesIds.includes(+item.id))) ||
					[];
		}

		// if (!!values) {
		// 	findItems = (listJS.length > 0 &&
		// 		listJS.filter((item) => +values.value === +item.id)) || [
		// 		{ id: values.value, name: values.label },
		// 	];
		// }

		let tempList = [...findItems, ...list];

		//Remove duplicates from tempList
		const ids = tempList.map(({ id }) => id);
		const filteredList = tempList.filter(
			({ id }, index) => !ids.includes(id, index + 1)
		);

		return state.set("asyncLoadBuildings", fromJS(filteredList));
	};

	/**
	 * Set temp building levels
	 * @returns tempBuilding
	 */
	const setTempBuildingLevels = () => {
		const building = state.get("building");
		const buildingJS = building && building?.toJS();

		const levels = buildingJS.levels.sort((a, b) => {
			return b.level - a.level;
		});

		let floors = [];

		levels.forEach((item) => {
			floors.push({
				id: item.id,
				key: `floor__${item.level}`,
				level: item.level,
				isRoof: item.isRoof,
				levelName: `Level ${item.level}`,
				elevation: (+item.elevation).toFixed(0),
				thickness: (+item.thickness).toFixed(0),
				height: (+item.height).toFixed(0),
				name: item.name,
				valid: true,
			});
		});

		return state
			.setIn(["tempBuilding", "initial"], false)
			.setIn(["tempBuilding", "levelFrom"], buildingJS.levelFrom)
			.setIn(["tempBuilding", "levelTo"], buildingJS.levelTo)
			.setIn(
				["tempBuilding", "defaultLevelHeight"],
				buildingJS.defaultLevelHeight
			)
			.setIn(["tempBuilding", "floors"], floors);
	};

	/**
	 * Set temp building information (name,size,address,type,customer)
	 * @returns tempBuilding
	 */
	const setTempBuildingInfo = () => {
		const building = state.get("building");

		return state
			.setIn(["tempBuilding", "size"], building.get("size"))
			.setIn(["tempBuilding", "name"], building.get("name"))
			.setIn(["tempBuilding", "address"], building.get("address"))
			.setIn(["tempBuilding", "type"], building.getIn(["type", "id"]))
			.setIn(["tempBuilding", "customer"], building.getIn(["customer", "id"]));
	};

	/**
	 * Set initial building levels
	 * @returns tempBuilding
	 */
	const initialBuildingLevels = () => {
		let floors = [];
		let height = 3000;
		for (let i = 2; i >= -2; i--) {
			let floorElevation = Number(height * i).toFixed(0);

			floors.push({
				key: `floor__${i}`,
				level: i,
				levelName: `Level ${i}`,
				name: null,
				elevation: floorElevation,
				height,
				thickness: 150,
				valid: true,
			});
		}
		const fristFloor = floors[0].elevation;
		floors.unshift({
			key: `floor__roof`,
			level: 3,
			levelName: "roof",
			name: "Roof",
			elevation: +fristFloor + height,
			height,
			thickness: 150,
			isRoof: true,
			valid: true,
		});
		return state
			.setIn(["tempBuilding", "initial"], false)
			.setIn(["tempBuilding", "levelFrom"], -2)
			.setIn(["tempBuilding", "levelTo"], 2)
			.setIn(["tempBuilding", "defaultLevelHeight"], 3000)
			.setIn(["tempBuilding", "floors"], floors);
	};

	/**
	 * Update building levels
	 * @param {*} data
	 * @returns tempBuilding
	 */
	const updateBuildingLevels = (data) => {
		let floors = [];
		let highestFloor = data.levelsTo;
		let lowestFloor = data.levelsFrom;

		let height = data.height ? Number(data.height).toFixed(2) : 2.4;

		const oldFloors =
			state.getIn(["tempBuilding", "floors"]) &&
			state.getIn(["tempBuilding", "floors"]);

		for (
			let currentFloor = highestFloor;
			currentFloor >= lowestFloor;
			currentFloor--
		) {
			let floorElevation = Number(height * currentFloor).toFixed(0);
			const floor =
				oldFloors && oldFloors?.find((floor) => +floor.level === +currentFloor);

			if (floor?.id) {
				floors.push({
					id: floor.id,
					key: floor.key,
					level: floor.level,
					levelName: floor.levelName,
					elevation: floorElevation,
					name: floor?.name && floor?.name !== "Roof" ? floor?.name : null,
					height,
					thickness: floor?.thickness || 200,
					valid: true,
				});
			} else {
				floors.push({
					id: null,
					key: `floor__${currentFloor}`,
					level: currentFloor,
					levelName: `Level ${currentFloor}`,
					elevation: floorElevation,
					name: floor?.name && floor?.name !== "Roof" ? floor?.name : null,
					height,
					thickness: floor?.thickness || 200,
					valid: true,
				});
			}
		}

		const fristFloor = floors[0]?.elevation;
		const level = floors[0]?.level;
		const nextLevel = oldFloors?.find((floor) => floor.level === level + 1);

		floors.unshift({
			key: `floor__roof`,
			level: Number(+highestFloor + 1),
			isRoof: true,
			levelName: "roof",
			name: "Roof",
			thickness: !!nextLevel ? nextLevel.thickness : 150,
			elevation: Number(+fristFloor + +height),
			height,
			valid: true,
		});
		return state
			.setIn(["tempBuilding", "levelFrom"], data.levelsFrom)
			.setIn(["tempBuilding", "levelTo"], data.levelsTo)
			.setIn(["tempBuilding", "defaultLevelHeight"], data.height)
			.setIn(["tempBuilding", "floors"], floors);
	};

	/**
	 * Update building level (floor)
	 * @param {*} data - { floorKey, stateKey, value }
	 * @returns tempBuilding.tempBuilding
	 */
	const updateBuildingLevel = (data) => {
		const { floorKey, stateKey, value } = data;
		let floors = state.getIn(["tempBuilding", "floors"]);

		if (stateKey === "elevation" && floorKey === "floor__roof") {
			const secondFloorElevation = floors[1].elevation;

			return state
				.setIn(["tempBuilding", "floors", 0, stateKey], value)
				.setIn(
					["tempBuilding", "floors", 0, "valid"],
					Number(+secondFloorElevation + 1000) > +value ? false : true
				);
		}

		if (stateKey === "thickness" && floorKey === "floor__roof") {
			return state.setIn(["tempBuilding", "floors", 0, stateKey], value);
		}

		let findFloor = floors?.find((floor) => {
			return floor.key === floorKey;
		});
		let floorIndex = floors?.findIndex((floor) => {
			return floor.key === floorKey;
		});

		let valid = true;

		if (stateKey === "elevation" && floors) {
			let lowestFloors = null;
			if (findFloor.level > 0) {
				lowestFloors = floors.filter(
					(floor) => floor.level >= 0 && floor.level < findFloor.level
				);
			} else {
				lowestFloors = floors.filter(
					(floor) => +floor.level < +findFloor.level
				);
			}

			let upperFloors = floors.filter((floor) => floor.level > findFloor.level);

			lowestFloors.forEach((lowestF) => {
				if (findFloor.level > 0) {
					if (+lowestF.elevation + 1000 > +value) {
						valid = false;
					}
				} else {
					if (+lowestF.elevation - 1000 > +value) {
						valid = false;
					}
				}
			});
			upperFloors.forEach((upperF) => {
				if (+upperF.elevation < +value + 1000) {
					valid = false;
				}
			});
		}

		if (floors)
			return state
				.setIn(["tempBuilding", "floors", floorIndex, stateKey], value)
				.setIn(["tempBuilding", "floors", floorIndex, "valid"], valid);

		return state;
	};

	/**
	 * Get and parse building specification
	 * @param {object} data
	 * @returns
	 */
	const getBuildingSpecification = (data) => {
		const { buildingDisciplineComponentCategoryLods = null, lodSpecification } =
			data;
		let specificationState = [];

		buildingDisciplineComponentCategoryLods &&
			buildingDisciplineComponentCategoryLods.map((element) => {
				let tempParameterCategories = [];
				const {
					lod,
					discipline,
					description,
					twoDFile,
					threeDFile,
					componentCategory,
					buildingDisciplineComponentCategoryLodParameterCatgories = null,
				} = element;

				//get all parent parameter categories
				const parentParameterCategories =
					buildingDisciplineComponentCategoryLodParameterCatgories
						?.filter((pc) => pc?.parameterCategory?.parentId === null)
						?.sort(
							(a, b) =>
								a?.parameterCategory?.sortOrder -
								b?.parameterCategory?.sortOrder
						);

				//get through all parent parameter categories
				parentParameterCategories &&
					parentParameterCategories.forEach((pc) => {
						//get all child parameter categories
						const childParameterCategories =
							buildingDisciplineComponentCategoryLodParameterCatgories
								?.filter(
									(cpc) =>
										cpc?.parameterCategory?.parentId ===
										pc?.parameterCategory?.id
								)
								?.sort((a, b) => a?.sortOrder - b?.sortOrder);

						//push parent parameter category with child parameter categories
						tempParameterCategories.push({
							id: pc?.parameterCategory?.id,
							name: pc?.parameterCategory?.name,
							parameters: childParameterCategories,
						});
					});

				return specificationState.push({
					lod,
					discipline,
					componentCategory,
					content: {
						twoDFile,
						threeDFile,
						parameterCategories: tempParameterCategories,
						description,
					},
				});
			});

		let tempData = {
			id: lodSpecification?.id || null,
			name: lodSpecification?.name || null,
			packages: lodSpecification?.packages ? lodSpecification?.packages : null,
			buildingDisciplineComponentCategoryLods: specificationState,
		};

		return state.set("specification", fromJS(tempData));
	};

	/**
	 * UPDATE temp file preview
	 * @param {object} data - { fileId, disciplineId, buildingId }
	 * @returns
	 */
	const updateTempFilePreview = (data) => {
		const { fileId, disciplineId, buildingId } = data;

		const fileList =
			state
				.get(`filesList_${action.data.buildingId}_${action.data.disciplineId}`)
				.toJS() || [];

		const tempFiles = [];

		fileList &&
			fileList.map((file) => {
				let buildingDisciplineFile = [];

				file.buildingDisciplineFile &&
					file.buildingDisciplineFile.map((bdFile) => {
						return buildingDisciplineFile.push({
							...bdFile,
							isPreview: +file.id === +fileId ? true : false,
						});
					});

				return tempFiles.push({
					...file,
					buildingDisciplineFile: buildingDisciplineFile,
				});
			});

		return state.set(
			`filesList_${buildingId}_${disciplineId}`,
			fromJS(tempFiles)
		);
	};

	switch (action.type) {
		case buildingConstants.GET_BUILDINGS:
			return state
				.set("buildingsList", fromJS(action.data.result))
				.set("buildingsMeta", fromJS(action.data.meta));

		case buildingConstants.UPDATE_TEMP_FILE_PREVIEW:
			return updateTempFilePreview(action.data);

		case buildingConstants.CLEAR_BUILDING:
			return state.set("building", Map());

		case buildingConstants.GET_BUILDING:
			return state.set("building", fromJS(action.data.result));

		case buildingConstants.B_ACTION_SUCCESS:
			return state.set("action", true).set("request", true);

		case buildingConstants.B_ACTION_FAILURE:
			return state
				.set("action", false)
				.set("request", true)
				.set("errorMessage", fromJS(action.error));

		case buildingConstants.B_REQUEST_DONE:
			return state.set("request", false);

		case buildingConstants.SET_FULL_ADDRESS_BUILDING:
			return state.set("fullAddress", fromJS(action.address));

		case buildingConstants.UPDATE_BUILDING_LEVELS:
			return updateBuildingLevels(action.data);

		case buildingConstants.UPDATE_BUILDING_LEVEL:
			return updateBuildingLevel(action.data);

		case buildingConstants.SET_SUCCESS_MESSAGE:
			return state.set("successMessage", fromJS(action.data));

		case buildingConstants.SET_ERROR_MESSAGE:
			return state.set("errorMessage", fromJS(action.data));

		case buildingConstants.CLEAR_BUILDING_REQUEST_STATE:
			return state
				.set("action", false)
				.set("request", false)
				.set("errorMessage", "")
				.set("successMessage", "");

		case buildingConstants.SET_STATE_BUILDING_FIELD_VALUE:
			return state
				.setIn(["tempBuilding", action.data.stateKey], action.data.value)
				.setIn(
					["tempBuilding", "validity", action.data.stateKey],
					action.data.isValid
				);

		case buildingConstants.INITIAL_BUILDING_LEVELS:
			return initialBuildingLevels();

		case buildingConstants.CLEAR_TEMP_BUILDING:
			return state.set("tempBuilding", initialState.get("tempBuilding"));

		case buildingConstants.SET_TEMP_BULDING_LEVELS:
			return setTempBuildingLevels();

		case buildingConstants.SET_TEMP_BULDING_INFO:
			return setTempBuildingInfo();

		case buildingConstants.CHANGE_FORGE_VIEWER_URN:
			return state
				.setIn(["building", "viewerUrn"], action.data)
				.setIn(["building", "previewLink"], null)
				.setIn(["building", "fileName"], null);

		case buildingConstants.GET_DISCIPLINE_FILES:
			return state
				.set("tempFilesList", fromJS(action.data.files.result))
				.set("filesList", fromJS(action.data.files.result))
				.set(
					`filesList_${action.data.buildingId}_${action.data.disciplineId}`,
					fromJS(action.data.files.result)
				)
				.set("filesMeta", fromJS(action.data.files.meta));

		case buildingConstants.GET_ALL_BUILDING_DISCIPLINE_FILES:
			return state.set(
				`allFilesList_${action.data.buildingId}_${action.data.disciplineId}`,
				fromJS(action.data.files.result)
			);

		case buildingConstants.GET_FORGE_PUBLIC_TOKEN:
			return state.setIn(["building", "forgeAuth"], action.data);

		case buildingConstants.CLEAR_DISCIPLINE_FILE:
			return state.set("filesList", fromJS([])).set("filesMeta", fromJS(Map()));

		case buildingConstants.BUILDING_STATS:
			return state.set("stats", fromJS(action.data.result));

		case buildingConstants.UPDATE_DISCIPLINE_FILES:
			return state.set("filesList", fromJS(action.data));

		case buildingConstants.SET_FORGE_FILE_NOT_SUPPORTED_MESSAGE:
			return state.set("forgeFileNotSupportedMessage", fromJS(action.data));

		case buildingConstants.GET_BUILDING_SPECIFICATION:
			return getBuildingSpecification(action.data);

		case buildingConstants.UPDATE_ASYNC_LOAD_BUILDINGS:
			return updateAsyncLoadBuildings(action.data);

		case buildingConstants.CHANGE_FILE_URL:
			return state
				.setIn(["building", "previewLink"], action.data?.previewLink || null)
				.setIn(["building", "fileName"], action.data?.fileName || null)
				.setIn(["building", "viewerUrn"], null);

		case buildingConstants.CLEAR_BUILDING_LIST_STATE:
			return state
				.set("buildingsList", initialState.get("buildingsList"))
				.set("buildingsMeta", initialState.get("buildingsMeta"));

		case buildingConstants.GET_BUILDING_COMPANIES:
			return state.set("buildingCompanies", fromJS(action.data));

		case buildingConstants.GET_BUILDING_USERS:
			return state.set("buildingUsers", fromJS(action.data));

		case buildingConstants.CLEAR_BUILDING_USERS:
			return state.set("buildingUsers", List());

		case buildingConstants.CLEAR_ALL_BUILDING_STATE:
			return initialState;

		default:
			return state;
	}
};
