import React, { useCallback, useMemo } from "react";
import localize, { LocKeys } from "../../../../../../constants/localizations";
import {
	removeTempRevitTemplateFile,
	setTempRevitTemplateFile,
	setTempRevitTemplateFileData,
} from "../../../../../../actions";
import { connect } from "react-redux";
import FileInput from "../../../../../fields/FileInput";
import SingleFile from "../../single-view/template/SingleFile";
import CustomLabel from "../../../../../fields/CustomLabel";

/**
 * Re-upload template file
 */
const TemplateFile = ({
	file,
	fileId,
	fileData,
	fileURL = null,
	onCancel,
	...props
}) => {
	const {
		setTempRevitTemplateFile,
		setTempRevitTemplateFileData,
		removeTempRevitTemplateFile,
	} = props;

	const fileInputOnChange = useCallback(
		(files) => {
			if (files[0]) {
				const file = files[0];

				setTempRevitTemplateFile(file);
				setTempRevitTemplateFileData({
					url: URL.createObjectURL(file),
					file: {
						name: file.name,
						size: file.size,
					},
				});
			}
		},
		[setTempRevitTemplateFile, setTempRevitTemplateFileData]
	);

	const renderBody = useMemo(() => {
		if (!!fileURL)
			return (
				<SingleFile
					isEdit={true}
					removeTempRevitTemplateFile={() => removeTempRevitTemplateFile()}
				/>
			);
		else
			return (
				<div className="pb-48 h-100">
					<FileInput
						accept="application/octet-stream,.rvt"
						showFileList={false}
						customOnChange={fileInputOnChange}
						multipleFilesAllowed={false}
						customSupportedText={"Supported format (2022 version .rvt files)"}
					/>
				</div>
			);
	}, [fileURL, removeTempRevitTemplateFile, fileInputOnChange]);

	return (
		<>
			<CustomLabel label={localize(LocKeys.TEMPLATE_LABEL)} required={true} />
			{renderBody}
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		fileURL: state.getIn([
			"template",
			"tempRevitTemplate",
			"template",
			"fileData",
			"url",
		]),
		file: state.getIn(["template", "tempRevitTemplate", "template", "file"]),
		fileId: state.getIn(["template", "tempRevitTemplate", "template", "id"]),
	};
};

export default connect(mapStateToProps, {
	setTempRevitTemplateFile,
	setTempRevitTemplateFileData,
	removeTempRevitTemplateFile,
})(TemplateFile);
