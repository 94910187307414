import React from "react";

/**
 * Custom section header - offer view
 * @param {*} param0
 * @returns
 */
const SectionHeader = ({ title, wrapperClasses = "" }) => {
	return <h4 className={`color-n300  ${wrapperClasses}`}>{title}</h4>;
};

export default SectionHeader;
