export const ticketingConstants = {
	UPDATE_TEMP_TICKET: "UPDATE_TEMP_TICKET",
	CLEAR_STATE: "CLEAR_STATE",
	UPDATE_TEMP_TICKET_FILES: "UPDATE_TEMP_TICKET_FILES",
	REMOVE_TEMP_TICKET_FILE: "REMOVE_TEMP_TICKET_FILE",
	TICKET_ACTION_SUCCESS: "TICKET_ACTION_SUCCESS",
	TICKET_ACTION_FAILURE: "TICKET_ACTION_FAILURE",
	CLEAR_TICKET_REQUEST_STATE: "CLEAR_TICKET_REQUEST_STATE",
	GET_TICKETS: "GET_TICKETS",
	CLEAR_TEMP_TICKET: "CLEAR_TEMP_TICKET",
	TICKET_STATS: "TICKET_STATS",
	GET_TICKET: "GET_TICKET",
	RESET_TEMP_TICKET_NAME: "RESET_TEMP_TICKET_NAME",
	RESET_TEMP_TICKET_INFORMATION: "RESET_TEMP_TICKET_INFORMATION",
	RESET_TEMP_TICKET_DESCRIPTION: "RESET_TEMP_TICKET_DESCRIPTION",
	CLEAR_SINGLE_TICKET: "CLEAR_SINGLE_TICKET",
	GET_ACTIVITY_COMMENTS: "GET_ACTIVITY_COMMENTS",
	CLEAR_ACTIVITY_COMMENT: "CLEAR_ACTIVITY_COMMENT",
	RESET_TEMP_TICKET_ATTACHMENTS: "RESET_TEMP_TICKET_ATTACHMENTS",
	SET_COMMENTS_COUNTER: "SET_COMMENTS_COUNTER",
	INCREMENT_COMMENTS_COUNTER: "INCREMENT_COMMENTS_COUNTER",
};
