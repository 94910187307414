import React, { useEffect, useState } from "react";
import { Field } from "redux-form/immutable";
import renderCheckBoxField from "../../../../../fields/renderCheckBoxField";
import localize, { LocKeys } from "../../../../../../constants/localizations";
import { connect } from "react-redux";
import { updateTempRevitTemplateData } from "../../../../../../actions";

const TemplateBody = ({ isDefault = false, updateTempRevitTemplateData }) => {
	const [defaultChecked, setDefaultChecked] = useState(isDefault);

	useEffect(() => {
		setDefaultChecked(isDefault);
	}, [isDefault]);

	const handleChange = (e) => {
		updateTempRevitTemplateData({
			fieldKey: "isDefault",
			value: e.target.checked,
		});

		setDefaultChecked(e.target.checked);
	};
	return (
		<Field
			name="isDefault"
			component={renderCheckBoxField}
			label={localize(LocKeys.DEFAULT)}
			handleChange={handleChange}
			checked={defaultChecked || false}
		/>
	);
};

const mapStateToProps = (state) => {
	return {
		isDefault: !!state.getIn(["template", "tempRevitTemplate", "isDefault"])
			? true
			: false,
	};
};

export default connect(mapStateToProps, { updateTempRevitTemplateData })(
	TemplateBody
);
