import { Map, fromJS } from "immutable";
import priceConstants from "../constants/priceConstants";

const initialState = Map({
	totalExportPrice: 0,
	totalOfferPrice: 0,
	buildingPrice: Map(),
});

export default (state = initialState, action) => {
	switch (action.type) {
		case priceConstants.GET_PRICE:
			return state
				.set("totalExportPrice", fromJS(action.data.totalExportPrice))
				.set("totalOfferPrice", fromJS(action.data.totalOfferPrice))
				.set("buildingPrice", fromJS(action.data.buildingPrice))
				.set("exportsPrice", fromJS(action.data.exportsPrice));

		case priceConstants.CLEAR_PRICING:
			return state
				.set("totalExportPrice", null)
				.set("totalOfferPrice", null)
				.set("buildingPrice", null)
				.set("exportsPrice", null);

		case priceConstants.REMOVE_PRICE_PER_BUILDING:
			const buildingId = action.data;
			const buildingPrice = state.get("buildingPrice");
			const pricePerBuilding = buildingPrice?.get("pricePerBuilding");

			if (pricePerBuilding) {
				return state.setIn(
					["buildingPrice", "pricePerBuilding", String(buildingId)],
					0
				);
			}
			return state;

		case priceConstants.REMOVE_BUILDING_TOTAL_PRICE:
			const buildingPricetest = state.get("buildingPrice");
			if (buildingPricetest) {
				return state.setIn(["buildingPrice", "total", "modelingPrice"], 0);
			}
			return state;
		default:
			return state;
	}
};
