import React from "react";

export default (props) => {
	return (
		<span className={`icon ${props.iconClass ? props.iconClass : ""}`}>
			<svg
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M14.9 13.1812C14.9 11.775 16.7 11.4937 16.7 8.4C16.7 5.83275 15.3657 4.2474 12.9312 3.50625C11.525 3.0783 9.67685 3 7.7 3C6.8 3 5 3 5 3V11.4937L7.25 13.8H10.85L15.35 18.75L19.4 21C19.4 21 14.9 14.5312 14.9 13.1812ZM12.2 8.4C12.2 9.39405 11.394 10.2 10.4 10.2H9.95C9.45275 10.2 9.05 9.79725 9.05 9.3V7.5C9.05 7.00275 9.45275 6.6 9.95 6.6H10.85C11.5956 6.6 12.2 7.20435 12.2 7.95V8.4Z"
					fill="#1976D2"
				/>
				<path
					d="M12.1965 8.43375C12.178 9.41205 11.3824 10.2 10.4001 10.2C10.4001 10.2 10.2876 10.65 9.44382 11.4375C8.97852 11.8717 7.70007 12.45 7.70007 12.45C7.70007 12.45 12.6933 12.7798 14.0887 10.0069C13.8552 9.2037 13.1914 8.5782 12.1965 8.43375Z"
					fill="#1565C0"
				/>
				<path
					d="M11.3 5.25H9.49996C8.75431 5.25 8.14996 5.85435 8.14996 6.6V7.95C8.14996 9.09165 8.91046 10.0245 9.73171 10.1703C9.34066 10.0722 9.04996 9.7212 9.04996 9.3V7.5C9.04996 7.00275 9.45271 6.6 9.94996 6.6H10.85C11.5956 6.6 12.2 7.20435 12.2 7.95V8.4C12.2 8.4117 12.1968 8.4225 12.1964 8.43375C13.1913 8.5782 13.8546 9.20325 14.0882 10.0065C14.315 9.55695 14.45 9.0282 14.45 8.4C14.45 6.6603 13.0397 5.25 11.3 5.25Z"
					fill="#1950A6"
				/>
				<path
					d="M9.5 5.25C8.75435 5.25 8.15 5.85435 8.15 6.6V7.95C8.15 9.09165 8.9105 10.0245 9.73175 10.1703C9.3407 10.0722 9.05 9.7212 9.05 9.3V7.5C9.05 7.00275 9.45275 6.6 9.95 6.6H10.85C11.5957 6.6 12.2 7.20435 12.2 7.95V8.4C12.2 9.39405 11.3941 10.2 10.4 10.2C10.4 10.2 13.1 10.2 13.1 7.5V7.0518C13.1 6.0888 12.3436 5.27565 11.381 5.2509C11.354 5.25045 11.327 5.25 11.3 5.25H9.5ZM5 3V20.1L6.8 21L7.7 12.45C7.7 12.45 5 11.1 5 3Z"
					fill="#0D47A1"
				/>
				<path
					d="M6.79993 21L7.69993 12.45C7.69993 12.45 10.3999 12.45 11.7499 13.35C12.3367 13.7415 13.9675 15.6468 15.4624 17.3437C17.5437 19.7062 19.3999 21 19.3999 21C19.3999 21 16.3062 20.775 15.0741 20.6022C14.6803 20.5468 14.3167 20.3637 14.0863 20.0397C13.4118 19.0911 12.1999 16.725 10.3999 14.925C10.2942 14.8192 10.1749 14.7022 9.94993 14.7022C9.72088 14.7022 9.49993 14.8809 9.49993 15.1545V19.7755C9.49993 19.969 9.37618 20.1414 9.19213 20.2026L6.79993 21Z"
					fill="#3AA2DB"
				/>
			</svg>
		</span>
	);
};
