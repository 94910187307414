import { Map, fromJS } from "immutable";
import { manualConstants } from "../constants/manualConstant";

/**
 * Manual reducer redux
 */

const initialState = Map({
	manualsList: Map(),
	manualsMeta: Map(),
	manual: Map(),
	tempManual: Map({
		title: null,
		owner: null,
		content: "",
		inputFormats: [],
		jobStatuses: [],
		isDefault: false,
	}),
	action: false,
	request: false,
	errorResponse: "",
	stats: null,
	successMessage: "Success!",
	errorMessage: "Something went wrong",
});

export default (state = initialState, action) => {
	const setTempManual = (manual) => {
		const defaults = manual?.defaultManuals || [];

		const defaultInputFormats = [];
		const defaultJobStatuses = [];

		if (defaults && defaults?.length > 0) {
			for (const def of defaults) {
				const inputFormat = def.inputFormat;
				const jobStatus = def.jobStatus;

				if (inputFormat && !defaultInputFormats.includes(inputFormat.id)) {
					defaultInputFormats.push(inputFormat.id);
				}

				if (jobStatus && !defaultJobStatuses.includes(jobStatus.id)) {
					defaultJobStatuses.push(jobStatus.id);
				}
			}
		}

		return state
			.setIn(["tempManual", "title"], manual.title)
			.setIn(["tempManual", "content"], manual.content)
			.setIn(["tempManual", "isDefault"], manual?.isDefault || false)
			.setIn(
				["tempManual", "owner"],
				manual?.companies?.length > 0
					? manual?.companies[0]?.company?.id || null
					: null
			)
			.setIn(["tempManual", "inputFormats"], defaultInputFormats)
			.setIn(["tempManual", "jobStatuses"], defaultJobStatuses);
	};

	switch (action.type) {
		case manualConstants.GET_MANUALS:
			return state
				.set("manualsList", fromJS(action.data.result))
				.set("manualsMeta", fromJS(action.data.meta));

		case manualConstants.GET_MANUAL:
			return state.set("manual", fromJS(action.data));

		case manualConstants.SET_TEMP_MANUAL:
			return setTempManual(action.data);

		case manualConstants.SET_TEMP_MANUAL_CONTENT:
			return state.setIn(["tempManual", "content"], action.data);

		case manualConstants.UPDATE_TEMP_FIELD_MANUAL:
			const { fieldKey, value } = action.data;

			return state.setIn(["tempManual", fieldKey], value);

		case manualConstants.CLEAR_TEMP_MANUAL:
			return state.set("tempManual", initialState.get("tempManual"));

		case manualConstants.CLEAR_SINGLE_MANUAL:
			return state.set("manual", initialState.get("manual"));

		case manualConstants.MANUAL_ACTION_GET_SUCCESS:
			return state
				.set("successMessage", fromJS(action.data))
				.set("action", true)
				.set("request", true);

		case manualConstants.MANUAL_ACTION_GET_FAILURE:
			return state
				.set("action", false)
				.set("request", true)
				.set("errorResponse", fromJS(action.error));

		case manualConstants.CLEAR_MANUAL_REQUEST_STATE:
			return state
				.set("action", false)
				.set("request", false)
				.set("errorMessage", "")
				.set("successMessage", "");

		default:
			return state;
	}
};
