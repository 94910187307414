import { Map } from "immutable";
import { bimifyConstants } from "../constants/constants";
import { globalConstants } from "../constants/globalConstants";

/**
 * File reducer redux
 */
const initialState = Map({
	selectedRows: [],
	view: "",
	customer: null,
});

export default (state = initialState, action) => {
	switch (action.type) {
		case globalConstants.SET_SELECTED_ROWS:
			const selectedRows = action.data?.selectedRows;
			let selectedRowState = [];

			selectedRows &&
				selectedRows.forEach((row) => {
					let object = {
						id: row.original.id,
						name:
							action.data?.view === bimifyConstants.USER
								? row.original.firstName + " " + row.original.lastName
								: row.original.name,
						path:
							action.data?.view === bimifyConstants.FILES
								? row.original?.path
								: null,
						extension:
							action.data?.view === bimifyConstants.FILES
								? row.original?.extension
								: null,
					};

					selectedRowState.push(object);
				});
			return state
				.set("selectedRows", selectedRowState)
				.set("view", action.data?.view);

		case globalConstants.SET_CUSTOMER:
			return state.set("customer", action.data);

		case globalConstants.SET_ROOM:
			return state.set("room", action.data);

		default:
			return state;
	}
};
