import React, { useEffect } from "react";
import localize, { LocKeys } from "../../../constants/localizations";
import { useTimer } from "../../../hooks/useTimer";
import { convertTime } from "../helpers";

/**
 * Time counter from HIGHER to LOWEST number
 *
 * @param {Function} goBack
 * @param {number} timer - default timer value
 * @param {boolean} resetTimer - true/false
 * @param {Function} setResetTimer - reset timer function
 * @returns
 */
const TimeCounter = ({ goBack, timer = 900, resetTimer, setResetTimer }) => {
	const { timeToLeft, setTimeToLeft } = useTimer({ timer });

	useEffect(() => {
		if (resetTimer) {
			setTimeToLeft(timer);
			setResetTimer(false);
		}
	}, [setTimeToLeft, setResetTimer, resetTimer, timer]);

	if (timeToLeft <= 0) {
		goBack();
	} else {
		return (
			<div className="mt-32 text-center body-font--small mb-xs">
				<span className="color-n300">{localize(LocKeys.TIME_TO_LEFT)}:</span>
				&nbsp;
				<strong className="semi"> {convertTime(timeToLeft)}</strong>
			</div>
		);
	}
	return "";
};

export default TimeCounter;
