import React from "react";
import { connect } from "react-redux";
import CustomAccordion from "../../../CustomAccordion/CustomAccordion";
import TooltipWithHtml from "../../../htmlTooltip/TooltipWithHtml";
import localize, { LocKeys } from "../../../../constants/localizations";
import FileIcon from "../../../icons/files/FileIcon";

/**
 * Component for upload files inside CustomWidget
 *
 */
const DownloadZip = (props) => {
	const { zipName } = props;
	const renderTitle = () => {
		return (
			<>
				<strong className="semi">{localize(LocKeys.ZIP_IN_PROGIRESS)}</strong>
			</>
		);
	};

	return (
		<CustomAccordion
			wrapperClasses="download-zip-files"
			initialValue={true}
			titleContentComponent={renderTitle}
		>
			<div className="d-flex flex-column w-100">
				<div className={`py-20  px-regular bg-n100`}>
					<div className="row row--xs-gutters">
						<div className="col col-60 align-items-center d-flex">
							<FileIcon iconClass="mr-m" />
							<TooltipWithHtml
								tooltipContent={zipName}
								tooltipPlacement="top-start"
								tooltiptextClasses="text-overflow-ellipsis  max-w-340p"
							>
								<h4 className="text-overflow-ellipsis  max-w-170p">
									{zipName}
									<span className="color-n300">.zip</span>
								</h4>
							</TooltipWithHtml>
						</div>
						<div className="col col-25 align-items-center justify-content-end"></div>
						<div
							className={`col col-15 align-items-center justify-content-end `}
						>
							<span className="loading-icon"></span>
						</div>
					</div>
				</div>
			</div>
		</CustomAccordion>
	);
};

const mapStateToProps = (state) => {
	return {
		zipName: state.getIn(["globalWidget", "zipName"]),
	};
};

export default connect(mapStateToProps, {})(DownloadZip);
