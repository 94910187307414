import React from "react";

export default ({ imageClass, imageLink, name }) => {
	if (imageLink) {
		return (
			<div
				title={name}
				className={`${imageClass ? imageClass : ""}`}
				style={{
					backgroundImage: "url(" + imageLink + ")",
				}}
			/>
		);
	}
	return null;
};
