import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form/immutable";
import validate from "../fields/validateAuth";
import { userRequestDone } from "../../actions";
import renderPasswordField from "../fields/renderPasswordField";
import localize, { LocKeys } from "../../constants/localizations";
import FormSubmitButtons from "../../shared/components/FormComponents/FormSubmitButtons";

/**
 * Change password form component
 */

const ChangePasswordForm = (props) => {
	//Send form data as props to parent component and perform updating database
	const onSubmit = (formValues) => {
		try {
			let data = formValues.toJS();
			return props.onSubmit(data);
		} catch (error) {
			return;
		}
	};

	//Simple function for closing modal
	const onClose = (e) => {
		props.onClose && props.onClose(e);
	};

	return (
		<form
			className={"form d-flex flex-column flex-auto standardized-form "}
			onSubmit={props.handleSubmit(onSubmit)}
			autoComplete="off"
			noValidate
		>
			<Field
				name="oldPassword"
				id="old-password"
				classField="mb-24"
				component={renderPasswordField}
				label={localize(LocKeys.OLD_PASSWORD)}
			/>

			<Field
				id="re-password"
				name="rePassword"
				classField="mb-24"
				component={renderPasswordField}
				label={localize(LocKeys.NEW_PASSWORD)}
			/>

			<Field
				id="confirm-password"
				name="reConfirmPassword"
				component={renderPasswordField}
				classField="mb-24"
				label={localize(LocKeys.CONFRIM_NEW_PASSWORD)}
			/>

			<FormSubmitButtons onCancel={onClose} onSubmit={onSubmit} />
		</form>
	);
};

export default connect(null, {
	userRequestDone,
})(
	reduxForm({
		form: "changePasswordForm",
		validate,
		enableReinitialize: true,
		touchOnBlur: false,
	})(ChangePasswordForm)
);
