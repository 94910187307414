import React from "react";

export default (props) => {
	return (
		<span className={`icon ${props.iconClass ? props.iconClass : ""}`}>
			<svg
				width="16"
				height="16"
				viewBox="0 0 16 16"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M8 0C3.58867 0 0 3.58867 0 8C0 12.4113 3.58867 16 8 16C12.4113 16 16 12.4113 16 8C16 3.58867 12.4113 0 8 0ZM9.772 12.1893C9.33133 12.3627 7.804 13.092 6.924 12.3167C6.66133 12.086 6.53067 11.7933 6.53067 11.438C6.53067 10.7727 6.74933 10.1927 7.14333 8.8C7.21267 8.53667 7.29733 8.19533 7.29733 7.92467C7.29733 7.45733 7.12 7.33333 6.63933 7.33333C6.40467 7.33333 6.14467 7.41667 5.90933 7.50467L6.03933 6.972C6.564 6.75867 7.22267 6.49867 7.78667 6.49867C8.63267 6.49867 9.25533 6.92067 9.25533 7.72333C9.25533 7.95467 9.21533 8.36 9.13133 8.64L8.64467 10.3613C8.544 10.7093 8.362 11.4767 8.644 11.704C8.92133 11.9287 9.578 11.8093 9.902 11.6567L9.772 12.1893ZM8.968 5.33333C8.416 5.33333 7.968 4.88533 7.968 4.33333C7.968 3.78133 8.416 3.33333 8.968 3.33333C9.52 3.33333 9.968 3.78133 9.968 4.33333C9.968 4.88533 9.52 5.33333 8.968 5.33333Z"
					fill="#2877B8"
				/>
			</svg>
		</span>
	);
};
