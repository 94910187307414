const administrationConstants = {
	ADMINISTRATION_MODAL_ACTION_FAILURE: "ADMINISTRATION_MODAL_ACTION_FAILURE",
	CLEAR_ADMINISTRATION_MODAL_REQUEST_STATE:
		"CLEAR_ADMINISTRATION_MODAL_REQUEST_STATE",
	GET_BUILDING_TYPES: "GET_BUILDING_TYPES",
	GET_DRAWING_REFERENCES: "GET_DRAWING_REFERENCES",
	GET_DELIVERY_FORMATS: "GET_DELIVERY_FORMATS",
	GET_LODS: "GET_LODS",
	GET_BUILDING_COMPONENTS: "GET_BUILDING_COMPONENTS",
	GET_DISCIPLINES: "GET_DISCIPLINES",
	GET_EXPORTS: "GET_EXPORTS",
	GET_DELIVERY_FORMAT: "GET_DELIVERY_FORMAT",
	GET_BUILDING_CATEGORY_ADJUSTMENTS: "GET_BUILDING_CATEGORY_ADJUSTMENTS",
	GET_LOD_ADJUSTMENTS: "GET_LOD_ADJUSTMENTS",
	CLEAR_ADMINISTRATION_REQUEST_STATE: "CLEAR_ADMINISTRATION_REQUEST_STATE",
	ADMINISTRATION_ACTION_SUCCESS: "ADMINISTRATION_ACTION_SUCCESS",
	ADMINISTRATION_ACTION_FAILURE: "ADMINISTRATION_ACTION_FAILURE",
	GET_BUILDING_TYPE_CHILDRENS: "GET_BUILDING_TYPE_CHILDRENS",
	GET_BUILDING_TYPE_PARENTS: "GET_BUILDING_TYPE_PARENTS",
	GET_SETTINGS: "GET_SETTINGS",
	GET_NORMALISATION_MANUAL: "GET_NORMALISATION_MANUAL",
	GET_CUSTOMISATION_MANUAL: "GET_CUSTOMISATION_MANUAL",
	GET_QA_MANUAL: "GET_QA_MANUAL",
	GET_PACKAGE_CATEGORIES: "GET_PACKAGE_CATEGORIES",
	CLEAR_MANUALS: "CLEAR_MANUALS",
	UPDATE_DISCIPLINE_ORDER: "UPDATE_DISCIPLINE_ORDER",
	UPDATE_TERMS_AND_CONDITION: "UPDATE_TERMS_AND_CONDITION",
	GET_TERMS_AND_CONDITION: "GET_TERMS_AND_CONDITION",
	GET_REPETITIVENESS_ADJUSTMENTS: "GET_REPETITIVENESS_ADJUSTMENTS",
	GET_MODELING_DATA: "GET_MODELING_DATA",
	GET_BUILDING_CATEGORY_TIME_ADJUSTMENTS:
		"GET_BUILDING_CATEGORY_TIME_ADJUSTMENTS",
	GET_LOD_TIME_ADJUSTMENTS: "GET_LOD_TIME_ADJUSTMENTS",
	GET_SPECIFICATION_PARAMETERS: "GET_SPECIFICATION_PARAMETERS",
	GET_SPECIFICATION_CATEGORIES: "GET_SPECIFICATION_CATEGORIES",
	GET_FIELD_TYPES: "GET_FIELD_TYPES",
	GET_COMPONENT_CATEGORY_CONTENT: "GET_COMPONENT_CATEGORY_CONTENT",
	GET_NUMBER_OF_COMPONENT_CATEGORIES_BY_PARAMETER_CATEGORY_ID:
		"GET_NUMBER_OF_COMPONENT_CATEGORIES_BY_PARAMETER_CATEGORY_ID",
	GET_FAMILIES: "GET_FAMILIES",
	GET_CUSTOMISATION_TIME_BY_INPUT_FORMAT:
		"GET_CUSTOMISATION_TIME_BY_INPUT_FORMAT",
	GET_ADMIN_FAMILIES: "GET_ADMIN_FAMILIES",
	GET_EXPORTS_ADJUSTMENT_BY_INPUT_FORMAT:
		"GET_EXPORTS_ADJUSTMENT_BY_INPUT_FORMAT",
	GET_SERVICES_ADJUSTMENT_INPUT_FORMAT: "GET_SERVICES_ADJUSTMENT_INPUT_FORMAT",
	GET_SYMBOL_FUNCTIONS: "GET_SYMBOL_FUNCTIONS",
	GET_ALL_DISCIPLINE_COMPONENTS: "GET_ALL_DISCIPLINE_COMPONENTS",
	GET_DXF_SERVER_COMPONENTS: "GET_DXF_SERVER_COMPONENTS",
	GET_DXF_SERVER_COMPONENT_PARAMETERS: "GET_DXF_SERVER_COMPONENT_PARAMETERS",
	GET_DXF_MODEL_PROPERTIES: "GET_DXF_MODEL_PROPERTIES",
	CLEAR_DXF_SERVER_COMPONENTS: "CLEAR_DXF_SERVER_COMPONENTS",

	GET_REVIT_TEMPLATE: "GET_REVIT_TEMPLATE",
	GET_REVIT_TEMPLATE_FAMILIES: "GET_REVIT_TEMPLATE_FAMILIES",
	GET_REVIT_TEMPLATE_FAMILY_MAPPING: "GET_REVIT_TEMPLATE_FAMILY_MAPPING",
};

export default administrationConstants;
