import React from "react";

export default (props) => {
	return (
		<div className={`icon ${props.iconClass ? props.iconClass : ""}`}>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="18"
				height="18"
				viewBox="0 0 18 18"
			>
				<circle cx="9" cy="9" r="9" fill="#55d595" />
				<path
					d="M-4825.739-2210.678l2.724,2.724,4.776-4.776"
					transform="translate(4831.225 2219.42)"
					fill="none"
					stroke="#fff"
					strokeLinecap="round"
					strokeWidth="2"
				/>
			</svg>
		</div>
	);
};
