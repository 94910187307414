import React, { useEffect } from "react";
/**
 * Custom modal component used widely through app
 */
const Modal = ({
	show = false,
	title = "",
	children,
	onClose,
	modalClasses = "modal",
	hideClose = false,
	hideHeader = false,
}) => {
	const handleClose = (e) => {
		onClose && onClose(e);
		document.body.classList.remove("modal-visible");
	};

	useEffect(() => {
		if (show) {
			document.body.classList.add("modal-visible");
		} else {
			document.body.classList.remove("modal-visible");
		}
	}, [show]);

	if (show) {
		return (
			<div className="bimify-mp">
				<div className="mp__overlay"></div>
				<div className="mp-wrapper d-flex justify-content-center align-content-center">
					<div
						className={`${modalClasses} bg-n000 card card--3 py-regular px-24`}
					>
						{!hideHeader && (
							<div className="header d-flex justify-content-between w-100 mb-24">
								{!!title && <h6 className="color-n300">{title}</h6>}
								{!hideClose && (
									<button
										onClick={(e) => handleClose(e)}
										className="mp__close ml-auto mr-0"
									></button>
								)}
							</div>
						)}

						{children}
					</div>
				</div>
			</div>
		);
	}
	return null;
};

export default Modal;
