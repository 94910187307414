import React, { useEffect } from "react";
import { connect } from "react-redux";
import HeaderLayout from "../../../../layout/content/HeaderLayout";
import { getComponentCategoryContent, getLods } from "../../../../../actions";
import SummaryImage from "./view/SummaryImage";
import ParameterCategory from "./view/ParameterCategory";
import AdministrationRequestPopups from "../../../AdministrationRequestPopups";
import { useParams } from "react-router-dom-v5-compat";

const LodContentView = (props) => {
	const { lodContentId } = useParams();
	const { componentCategory, getComponentCategoryContent, getLods } = props;

	useEffect(() => {
		getLods();
		getComponentCategoryContent(lodContentId);
	}, [lodContentId, getComponentCategoryContent, getLods]);

	return (
		<div className="card card--border--none  card--2 bg-n000 card page-content--large">
			<AdministrationRequestPopups />
			<HeaderLayout wrapperClasses="px-24 d-flex align-items-center justify-content-between">
				<h3>{componentCategory?.get("type")}</h3>
			</HeaderLayout>

			<div className="pt-regular px-regular page-content--large-body overflow-y-auto">
				<SummaryImage />
				<ParameterCategory />
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		componentCategory: state.getIn([
			"administration",
			"componentCategoryContent",
			"componentCategory",
		]),
	};
};

export default connect(mapStateToProps, {
	getComponentCategoryContent,
	getLods,
})(LodContentView);
