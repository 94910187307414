import React from "react";

const HealthcareIcon = (props) => {
	return (
		<div className={`icon ${props.iconClass}`}>
			<svg
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<g clipPath="url(#clip0_3899_23048)">
					<path
						d="M19.1472 8.06703H4.85319C4.7929 8.06878 4.73438 8.04665 4.6905 8.00551C4.64661 7.96437 4.62094 7.90758 4.61914 7.84763V4.96652C4.62153 4.90677 4.64738 4.85034 4.69114 4.80932C4.73489 4.76831 4.79307 4.74598 4.85319 4.74712H19.1472C19.2074 4.74588 19.2657 4.76816 19.3095 4.80919C19.3533 4.85021 19.3792 4.9067 19.3816 4.96652V7.84763C19.3792 7.90745 19.3533 7.96394 19.3095 8.00496C19.2657 8.04599 19.2074 8.06828 19.1472 8.06703Z"
						fill="#FFC350"
					/>
					<path
						d="M20.1719 8.06641H3.82812V18.06H20.1719V8.06641Z"
						fill="white"
					/>
					<path
						d="M20.1716 18.2871H3.82749C3.76654 18.2871 3.70808 18.263 3.66497 18.2201C3.62187 18.1773 3.59766 18.1192 3.59766 18.0585V8.06643C3.59766 8.00582 3.62187 7.94769 3.66497 7.90483C3.70808 7.86197 3.76654 7.83789 3.82749 7.83789H20.1716C20.2326 7.83789 20.2911 7.86197 20.3342 7.90483C20.3773 7.94769 20.4015 8.00582 20.4015 8.06643V18.0604C20.401 18.1207 20.3765 18.1784 20.3335 18.2208C20.2904 18.2633 20.2323 18.2871 20.1716 18.2871ZM4.0558 17.8338H19.9433V8.29306H4.05542L4.0558 17.8338Z"
						fill="#EE6712"
					/>
					<path
						d="M15.3385 1.42676H8.66016V8.06658H15.3385V1.42676Z"
						fill="white"
					/>
					<path
						d="M15.3378 8.29324H8.65952C8.62934 8.29324 8.59945 8.28733 8.57157 8.27584C8.54368 8.26436 8.51835 8.24752 8.49701 8.2263C8.47566 8.20508 8.45873 8.17989 8.44718 8.15216C8.43563 8.12443 8.42969 8.09471 8.42969 8.0647V1.42678C8.42969 1.39677 8.43563 1.36705 8.44718 1.33932C8.45873 1.3116 8.47566 1.2864 8.49701 1.26518C8.51835 1.24396 8.54368 1.22712 8.57157 1.21564C8.59945 1.20415 8.62934 1.19824 8.65952 1.19824H15.3378C15.3988 1.19824 15.4573 1.22232 15.5004 1.26518C15.5435 1.30804 15.5677 1.36617 15.5677 1.42678V8.06661C15.5672 8.12689 15.5427 8.18453 15.4997 8.22698C15.4566 8.26943 15.3985 8.29324 15.3378 8.29324ZM8.88783 7.83997H15.1099V1.65342H8.88745L8.88783 7.83997Z"
						fill="#EE6712"
					/>
					<path
						d="M13.5063 4.17618H12.5732V3.24983C12.5733 3.23798 12.571 3.22623 12.5664 3.21526C12.5619 3.20429 12.5553 3.19431 12.5469 3.18589C12.5385 3.17747 12.5285 3.17078 12.5175 3.1662C12.5065 3.16161 12.4947 3.15923 12.4828 3.15918H11.5175C11.4933 3.15928 11.4702 3.16886 11.4531 3.18584C11.436 3.20282 11.4264 3.22582 11.4263 3.24983V4.17618H10.4955C10.4714 4.17618 10.4483 4.18562 10.4313 4.20246C10.4142 4.2193 10.4045 4.24216 10.4043 4.26607V5.22555C10.4043 5.23741 10.4067 5.24914 10.4114 5.26007C10.416 5.271 10.4227 5.28093 10.4312 5.28928C10.4396 5.29762 10.4497 5.30423 10.4607 5.30872C10.4717 5.31321 10.4835 5.3155 10.4955 5.31545H11.4263V6.24255C11.4264 6.26657 11.436 6.28957 11.4531 6.30655C11.4702 6.32352 11.4933 6.33311 11.5175 6.33321H12.4828C12.5068 6.33301 12.5298 6.32337 12.5468 6.30639C12.5637 6.28941 12.5732 6.26646 12.5732 6.24255V5.31621H13.5063C13.5183 5.31626 13.5301 5.31397 13.5411 5.30948C13.5521 5.30499 13.5622 5.29838 13.5707 5.29004C13.5791 5.28169 13.5858 5.27177 13.5905 5.26083C13.5951 5.2499 13.5975 5.23817 13.5975 5.22632V4.26607C13.5972 4.24219 13.5875 4.21939 13.5704 4.20257C13.5534 4.18576 13.5304 4.17628 13.5063 4.17618Z"
						fill="#EE6712"
					/>
					<path
						d="M19.3816 18.208H4.61914V22.5735H19.3816V18.208Z"
						fill="#FFC350"
					/>
					<path
						d="M18.9488 12.2553H5.05015C4.98919 12.2553 4.93073 12.2313 4.88763 12.1884C4.84453 12.1455 4.82031 12.0874 4.82031 12.0268V10.0537C4.82031 9.99312 4.84453 9.93499 4.88763 9.89213C4.93073 9.84927 4.98919 9.8252 5.05015 9.8252H18.9488C18.979 9.8252 19.0089 9.83111 19.0368 9.84259C19.0647 9.85408 19.09 9.87091 19.1114 9.89213C19.1327 9.91336 19.1496 9.93855 19.1612 9.96628C19.1727 9.994 19.1787 10.0237 19.1787 10.0537V12.0268C19.1787 12.0568 19.1727 12.0865 19.1612 12.1142C19.1496 12.142 19.1327 12.1672 19.1114 12.1884C19.09 12.2096 19.0647 12.2264 19.0368 12.2379C19.0089 12.2494 18.979 12.2553 18.9488 12.2553ZM5.27807 11.8017H18.7209V10.2819H5.27807V11.8017Z"
						fill="#EE6712"
					/>
					<path
						d="M18.9488 16.2985H5.05015C4.98919 16.2985 4.93073 16.2744 4.88763 16.2316C4.84453 16.1887 4.82031 16.1306 4.82031 16.07V14.0977C4.82031 14.0677 4.82626 14.0379 4.83781 14.0102C4.84936 13.9825 4.86629 13.9573 4.88763 13.9361C4.90897 13.9149 4.93431 13.898 4.9622 13.8865C4.99008 13.8751 5.01997 13.8691 5.05015 13.8691H18.9488C18.979 13.8691 19.0089 13.8751 19.0368 13.8865C19.0647 13.898 19.09 13.9149 19.1114 13.9361C19.1327 13.9573 19.1496 13.9825 19.1612 14.0102C19.1727 14.0379 19.1787 14.0677 19.1787 14.0977V16.0738C19.1777 16.1337 19.153 16.1909 19.11 16.2329C19.067 16.275 19.0091 16.2985 18.9488 16.2985ZM5.27807 15.8452H18.7209V14.3243H5.27807V15.8452Z"
						fill="#EE6712"
					/>
					<path
						d="M19.4312 22.8001H4.61851C4.55755 22.8001 4.49909 22.776 4.45599 22.7332C4.41289 22.6903 4.38867 22.6322 4.38867 22.5715V18.0606C4.38867 18 4.41289 17.9418 4.45599 17.899C4.49909 17.8561 4.55755 17.832 4.61851 17.832H19.4312C19.4921 17.832 19.5506 17.8561 19.5937 17.899C19.6368 17.9418 19.661 18 19.661 18.0606V22.5735C19.6605 22.6337 19.6361 22.6914 19.593 22.7338C19.55 22.7763 19.4918 22.8001 19.4312 22.8001ZM4.84682 22.3468H19.204V18.2872H4.84643L4.84682 22.3468Z"
						fill="#EE6712"
					/>
					<path
						d="M12.0001 19.6006H9.05469V22.5739H12.0001V19.6006Z"
						fill="white"
					/>
					<path
						d="M11.9994 22.8005H9.05406C8.9931 22.8005 8.93464 22.7765 8.89154 22.7336C8.84843 22.6907 8.82422 22.6326 8.82422 22.572V19.6006C8.82422 19.54 8.84843 19.4819 8.89154 19.439C8.93464 19.3961 8.9931 19.3721 9.05406 19.3721H11.9994C12.0604 19.3721 12.1188 19.3961 12.1619 19.439C12.2051 19.4819 12.2293 19.54 12.2293 19.6006V22.5739C12.2288 22.6342 12.2043 22.6918 12.1613 22.7343C12.1182 22.7767 12.0601 22.8005 11.9994 22.8005ZM9.28198 22.3473H11.7719V19.8272H9.28198V22.3473Z"
						fill="#EE6712"
					/>
					<path d="M14.9454 19.6006H12V22.5739H14.9454V19.6006Z" fill="white" />
					<path
						d="M14.9452 22.8005H12.0013C11.9404 22.8005 11.8819 22.7765 11.8388 22.7336C11.7957 22.6907 11.7715 22.6326 11.7715 22.572V19.6006C11.7715 19.54 11.7957 19.4819 11.8388 19.439C11.8819 19.3961 11.9404 19.3721 12.0013 19.3721H14.9467C15.0077 19.3721 15.0661 19.3961 15.1092 19.439C15.1523 19.4819 15.1765 19.54 15.1765 19.6006V22.5739C15.176 22.6344 15.1514 22.6923 15.108 22.7348C15.0646 22.7773 15.0061 22.8009 14.9452 22.8005ZM12.2277 22.3473H14.7176V19.8272H12.2277V22.3473Z"
						fill="#EE6712"
					/>
				</g>
				<defs>
					<clipPath id="clip0_3899_23048">
						<rect
							width="16.8"
							height="21.6"
							fill="white"
							transform="translate(3.59961 1.2002)"
						/>
					</clipPath>
				</defs>
			</svg>
		</div>
	);
};

export default HealthcareIcon;
