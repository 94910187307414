const companyConstants = {
	SET_FULL_ADDRESS: "SET_FULL_ADDRESS",
	GET_COMPANIES: "GET_COMPANIES",
	GET_CHAT_COMPANIES: "GET_CHAT_COMPANIES",
	GET_BIMIFY_COMPANY: "GET_BIMIFY_COMPANY",
	GET_COMPANY: "GET_COMPANY",
	GET_COMPANY_COUNTRIES: "GET_COMPANY_COUNTRIES",
	GET_SUPPLIER: "GET_SUPPLIER",
	FIELD_VALUE: "FIELD_VALUE",
	SET_TEMP_IMAGE: "SET_TEMP_IMAGE",
	CLEAR_COMPANY_TEMP: "CLEAR_COMPANY_TEMP",
	CLEAR_COMPANY: "CLEAR_COMPANY",
	SET_SUCCESS_MESSAGE: "SET_SUCCESS_MESSAGE",
	C_ACTION_SUCCESS: "C_ACTION_SUCCESS",
	C_ACTION_FAILURE: "C_ACTION_FAILURE",
	ACTION_GET_FAILURE: "ACTION_GET_FAILURE",
	C_REQUEST_DONE: "C_REQUEST_DONE",
	CLEAR_COMPANY_REQUEST_STATE: "CLEAR_COMPANY_REQUEST_STATE",
	GET_SUPPLIER_ADMINS: "GET_SUPPLIER_ADMINS",
	GET_ADMINS: "GET_ADMINS",
	COMPANY_STATS: "COMPANY_STATS",
	GET_CLINET_PL: "GET_CLINET_PL",
	GET_SUPPLIER_PL: "GET_SUPPLIER_PL",
};

export default companyConstants;
