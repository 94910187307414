import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import localize, { LocKeys } from "../../../constants/localizations";
import Get404 from "./Get404";

const Page404 = (props) => {
	return (
		<div className="page-404 mh-outlet align-items-center d-flex">
			<Get404>
				<h1>{localize(LocKeys.PAGE_NOT_EXIST)}</h1>
				<Link to="/">{localize(LocKeys.GO_TO_HOME)}</Link>
			</Get404>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {};
};

export default connect(mapStateToProps, {})(Page404);
