import { List, Map, fromJS } from "immutable";
import { templateConstants } from "../constants/templateConstants";
import { v4 as uuid } from "uuid";

/**
 * Template redux
 */

const initialState = Map({
	action: false,
	request: false,
	revitTemplateList: List(),
	revitTemplateMeta: Map(),
	revitTemplateStats: null,
	tempRevitTemplate: Map({
		name: "",
		customer: null,
		isDefault: false,
	}),
	tempRevitTemplateCopy: Map({}),
	revitTemplate: Map(),
	successMessage: "",
	errorMessage: "Something went wrong",
});

export default (state = initialState, action) => {
	//---------------------------TEMP REVIT_TEMPLATE-----------------------

	const setTempRevitTemplate = (data) => {
		const revitTemplate = {
			name: data?.name || "",
			customer: data?.company?.id || null,
			isDefault: data?.isDefault || false,
			template: {
				file: null,
				fileData: {
					file: data?.file || null,
					url: data?.url || null,
					version: data?.version || null,
				},
			},
		};

		return state
			.set("tempRevitTemplate", fromJS(revitTemplate))
			.set("tempRevitTemplateCopy", fromJS(revitTemplate));
	};

	/**
	 * Set TEMP Revit Template
	 * @param {object} data - disciplineId, componentId, result
	 */
	const setTempRevitTemplateFamilyMapping = (data) => {
		const { disciplineId, componentId, stateLods, result } = data;
		const tempLods = stateLods?.toJS() || [];

		const familyMapping = [];

		result &&
			result.map((item) => {
				const lods = [];
				if (item?.mappings?.length > 0) {
					const sortedMappings = item?.mappings.sort(
						(a, b) => a.lod.sortOrder - b.lod.sortOrder
					);

					sortedMappings &&
						sortedMappings.map((sortedMapping) => {
							if (sortedMapping.lod.type !== "0")
								lods.push({
									lodId: sortedMapping?.lod?.id || null,
									lod: sortedMapping?.lod?.type || "",
									familyMappingId: +sortedMapping?.familyMapping?.id || null,
								});

							return lods;
						});
				} else {
					tempLods &&
						tempLods.map((lod) => {
							if (lod.type !== "0")
								lods.push({
									lodId: lod.id,
									lod: lod.type,
									familyMappingId: null,
								});

							return lods;
						});
				}

				return familyMapping.push({
					id: item.id,
					tempId: uuid(),
					familyId: item.id,
					familyCheck: item?.isDefault || false,
					family: item.name,
					lods,
				});
			});

		return state.setIn(
			[
				"tempRevitTemplate",
				"familyMapping",
				`discipline_${disciplineId}`,
				`component_${componentId}`,
			],
			fromJS(familyMapping)
		);
	};

	/**
	 * Add TEMP Revit Template Family Mapping
	 * @param {object} data - disciplineId, componentId
	 */
	const addTempRevitTemplateFamilyMapping = (data) => {
		const { disciplineId, componentId, stateLods } = data;

		const tempLods = stateLods?.toJS() || [];

		const families = state.getIn([
			"tempRevitTemplate",
			"familyMapping",
			`discipline_${disciplineId}`,
			`component_${componentId}`,
		]);
		const familiesJS = families ? families?.toJS() : [];

		const lods = [];

		tempLods &&
			tempLods.map((lod) => {
				if (lod.type !== "0")
					lods.push({
						lodId: lod.id,
						lod: lod.type,
						familyMappingId: null,
					});

				return lods;
			});

		familiesJS.push({
			id: null,
			tempId: uuid(),
			familyId: null,
			family: "",
			lods,
		});

		return state.setIn(
			[
				"tempRevitTemplate",
				"familyMapping",
				`discipline_${disciplineId}`,
				`component_${componentId}`,
			],
			fromJS(familiesJS)
		);
	};

	/**
	 * Update TEMP Revit Template Family Mapping
	 * @param {object} data - disciplineId, componentId, tempId, value, fieldKey, lodId
	 */
	const updateTempRevitTemplateFamilyMapping = (data) => {
		const {
			disciplineId,
			componentId,
			tempId,
			value,
			lodId = null,
			fieldKey,
		} = data;

		const families = state.getIn([
			"tempRevitTemplate",
			"familyMapping",
			`discipline_${disciplineId}`,
			`component_${componentId}`,
		]);
		const familiesJS = families ? families?.toJS() : [];

		const findIndex = familiesJS.findIndex((item) => item.tempId === tempId);

		if (!!lodId) {
			const familyLods =
				state
					.getIn([
						"tempRevitTemplate",
						"familyMapping",
						`discipline_${disciplineId}`,
						`component_${componentId}`,
						findIndex,
						"lods",
					])
					?.toJS() || [];

			const lodIndex = familyLods.findIndex((item) => +item.lodId === +lodId);

			return state.setIn(
				[
					"tempRevitTemplate",
					"familyMapping",
					`discipline_${disciplineId}`,
					`component_${componentId}`,
					findIndex,
					"lods",
					lodIndex,
					fieldKey,
				],
				value
			);
		}

		return state.setIn(
			[
				"tempRevitTemplate",
				"familyMapping",
				`discipline_${disciplineId}`,
				`component_${componentId}`,
				findIndex,
				fieldKey,
			],
			value
		);
	};

	/**
	 * Remove TEMP Family Mapping
	 *
	 * @param {object} data - disciplineId, componentId, tempId
	 * @param {number} data.tempId  - tempId of the family
	 * @param {number} data.disciplineId - unique id of the discipline
	 * @param {number} data.componentId - unique id of the component
	 *
	 */
	const removeTempRevitTemplateFamilyMapping = (data) => {
		const { tempId, disciplineId, componentId } = data;

		const families = state.getIn([
			"tempRevitTemplate",
			"familyMapping",
			`discipline_${disciplineId}`,
			`component_${componentId}`,
		]);
		const familiesJS = families ? families?.toJS() : [];
		const filteredFamilies = familiesJS.filter(
			(family) => family.tempId !== tempId
		);

		return state.setIn(
			[
				"tempRevitTemplate",
				"familyMapping",
				`discipline_${disciplineId}`,
				`component_${componentId}`,
			],
			fromJS(filteredFamilies)
		);
	};

	const updateAllFamilyMappingCheckDefault = (data) => {
		const { disciplineId, componentId, value } = data;

		const familyMapping = state.getIn([
			"tempRevitTemplate",
			"familyMapping",
			`discipline_${disciplineId}`,
			`component_${componentId}`,
		]);

		const familyMappingJS = familyMapping ? familyMapping?.toJS() : [];

		const updatedFamilyMapping =
			familyMappingJS &&
			familyMappingJS.map((family) => {
				return { ...family, familyCheck: value };
			});

		return state.setIn(
			[
				"tempRevitTemplate",
				"familyMapping",
				`discipline_${disciplineId}`,
				`component_${componentId}`,
			],
			fromJS(updatedFamilyMapping)
		);
	};

	//----------------------------REVIT TEMPLATE---------------------------------

	/**
	 * GET REVIT TEMPLATE
	 * @param {object} data - response data
	 */
	const getRevitTemplate = (data) => {
		return state.set("revitTemplate", fromJS(data));
	};

	/**
	 * GET REVIT TEMPLATE FAMILIES
	 * @param {object} data - response data
	 * @param {number} data.disciplineId - unique discipline identifier
	 * @param {number} data.componentId - unique component identifier
	 * @param {array} data.result - response data
	 */
	const getRevitTemplateFamilies = (data) => {
		const { disciplineId, componentId, result } = data;

		return state.setIn(
			[
				"revitTemplate",
				"families",
				`discipline_${disciplineId}`,
				`component_${componentId}`,
			],
			fromJS(result)
		);
	};

	/**
	 * GET REVIT TEMPLATE FAMILY MAPPING DATA
	 * @param {object} data - response data
	 * @param {number} data.disciplineId - unique discipline identifier
	 * @param {number} data.componentId - unique component identifier
	 * @param {array} data.result - response data
	 */
	const getRevitTemplateFamilyMapping = (data) => {
		const { disciplineId, componentId, result } = data;

		return state.setIn(
			[
				"revitTemplate",
				"familyMapping",
				`discipline_${disciplineId}`,
				`component_${componentId}`,
			],
			fromJS(result)
		);
	};

	switch (action.type) {
		case templateConstants.GET_REVIT_TEMPLATES:
			return state
				.set("revitTemplateList", fromJS(action.data?.result))
				.set("revitTemplateMeta", fromJS(action.data?.meta));

		case templateConstants.CLEAR_REVIT_TEMPLATES:
			return state
				.set("revitTemplateList", initialState.get("revitTemplateList"))
				.set("revitTemplateMeta", initialState.get("revitTemplateMeta"));

		case templateConstants.CLEAR_TEMPLATE_REQUEST_STATE:
			return state
				.set("action", false)
				.set("request", false)
				.set("errorMessage", "")
				.set("successMessage", "");

		case templateConstants.TEMPLATE_ACTION_FAILURE:
			return state
				.set("action", false)
				.set("request", true)
				.set("errorMessage", fromJS(action.data));

		case templateConstants.TEMPLATE_ACTION_SUCCESS:
			return state
				.set("successMessage", fromJS(action.data))
				.set("action", true)
				.set("request", true);

		//-------------------REVIT TEMPLATE--------------------------

		case templateConstants.GET_REVIT_TEMPLATE:
			return getRevitTemplate(action.data);

		case templateConstants.GET_REVIT_TEMPLATE_FAMILIES:
			return getRevitTemplateFamilies(action.data);

		case templateConstants.GET_REVIT_TEMPLATE_FAMILY_MAPPING:
			return getRevitTemplateFamilyMapping(action.data);

		case templateConstants.CLEAR_SINGLE_REVIT_TEMPLATE:
			return state.set("revitTemplate", initialState.get("revitTemplate"));

		//---------------------------TEMP REVIT TEMPLATE-------------------------------
		case templateConstants.RESET_TEMP_REVIT_TEMPLATE:
			return state.set("tempRevitTemplate", state.get("tempRevitTemplateCopy"));

		case templateConstants.SET_TEMP_REVIT_TEMPLATE:
			return setTempRevitTemplate(action.data);

		case templateConstants.CLEAR_TEMP_REVIT_TEMPLATE:
			return state
				.set("tempRevitTemplate", initialState.get("tempRevitTemplate"))
				.set(
					"tempRevitTemplateCopy",
					initialState.get("tempRevitTemplateCopy")
				);

		case templateConstants.UPDATE_TEMP_REVIT_TEMPLATE_DATA:
			return state.setIn(
				["tempRevitTemplate", action.data.fieldKey],
				action.data.value
			);
		case templateConstants.SET_TEMP_REVIT_TEMPLATE_FILE:
			return state.setIn(
				["tempRevitTemplate", "template", "file"],
				action.data
			);

		case templateConstants.SET_TEMP_REVIT_TEMPLATE_FILE_DATA:
			return state.setIn(
				["tempRevitTemplate", "template", "fileData"],
				fromJS(action.data)
			);

		case templateConstants.REMOVE_TEMP_REVIT_TEMPLATE_FILE:
			return state.setIn(
				["tempRevitTemplate", "template"],
				Map({
					file: null,
					fileData: null,
				})
			);

		case templateConstants.SET_TEMP_REVIT_TEMPLATE_FAMILY_MAPPING:
			return setTempRevitTemplateFamilyMapping(action.data);

		case templateConstants.ADD_TEMP_REVIT_TEMPLATE_FAMILY_MAPPING:
			return addTempRevitTemplateFamilyMapping(action.data);

		case templateConstants.UPDATE_TEMP_REVIT_TEMPLATE_FAMILY_MAPPING:
			return updateTempRevitTemplateFamilyMapping(action.data);

		case templateConstants.REMOVE_TEMP_REVIT_TEMPLATE_FAMILY_MAPPING:
			return removeTempRevitTemplateFamilyMapping(action.data);

		case templateConstants.UPDATE_ALL_FAMILY_MAPPING_CHECK_DEFAULT:
			return updateAllFamilyMappingCheckDefault(action.data);

		case templateConstants.CLEAR_STATE:
			return initialState;

		default:
			return state;
	}
};
