import React from "react";

export default (props) => {
	return (
		<div className={`icon ${props.iconClass ? props.iconClass : ""}`}>
			<svg
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M19.18 8.78998L15 4.55998L13.63 5.88998L12 4.21998V4.13998L13.57 2.59998C13.7492 2.41335 13.9642 2.26486 14.2022 2.16343C14.4402 2.06199 14.6963 2.0097 14.955 2.0097C15.2137 2.0097 15.4698 2.06199 15.7078 2.16343C15.9458 2.26486 16.1608 2.41335 16.34 2.59998C17.9533 4.19998 19.5567 5.79998 21.15 7.39998C21.3348 7.57814 21.4818 7.79173 21.5822 8.02798C21.6826 8.26422 21.7343 8.51828 21.7343 8.77498C21.7343 9.03167 21.6826 9.28573 21.5822 9.52198C21.4818 9.75822 21.3348 9.97181 21.15 10.15L17.15 14.15C16.9706 14.3308 16.7572 14.4743 16.522 14.5722C16.2869 14.6701 16.0347 14.7206 15.78 14.7206C15.5253 14.7206 15.2731 14.6701 15.038 14.5722C14.8028 14.4743 14.5894 14.3308 14.41 14.15L14.11 13.86L19.18 8.78998Z"
					fill="#DD0124"
				/>
				<path
					d="M9.93991 10.13L4.84991 15.22L9.06991 19.44L10.3999 18.11L12.0599 19.78L11.9799 19.87L10.3999 21.44C10.2292 21.6205 10.0228 21.7635 9.79385 21.8599C9.56487 21.9564 9.31833 22.004 9.06991 22C8.82512 22.0083 8.58128 21.9655 8.35398 21.8743C8.12669 21.783 7.92094 21.6453 7.74991 21.47C6.10325 19.8233 4.45658 18.18 2.80991 16.54C2.46414 16.1836 2.27075 15.7065 2.27075 15.21C2.27075 14.7134 2.46414 14.2364 2.80991 13.88C4.14991 12.52 5.50991 11.18 6.85991 9.82997C7.20838 9.47696 7.67996 9.2725 8.17582 9.25945C8.67168 9.24641 9.15336 9.42577 9.51991 9.75997L9.93991 10.13Z"
					fill="#AB007C"
				/>
				<path
					d="M4.50996 8.99999L5.88996 10.4L4.23996 12.06C3.65996 11.48 3.05996 10.92 2.49996 10.32C2.17951 9.9606 2.00831 9.49245 2.02132 9.01111C2.03433 8.52978 2.23056 8.07155 2.56996 7.72999L7.45996 2.82999C7.81635 2.48422 8.2934 2.29083 8.78996 2.29083C9.28652 2.29083 9.76357 2.48422 10.12 2.82999L14.22 6.91999C14.579 7.28155 14.7805 7.77043 14.7805 8.27999C14.7805 8.78955 14.579 9.27844 14.22 9.63999L13.91 9.97999C13.8755 9.90489 13.8318 9.83435 13.78 9.76999L8.89996 4.93999C8.78996 4.83999 8.73996 4.82999 8.63996 4.93999L4.63996 8.93999C4.63996 8.93999 4.56996 8.99999 4.50996 8.99999Z"
					fill="#004D95"
				/>
				<path
					d="M15.2499 19.19L15.3899 19.07L19.3899 15.07C19.4899 14.97 19.4899 14.92 19.3899 14.82L18.1699 13.61L19.8099 12L19.8799 12.07L21.4099 13.59C21.596 13.7674 21.7441 13.9808 21.8453 14.2171C21.9465 14.4535 21.9987 14.7079 21.9987 14.965C21.9987 15.2221 21.9465 15.4765 21.8453 15.7129C21.7441 15.9492 21.596 16.1626 21.4099 16.34L16.6099 21.14C16.4336 21.3227 16.2223 21.468 15.9887 21.5672C15.755 21.6664 15.5038 21.7176 15.2499 21.7176C14.996 21.7176 14.7448 21.6664 14.5111 21.5672C14.2775 21.468 14.0662 21.3227 13.8899 21.14L9.8899 17.14C9.71302 16.9635 9.57269 16.7539 9.47694 16.5231C9.38119 16.2923 9.33191 16.0449 9.33191 15.795C9.33191 15.5451 9.38119 15.2977 9.47694 15.0669C9.57269 14.8361 9.71302 14.6265 9.8899 14.45C9.97137 14.3819 10.0482 14.3084 10.1199 14.23C10.1799 14.16 10.2299 14.16 10.2899 14.23L10.3799 14.32L15.1699 19.11C15.1948 19.1384 15.2215 19.1651 15.2499 19.19Z"
					fill="#0093A8"
				/>
				<path
					d="M6.32996 14.19L10.14 10.38L11.79 12L11.72 12.08L9.41996 14.38C9.1385 14.6916 8.76264 14.9024 8.34996 14.98C8.04418 15.043 7.7275 15.0284 7.42881 14.9375C7.13012 14.8467 6.85892 14.6825 6.63996 14.46L6.32996 14.19Z"
					fill="#004D95"
				/>
				<path
					d="M13.66 10.13L12 11.79L11.9 11.7L9.56999 9.36999C9.38181 9.19067 9.23199 8.97501 9.12963 8.73608C9.02727 8.49715 8.97449 8.23992 8.97449 7.97999C8.97449 7.72005 9.02727 7.46282 9.12963 7.22389C9.23199 6.98496 9.38181 6.7693 9.56999 6.58999L9.84999 6.29999L13.66 10.13Z"
					fill="#DD0124"
				/>
				<path
					d="M10.39 13.84L12 12.21L12.72 12.94L14.5 14.7C14.8488 15.0568 15.044 15.536 15.044 16.035C15.044 16.5339 14.8488 17.0131 14.5 17.37C14.4 17.47 14.31 17.57 14.22 17.65L10.39 13.84Z"
					fill="#AB007C"
				/>
				<path
					d="M12.25 12L12.39 11.9L14.7 9.53995C15.0564 9.19418 15.5334 9.00079 16.03 9.00079C16.5266 9.00079 17.0036 9.19418 17.36 9.53995C17.4516 9.63524 17.5484 9.72538 17.65 9.80995L13.87 13.59L12.25 12Z"
					fill="#0093A8"
				/>
				<path
					d="M20.4699 19.65C20.6226 19.65 20.7719 19.6954 20.8987 19.7805C21.0256 19.8655 21.1242 19.9864 21.1822 20.1277C21.2402 20.269 21.2548 20.4243 21.2243 20.5739C21.1938 20.7236 21.1194 20.8607 21.0108 20.968C20.9021 21.0753 20.7639 21.1478 20.6139 21.1764C20.4639 21.205 20.3088 21.1883 20.1683 21.1285C20.0277 21.0687 19.9082 20.9684 19.8248 20.8405C19.7414 20.7125 19.6979 20.5627 19.6999 20.41C19.6999 20.3093 19.7199 20.2097 19.7587 20.1168C19.7975 20.024 19.8544 19.9397 19.926 19.869C19.9977 19.7983 20.0826 19.7426 20.176 19.705C20.2694 19.6674 20.3693 19.6487 20.4699 19.65ZM20.4699 19.56C20.299 19.558 20.1314 19.607 19.9884 19.7008C19.8455 19.7945 19.7337 19.9287 19.6674 20.0862C19.601 20.2438 19.5832 20.4175 19.616 20.5853C19.6489 20.753 19.731 20.9072 19.8519 21.028C19.9727 21.1489 20.1269 21.231 20.2946 21.2639C20.4624 21.2968 20.6361 21.2789 20.7937 21.2126C20.9512 21.1462 21.0854 21.0345 21.1792 20.8915C21.2729 20.7486 21.3219 20.5809 21.3199 20.41C21.3199 20.1846 21.2304 19.9684 21.071 19.809C20.9116 19.6495 20.6954 19.56 20.4699 19.56Z"
					fill="black"
				/>
				<path
					d="M20.2899 20.5V20.9H20.1799V19.9H20.4499C20.5408 19.8919 20.6318 19.9129 20.7099 19.96C20.7452 19.9937 20.7711 20.0359 20.7851 20.0827C20.799 20.1294 20.8005 20.179 20.7893 20.2265C20.7782 20.2739 20.7549 20.3177 20.7217 20.3534C20.6885 20.3891 20.6465 20.4155 20.5999 20.43L20.8699 20.87H20.7299L20.4999 20.47L20.2899 20.5ZM20.2899 20.4H20.4499C20.5049 20.4199 20.565 20.4199 20.6199 20.4C20.6401 20.3829 20.6561 20.3614 20.6665 20.3371C20.6769 20.3128 20.6815 20.2864 20.6799 20.26C20.6824 20.2335 20.6781 20.2069 20.6677 20.1824C20.6572 20.158 20.6408 20.1365 20.6199 20.12C20.5674 20.0832 20.5039 20.0656 20.4399 20.07H20.2899V20.4Z"
					fill="black"
				/>
			</svg>
		</div>
	);
};
