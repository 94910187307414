import React from "react";

export default (props) => {
	return (
		<div className={`icon ${props.iconClass ? props.iconClass : ""}`}>
			<svg
				width="32"
				height="32"
				viewBox="0 0 32 32"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M16 4H4V13.3333V16V28H16H18.6667H28V16V4H16ZM25.3333 25.3333H18.6667V20.6667H16V25.3333H6.66667V16H11.3333V13.3333H6.66667V6.66667H16V16H25.3333V25.3333ZM25.3333 13.3333H18.6667V6.66667H25.3333V13.3333Z"
					fill="#1B2024"
				/>
			</svg>
		</div>
	);
};
