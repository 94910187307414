import React from "react";
import InputLabel from "@mui/material/InputLabel";
import TooltipWithHtml from "../../shared/htmlTooltip/TooltipWithHtml";
import QuestionIcon from "../../shared/icons/misc/QuestionIcon";

/**
 * Component for simple text field
 */

export default ({
	disableAnimation = true,
	shrink = false,
	required = false,
	label,
	labelAppend = undefined,
	infoContent,
	note,
	id,
}) => {
	return (
		<InputLabel
			htmlFor={id}
			disableAnimation={disableAnimation}
			shrink={shrink}
			classes={{
				root: `label
					${required ? "label--required" : ""}
				`,
				focused: "label--focused",
			}}
		>
			{label} {labelAppend && labelAppend()}
			{required && <span className="label--required__asterisk">*</span>}
			{infoContent && (
				<TooltipWithHtml
					tooltipContent={infoContent}
					tooltipBodyClasses={"label__tooltip"}
					tooltipPlacement="right"
				>
					<QuestionIcon iconClass="icon--info icon--target-fill" />
				</TooltipWithHtml>
			)}
			{note && <span className="label__note">{note}</span>}
		</InputLabel>
	);
};
