import { Map, fromJS, List } from "immutable";
import companySpecsConstants from "../constants/companySpecsConstants";
/**
 * Company specs reducer redux
 */

const initialState = Map({
	companyTypes: List(),
	subscriptionTypes: List(),
});
//

export default (state = initialState, action) => {
	switch (action.type) {
		case companySpecsConstants.GET_COMPANY_TYPES:
			return state.set("companyTypes", fromJS(action.data));

		default:
			return state;
	}
};
