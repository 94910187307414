import React, { useState, useEffect } from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { colorConstants } from "../../constants/constants";

/**
 * Suspense Loader for lazy component
 *
 * @param {number} delay
 * @returns
 */
const SuspenseLoader = ({ delay = 100 }) => {
	const [show, setShow] = useState(false);

	useEffect(() => {
		let timeout = setTimeout(() => setShow(true), delay);
		return () => {
			clearTimeout(timeout);
		};
	}, [delay]);

	return (
		show && (
			<Backdrop
				sx={{
					color: colorConstants.N000,
					zIndex: (theme) => theme.zIndex.drawer + 1,
					backgroundColor: "rgba(0, 0, 0, 0.5)",
				}}
				open={true}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
		)
	);
};

export default SuspenseLoader;
