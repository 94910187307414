import React, { useEffect } from "react";
import { useParams } from "react-router-dom-v5-compat";
import { getPackage } from "../../../actions";
import { connect } from "react-redux";
import HeaderLayout from "../../layout/content/HeaderLayout";
import BodyLayout from "../../layout/content/BodyLayout";
import PackageInformation from "../crud/single-view/view/PackageInformation";
import FooterView from "../crud/single-view/view/FooterView";
import PackageSpecification from "../crud/single-view/view/PackageSpecification";

const PublicSinglePackage = ({ name, getPackage }) => {
	const { packageId } = useParams();

	useEffect(() => {
		getPackage(packageId, true, false);
	}, [packageId, getPackage]);

	return (
		<div className="card card--border--none  card--2 bg-n000">
			<HeaderLayout wrapperClasses={`px-48 d-flex align-items-center `}>
				<div className="d-flex align-items-center justify-content-between w-100">
					<h3>{name}</h3>
				</div>
			</HeaderLayout>

			<BodyLayout
				wrapperClasses="page-content--dashboard p-48 overflow-y-auto"
				isTableView={true}
			>
				<PackageInformation isForPublicView={true} />
				<PackageSpecification />
			</BodyLayout>
			<FooterView isForPublicView={true} />
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		name: state.getIn(["dashboard", "salesPackage", "name"]),
	};
};

export default connect(mapStateToProps, { getPackage })(PublicSinglePackage);
