import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import CustomAccordion from "../../../CustomAccordion/CustomAccordion";
import TooltipWithHtml from "../../../htmlTooltip/TooltipWithHtml";
import CheckmarkIconCircle from "../../../icons/CheckmarkIconCircle";
import {
	cancelUploadRequest,
	removeFileFromList,
	removeFiles,
} from "../../../../actions";
import ErrorIcon from "../../../icons/misc/ErrorIcon";
import IconWrapper from "../../../icons/IconWrapper";
import {
	fileSizes,
	fileStatusConstants,
} from "../../../../constants/constants";
import localize, { LocKeys } from "../../../../constants/localizations";
import CustomCircularProgress from "../../CircularProgress/CustomCircularProgress";
import FileIcon from "../../../icons/files/FileIcon";

export const convertToMBorGB = (size) => {
	if (Number(size) >= fileSizes.GB) {
		return Number(size / (1024 * 1024 * 1024)).toFixed(2) + " GB";
	} else if (Number(size) >= fileSizes.MB) {
		return Number(size / (1024 * 1024)).toFixed(2) + " MB";
	} else {
		return Number(size / 1024).toFixed(2) + " kB";
	}
};
/**
 * Component for upload files inside CustomWidget
 *
 */
const UploadFile = (props) => {
	const {
		//state
		handleClose,
		snackbarFiles,
		//actions
		removeFileFromList,
		cancelUploadRequest,
		removeFiles,
	} = props;

	const [inProgressItem, setInProgressItem] = useState();
	const [row, setRow] = useState({ isHover: false, key: null });
	const { isHover, key } = row;

	useEffect(() => {
		const inProgress = [];
		if (snackbarFiles) {
			snackbarFiles.forEach((file) => {
				if (file.get("status") === fileStatusConstants.IN_PROGRESS) {
					inProgress.push(file);
				}
			});
		}

		setInProgressItem(inProgress.length);
	}, [snackbarFiles]);

	const removeFile = (fileId, isLessThanHundred, isCanceled) => {
		if (isLessThanHundred && !isCanceled) {
			cancelUploadRequest(fileId);
		} else {
			removeFileFromList(fileId);
		}
	};

	const removeAllFiles = () => {
		removeFiles();
		handleClose();
	};
	const renderTitle = () => {
		return (
			<>
				<strong className="semi">
					{Number(inProgressItem) > 0
						? localize(LocKeys.UPLOADING_ITEMS, [inProgressItem])
						: localize(LocKeys.UPLOADED_ITEMS)}
				</strong>

				<button
					onClick={() => removeAllFiles()}
					className="btn btn--stripped btn--icon btn--icon--after btn--icon--solo btn--icon--solo--xs  btn--close--white pos-abs"
				></button>
			</>
		);
	};

	const renderRemoveButton = (fileId, value, isCanceled) => {
		const tooltipContent =
			Number(value) < 100 && !isCanceled
				? localize(LocKeys.CANCEL_UPLOAD)
				: localize(LocKeys.REMOVE_FILE);
		return (
			<TooltipWithHtml tooltipContent={tooltipContent} tooltipPlacement="left">
				<button
					onClick={() => removeFile(fileId, Number(value) < 100, isCanceled)}
					className="btn btn--stripped btn--icon btn--icon--after btn--icon--solo btn--icon--solo--xs  btn--close"
				></button>
			</TooltipWithHtml>
		);
	};

	const renderStatus = (value, isCanceled) => {
		return Number(value) < 100 ? (
			isCanceled ? (
				<IconWrapper icon={<ErrorIcon iconClass="d-flex" />} />
			) : (
				<CustomCircularProgress value={value} />
			)
		) : (
			<IconWrapper icon={<CheckmarkIconCircle iconClass="d-flex" />} />
		);
	};

	return (
		<CustomAccordion
			wrapperClasses="upload-files"
			initialValue={true}
			titleContentComponent={renderTitle}
		>
			<div className="d-flex flex-column w-100">
				{snackbarFiles && snackbarFiles.size > 0 ? (
					snackbarFiles.valueSeq().map((file) => {
						const isRow = isHover && file.get("id") === key;
						const isCanceled =
							file.get("status") === fileStatusConstants.CANCELED;
						const fileName = file.get("name").split("/").pop().split(".");
						return (
							<div
								key={file.get("id")}
								onMouseEnter={() =>
									setRow({ isHover: true, key: file.get("id") })
								}
								onMouseLeave={() => setRow({ isHover: false, key: null })}
								className={`py-20  px-regular ${isRow ? "bg-n100" : ""} `}
							>
								<div className="row row--xs-gutters">
									<div className="col col-60 align-items-center d-flex">
										<FileIcon iconClass="mr-m" />
										<TooltipWithHtml
											tooltipContent={file.get("name")}
											tooltipPlacement="top-start"
											tooltiptextClasses="text-overflow-ellipsis  max-w-340p"
										>
											<h4 className="text-overflow-ellipsis  max-w-170p">
												{fileName[0]}
												<span className="color-n300">.{fileName[1]}</span>
											</h4>
										</TooltipWithHtml>
									</div>
									<div className="col col-25 align-items-center justify-content-end">
										{convertToMBorGB(file.get("size"))}
									</div>
									<div
										className={`col col-15 align-items-center justify-content-end `}
									>
										{isRow
											? renderRemoveButton(
													file.get("id"),
													file.get("value"),
													isCanceled
												)
											: renderStatus(file.get("value"), isCanceled)}
									</div>
								</div>
							</div>
						);
					})
				) : (
					<div className="w-100 d-flex align-items-center justify-content-center w-24p h-24p py-20">
						<span className="loading-icon wh-24p mx-auto"></span>
					</div>
				)}
			</div>
		</CustomAccordion>
	);
};

const mapStateToProps = (state) => {
	return {
		snackbarFiles: state.getIn(["globalWidget", "files"]),
	};
};

export default connect(mapStateToProps, {
	removeFileFromList,
	cancelUploadRequest,
	removeFiles,
})(UploadFile);
