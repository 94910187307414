const tempAdministrationConstants = {
	SET_TEMP_DISCIPLINES: "SET_DISCIPLINES",
	UPDATE_TEMP_DISCIPLINES: "UPDATE_DISCIPLINES",
	ADD_TEMP_DISCIPLINE: "ADD_DISCIPLINE",
	ADD_TEMP_QA_MANUAL: "ADD_TEMP_QA_MANUAL",
	REMOVE_TEMP_DISCIPLINE: "REMOVE_DISCIPLINE",
	REMOVE_TEMP_LOD: "REMOVE_LOD",
	ADD_TEMP_LOD: "ADD_LOD",
	UPDATE_TEMP_LOD: "UPDATE_TEMP_LOD",
	SET_TEMP_LODS: "SET_TEMP_LODS",
	SET_TEMP_BUILDING_COMPONENTS: "SET_BUILDING_COMPONENTS",
	UPDATE_TEMP_BUILDING_COMPONENTS: "UPDATE_BUILDING_COMPONENTS",
	ADD_TEMP_BUILDING_COMPONENTS: "ADD_BUILDING_COMPONENTS",
	REMOVE_TEMP_BUILDING_COMPONENTS: "REMOVE_BUILDING_COMPONENTS",
	SET_TEMP_BUILDING_CATEGORIES: "SET_TEMP_BUILDING_CATEGORIES",
	ADD_TEMP_BUILDING_CATEGORIES: "ADD_TEMP_BUILDING_CATEGORIES",
	REMOVE_TEMP_BUILDING_CATEGORIES: "REMOVE_TEMP_BUILDING_CATEGORIES",
	UPDATE_TEMP_BUILDING_CATEGORIES: "UPDATE_TEMP_BUILDING_CATEGORIES",
	SET_TEMP_MODELING_DATA: "SET_TEMP_MODELING_DATA",
	SET_TEMP_BUILDING_TYPES: "SET_TEMP_BUILDING_TYPES",
	ADD_TEMP_BUILDING_TYPES: "ADD_TEMP_BUILDING_TYPES",
	REMOVE_TEMP_BUILDING_TYPES: "REMOVE_TEMP_BUILDING_TYPES",
	UPDATE_TEMP_BUILDING_TYPES: "UPDATE_TEMP_BUILDING_TYPES",
	UPDATE_TEMP_DISCIPLINE_ORDER: "UPDATE_TEMP_DISCIPLINE_ORDER",
	SET_TEMP_LOD_ADJUSTMENT: "SET_TEMP_LOD_ADJUSTMENT",
	SET_TEMP_BUILDING_CATEGORY_ADJUSTMENT:
		"SET_TEMP_BUILDING_CATEGORY_ADJUSTMENT",
	UPDATE_TEMP_MODELING_DATA: "UPDATE_TEMP_MODELING_DATA",
	REMOVE_TEMP_MODELING_DATA: "REMOVE_TEMP_MODELING_DATA",
	ADD_TEMP_MODELING_DATA: "ADD_TEMP_MODELING_DATA",
	GET_TEMP_INPUT_FORMATS: "GET_TEMP_INPUT_FORMATS",
	ADD_TEMP_LOD_ADJUSTMENT: "ADD_TEMP_LOD_ADJUSTMENT",
	UPDATE_TEMP_LOD_ADJUSTMENT: "UPDATE_TEMP_LOD_ADJUSTMENT",
	REMOVE_TEMP_LOD_ADJUSTMENT: "REMOVE_TEMP_LOD_ADJUSTMENT",
	ADD_TEMP_BUILDING_CATEGORY_ADJUSTMENT:
		"ADD_TEMP_BUILDING_CATEGORY_ADJUSTMENT",
	UPDATE_TEMP_BUILDING_CATEGORY_ADJUSTMENT:
		"UPDATE_TEMP_BUILDING_CATEGORY_ADJUSTMENT",
	REMOVE_TEMP_BUILDING_CATEGORY_ADJUSTMENT:
		"REMOVE_TEMP_BUILDING_CATEGORY_ADJUSTMENT",
	SET_TEMP_OTHERS: "SET_TEMP_OTHERS",
	REMOVE_TEMP_OTHERS: "REMOVE_TEMP_OTHERS",
	ADD_TEMP_OTHERS: "ADD_TEMP_OTHERS",
	UPDATE_TEMP_OTHERS: "UPDATE_TEMP_OTHERS",
	SET_TEMP_LOD_CONTENT: "SET_TEMP_LOD_CONTENT",
	UPDATE_TEMP_LOD_CONTENT_SUMMARY: "UPDATE_TEMP_LOD_CONTENT_SUMMARY",
	ADD_TEMP_NORMALISATION_MANUAL: "ADD_TEMP_NORMALISATION_MANUAL",
	UPDATE_TEMP_NORMALISATION_MANUAL: "UPDATE_TEMP_NORMALISATION_MANUAL",
	ADD_TEMP_CUSTOMISATION_MANUAL: "ADD_TEMP_CUSTOMISATION_MANUAL",
	UPDATE_TEMP_CUSTOMISATION_MANUAL: "UPDATE_TEMP_CUSTOMISATION_MANUAL",
	SET_TEMP_QA_MANUAL: "SET_TEMP_QA_MANUAL",
	UPDATE_TEMP_QA_MANUAL: "UPDATE_TEMP_QA_MANUAL",
	SET_TEMP_NORMALISATION_MANUAL: "SET_TEMP_NORMALISATION_MANUAL",
	SET_TEMP_CUSTOMISATION_MANUAL: "SET_TEMP_CUSTOMISATION_MANUAL",
	UPDATE_TEMP_LOD_SORT_ORDER: "UPDATE_TEMP_LOD_SORT_ORDER",
	UPDATE_TEMP_BUILDING_COMPONENTS_SORT_ORDER:
		"UPDATE_TEMP_BUILDING_COMPONENTS_SORT_ORDER",
	UPDATE_TEMP_BUILDING_CATEGORIES_SORT_ORDER:
		"UPDATE_TEMP_BUILDING_CATEGORIES_SORT_ORDER",
	UPDATE_TEMP_BUILDING_TYPES_SORT_ORDER:
		"UPDATE_TEMP_BUILDING_TYPES_SORT_ORDER",
	SET_MANUAL_LOADER: "SET_MANUAL_LOADER",
	CLEAR_TEMP_MANUALS: "CLEAR_TEMP_MANUALS",
	SET_TERMS_AND_CONDITION: "SET_TERMS_AND_CONDITION",
	REMOVE_TEMP_REPETITIVENESS_ADJUSTMENT:
		"REMOVE_TEMP_REPETITIVENESS_ADJUSTMENT",
	UPDATE_TEMP_REPETITIVENESS_ADJUSTMENT:
		"UPDATE_TEMP_REPETITIVENESS_ADJUSTMENT",
	ADD_TEMP_REPETITIVENESS_ADJUSTMENT: "ADD_TEMP_REPETITIVENESS_ADJUSTMENT",
	SET_TEMP_REPETITIVENESS_ADJUSTMENT: "SET_TEMP_REPETITIVENESS_ADJUSTMENT",
	SET_SPECIFICATION_CATEGORIES: "SET_SPECIFICATION_CATEGORIES",
	SET_SPECIFICATION_PARAMETERS: "SET_SPECIFICATION_PARAMETERS",
	ADD_SPECIFICATION_CATEGORIES: "ADD_SPECIFICATION_CATEGORIES",
	ADD_SPECIFICATION_PARAMETERS: "ADD_SPECIFICATION_PARAMETERS",
	UPDATE_SPECIFICATION_CATEGORIES: "UPDATE_SPECIFICATION_CATEGORIES",
	REMOVE_SPECIFICATION_CATEGORIES: "REMOVE_SPECIFICATION_CATEGORIES",
	UPDATE_SPECIFICATION_PARAMETERS: "UPDATE_SPECIFICATION_PARAMETERS",
	REMOVE_SPECIFICATION_PARAMETERS: "REMOVE_SPECIFICATION_PARAMETERS",
	ADD_NEW_TEMP_PARAMETER: "ADD_NEW_TEMP_PARAMETER",
	RESET_LOD_PARAMETER_CATEGORIES: "RESET_LOD_PARAMETER_CATEGORIES",
	UPDATE_LOD_PARAMETER_CATEGORIES: "UPDATE_LOD_PARAMETER_CATEGORIES",
	SET_CONTENT_IMAGE_TEMP_FILE: "SET_CONTENT_IMAGE_TEMP_FILE",
	DELETE_CONTENT_IMAGE_TEMP_FILE: "DELETE_CONTENT_IMAGE_TEMP_FILE",
	RESET_TEMP_LOD_CONTENT: "RESET_TEMP_LOD_CONTENT",
	RESET_LOD_CONTENT: "RESET_LOD_CONTENT",
	CLEAR_COMPONENT_CATEGORY_CONTENT: "CLEAR_COMPONENT_CATEGORY_CONTENT",
	REMOVE_TEMP_PARAMETER: "REMOVE_TEMP_PARAMETER",
	SORT_SPECIFICATION_CATEGORIES: "SORT_SPECIFICATION_CATEGORIES",
	SORT_SPECIFICATION_PARAMETERS: "SORT_SPECIFICATION_PARAMETERS",
	SORT_LOD_PARAMETER_CATEGORIES: "SORT_LOD_PARAMETER_CATEGORIES",
	UPDATE_TEMP_CUSTOMISATION_TIME_BY_INPUT_FORMAT:
		"UPDATE_TEMP_CUSTOMISATION_TIME_BY_INPUT_FORMAT",
	RESET_TEMP_CUSTOMISATION_TIME_BY_INPUT_FORMAT:
		"RESET_TEMP_CUSTOMISATION_TIME_BY_INPUT_FORMAT",
	UPDATE_TEMP_EXPORT_ADJUSTMENT_INPUT_FORMAT:
		"UPDATE_TEMP_EXPORT_ADJUSTMENT_INPUT_FORMAT",
	RESET_TEMP_EXPORT_ADJUSTMENT_INPUT_FORMAT:
		"RESET_TEMP_EXPORT_ADJUSTMENT_INPUT_FORMAT",
	UPDATE_TEMP_SERVICES_ADJUSTMENT_INPUT_FORMAT:
		"UPDATE_TEMP_SERVICES_ADJUSTMENT_INPUT_FORMAT",
	RESET_TEMP_SERVICES_ADJUSTMENT_INPUT_FORMAT:
		"RESET_TEMP_SERVICES_ADJUSTMENT_INPUT_FORMAT",

	ADD_DXF_SERVER_COMPONENT: "ADD_DXF_SERVER_COMPONENT",
	SET_DXF_SERVER_COMPONENTS: "SET_DXF_SERVER_COMPONENTS",
	REMOVE_DXF_SERVER_COMPONENT: "REMOVE_DXF_SERVER_COMPONENT",
	UPDATE_DXF_SERVER_COMPONENT: "UPDATE_DXF_SERVER_COMPONENT",
	RESET_DXF_SERVER_COMPONENT: "RESET_DXF_SERVER_COMPONENT",
	SET_DXF_SERVER_COMPONENT_PARAMETERS: "SET_DXF_SERVER_COMPONENT_PARAMETERS",
	ADD_DXF_SERVER_COMPONENT_PARAMETER: "ADD_DXF_SERVER_COMPONENT_PARAMETER",
	UPDATE_DXF_SERVER_COMPONENT_PARAMETER:
		"UPDATE_DXF_SERVER_COMPONENT_PARAMETER",
	RESET_DXF_SERVER_COMPONENT_PARAMETER: "RESET_DXF_SERVER_COMPONENT_PARAMETER",
	CLEAR_TEMP_DXF_SERVER_COMPONENTS: "CLEAR_TEMP_DXF_SERVER_COMPONENTS",
	REMOVE_DXF_SERVER_COMPONENT_PARAMETER:
		"REMOVE_DXF_SERVER_COMPONENT_PARAMETER",

	SET_TEMP_REVIT_TEMPLATE_FILE: "SET_TEMP_REVIT_TEMPLATE_FILE",
	SET_TEMP_REVIT_TEMPLATE_FILE_DATA: "SET_TEMP_REVIT_TEMPLATE_FILE_DATA",
	REMOVE_TEMP_REVIT_TEMPLATE_FILE: "REMOVE_TEMP_REVIT_TEMPLATE_FILE",
	SET_TEMP_REVIT_TEMPLATE_FAMILY_MAPPING:
		"SET_TEMP_REVIT_TEMPLATE_FAMILY_MAPPING",
	ADD_TEMP_REVIT_TEMPLATE_FAMILY_MAPPING:
		"ADD_TEMP_REVIT_TEMPLATE_FAMILY_MAPPING",
	UPDATE_TEMP_REVIT_TEMPLATE_FAMILY_MAPPING:
		"UPDATE_TEMP_REVIT_TEMPLATE_FAMILY_MAPPING",
	REMOVE_TEMP_REVIT_TEMPLATE_FAMILY_MAPPING:
		"REMOVE_TEMP_REVIT_TEMPLATE_FAMILY_MAPPING",
};

export default tempAdministrationConstants;
