import { api } from "../api";
import buildingConstants from "../constants/buildingConstants";
import { snackbarTypeConstants } from "../constants/constants";
import fileConstants from "../constants/fileConstants";
import { jobConstants } from "../constants/jobConstants";
import localize, { LocKeys } from "../constants/localizations";
import orderConstants from "../constants/orderConstants";
import { download, multiDownload } from "../utils/download.util";
import { getToken } from "../utils/local-storage.util";
import { getPagination } from "../utils/table-filter";
import { closeSnackbar, openSnackbar } from "./globalWidgetActions";
import { getJobStatusFile } from "./jobActions";

/**
 * Download project file with pre-signed url
 * @param {object} data
 * @returns
 */
export const downloadFile = (data) => {
	return (dispatch) => {
		const requestOptions = {
			method: "POST",
			url: "/file/download",
			headers: {
				Authorization: "Bearer " + getToken(),
				"Content-Type": "application/json",
			},
			data,
		};
		return api(requestOptions).then(
			(res) => {
				const href = res.data.result;
				download(href);
			},
			(err) => {
				dispatch(actionSetErrorMessage(err.response.data.message));
			}
		);
	};
};

/**
 * Download files with pre-signed urls
 *
 * @param {array} data - array of file paths
 * @param {string} zipName - name of the zip file
 * @returns
 */
export const downloadFiles = (data, zipName = null) => {
	const zipNameUrl = zipName
		? zipName?.toString()?.replace("/", "-")
		: `${new Date()}.zip`;
	return (dispatch) => {
		const requestOptions = {
			method: "POST",
			url: `/file/download-multiple-zip?zipName=${zipNameUrl}`,
			headers: {
				Authorization: "Bearer " + getToken(),
				"Content-Type": "application/json",
			},
			data,
		};
		dispatch(openSnackbar(snackbarTypeConstants.DOWNLOAD_ZIP));

		return api(requestOptions).then(
			(res) => {
				const links = res.data.result;
				multiDownload(links);
				dispatch(closeSnackbar());

				// const reader = new FileReader(res.data);
				// console.log({ reader });
				// console.log("typeof data: ", typeof res.data);
				// const myblob = new Blob([res.data]);
				// console.log({ myblob });
				// const link = document.createElement("a");
				// link.href = URL.createObjectURL(myblob);
				// link.setAttribute("download", zipName);
				// document.body.appendChild(link);
				// link.click();

				// const url = URL.createObjectURL(new Blob([res.data]));
			},
			(err) => {
				dispatch(actionSetErrorMessage(err.response.data.message));
			}
		);
	};
};
// .then(res => res.blob())
//   .then(blob => {
//     const link = document.createElement("a");
//     link.href = URL.createObjectURL(blob);
//     link.setAttribute("download", "file.pdf");
//     document.body.appendChild(link);
//     link.click();
//   })
/**
 * Delete file
 *
 * SUCCESS: 'getFiles' endpoint
 *
 * @param {number} fileId file id
 * @param {string} fileName file name
 * @param {number} projectId project id
 * @param {number} buildingId building id
 * @param {number} disciplineId discipline id
 * @param {number} jobId job id
 * @param {number} limit
 * @returns
 */
export const deleteFile = ({
	fileId,
	fileName = "",
	projectId = null,
	buildingId = null,
	disciplineId = null,
	jobId = null,
	limit = 8,
	cbSuccess = () => {},
}) => {
	return (dispatch) => {
		const requestOptions = {
			method: "DELETE",
			url: `/file/${fileId}`,
			headers: {
				"Content-Type": "application/json",
				Accept: "*/*",
				Authorization: "Bearer " + getToken(),
			},
		};
		return api(requestOptions).then(
			(res) => {
				const successMessage = localize(LocKeys.DELETE_SUCCESS_MESSAGE, [
					fileName,
				]);
				fileName && dispatch(actionSetSuccessMessage(successMessage));
				if (projectId) {
					dispatch(getFiles({ orderId: projectId, limit }));
				}
				if (buildingId && disciplineId) {
					dispatch(getFiles({ buildingId, disciplineId, limit }));
				}
				if (jobId) {
					dispatch(getFiles({ jobId, limit }));
				}
				cbSuccess();
			},
			(err) => {
				const errorMessage = localize(LocKeys.DELETE_FILE_ERROR_MESSAGE);
				dispatch(actionSetErrorMessage(errorMessage));
			}
		);
	};
};

/**
 * Delete files
 *
 * SUCCESS: 'getFiles' endpoint
 *
 * @param {number} data
 * @param {number} projectId project id
 * @param {number} buildingId building id
 * @param {number} disciplineId discipline id
 * @param {number} jobId job id
 * @param {number} limit
 * @returns
 */

export const deleteFiles = ({
	data,
	projectId = null,
	buildingId = null,
	disciplineId = null,
	jobId = null,
	limit = 8,
	cbSuccess = null,
}) => {
	return (dispatch) => {
		const requestOptions = {
			method: "PUT",
			url: "/file/delete-multiple",
			headers: {
				"Content-Type": "application/json",
				Accept: "*/*",
				Authorization: "Bearer " + getToken(),
			},
			data,
		};
		return api(requestOptions).then(
			(res) => {
				const numOfFiles = data.length;
				const firstFile = data[0];
				const singleFileSuccessMessage = localize(
					LocKeys.DELETE_SUCCESS_MESSAGE,
					[firstFile.name]
				);
				const multiFilesSuccessMessage = localize(
					LocKeys.DELETE_SUCCESS_MESSAGE,
					[localize(LocKeys.FILES)]
				);
				const successMessage =
					numOfFiles > 1 ? multiFilesSuccessMessage : singleFileSuccessMessage;
				!cbSuccess && dispatch(actionSetSuccessMessage(successMessage));
				if (projectId) {
					dispatch(getFiles({ orderId: projectId, limit }));
				}
				if (buildingId && disciplineId) {
					dispatch(getFiles({ buildingId, disciplineId, limit }));
				}
				if (jobId) {
					dispatch(getFiles({ jobId, limit }));
				}
				cbSuccess && cbSuccess();
			},
			(err) => {
				const errorMessage = localize(LocKeys.DELETE_FILE_ERROR_MESSAGE);
				dispatch(actionSetErrorMessage(errorMessage));
			}
		);
	};
};

/**
 * Retrieves all files
 *
 *  SUCCESS:
 * 		1. Populates 'tempFilesList' & 'filesList' for disciplineId in the building state.
 * 		2. Populates  'filesList' for orderId in the project state.
 * 		3. Populates  'jobFilesList' for jobId in the job state.
 *
 * @param {*} orderId - get files for offer
 * @param {*} jobId - get files for job
 * @param {*} disciplineId - get files for discipline
 * @param {*} usePagination
 * @param {*} searchQuery
 * @param {*} page
 * @param {*} sortBy
 * @param {*} limit
 * @returns
 */
export const getFiles = ({
	orderId = null,
	buildingId = null,
	disciplineId = null,
	jobId = null,
	jobStatusId = null,
	usePagination = true,
	extensions = false,
	searchQuery = "",
	page = 1,
	limit = 8,
	showNormalised = false,
	isForAllFiles = false,
}) => {
	return (dispatch, getState) => {
		const state = getState();

		const tablePagination = state.getIn(["form", "tablePagination", "values"]);

		const { currentPage, currentLimit } = getPagination({
			tablePagination,
			page,
			limit,
		});

		const requestOptions = {
			method: "PUT",
			url: `/file/get-all`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
			data: {
				orderId,
				buildingId,
				disciplineId,
				jobId,
				jobStatusId,
				usePagination,
				page: currentPage,
				limit: currentLimit,
				sortBy: null,
				search: searchQuery,
				extensions: extensions,
				showNormalised,
			},
		};
		return api(requestOptions).then(
			(res) => {
				if (disciplineId) {
					if (!!isForAllFiles) {
						dispatch({
							type: buildingConstants.GET_ALL_BUILDING_DISCIPLINE_FILES,
							data: { files: res.data, disciplineId, buildingId },
						});
					} else {
						dispatch({
							type: buildingConstants.GET_DISCIPLINE_FILES,
							data: { files: res.data, disciplineId, buildingId },
						});
					}
				}
				if (orderId) {
					dispatch({
						type: orderConstants.GET_PROJECT_FILES,
						data: res.data,
					});
				}
				if (jobId) {
					dispatch({
						type: jobConstants.GET_JOB_FILES,
						data: res.data,
					});
				}
			},
			(err) => {
				dispatch(actionSetErrorMessage(err.response.data.message));
			}
		);
	};
};

/**
 * SET file details in database
 * @param {object} data
 * @returns
 */
export const setFileDetails = (data, onUploadProgress = () => {}) => {
	return (dispatch) => {
		const requestOptions = {
			method: "POST",
			url: "/file/details",
			headers: {
				"Content-Type": "application/json",
				Accept: "*/*",
				Authorization: "Bearer " + getToken(),
			},
			onUploadProgress: () => onUploadProgress({ uploadDone: true }),
			data,
		};
		return api(requestOptions).catch((err) => {
			console.log({ err });
		});
	};
};

/**
 * Preview file for Forge or Lightbox Viewer
 * @param {*} data { buildingId, disciplineId, fileId, jobId, jobStatusId }
 * @returns
 */
export const filePreview = (data, isForJob = false, cb = () => {}) => {
	return (dispatch, getState) => {
		const requestOptions = {
			method: "PUT",
			url: `/file/preview`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
			data,
		};
		return api(requestOptions).then(
			(res) => {
				const { buildingId, disciplineId, jobId, jobStatusId } = data;

				if (isForJob) {
					dispatch(getJobStatusFile({ jobId, jobStatusId }));
				} else if (disciplineId && buildingId) {
					const state = getState();

					const currentPage = state.getIn([
						"form",
						"tablePagination",
						"values",
						"currentPage",
					]);

					dispatch(
						getFiles({
							buildingId,
							disciplineId,
							page: currentPage,
							limit: 7,
						})
					);
				}
				cb();
			},
			(err) => {
				return err.response.data.message;
			}
		);
	};
};

/**
 * View file public link
 * @param {number} id - unique file id
 * @returns
 */
export const fileView = (id, cb = () => {}) => {
	return (dispatch) => {
		const requestOptions = {
			method: "GET",
			url: `/file-view/${id}`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
		};
		return api(requestOptions).then(
			(res) => {
				cb({ urlLink: res.data?.result });
			},
			(err) => {
				//return err.response.data.message;
			}
		);
	};
};

/**
 * Send files to email
 *
 * @param {Obj} files - array of files to send
 * @param {Array} emails - array of emails to send files to
 * @param {Function} cb - callback function
 * @returns
 */

/**
 *
 * Send files to email
 *
 * @param {Object}  data - contains files, emails, zipName, buildingId, zipActive
 * @param {Array}   data.files - array of files to send
 * @param {Array}   data.recipients - array of emails to send files to
 * @param {string}  data.zipName - name of the zip file
 * @param {number}  data.buildingId - unique building id
 * @param {number}  data.buildingName - name of the building
 * @param {boolean} data.zipActive - true/false
 * @param {Function} cb - callback function
 *
 * @returns callback function
 */
export const sendFilesToEmail = (data, cb = () => {}) => {
	return (dispatch) => {
		const requestOptions = {
			method: "PUT",
			url: `/file/send-to-email`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
			data,
		};
		return api(requestOptions).then(
			(res) => {
				cb();
				const { recipients = [], buildingName = "" } = data;
				let recipientsString = ``;

				recipients.forEach((recipient, index) => {
					const isLast = index === data?.recipients.length - 1;
					recipientsString += `${recipient}${isLast ? "." : ",\n"}`;
				});

				dispatch(
					actionSetSuccessMessage(
						localize(LocKeys.SEND_BUILDING_FILE_SUCCESS, [
							buildingName,
							recipientsString,
						])
					)
				);
			},
			(err) => {
				return err.response.data.message;
			}
		);
	};
};

/**
 * Update temp file preview
 *
 * @param {number} buildingId - unique building id
 * @param {number} disciplineId - unique discipline id
 * @param {number} fileId - unique file id
 *
 */
export const updateTempFilePreview = ({ buildingId, disciplineId, fileId }) => {
	return {
		type: buildingConstants.UPDATE_TEMP_FILE_PREVIEW,
		data: { buildingId, disciplineId, fileId },
	};
};

/**
 * Set selected files
 * @param {array} files
 * @returns
 */
export const setSelectedFiles = (files) => {
	return { type: fileConstants.SET_SELECTED_FILES, files };
};

/**
 * Clear both success and error messages for file delete actions
 * @returns
 */
export const clearFileRequestState = () => {
	return { type: fileConstants.CLEAR_FILE_REQUEST_STATE };
};

/**
 * Set success message on file delete action
 * @param {string} message
 * @returns
 */
export const actionSetSuccessMessage = (message) => {
	return { type: fileConstants.FILE_ACTION_SUCCESS, message };
};

/**
 * Set error message on file delete action
 * @param {string} message
 * @returns
 */
export const actionSetErrorMessage = (message) => {
	return { type: fileConstants.FILE_ACTION_FAILURE, message };
};
