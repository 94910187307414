import React, { useCallback, useEffect } from "react";
import { Field, reduxForm } from "redux-form/immutable";
import { connect } from "react-redux";
import CustomAutocomplete from "../fields/CustomAutocomplete";
import { isBimify } from "../../utils/permission/user";
import { getChatCompanies, setCustomer } from "../../actions";
import localize, { LocKeys } from "../../constants/localizations";

/**
 * Global company component for global customer selection
 * @param {*} props
 * @returns
 */
const GlobalCompany = (props) => {
	const {
		initialValues,
		customer,
		//Actions
		getChatCompanies,
		setCustomer,
	} = props;

	useEffect(() => {
		getChatCompanies();
	}, [getChatCompanies]);

	const setValue = useCallback(
		(value) => {
			setCustomer(value);
		},
		[setCustomer]
	);

	useEffect(() => {
		setValue(customer);
	}, [customer, setValue]);

	let companyOptions = [];
	if (props.companies && props.companies.size > 0) {
		props.companies.map((company) => {
			const value = company.get("c_id");
			const label = company.get("c_name");

			return companyOptions.push({
				value,
				label,
			});
		});
	}

	const renderCustomOption = useCallback((props, option) => {
		const { label } = option;

		return (
			<li {...props}>
				<div className="d-flex align-items-center">{label}</div>
			</li>
		);
	}, []);

	return (
		<form
			className={`form d-flex flex-column flex-auto standardized-form	`}
			autoComplete="off"
			noValidate
		>
			<div className="d-flex align-items-center ">
				<div className="w-100">
					<Field
						name="customer"
						id="customer"
						disableClearable={!isBimify()}
						component={CustomAutocomplete}
						placeholder={localize(LocKeys.SELECT_CUSTOMER) + "..."}
						showPlaceholder={true}
						initialValue={initialValues?.get("customer") || null}
						options={companyOptions}
						customOnChange={(value) => setValue(value)}
						disabled={!isBimify()}
						customRenderOption={(props, option) =>
							renderCustomOption(props, option)
						}
					/>
				</div>
			</div>
		</form>
	);
};

const mapStateToProps = (state) => {
	return {
		companies: state.getIn(["company", "chatCompanies"]),
		customer: state.getIn(["global", "customer"]),

		initialValues: {
			customer: state.getIn(["global", "customer"]),
		},
	};
};

export default connect(mapStateToProps, {
	getChatCompanies,
	setCustomer,
})(
	reduxForm({
		form: "globalCompanyForm",
		destroyOnUnmount: true,
		touchOnBlur: false,
	})(GlobalCompany)
);
