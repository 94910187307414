import React from "react";

export default (props) => {
	return (
		<div className={`icon ${props.iconClass ? props.iconClass : ""}`}>
			<svg
				width="32"
				height="32"
				viewBox="0 0 32 32"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M18.666 8.00033V5.33366H13.3327V8.00033H18.666ZM5.33268 10.667V25.3337H26.666V10.667H5.33268ZM26.666 8.00033C28.146 8.00033 29.3327 9.18699 29.3327 10.667V25.3337C29.3327 26.8137 28.146 28.0003 26.666 28.0003H5.33268C3.85268 28.0003 2.66602 26.8137 2.66602 25.3337L2.67935 10.667C2.67935 9.18699 3.85268 8.00033 5.33268 8.00033H10.666V5.33366C10.666 3.85366 11.8527 2.66699 13.3327 2.66699H18.666C20.146 2.66699 21.3327 3.85366 21.3327 5.33366V8.00033H26.666Z"
					fill="#1b2024"
				/>
			</svg>
		</div>
	);
};
