const userConstants = {
	USER_ACTION_GET_FAILURE: "USER_ACTION_GET_FAILURE",
	USER_ACTION_GET_SUCCESS: "USER_ACTION_GET_SUCCESS",
	USER_CLEAR_REQUEST_STATE: "CLEAR_REQUEST_STATE",
	GET_USER_ROLES: "GET_USER_ROLES",
	U_REQUEST_DONE: "U_REQUEST_DONE",
	GET_USER_COMPANIES: "GET_USER_COMPANIES",
	GET_USERS: "GET_USERS",
	GET_USER: "GET_USER",
	ACTION_ACTIVATION: "ACTION_ACTIVATION",
	SET_USER_IMAGE: "SET_USER_IMAGE",
	CLEAR_CURRENT_USER: "CLEAR_CURRENT_USER",
	SET_TEMP_USER: "SET_TEMP_USER",
	SET_VERIFY_CODE: "SET_VERIFY_CODE",
	RESET_VERIFY_CODE: "RESET_VERIFY_CODE",
	SET_USER: "SET_USER",
	RESET_AUTHENTICATION_CODE: "RESET_AUTHENTICATION_CODE",
	SET_AUTHENTICATION_CODE: "SET_AUTHENTICATION_CODE",
	GET_USERS_BY_LOGGED_USER: "GET_USERS_BY_LOGGED_USER",
};

export default userConstants;
