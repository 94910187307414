import React, { useCallback, useEffect, useMemo, useState } from "react";
import FormSubmitButtons from "../../../../../shared/components/FormComponents/FormSubmitButtons";
import FooterLayout from "../../../../layout/content/FooterLayout";
import localize, { LocKeys } from "../../../../../constants/localizations";
import HeaderLayout from "../../../../layout/content/HeaderLayout";
import { reduxForm } from "redux-form/immutable";
import { connect } from "react-redux";
import TemplateHeader from "./parts/TemplateHeader";
import TemplateFile from "./parts/TemplateFile";
import LinearProgressWithLabel from "../../../../../shared/components/linear-progress-with-label/LinearProgressWithLabel";
import IconWrapper from "../../../../../shared/icons/IconWrapper";
import ErrorIcon from "../../../../../shared/icons/misc/ErrorIcon";
import SuccessIcon from "../../../../../shared/icons/misc/SuccessIcon";
import {
	getPresignedPostUploadUrl,
	getUserCompanies,
	uploadToDigitalOcean,
} from "../../../../../actions";
import { uploadFile } from "../../../../../shared/components/UploadFile/uploadFile";
import { tokenParams } from "../../../../../utils/local-storage.util";
import { socket } from "../../../../../utils/web-socket";
import { useLocation, useParams } from "react-router-dom-v5-compat";

const RevitTemplateForm = (props) => {
	const { companyId } = useParams();
	const {
		//Own props
		editView = false,
		onSubmit,
		onCancel,

		//State props
		file,
		fileURL,
		tempRevitTemplate,
		initialValues,

		//Actions
		getPresignedPostUploadUrl,
		uploadToDigitalOcean,
		getUserCompanies,
	} = props;
	const { pathname } = useLocation();

	const isCustomerView =
		pathname && pathname.toString().startsWith("/customers");
	let isSettingsView =
		pathname &&
		(pathname.toString().startsWith("/settings") ||
			pathname.toString().startsWith("/customers"));
	const loggedUserCompanyId = tokenParams()?.company?.id;
	const [progress, setProgress] = useState({
		success: false,
		error: false,
		inProgress: false,
		templateReading: false,
		progressMessage: "",
		progressWidth: 0,
		revitFileId: null,
		cb: null,
	});

	useEffect(() => {
		getUserCompanies();
	}, [getUserCompanies]);

	useEffect(() => {
		if (!!progress.revitFileId) {
			socket.connect();

			const listener = () => {
				setProgress({
					...progress,
					templateReading: false,
					revitFileId: null,
				});
				progress.cb && progress.cb();
			};

			socket.on(`rtp-completed-${progress.revitFileId}`, listener);

			return () => {
				socket.off(`rtp-completed-${progress.revitFileId}`, listener);
				socket.disconnect();
			};
		}
	}, [progress]);

	const fields = useMemo(() => {
		let options = ["name"];
		if (!isSettingsView) {
			options.push("customer");
		}
		return options;
	}, [isSettingsView]);

	const handleUploadFile = useCallback(
		(id, argCompanyId, cb = () => {}) => {
			try {
				const isFile = file instanceof File;

				if (file && isFile) {
					// if (fileId) {
					// 	deleteFile({ fileId });
					// }
					setProgress({
						...progress,
						inProgress: true,
						progressMessage: localize(LocKeys.UPLOADING_FILE),
						progressWidth: 1,
					});
					const dbFileData = {
						file: {
							name: file.name,
							size: file.size,
						},
						path: null,
						revitFileCompanyId: argCompanyId,
						revitFileId: id,
						isBimifyTemplate: true,
					};
					const preSignedPostUploadUrlBody = {
						fileName: file.name,
						isBimifyTemplate: true,
						revitFileCompanyId: argCompanyId,
						revitFileId: id,
					};
					uploadFile({
						dbFileData,
						file: file,
						preSignedPostUploadUrlBody,
						uploadToDigitalOcean,
						getPresignedPostUploadUrl,
						cbUploadProgress: (progressWidth) => {
							const isFinished = +progressWidth === 100;
							setProgress({
								...progress,
								inProgress: true,
								success: isFinished,
								progressMessage: localize(LocKeys.UPLOADING_FILE),
								progressWidth: progressWidth,
								revitFileId: id,
							});
						},
						cbDBUpload: () => {
							setProgress({
								...progress,
								inProgress: false,
								success: false,
								templateReading: true,
								revitFileId: id,
								cb,
							});
						},
					});
				} else {
					cb();
					// if (!fileURL && fileId) {
					// 	deleteFile({
					// 		fileId,
					// 		cbSuccess: () => {
					// 			cb();
					// 		},
					// 	});
					// } else {
					// 	cb();
					// }
				}
			} catch (error) {
				console.log({ error });
			}
		},
		[file, progress, uploadToDigitalOcean, getPresignedPostUploadUrl]
	);

	const onSubmitForm = useCallback(() => {
		try {
			const data = tempRevitTemplate?.toJS();

			const body = {
				name: data?.name,
				companyId: !!isSettingsView
					? isCustomerView
						? companyId
						: loggedUserCompanyId
					: data?.customer,
				isDefault: data?.isDefault,
			};

			onSubmit(body, (id, argCompanyId, cb) => {
				handleUploadFile(id, argCompanyId, cb);
			});

			// if (editView) {
			// 	console.log("update");

			// 	onSubmit(body, (id, companyId, cb) => {
			// 		handleUploadFile(id, companyId, cb);
			// 	});
			// } else {
			// 	onSubmit(body, (id, companyId, cb) => {
			// 		handleUploadFile(id, companyId, cb);
			// 	});
			// }
		} catch (error) {
			return;
		}
	}, [
		tempRevitTemplate,
		onSubmit,
		isSettingsView,
		isCustomerView,
		loggedUserCompanyId,
		companyId,
		handleUploadFile,
	]);

	const isValid = useMemo(() => {
		let hasInvalidValue = Object.keys(tempRevitTemplate.toJS()).some(
			(key) => fields.includes(key) && !tempRevitTemplate.get(key)
		);

		return hasInvalidValue || !fileURL;
	}, [fields, tempRevitTemplate, fileURL]);

	const renderProgressBar = useMemo(() => {
		if (progress.templateReading) {
			return (
				<div className="d-flex align-items-center">
					<span className="loading-icon"></span>
					<span className="ml-m">{localize(LocKeys.READING_TEMPLATE)}...</span>
				</div>
			);
		}
		if (progress.success) {
			return (
				<div className="d-flex align-items-center h-100">
					<IconWrapper
						wrapperClasses="mr-s"
						size="24"
						icon={<SuccessIcon iconClass="d-flex" />}
					/>

					{/* <div> {localize(LocKeys.MODELING_COMPLETED)}</div> */}
					<div>{localize(LocKeys.FILE_UPLOADED)}</div>
				</div>
			);
		}
		if (progress.error) {
			return (
				<div className="d-flex align-items-center h-100">
					<IconWrapper
						wrapperClasses="mr-s"
						size="24"
						icon={<ErrorIcon iconClass="d-flex" />}
					/>
					<div>
						{localize(LocKeys.MODELING_FAILED)}&nbsp;
						{progress.progressMessage
							? `Message: ${progress.progressMessage}`
							: ""}
					</div>
				</div>
			);
		}

		if (progress.inProgress) {
			return (
				<div className="w-100 color-grey-superdark">
					<div className="d-flex align-items-center mb-xs">
						<div>{Math.round(progress.progressWidth)}%</div>

						<div>
							&nbsp;-&nbsp;
							{progress.progressMessage}
						</div>
					</div>

					<div>
						<LinearProgressWithLabel value={progress.progressWidth} />
					</div>
				</div>
			);
		}

		return "";
	}, [progress]);

	return (
		<div className="page-content--large">
			<form
				className={`form d-flex flex-column flex-auto standardized-form card  ${
					editView
						? "bg-primary--lighter card--primary"
						: "card--border--none  card--2 bg-n000"
				}	`}
				onSubmit={props.handleSubmit(onSubmitForm)}
				autoComplete="off"
				noValidate
			>
				<HeaderLayout
					wrapperClasses={`d-flex align-items-center px-24 py-14  ${
						editView ? "border-primary" : ""
					}`}
				>
					<TemplateHeader
						initialValues={initialValues}
						isSettingsView={isSettingsView}
						editView={editView}
					/>
				</HeaderLayout>
				<div className="pt-regular px-24 page-content--medium overflow-y-auto">
					<div className="h-271p w-100 pos-rel">
						<TemplateFile />
						{renderProgressBar && (
							<div className="pt-24">
								<div className="w-25-5">{renderProgressBar}</div>
							</div>
						)}
					</div>
				</div>
				<FooterLayout
					wrapperClasses={`px-24 pt-24 ${editView ? "border-primary" : ""}`}
				>
					<FormSubmitButtons
						wrapperClasses="w-100"
						onCancel={() => onCancel(true)}
						onSubmit={props.handleSubmit(onSubmitForm)}
						cancelLabel={localize(LocKeys.CANCEL)}
						submitClassName={!editView ? "btn--success" : ""}
						submitLabel={
							editView ? localize(LocKeys.SAVE) : localize(LocKeys.CREATE)
						}
						loading={progress.inProgress || progress.templateReading}
						submitDisabled={
							isValid || progress.inProgress || progress.templateReading
						}
					/>
				</FooterLayout>
			</form>
		</div>
	);
};

const mapStateToProps = (state, ownProps) => {
	const { formName } = ownProps;
	const tempRevitTemplate = state.getIn(["template", "tempRevitTemplate"]);

	const initialValues = {
		name: tempRevitTemplate?.get("name") || "",
		customer: tempRevitTemplate?.get("customer") || null,
	};
	return {
		form: formName,
		tempRevitTemplate,
		initialValues,
		fileURL: state.getIn([
			"template",
			"tempRevitTemplate",
			"template",
			"fileData",
			"url",
		]),
		file: state.getIn(["template", "tempRevitTemplate", "template", "file"]),
		fileId: state.getIn(["template", "revitTemplate", "template", "id"]),
	};
};

export default connect(mapStateToProps, {
	getPresignedPostUploadUrl,
	uploadToDigitalOcean,
	getUserCompanies,
})(
	reduxForm({
		touchOnBlur: false,
		destroyOnUnmount: false,
		enableReinitialize: true,
	})(RevitTemplateForm)
);
